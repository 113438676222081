import { Component, Input } from '@angular/core';
import { ICourseTile } from '@portal-app/interfaces/course/project/course.interface';

@Component({
  selector: 'portal-course-card-preview[item]',
  templateUrl: './course-card-preview.component.html',
  styleUrls: ['./course-card-preview.component.scss'],
})
export class CourseCardPreviewComponent {
  @Input() item!: ICourseTile;
}
