import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { INextBook } from '@portal-app/interfaces/book/next/next-book';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppInitService } from '../shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class BookFavoriteService {
  private data = new ReplaySubject<INextBook[]>(1);
  data$ = this.data.asObservable();

  constructor(private api: ApiService, private userService: UserService, private appInitService: AppInitService) {}

  private loaded = false;

  initialize() {
    const subject = new ReplaySubject<boolean>(1);
    if (!this.loaded) {
      this.fetchAndUpdate().subscribe(
        (data) => {
          if (data) {
            subject.next(true);
            this.loaded = true;
          } else {
            subject.next(false);
          }
        },
        (error: HttpErrorResponse) => {
          subject.next(false);
        }
      );
    } else {
      subject.next(true);
    }
    return subject;
  }

  fetchAndUpdate() {
    const subject = new ReplaySubject<boolean>(1);
    if (this.userService.isLoggedIn && (this.appInitService.isSuperbog || this.appInitService.isSuperreader)) {
      this.api
        .getNext<INextBook[]>(
          `Book/GetFavoriteBooks?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}`
        )
        .subscribe(
          (data) => {
            subject.next(true);
            this.data.next(data || []);
          },
          (error: HttpErrorResponse) => {
            subject.next(false);
          }
        );
    } else {
      subject.next(true);
    }
    return subject;
  }

  addBookToFavorites(id: string) {
    return this.api.postNext<any>(`BookFavorite/AddBookToFavorites/${id}`).pipe(
      map((result: string) => {
        this.fetchAndUpdate();
        return result;
      })
    );
  }

  deleteBookFromFavorites(id: string) {
    return this.api.deleteNext<any>(`BookFavorite/DeleteBookFromFavorites/${id}`).pipe(
      map((result: string) => {
        this.fetchAndUpdate();
        return result;
      })
    );
  }
}
