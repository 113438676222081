import { Pipe, PipeTransform } from '@angular/core';
import { IUserPermissionWithSelected } from '../../modules/lounge/store/interfaces/team-edit.interface';

/**
 * Filter users that includes the searchTerm in their name
 */
@Pipe({
  name: 'searchUserPermissionWithSelectedList',
})
export class SearchUserPermissionWithSelectedListPipe implements PipeTransform {
  /**
   *
   * @param values List of users
   * @param searchTerm Query
   * @returns List of users with names that includes the query
   */
  transform(values: IUserPermissionWithSelected[], searchTerm: string): IUserPermissionWithSelected[] {
    if (!searchTerm) {
      return values;
    }

    return values.filter((x) => x.user.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
  }
}
