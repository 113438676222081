import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  share,
  Subject,
  tap,
} from 'rxjs';
import { UserService } from '../../../services/core/user/user.service';
import { ToastService } from '../../toast/services/toast.service';
import { LoungeModalPages } from '../enums/lounge-modal-pages.enum';
import { LoungeService } from './lounge.service';

/** Service for handling modals like page, active status and the height of the modal */
@Injectable({ providedIn: 'root' })
export class LoungeModalService {
  /** Property used for checking if the modal should be visible */
  modalActive = false;

  /** {@link BehaviorSubject} used for storing the current page that should be shown in the modal */
  private page = new BehaviorSubject<LoungeModalPages>(LoungeModalPages.none);

  /** Used for getting the current {@link LoungeModalPages} page of the team create / edit flow.
   * Also handles changing the modal height so it matches page and clearing students and teacher selections */
  page$ = this.page.pipe(
    distinctUntilChanged(),
    tap((page) => {
      this.modalHeight.next(this.pageToModalHeight(page));

      if (page === LoungeModalPages.none) {
        this.loungeService.removeStudentsFromSelection();
        this.loungeService.removeTeachersFromSelection();

        this.modalActive = false;
      } else {
        this.modalActive = true;
      }
    }),
    share({ resetOnRefCountZero: true })
  );

  /** {@link Subject} used for storing values for {@link modalHeight$} */
  private modalHeight = new Subject<string>();

  /** Used for getting the height for the modal. Calculated from {@link LoungeModalPages} */
  modalHeight$ = this.modalHeight.pipe(share({ resetOnRefCountZero: true }));

  /** Changes page. */
  async setPage(page: LoungeModalPages): Promise<void> {
    if (page === LoungeModalPages.removeTeacherSelf) {
      (await this.allowRemoveYourself()) ? this.page.next(page) : null;
    } else {
      this.page.next(page);
    }
  }

  /** Method for setting {@link page$} to {@link LoungeModalPages.none} */
  setPageToNone(): void {
    this.page.next(LoungeModalPages.none);
  }

  /** @ignore */
  constructor(
    private loungeService: LoungeService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  /** Converts a specific page to a string containing the specific height that is ideal for a modal window containing that specific page
   * @param page The page to convert to height
   * @returns String containing a height in pixels */
  private pageToModalHeight(page: LoungeModalPages): string {
    switch (page) {
      case LoungeModalPages.deleteTeam:
      case LoungeModalPages.removeTeacherSelf:
        return '250px';
      case LoungeModalPages.detailsInput:
        return '480px';
      default:
        return '900px';
    }
  }

  /** Checks if user is allowed to remove themself from the team. If user is forbidden we output a toast message explaining why.
   * @returns true if allowed, false if not */
  private async allowRemoveYourself(): Promise<boolean> {
    const [teamEdit, user] = await firstValueFrom(
      combineLatest([
        this.loungeService.teamEdit$.pipe(filter((x) => !!x)),
        this.userService.user$.pipe(filter((x) => !!x)),
      ])
    );

    if (!teamEdit) {
      this.toastService.displayMessage('Der er ikke noget hold i edit mode.');
      return false;
    }

    const userFound = teamEdit.teachers.find((t) => t.user.id === user?.userId);

    if (!userFound) {
      this.toastService.displayMessage('Du blev ikke fundet som lærer på holdet.');
      return false;
    }

    if (teamEdit.teachers.filter((t) => t.permission.canEditTeam).length < 2) {
      this.toastService.displayMessage(
        'Du kan ikke fjerne dig selv fra holdet, da det ikke er delt med en anden lærer.'
      );
      return false;
    }

    return true;
  }
}
