<div
  class="dropdown"
  [id]="id"
  [class.open]="dropdownOpen?.Id === id"
  [style.z-index]="dropdownOpen?.Id === id ? dropdownOpen?.ZIndex : ''"
>
  <button
    class="toggle-dropdown"
    [class]="buttonClasses"
    [attr.aria-label]="
      useCustomAriaLabel
        ? ariaLabel
        : ((dropdownOpen?.Id === id ? 'Dropdown.CloseBlankDropdown' : 'Dropdown.OpenBlankDropdown')
          | translate
            : {
                name: (ariaLabel ? ariaLabel : ('Dropdown.Type.NotSpecified' | translate)) | lowercase
              })
    "
    (click)="onToggleDropdown()"
    tabindex="0"
  >
    <ng-content select="[button]"></ng-content>
    <i
      *ngIf="useArrow"
      class="icon icon-{{ iconSize }}"
      [inlineSVG]="dropdownOpen?.Id === id ? 'chevron-up.svg' : 'chevron-down.svg'"
    ></i>
  </button>
  <div class="dropdown-content" [class]="contentClasses">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
