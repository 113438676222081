import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedLoadingKeys } from '.././../../../../services/loading/shared-loading-keys.enums';
import { LoungeModalService } from '../../../services/lounge-modal.service';
import { LoungeService } from '../../../services/lounge.service';
import { ITeamEdit } from '../../../store/interfaces/team-edit.interface';

/** A component for deleting a team */
@Component({
  selector: 'lru-team-edit-delete[teamEdit]',
  templateUrl: './team-edit-delete.component.html',
  styleUrls: ['./team-edit-delete.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditDeleteComponent {
  /** The team to delete */
  @Input() teamEdit!: ITeamEdit;

  /** @ignore */
  constructor(private loungeService: LoungeService, private loungeModalService: LoungeModalService) {}

  /** Function that asks {@link LoungeModalService} to set modal page to none.
   * @returns A function that is used for inputting to the header and called from there. */
  onClickClose(): Function {
    return () => this.loungeModalService.setPageToNone();
  }

  /** Asks {@link LoungeService} to delete the {@link teamEdit} input */
  onClickDelete(): void {
    this.loungeService.teamDelete(this.teamEdit.id, SharedLoadingKeys.teamDelete);
  }
}
