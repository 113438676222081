import { DomPortalOutlet } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, withLatestFrom } from 'rxjs/operators';
import { MouseKeyboardService } from '../../services/mouse-keyboard/mouse-keyboard.service';
import { DropdownComponent } from './components/dropdown/dropdown.component';

/** Handles all dropdowns and makes sure we're only displaying a single dropdown at a time.
 * Dropdowns are created using the <app-dropdown> component in any template and displayed using the <app--dropdown-output> component in the app component. */
@Injectable({
  providedIn: 'root',
})
export class DropdownService implements OnDestroy {
  /** Portal outlet used by the dropdowns */
  private outlet?: DomPortalOutlet;

  /** @see BehaviorSubject for {@link open$} */
  private open = new BehaviorSubject<DropdownComponent | undefined>(undefined);

  /** Observable that returns a {@link DropdownComponent} if dropdown is open and undefined if not */
  open$ = this.open.asObservable();

  /** @see BehaviorSubject for {@link zIndex$} */
  private zIndex = new BehaviorSubject(0);

  /** Observable that returns the zIndex for the dropdown  */
  zIndex$ = this.zIndex.pipe(distinctUntilChanged());

  /** Selector for elements that can be selected with arrow keys */
  validElements = '[tabindex="0"], a:not([tabindex="-1"]), button:not([tabindex="-1"])';

  /** listen for keyDown event */
  private keyDownSubscription = this.mouseKeyboardService.keyDown$
    .pipe(
      withLatestFrom(this.open$),
      filter(([_, open]) => open !== undefined),
      distinctUntilChanged(([prevEvent, _], [event, _2]) => prevEvent === event)
    )
    .subscribe(([event, _]) => {
      if (event.key === 'Escape') {
        this.closeDropdown();
      } else if (
        event.key === 'ArrowDown' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight'
      ) {
        event.preventDefault(); // needed so the browser window doesnt scroll
        const targetItems = this.outlet?.outletElement
          .querySelector('.dropdown-content')
          ?.querySelectorAll<HTMLElement>(this.validElements);

        if (targetItems) {
          let elementToSetFocusTo: HTMLElement | undefined = undefined;

          let next = event.key === 'ArrowDown' || event.key === 'ArrowRight';

          if (next) {
            elementToSetFocusTo = this.mouseKeyboardService.selectNext(Array.from(targetItems), true);
          } else {
            elementToSetFocusTo = this.mouseKeyboardService.selectPrev(Array.from(targetItems), true);
          }

          if (elementToSetFocusTo) {
            this.mouseKeyboardService.removeFocus();
            this.mouseKeyboardService.addHover(elementToSetFocusTo);
          }
        }
      }
    });

  /** listen for click/touch event */
  private clickOrTouchSubscription = this.mouseKeyboardService.clickOrTouch$
    .pipe(
      withLatestFrom(this.open$),
      filter(([_, open]) => open !== undefined),
      distinctUntilChanged(([prevEvent, _], [event, _2]) => prevEvent === event)
    )
    .subscribe(([event, _]) => {
      let target = event.target as HTMLElement;

      if (target === this.outlet?.outletElement) {
        this.closeDropdown();
      }
    });

  /** @ignore */
  constructor(private mouseKeyboardService: MouseKeyboardService) {}

  /** Setter for {@link zIndex$} */
  setZIndex(zindex: number): void {
    this.zIndex.next(zindex);
  }

  /** Sets the portal outlet which should be used by the dropdowns.
   * Should only be called from the <app-dropdown-outlet> component. */
  setPortalOutlet(outlet: DomPortalOutlet): void {
    this.outlet = outlet;
  }

  /** Hides the current dropdown if a dropdown is open. */
  async closeDropdown(): Promise<void> {
    const current = await firstValueFrom(this.open);

    if (current) {
      current.closeDropdown();
      this.open.next(undefined);
      this.detachCurrentDropdown();
    }
  }

  /** Stores the visibility of a dropdown in the service and reacts to it.
   * Should only be called from the <app-dropdown> component.
   *
   * If a dropdown is hidden, the next Open dropdown in the stack will be displayed.
   * If a dropdown is shown, the previously dropdown in the stack will be hidden, if one exists.
   *
   * Updates the overlay subject with true if any dropdown is Open or false if no dropdown is Open. */
  async setState(dropdown: DropdownComponent, open: boolean): Promise<void> {
    const current = await firstValueFrom(this.open);
    const attached = current === dropdown;

    if (current && !attached) {
      current.closeDropdown();
    }

    if (open && !attached) {
      this.attachDropdown(dropdown);
      this.open.next(dropdown);
    } else if (!open && current) {
      this.detachCurrentDropdown();
      this.open.next(undefined);
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    this.keyDownSubscription?.unsubscribe();
    this.clickOrTouchSubscription?.unsubscribe();
  }

  /** Attaches the given dropdown to the outlet and removes any old dropdown from the outlet. */
  private attachDropdown(dropdown: DropdownComponent): void {
    if (!this.outlet) {
      return;
    }

    this.detachCurrentDropdown();
    this.outlet.attach(dropdown.portal);
  }

  /** Detaches the current dropdown from the outlet. */
  private detachCurrentDropdown(): void {
    if (!this.outlet) {
      return;
    }

    this.outlet.detach();
  }

  /** Relays focus events from the <app-dropdown-outlet> component to the current <app-dropdown> component. */
  async handleFocus(event: FocusEvent): Promise<void> {
    const current = await firstValueFrom(this.open);

    if (current && event.target) {
      current.trapFocus(event.target);
    }
  }
}
