/** Enum for common shared loading keys used for starting and stopping loading */
export enum SharedLoadingKeys {
  fullLevel = 'fullLevel',
  teams = 'teams',
  dlpClasses = 'dlpClasses',
  teachers = 'teachers',
  teachersWithoutCurrentUser = 'teachersWithoutCurrentUser',
  teamStudents = 'teamStudents',
  createTeam = 'createTeam',
  editTeam = 'editTeam',
  saveDetailsToTeam = 'saveDetailsToTeam',
  teamDelete = 'teamDelete',
  teamSaveTeachersToTeam = 'teamSaveTeachersToTeam',
  teamSaveStudentsToTeam = 'teamSaveStudentsToTeam',
  teamRemoveTeacherSelf = 'teamRemoveTeacherSelf',
}
