import { DomPortalOutlet } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { getLastItemFromArray, removeFromArray } from '../../utils/array';
import { ModalComponent } from './components/modal/modal.component';

/** Handles all modals and makes sure we're only displaying a single modal at a time.
 * Modals are created using the <lru-modal> component in any template and displayed using the <lru-modal-outlet> component in the app component. */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  /** A Portal Outlet for attaching the modal components to */
  private outlet?: DomPortalOutlet;

  /** Property for storing the modal to attach to the outlet when it is ready */
  private modalToAttachWhenReady?: ModalComponent;

  /** List of all visible modals */
  private visibleModals: ModalComponent[] = [];

  /** @see {@link displayOverlay$} */
  private displayOverlaySubject = new BehaviorSubject(false);

  /** Make sure we're only emitting to the outside world when a real change happens by using `distinctUntilChanged` */
  displayOverlay$ = this.displayOverlaySubject.pipe(distinctUntilChanged());

  /** Returns the current visible {@link ModalComponent} */
  private get currentlyVisibleModal(): ModalComponent | undefined {
    return getLastItemFromArray(this.visibleModals);
  }

  /** Sets the portal outlet which should be used by the modals.
   * Should only be called from the <lru-modal-outlet> component. */
  setPortalOutlet(outlet: DomPortalOutlet): void {
    this.outlet = outlet;

    if (this.modalToAttachWhenReady) {
      this.outlet.attach(this.modalToAttachWhenReady.portal);
      this.modalToAttachWhenReady = undefined;
    }
  }

  /** Sets the visibility for the given modal. */
  setModalVisible(modal: ModalComponent, visible: boolean): void {
    modal.setVisibility(visible);
  }

  /** Hides the current modal if a modal is visible. */
  hideCurrentModal(): void {
    this.currentlyVisibleModal?.setVisibility(false);
  }

  /** Stores the visibility of a modal in the service and reacts to it.
   * Should only be called from the <lru-modal> component.
   *
   * If a modal is hidden, the next visible modal in the stack will be displayed.
   * If a modal is shown, the previously modal in the stack will be hidden, if one exists.
   *
   * Updates the overlay subject with true if any modal is visible or false if no modal is visible. */
  storeModalVisibility(modal: ModalComponent, visible: boolean): void {
    const wasLatestVisible = this.currentlyVisibleModal === modal;

    // The most recent displayed modal should always be last in the array
    // To ensure no duplicate entries we always try to remove the modal before re-inserting it if necessary
    removeFromArray(this.visibleModals, modal);

    if (visible) {
      this.visibleModals.push(modal);
      this.attachModal(modal);
    } else if (wasLatestVisible) {
      this.detachCurrentModal();
    }

    const currentlyVisibleModal = this.currentlyVisibleModal;

    if (!visible && wasLatestVisible && currentlyVisibleModal) {
      this.setModalVisible(currentlyVisibleModal, true);
    }

    this.displayOverlaySubject.next(!!currentlyVisibleModal);
  }

  /** Method for checking if the modal is in the visible stack
   * @returns true if the given modal is or should be visible. */
  isModalInVisibleStack(modal: ModalComponent): boolean {
    return this.visibleModals.includes(modal);
  }

  /** Attaches the given modal to the outlet and removes any old modal from the outlet. */
  private attachModal(modal: ModalComponent): void {
    if (!this.outlet) {
      // Queue the modal if the outlet hasn't yet been set
      this.modalToAttachWhenReady = modal;
      return;
    }

    this.detachCurrentModal();
    this.outlet.attach(modal.portal);
  }

  /** Detaches the current modal from the outlet. */
  private detachCurrentModal(): void {
    this.outlet?.detach();
  }

  /** Relays focus events from the <lru-modal-outlet> component to the current <lru-modal> component. */
  handleFocus(event: FocusEvent): void {
    if (this.currentlyVisibleModal && event.target) {
      this.currentlyVisibleModal.trapFocus(event.target);
    }
  }
}
