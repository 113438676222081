import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IPsArticle } from '@portal-app/interfaces/ps/project/ps-article.interface';
import { HtmlService } from '@portal-app/services/shared/html.service';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { PsLibraryFilterEnum } from '../../enums/ps/ps-library-filter.enum';
import { SectionTypeEnum } from '../../enums/section-type.enum';
import { IUmbPsArticle } from '../../interfaces/ps/umbraco/umb-ps-article.interface';
import { HelperService } from '../../services/shared/helper.service';

@Injectable({
  providedIn: 'root',
})
export class PsArticleMapper {
  constructor(
    private htmlService: HtmlService,
    private helperService: HelperService,
    private translateService: TranslateService
  ) {}

  mapPsArticleFromApi(article: IUmbPsArticle): IPsArticle {
    return {
      Label: article.label,
      Authors: article.authors.map((elem) => ({
        Id: elem.id,
        Title: elem.title,
        Type: PsLibraryFilterEnum.Author,
      })),
      Id: article.id.toString(),
      IsFavorite: article.isFavorite,
      Title: article.title,
      Topics: article.topics.map((elem) => ({
        Id: elem.id,
        Title: elem.title,
        Type: PsLibraryFilterEnum.Topic,
      })),
      Teaser: this.htmlService.fixHtml(this.htmlService.truncateHTML(article.teaser, 150)),
      ContentGridHtml: this.htmlService.fixHtml(article.contentGridHtml),
      PublishDate: this.helperService.getLocalizedDate(new Date(article.publishDate)),
      Image: article.image,
      Related: article.relatedArticles?.length
        ? {
            Articles: this.mapPsArticlesFromApi(article.relatedArticles),
            SliderSettings: {
              Type: SectionTypeEnum.PsArticles,
              UseSlider: true,
              MaxSlidesPerView: 5,
              Title: this.translateService.instant('PsArticle.RelatedTitle'),
            },
          }
        : undefined,
      Url: article.customUrl
        ? this.helperService.getCustomUrl(article.customUrl)
        : `/${PageTypeEnum.PsArticle}/${article.id}`,
    };
  }

  mapPsArticlesFromApi(articles: IUmbPsArticle[]): IPsArticle[] {
    const result: IPsArticle[] = [];
    articles.forEach((article) => {
      result.push(this.mapPsArticleFromApi(article));
    });
    return result;
  }
}
