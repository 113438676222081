import { Component, HostBinding, Input } from '@angular/core';
import { LoadingAnimationVariant } from '../enum/loading-animation-variant.enum';

/**
 * Component to display loading indicator
 */
@Component({
  selector: 'lru-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
})
export class LoadingAnimationComponent {
  /** Set how big the line in the loading indicator should be */
  @Input() strokeWidth: number;

  /** Set specific loading variant */
  @Input() variant: LoadingAnimationVariant;

  /** Set the varient as class on the component */
  @HostBinding('class') get className(): string {
    return `loading-animation loading-animation-${this.variant}`;
  }

  /** Change size of loading indicator
   * @input 1 === 1.25rem */
  @HostBinding('style.--size') @Input() size: number | undefined;

  /** @ignore */
  constructor() {
    this.variant = LoadingAnimationVariant.BLOCK;
    this.strokeWidth = 10;
  }

  /** Ensure the loading indicator stays within the svg no matter the size of strokeWidth */
  get radius(): number {
    return 50 - this.strokeWidth / 2;
  }

  /** Used to remove part of the circle that is the loading indicator */
  get circleCutout(): number {
    return (2 * this.radius * Math.PI) / 3;
  }
}
