import { Location, PlatformLocation } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { FacetTypeEnum } from '@portal-app/enums/facet-type.enum';

import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { PageTypeEnum } from '@portal-app/enums/page-type.enum';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import { ILibraryFacet, ILibraryFacets } from '@portal-app/interfaces/course/project/course-library.interface';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { DropdownService } from '@portal-app/services/shared/dropdown.service';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { EMPTY, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  INextBookSearchAutoCompleteResponse,
  INextBookSearchResponse,
} from '../../interfaces/book/next/next-book-library.interface';
import { IBookLibraryFilters } from '../../interfaces/book/project/book-library.interface';
import { INextSearchSuggestions } from '../../interfaces/next/next-library';
import { BookLibraryMapper } from '../../mapper/book/book-library.mapper';
import { BookMapper } from '../../mapper/book/book.mapper';
import { PageService } from '../shared/page/page.service';

@Injectable({
  providedIn: 'root',
})
export class BookLibraryService {
  filters: IBookLibraryFilters;

  private data = new ReplaySubject<IBookLibraryFilters>(1);
  data$ = this.data.asObservable();

  constructor(
    private helperService: HelperService,
    private appInitService: AppInitService,
    private dropdownService: DropdownService,
    private readonly location: Location,
    private api: ApiService,
    private userService: UserService,
    private bookLibraryMapper: BookLibraryMapper,
    private bookMapper: BookMapper,
    private platformLocation: PlatformLocation,
    private pageService: PageService,
    private translate: TranslateService
  ) {
    this.filters = {
      FiltersLoaded: false,
      SearchLoaded: false,
      LoggedIn: this.userService.isLoggedIn,
      Loading: false,
      Query: '',
      SearchBody: {
        ProjectGroupId: this.appInitService.siteConfiguration.ProjectGroup || '',
        Top: 20,
        Skip: 0,
        Query: '',
        FacetCount: 500,
        Categories: [],
        DifficultyGroups: [],
        Grades: [],
        ReadingLevels: [],
        Languages: [],
        Illustrations: [],
        HasAudioBook: [],
        SeriesTitles: [],
      },
      Facets: undefined,
      Books: undefined,
      Collections: undefined,
    };
    this.platformLocation.onPopState(() => {
      if (this.filters) {
        this.filters.FiltersLoaded = false;
        this.filters.SearchLoaded = false;
      }
    });
  }

  initializeFilters() {
    const urlQuery = this.helperService.getQueryParam(QueryStrings.Query) || '';

    this.filters.Query = urlQuery;
    this.filters.SearchBody.Query = urlQuery ? urlQuery : '*';

    this.filters.SearchBody.Skip = 0;
    this.filters.SearchBody.Categories = this.helperService.getQuerystringFacetList(QueryStrings.Categories);
    this.filters.SearchBody.DifficultyGroups = this.helperService.getQuerystringFacetList(
      QueryStrings.DifficultyGroups
    );
    this.filters.SearchBody.ReadingLevels = this.helperService.getQuerystringFacetList(QueryStrings.ReadingLevels);
    this.filters.SearchBody.Grades = this.helperService.getQuerystringFacetList(QueryStrings.Grades);
    this.filters.SearchBody.Languages = this.helperService.getQuerystringFacetList(QueryStrings.Languages);
    this.filters.SearchBody.Illustrations = this.helperService.getQuerystringFacetList(QueryStrings.Illustrations);
    this.filters.SearchBody.HasAudioBook = this.helperService.getQuerystringFacetListAsBooleans(
      QueryStrings.HasAudioBook
    );
    this.filters.SearchBody.SeriesTitles = this.helperService.getQuerystringFacetList(QueryStrings.SeriesTitle);

    this.filters.FiltersLoaded = true;
    this.data.next(this.filters);
  }

  initializeSearch() {
    this.postAzureSearch()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.pageService.showError(window.location.pathname, error.status);
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.filters.Books = {
          SliderSettings: {
            MaxSlidesPerView: 5,
            Type: SectionTypeEnum.BookList,
            UseSlider: false,
            Title: this.translate.instant('BookList.Title'),
            Total: data.count,
          },
          Items: data.results ? this.bookMapper.mapBooksFromApi2(data.results) : [],
          Pagination: {
            Type: PageTypeEnum.BookLibrary,
            CollectionSize: data.count,
            Index: 1,
            PageSize: this.filters.SearchBody.Top,
          },
        };
        this.filters.Facets = this.bookLibraryMapper.mapFacetsFromApi(data.facets);
        this.filters.SearchLoaded = true;
        this.data.next(this.filters);
      });
  }

  postAzureSearch() {
    return this.api.postNextSearch<INextBookSearchResponse>('SearchBooksAsync', this.filters.SearchBody);
  }

  postAzureAutoComplete(query: string) {
    const body: INextSearchSuggestions = {
      Fuzzy: true,
      ProjectGroupId: this.appInitService.siteConfiguration.ProjectGroup || '',
      Query: query,
      Top: 3,
    };

    return this.api.postNextSearch<INextBookSearchAutoCompleteResponse>('BookSearchSuggestionsAsync', body);
  }

  setQueryString(replaceState?: boolean) {
    let params = new HttpParams();

    params = this.setQueryStringFacets(params);

    params = this.setQueryStringSearchBody(params);

    if (replaceState) {
      this.location.replaceState(window.location.pathname, params.toString());
    } else {
      this.location.go(window.location.pathname, params.toString());
    }
  }

  private setQueryStringSearchBody(params: HttpParams) {
    if (this.filters.SearchBody.Query !== '*' && this.filters.SearchBody.Query) {
      params = params.append(QueryStrings.Query.en, this.filters.SearchBody.Query);
    }

    if (this.filters.SearchBody.ReadingLevels.length) {
      params = params.append(
        QueryStrings.ReadingLevels.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.ReadingLevels)
      );
    }

    if (this.filters.SearchBody.Categories.length) {
      params = params.append(
        QueryStrings.Categories.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Categories)
      );
    }

    if (this.filters.SearchBody.DifficultyGroups.length) {
      params = params.append(
        QueryStrings.DifficultyGroups.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.DifficultyGroups)
      );
    }

    if (this.filters.SearchBody.Grades.length) {
      params = params.append(
        QueryStrings.Grades.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Grades)
      );
    }

    if (this.filters.SearchBody.Languages.length) {
      params = params.append(
        QueryStrings.Languages.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Languages)
      );
    }

    if (this.filters.SearchBody.Illustrations.length) {
      params = params.append(
        QueryStrings.Illustrations.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Illustrations)
      );
    }

    if (this.filters.SearchBody.HasAudioBook.length) {
      params = params.append(QueryStrings.HasAudioBook.en, this.filters.SearchBody.HasAudioBook.join(','));
    }

    if (this.filters.SearchBody.SeriesTitles.length) {
      params = params.append(
        QueryStrings.SeriesTitle.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.SeriesTitles)
      );
    }

    return params;
  }

  private setQueryStringFacets(params: HttpParams) {
    if (this.filters.Facets) {
      for (const facet of this.filters.Facets) {
        if (facet.Open) {
          params = params.append(facet.UrlTitle + QueryStrings.Open.en, 'true');
        }
      }
    }
    return params;
  }

  query() {
    this.filters.SearchBody.Query = this.filters.Query ? this.filters.Query : '*';
    this.search();
  }

  clearQuery() {
    this.filters.Query = '';
    this.query();
  }

  search(closeDropdown?: boolean) {
    if (closeDropdown) {
      this.dropdownService.closeDropdown();
    }
    this.filters.Loading = true;
    this.setQueryString();
    this.postAzureSearch()
      .pipe(
        catchError(() => {
          this.filters.Loading = false;
          return EMPTY;
        })
      )
      .subscribe((data) => this.handleBookSearchResponse(data));
  }

  private handleBookSearchResponse(data: INextBookSearchResponse) {
    if (this.filters.Books) {
      this.filters.Books.Items = data.results ? this.bookMapper.mapBooksFromApi2(data.results) : [];
      if (this.filters.Books.Pagination) {
        this.filters.Books.Pagination.CollectionSize = data.count;
      }
      this.filters.Books.SliderSettings.Total = data.count;
    }
    this.filters.Facets = this.bookLibraryMapper.mapFacetsFromApi(data.facets);
    this.filters.Loading = false;
    this.data.next(this.filters);
  }

  changeFacet(facet: ILibraryFacets) {
    switch (facet.Type) {
      case FacetTypeEnum.Categories:
        this.filters.SearchBody.Categories = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.DifficultyGroups:
        this.filters.SearchBody.DifficultyGroups = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.Grades:
        this.filters.SearchBody.Grades = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.Languages:
        this.filters.SearchBody.Languages = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.Illustrations:
        this.filters.SearchBody.Illustrations = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.SeriesTitle:
        this.filters.SearchBody.SeriesTitles = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.ReadingLevels:
        this.filters.SearchBody.ReadingLevels = this.mapFacet(facet.Items);
        break;
      case FacetTypeEnum.HasAudioBook:
        this.filters.SearchBody.HasAudioBook = facet.Items.filter((item) => item.Selected === true).map(
          (item) => item.Value.toLowerCase() === 'true'
        );
        break;
      default:
        break;
    }
  }

  private mapFacet(facets: ILibraryFacet[]) {
    return facets.filter((facet) => facet.Selected === true).map((facet) => facet.Value);
  }

  toggleFacetOpen(facet: ILibraryFacets) {
    this.filters?.Facets?.map((elem) => {
      if (elem.Title === facet.Title) {
        elem.Open = !facet.Open;
      } else {
        elem.Open = false;
      }
    });
    this.setQueryString();
  }

  reset() {
    this.filters.Query = '';
    this.filters.SearchBody.Query = '*';

    if (this.filters.Facets) {
      for (const facet of this.filters.Facets) {
        facet.Open = false;
        for (const item of facet.Items) {
          item.Selected = false;
        }
      }
    }

    this.filters.SearchBody.Skip = 0;

    this.filters.SearchBody.Categories = [];
    this.filters.SearchBody.DifficultyGroups = [];
    this.filters.SearchBody.Grades = [];
    this.filters.SearchBody.Languages = [];
    this.filters.SearchBody.Illustrations = [];
    this.filters.SearchBody.HasAudioBook = [];
    this.filters.SearchBody.SeriesTitles = [];
    this.filters.SearchBody.ReadingLevels = [];

    this.search();
  }

  setFacets(facets?: ILibraryFacets[]) {
    this.filters.Facets = facets;
    facets?.forEach((facet) => {
      this.changeFacet(facet);
    });
    this.data.next(this.filters);
  }

  changePage(pageIndex: number) {
    if (this.filters?.Books?.Pagination) {
      this.filters.Books.Pagination.Index = pageIndex;
    }
    this.filters.SearchBody.Skip = (pageIndex - 1) * this.filters.SearchBody.Top;
    window.scroll(0, 0);
    this.search();
  }
}
