import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITeacherDashboardState, teacherDashboardFeatureKey } from './teacher-dashboard.reducer';

const selectTeacherClassState = createFeatureSelector<ITeacherDashboardState>(teacherDashboardFeatureKey);

export const selectTeamsAndClasses = createSelector(selectTeacherClassState, (state) => state.teamsAndClasses);

export const selectGetSelectedClass = createSelector(selectTeacherClassState, (state) => state.selectedClass);

export const selectGetSelectedStudent = createSelector(selectTeacherClassState, (state) => state.selectedStudent);
export const selectGetSelectedStudentId = createSelector(selectGetSelectedStudent, (state) => state?.UserId);
export const selectGetSelectedStudentName = createSelector(selectGetSelectedStudent, (state) => state?.DisplayName);

export const selectorGetSelectedPeriod = createSelector(selectTeacherClassState, (state) => state.selectedPeriod);
export const selectorGetSelectedPeriodToDate = createSelector(
  selectTeacherClassState,
  (state) => state.selectedPeriodToDate
);

export const selectStudentsByClass = createSelector(selectTeacherClassState, (state) => state.students);
export const selectorGetSelectedBookTab = createSelector(selectTeacherClassState, (state) => state.selectedBookTab);

export const selectGetBookCollection = createSelector(selectTeacherClassState, (state) => state.bookCollection);
export const selectBooksInProgress = createSelector(selectTeacherClassState, (state) => state.booksInProgress);
export const selectBooksFinished = createSelector(selectTeacherClassState, (state) => state.booksFinished);
