import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AudioPlayerModule } from '../../shared/audio-player/audio-player.module';
import { BaseModule } from '../../shared/base/base.module';
import { LayoutAudioFullComponent } from './components/layout-audio-full/layout-audio-full.component';
import { LayoutAudioPartialComponent } from './components/layout-audio-partial/layout-audio-partial.component';
import { LayoutAudioRecordedComponent } from './components/layout-audio-recorded/layout-audio-recorded.component';

@NgModule({
  declarations: [LayoutAudioFullComponent, LayoutAudioRecordedComponent, LayoutAudioPartialComponent],
  imports: [CommonModule, BaseModule, AudioPlayerModule],
  exports: [LayoutAudioFullComponent, LayoutAudioRecordedComponent, LayoutAudioPartialComponent],
})
export class LayoutAudioModule {}
