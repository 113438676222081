import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { INextBook } from '@portal-app/interfaces/book/next/next-book';
import {
  INextBookCollection,
  INextBookCollectionAdd,
  INextBookCollectionEdit,
} from '@portal-app/interfaces/book/next/next-book-collection.interface';
import { ICheckbox } from '@portal-app/interfaces/project/input.interface';
import { IBookTileInform, ICollectionTileEmpty } from '@portal-app/interfaces/project/section.interface';
import { BookCollectionMapper } from '@portal-app/mapper/book/book-collection.mapper';
import { BookMapper } from '@portal-app/mapper/book/book.mapper';
import { map } from 'rxjs/operators';
import { AppInitService } from '../shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class BookCollectionService {
  constructor(
    private api: ApiService,
    private bookMapper: BookMapper,
    private bookCollectionMapper: BookCollectionMapper,
    private userService: UserService,
    private appInitService: AppInitService
  ) {}

  get(id: string) {
    return this.api.getNext<INextBookCollection>(`BookBox/GetBookBox/${id}`);
  }

  create(body: INextBookCollectionAdd) {
    return this.api.postNext<INextBookCollection>('BookBox/CreateBookBox', body);
  }

  edit(body: INextBookCollectionEdit) {
    return this.api.putNext<INextBookCollection>('BookBox/UpdateBookBox', body);
  }

  delete(id: string) {
    return this.api.deleteNext<string>(`BookBox/DeleteBookBox/${id}`);
  }

  changePublic(id: string) {
    return this.api.patchNext<string>(`BookBox/ChangeBookBoxState/${id}`);
  }

  /**
   * Gets the users book collections.
   * The output is cached for subsequent requests.
   */
  getMyBookCollections() {
    let url: string;
    if (this.userService.isTeacher) {
      url = `BookBox/GetAllBookBoxesAvailable?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}`;
    } else if (this.userService.isStudent) {
      url = `BookBox/GetBookBoxesSharedWithMyTeams?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}`;
    } else {
      url = `BookBox/GetBookBoxes?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}`;
    }
    return this.api
      .getNext<INextBookCollection[]>(url)
      .pipe(map((collections) => this.bookCollectionMapper.mapBookCollectionFromApi(collections)));
  }

  /**
   * Gets the books in the given collection.
   * The output is cached for subsequent requests.
   */
  getBooksInCollection(id: string) {
    return this.api
      .getNext<INextBook[]>(`Book/GetBooksByBookBoxId/${id}`)
      .pipe(map((books) => this.bookMapper.mapBooksFromApi(books)));
  }

  addBookToCollection(collectionId: string, bookId: string) {
    return this.api.postNext<string>('BookBox/AddBookToBookBox', {
      BookBoxId: collectionId,
      BookId: bookId,
    });
  }

  removeBookFromCollection(collectionId: string, bookId: string) {
    return this.api.deleteNext<string>('BookBox/RemoveBookFromBookBox', {
      BookBoxId: collectionId,
      BookId: bookId,
    });
  }

  getTeamsAvailable(collectionId: string) {
    return this.api.getNext<ICheckbox[]>(
      `BookBox/CheckBookboxSharedWithTeams/${collectionId}?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}&productId=${this.appInitService.siteConfiguration.Id}`
    );
  }

  addTeamToCollection(collectionId: string, teamId: string) {
    return this.api.postNext<string>('BookBox/AddBookBoxToTeam', {
      BookBoxId: collectionId,
      TeamId: teamId,
    });
  }

  removeTeamFromCollection(collectionId: string, teamId: string) {
    return this.api.deleteNext<string>('BookBox/RemoveBookBoxFromTeam', {
      BookBoxId: collectionId,
      TeamId: teamId,
    });
  }

  getBookById(id: string) {
    return this.api.getNext<INextBook>(`Book/GetBookOverviewById/${id}`);
  }

  getSingleInfoTile(tile: IBookTileInform) {
    const data: IBookTileInform[] = [
      {
        IsEmpty: true,
        IsInform: true,
        ...tile,
      },
    ];
    return data;
  }

  getEmptyBookCollectionList(amount: number, loading?: boolean) {
    const data: ICollectionTileEmpty[] = [];

    for (let index = 0; index < amount; index++) {
      data.push({ IsEmpty: true, Loading: loading ?? false });
    }
    return data;
  }

  /**
   * @param status (is BookBoxStatus on the endpoint)
   *
   * it's required on the endpoint and is default set to 0 (0,1,2 => All, Public, Private)
   * */
  getBookTileAvailableCollections(status: number = 0) {
    return this.api
      .getNext<INextBookCollection[]>(
        `BookBox/GetMyBookBoxes?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}&status=${status}`
      )
      .pipe(map((collections) => this.bookCollectionMapper.mapBookCollectionFromApi(collections)));
  }
}
