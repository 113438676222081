import { BackendUrlConfig } from '../../interfaces/backend-url-config.interface';
import { IApiServiceConfig } from './api-service-config.interface';

/**
 * Urls for API's the project uses
 */
export class ApiServiceConfig extends BackendUrlConfig implements IApiServiceConfig {
  /** Local hostname for when contacting Umbraco */
  localHostname = '';

  /** Umbraco */
  apiUmbracoBaseUrl = 'https://lri-mitalineaumb-dev.azurewebsites.net/api/';

  /** Next */
  apiNextBaseUrl = 'https://web-next-api-dev.azurewebsites.net/api/';

  /** Next search */
  apiNextSearchBaseUrl = 'https://nextsearch-dev.azurewebsites.net/api/';

  /** Lounge */
  apiLoungeBaseUrl = 'https://lounge.dev.alineadigital.dk/api/';

  /** Matematikfessor BFF gateway */
  apiBFFGatewayBaseUrl = 'https://bffgateway.dev.alineadigital.dk/api/';
}
