import { createReducer, on } from '@ngrx/store';
import { addMessage, removeMessage, setConfig, updateMessage } from './toast.actions';
import { initialToastState } from './toast.state';

export const toastFeatureKey = 'toastState';

export const toastReducer = createReducer(
  initialToastState,
  on(addMessage, (state, action) => {
    return {
      ...state,
      messages: [...state.messages, action.message],
    };
  }),
  on(updateMessage, (state, action) => {
    const messages = [...state.messages];
    const currentIndex = messages.findIndex((message) => message.id === action.message.id);

    if (currentIndex !== -1) {
      messages[currentIndex] = { ...action.message };
    }

    return {
      ...state,
      messages,
    };
  }),
  on(removeMessage, (state, action) => {
    const messages = state.messages.filter((message) => message.id !== action.id);

    return { ...state, messages: messages };
  }),
  on(setConfig, (state, action) => {
    return {
      ...state,
      config: action.config,
    };
  })
);
