import { IDropdownConfig } from './dropdown-config.interface';
import { dropdownAxis, dropdownHorizontalDirection, dropdownVerticalDirection } from './dropdown-types';

/**
 * Settings for dropdowns
 */
export class DropdownConfig implements IDropdownConfig {
  /** Function that will get triggered when dropdown is opened */
  openFunc = undefined;

  /** Function that will get triggered when dropdown is closed */
  closeFunc = undefined;

  /** Should dropdown content be the same width as the button? */
  sameWidth = true;

  /** Should dropdown content be the same height as the button? */
  sameHeight = false;

  /** Which horizontal direction should dropdown content go from the button? */
  horizontalDirection: dropdownHorizontalDirection = 'right';

  /** Which vertical direction should dropdown content go from the button? */
  verticalDirection: dropdownVerticalDirection = 'down';

  /** What is the minimum width of dropdown content before it should start ignoring its position relative to the button? */
  toleranceWidth = 100;

  /** What is the minimum height of dropdown content before it should start ignoring its position relative to the button? */
  toleranceHeight = 100;

  /** How many pixels space should there be between dropdown button and dropdown content? */
  spacingFromButton = 0;

  /** How many pixels space should there be from dropdown content to the browser window? */
  spacingFromWindow = 0;

  /** Should dropdown content be on top of dropdown button? */
  popOver = false;

  /** Should dropdown content be displayed over/under the dropdown? Then the axis should be "vertical" */
  axis: dropdownAxis = 'vertical';

  /** How often should the dropdown content position be checked on window scroll and resize? */
  throttleTime = 100;

  /** Should dropdown content fill out the browser window width minus spacingFromWindow? */
  fillWidth = false;

  /** Should dropdown content fill out the browser window height minus spacingFromWindow? */
  fillHeight = false;

  /** How wide can the dropdown content become? */
  maxWidth = undefined;

  /** How high can the dropdown content become? */
  maxHeight = undefined;

  /** Data-attribute to make it easier to add custom styling */
  variant = undefined;
}
