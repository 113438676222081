import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHelper } from '@portal-app/helpers/missing-translation.helper';
import { SharedPipeModule } from '@portal-app/pipes/shared/shared-pipe.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BookDifficultiesComponent } from './components/book-difficulties/book-difficulties.component';
import { BookTileComponent } from './components/book-tile/book-tile.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FavoriteComponent } from './components/favorite/favorite.component';
import { LibraryActiveFiltersComponent } from './components/library-active-filters/library-active-filters.component';
import { ManageBookCollectionComponent } from './components/manage-book-collection/manage-book-collection.component';
import { SelectImpressionsModalComponent } from './components/select-impressions-modal/select-impressions-modal.component';
import { ToggleComponent } from './components/toggle/toggle.component';

@NgModule({
  declarations: [
    FavoriteComponent,
    BreadcrumbsComponent,
    LibraryActiveFiltersComponent,
    BookTileComponent,
    SelectImpressionsModalComponent,
    CheckboxComponent,
    ToggleComponent,
    BookDifficultiesComponent,
    ManageBookCollectionComponent,
    DropdownComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedPipeModule,
    LazyLoadImageModule,
    InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }),
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    TranslateModule.forChild(),
  ],
  exports: [
    SharedPipeModule,
    RouterModule,
    LazyLoadImageModule,
    InlineSVGModule,
    FavoriteComponent,
    NgbPaginationModule,
    BreadcrumbsComponent,
    MatMenuModule,
    LibraryActiveFiltersComponent,
    BookTileComponent,
    CheckboxComponent,
    ToggleComponent,
    BookDifficultiesComponent,
    ManageBookCollectionComponent,
    DropdownComponent,
    TranslateModule,
  ],
  providers: [
    { provide: Window, useValue: window },
    {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHelper,
    },
  ],
})
export class BaseModule {}
