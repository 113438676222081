import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'backgroundImage',
})
export class BackgroundImagePipe implements PipeTransform {
  transform(value?: string) {
    if (value) {
      value = 'url(' + value + ')';
    }
    return value ?? '';
  }
}
