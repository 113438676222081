import { Injectable } from '@angular/core';
import { ApiService } from '@lru/felib';
import {
  IBookImpressionPercentages,
  IBookImpressions,
} from '@portal-app/interfaces/book/project/book-impression.interface';
import { INextImpressionTag } from '@portal-app/interfaces/next/next-tag.interface';
import { Subject } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BookImpressionService {
  private data = new Subject<IBookImpressions>();
  data$ = this.data.asObservable();

  constructor(private apiService: ApiService) {}

  fetchAndUpdate(bookId: string) {
    return this.apiService.getNext<INextImpressionTag[]>(`${this.getSelectedImpressionsUrl}${bookId}`).pipe(
      tap((data) => {
        this.data.next(
          {
            Id: bookId,
            Impressions: data,
          } || undefined
        );
      })
    );
  }

  getImpressions() {
    return this.apiService.getNext<INextImpressionTag[]>(this.getImpressionsUrl);
  }

  addSelectedImpressions(bookId: string, impressionId?: string) {
    const body = {
      BookId: bookId,
      Slug: impressionId,
    };

    return this.apiService.postNext<string>(this.addSelectedImpressionsUrl, body).pipe(
      withLatestFrom(this.fetchAndUpdate(bookId)),
      tap(([_, impressionTags]) => this.data.next({ Id: bookId, Impressions: impressionTags })),
      map(([result, _]) => result)
    );
  }

  removeSelectedImpressions(bookId: string, impressionId?: string) {
    const body = {
      BookId: bookId,
      Slug: impressionId,
    };
    return this.apiService.deleteNext<string>(this.removeSelectedImpressionsUrl, body).pipe(
      withLatestFrom(this.fetchAndUpdate(bookId)),
      tap(([_, impressionTags]) => this.data.next({ Id: bookId, Impressions: impressionTags })),
      map(([result, _]) => result)
    );
  }

  getImpressionPercentages(bookId: string) {
    return this.apiService.getNext<IBookImpressionPercentages>(`${this.getImpressionPercentagesUrl}${bookId}`);
  }

  get getImpressionsUrl() {
    return 'BookImpression/GetImpressionTypes';
  }

  get getSelectedImpressionsUrl() {
    return 'BookImpression/GetUsersImpressionById/';
  }

  get addSelectedImpressionsUrl() {
    return 'BookImpression/SaveImpression';
  }

  get removeSelectedImpressionsUrl() {
    return 'BookImpression/DeleteImpression';
  }

  get getImpressionPercentagesUrl() {
    return 'BookImpression/GetImpressionsById/';
  }
}
