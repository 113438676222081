import { Injectable } from '@angular/core';
import { ApiService } from '@lru/felib';
import {
  INextCourseSearchAssignment2,
  INextCourseSearchCourse,
  INextCourseSearchPage2,
} from '@portal-app/interfaces/course/next/next-course-library.interface';
import { INextFavorite } from '@portal-app/interfaces/next/next-favorite.interface';
import { AppInitService } from '../shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class CourseFavoriteApiService {
  constructor(private apiService: ApiService, private appInitService: AppInitService) {}

  getFavoriteActivitiesByIds(ids: (string | undefined)[]) {
    return this.apiService.postNext<INextFavorite[]>('Favorite/CheckIfContentsIsFavorite', ids);
  }

  getFavoriteCourseByIds(ids: (string | undefined)[]) {
    return this.apiService.postNext<INextFavorite[]>('Favorite/AreCourseAliasesFavorites', ids);
  }

  getFavoriteTextsByIds(ids: (string | undefined)[]) {
    return this.apiService.postNext<INextFavorite[]>('Favorite/AreChapterPagesFavorites', ids);
  }

  getFavoriteTasksByIds(ids: (string | undefined)[]) {
    return this.apiService.postNext<INextFavorite[]>('Favorite/AreAssignmentsFavorites', ids);
  }

  getFavoriteCoursesByProjectGroupId() {
    return this.apiService.getNext<INextCourseSearchCourse[]>(
      `Favorite/GetFavoriteCourses/${this.appInitService.siteConfiguration.ProjectGroup}`
    );
  }

  getFavoriteTextsByProjectGroupId() {
    return this.apiService.getNext<INextCourseSearchPage2[]>(
      `Favorite/GetFavoriteChapterPages/${this.appInitService.siteConfiguration.ProjectGroup}`
    );
  }

  getFavoriteTasksByIdsByProjectGroupId() {
    return this.apiService.getNext<INextCourseSearchAssignment2[]>(
      `Favorite/GetFavoriteAssignments/${this.appInitService.siteConfiguration.ProjectGroup}`
    );
  }

  addCourseFavorite(id: string) {
    return this.apiService.postNext<string>(`Favorite/AddCourseToFavorites/${id}`);
  }

  addTextFavorite(id: string) {
    return this.apiService.postNext<string>(`Favorite/AddChapterPageToFavorites/${id}`);
  }

  addTaskFavorite(id: string) {
    return this.apiService.postNext<string>(`Favorite/AddAssignmentToFavorites/${id}`);
  }

  removeCourseFavorite(id: string) {
    return this.apiService.deleteNext<string>(`Favorite/DeleteCourseFromFavorites/${id}`);
  }

  removeTextFavorite(id: string) {
    return this.apiService.deleteNext<string>(`Favorite/DeleteChapterPageFromFavorites/${id}`);
  }

  removeTaskFavorite(id: string) {
    return this.apiService.deleteNext<string>(`Favorite/DeleteAssignmentFromFavorites/${id}`);
  }
}
