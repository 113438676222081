import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BaseModule } from '../base/base.module';
import { BookCardPreviewComponent } from './components/book-card-preview/book-card-preview.component';
import { CourseCardPreviewComponent } from './components/course-card-preview/course-card-preview.component';
import { LibrarySearchComponent } from './components/library-search/library-search.component';

@NgModule({
  declarations: [LibrarySearchComponent, BookCardPreviewComponent, CourseCardPreviewComponent],
  imports: [CommonModule, BaseModule, FormsModule],
  exports: [LibrarySearchComponent, BookCardPreviewComponent, CourseCardPreviewComponent],
})
export class LibrarySearchModule {}
