import { Component, OnInit } from '@angular/core';
import { LoadingService, ZIndexService } from '@lru/felib';
import { LoadingKey } from '@portal-app/enums/loading-keys.enum';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'portal-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {
  /** unique id for this component */
  private uuid = uuidv4();

  loading = false;

  /** z-index for loader */
  public readonly zIndex$ = this.zIndexService.item$(this.uuid);

  constructor(private loaderService: LoadingService, private zIndexService: ZIndexService) {}

  ngOnInit() {
    this.loaderService.loading$(LoadingKey.Page).subscribe((loading) => {
      this.loading = loading;

      if (loading) {
        this.zIndexService.setItem(this.uuid);
      }
    });
  }
}
