import { Location, PlatformLocation } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { FacetTypeEnum } from '@portal-app/enums/facet-type.enum';
import {
  INextCourseSearchAutoCompleteResponse,
  INextCourseSearchResponse2,
} from '@portal-app/interfaces/course/next/next-course-library.interface';
import {
  ICourseLibraryFilters,
  ILibraryFacets,
  ILibraryTab,
} from '@portal-app/interfaces/course/project/course-library.interface';
import { CourseFavoriteMapper } from '@portal-app/mapper/course/course-favorite.mapper';
import { CourseLibraryMapper } from '@portal-app/mapper/course/course-library.mapper';
import { CourseTabsMapper } from '@portal-app/mapper/course/course-tabs.mapper';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { DropdownService } from '@portal-app/services/shared/dropdown.service';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { HtmlService } from '@portal-app/services/shared/html.service';
import { EMPTY, ReplaySubject, firstValueFrom } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ContentTypeEnum } from '../../enums/content-type.enum';
import { INextSearchSuggestions } from '../../interfaces/next/next-library';
import { PageService } from '../shared/page/page.service';

@Injectable({
  providedIn: 'root',
})
export class CourseLibraryService {
  filters: ICourseLibraryFilters;

  private data = new ReplaySubject<ICourseLibraryFilters>(1);
  data$ = this.data.asObservable();

  constructor(
    private courseFavoriteMapper: CourseFavoriteMapper,
    private courseTabsMapper: CourseTabsMapper,
    private helperService: HelperService,
    private appInitService: AppInitService,
    private dropdownService: DropdownService,
    private courseLibraryMapper: CourseLibraryMapper,
    private readonly location: Location,
    private api: ApiService,
    private userService: UserService,
    private htmlService: HtmlService,
    private platformLocation: PlatformLocation,
    private translateService: TranslateService,
    private pageService: PageService
  ) {
    const tab = this.helperService.getQueryParam(QueryStrings.Tab);
    this.filters = {
      FiltersLoaded: false,
      SearchLoaded: false,
      LoggedIn: this.userService.isLoggedIn,
      MinShown: 3,
      MinToCollapse: 6,
      Loading: false,
      Query: '',
      SearchBody: {
        ProjectGroupId: this.appInitService.siteConfiguration.ProjectGroup || '',
        SearchIn:
          QueryStrings.Courses.en === tab || QueryStrings.Courses.da === tab
            ? ContentTypeEnum.Courses
            : ContentTypeEnum.Texts,
        Top: 10,
        Skip: 0,
        Query: '*',
        OnlyLicense: false,
        AssignmentTypes: [],
        CollaborationTypes: [],
        Genres: [],
        Levels: [],
        Topics: [],
        Writers: [],
        Subjects: [],
        FacetCount: 500,
      },
      TabGroups: [],
      Facets: undefined,
      SearchCount: undefined,
      CurrentTab: undefined,
      OnlyLicense: {
        Title:
          this.htmlService.fixHtml(
            this.userService.isLoggedIn
              ? this.translateService.instant('CourseLibrary.Filters.OnlyLicenseLoggedIn')
              : this.translateService.instant('CourseLibrary.Filters.OnlyLicenseLoggedOut')
          ) || '',
        Checked: false,
      },
    };
    this.filters.SearchBody.Top = this.getPageSize();
    this.platformLocation.onPopState(() => {
      if (this.filters) {
        this.filters.FiltersLoaded = false;
        this.filters.SearchLoaded = false;
      }
    });
  }

  initializeFilters() {
    const onlyLicenseParam = this.helperService.getQueryParam(QueryStrings.OnlyLicense);

    const urlQuery = this.helperService.getQueryParam(QueryStrings.Query) || '*';
    this.filters.Query = urlQuery !== '*' ? urlQuery : '';
    this.filters.SearchBody.Query = urlQuery;
    if (this.appInitService.siteConfiguration.onlyLicenseToggle) {
      this.filters.SearchBody.OnlyLicense = onlyLicenseParam ? onlyLicenseParam !== 'false' : this.filters.LoggedIn;
      this.filters.OnlyLicense.Checked = this.filters.SearchBody.OnlyLicense;
    }
    const tab = this.helperService.getQueryParam(QueryStrings.Tab);

    if (tab) {
      this.filters.SearchBody.SearchIn =
        this.helperService.getKeyName(QueryStrings, tab) !== 'Texts'
          ? this.helperService.getKeyName(QueryStrings, tab) || ContentTypeEnum.Texts
          : ContentTypeEnum.Texts;
    } else {
      this.filters.SearchBody.SearchIn = ContentTypeEnum.Texts;
    }
    this.filters.SearchBody.Top = this.getPageSize();

    const urlPage = this.helperService.getQueryParam(QueryStrings.Page);
    this.filters.SearchBody.Skip = urlPage
      ? (this.helperService.tryParseInt(urlPage, 0) - 1) * this.filters.SearchBody.Top
      : 0;
    this.filters.SearchBody.AssignmentTypes = this.helperService.getQuerystringFacetList(QueryStrings.AssignmentTypes);
    this.filters.SearchBody.CollaborationTypes = this.helperService.getQuerystringFacetList(
      QueryStrings.CollaborationTypes
    );
    this.filters.SearchBody.Genres = this.helperService.getQuerystringFacetList(QueryStrings.Genres);
    this.filters.SearchBody.Levels = this.helperService.getQuerystringFacetList(QueryStrings.Levels);
    this.filters.SearchBody.Topics = this.helperService.getQuerystringFacetList(QueryStrings.Topics);
    this.filters.SearchBody.Writers = this.helperService.getQuerystringFacetList(QueryStrings.Writers);
    this.filters.SearchBody.Subjects = this.helperService.getQuerystringFacetList(QueryStrings.Subjects);

    this.filters.FiltersLoaded = true;
    this.data.next(this.filters);
  }

  initializeSearch() {
    this.postAzureSearch()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.pageService.showError(window.location.pathname, error.status);

          return EMPTY;
        }),
        tap((data) => {
          this.filters.TabGroups = this.courseTabsMapper.mapLibraryTabsFromApi(data);
          this.filters.Facets = this.courseLibraryMapper.mapFacetsFromApi(data.facets);
          this.filters.SearchCount = data.searchCount;
          this.filters.CurrentTab = this.setCurrentTab();

          if (this.filters.CurrentTab) {
            this.filters.CurrentTab.Pagination.Index = this.filters.SearchBody.Skip / this.filters.SearchBody.Top + 1;
          }
        }),
        switchMap(() => this.setFavorites())
      )
      .subscribe(() => {
        this.filters.SearchLoaded = true;
        this.data.next(this.filters);
      });
  }

  private setCurrentTab() {
    let CurrentTab: ILibraryTab | undefined = this.filters.TabGroups.find((x) =>
      x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Texts)
    )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Texts);

    if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Assignments) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Assignments)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Assignments);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Courses) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Courses)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Courses);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Activities) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Activities)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Activities);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Images) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Images)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Images);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Videos) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Videos)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Videos);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Audios) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Audios)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Audios);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Documents) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Documents)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Documents);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.GeoGebras) {
      CurrentTab = this.filters.TabGroups.find((x) =>
        x.Tabs.find((d) => d.TabsType === ContentTypeEnum.GeoGebras)
      )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.GeoGebras);
    }
    return CurrentTab;
  }

  postAzureSearch() {
    return this.api.postNextSearch<INextCourseSearchResponse2>('SearchPortalAsync', this.filters.SearchBody);
  }

  postAzureAutoComplete(query: string) {
    const body: INextSearchSuggestions = {
      Fuzzy: true,
      ProjectGroupId: this.appInitService.siteConfiguration.ProjectGroup || '',
      Query: query,
      Top: 3,
    };

    return this.api.postNextSearch<INextCourseSearchAutoCompleteResponse>('PortalSearchSuggestionsAsync', body);
  }

  setQueryString(replaceState?: boolean) {
    let params = new HttpParams();

    if (this.filters.SearchBody.SearchIn !== ContentTypeEnum.Texts && this.filters.CurrentTab) {
      params = params.append(QueryStrings.Tab.en, this.filters.CurrentTab.UrlTitle);
    }

    if (
      (this.filters.LoggedIn && !this.filters.SearchBody.OnlyLicense) ||
      (!this.filters.LoggedIn && this.filters.SearchBody.OnlyLicense)
    ) {
      params = params.append(QueryStrings.OnlyLicense.en, false);
    }

    if (this.filters.SearchBody.Query !== '*' && this.filters.SearchBody.Query) {
      params = params.append(QueryStrings.Query.en, this.filters.SearchBody.Query);
    }
    if (this.filters.Facets) {
      for (const facet of this.filters.Facets) {
        if (facet.Open) {
          params = params.append(facet.UrlTitle + QueryStrings.Open.en, 'true');
        }
      }
    }

    if (this.filters.SearchBody.AssignmentTypes?.length) {
      params = params.append(
        QueryStrings.AssignmentTypes.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.AssignmentTypes)
      );
    }
    if (this.filters.SearchBody.CollaborationTypes?.length) {
      params = params.append(
        QueryStrings.CollaborationTypes.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.CollaborationTypes)
      );
    }
    if (this.filters.SearchBody.Genres?.length) {
      params = params.append(
        QueryStrings.Genres.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Genres)
      );
    }
    if (this.filters.SearchBody.Levels?.length) {
      params = params.append(
        QueryStrings.Levels.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Levels)
      );
    }
    if (this.filters.SearchBody.Topics?.length) {
      params = params.append(
        QueryStrings.Topics.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Topics)
      );
    }
    if (this.filters.SearchBody.Writers?.length) {
      params = params.append(
        QueryStrings.Writers.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Writers)
      );
    }
    if (this.filters.SearchBody.Subjects?.length) {
      params = params.append(
        QueryStrings.Subjects.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Subjects)
      );
    }
    if (this.filters.SearchBody.Periods?.length) {
      params = params.append(
        QueryStrings.Periods.en,
        this.helperService.encodeStringArray(this.filters.SearchBody.Periods)
      );
    }

    if (this.filters.SearchBody.Skip) {
      params = params.append(
        QueryStrings.Page.en,
        (this.filters.SearchBody.Skip / this.filters.SearchBody.Top + 1).toString()
      );
    }

    if (replaceState) {
      this.location.replaceState(window.location.pathname, params.toString());
    } else {
      this.location.go(window.location.pathname, params.toString());
    }
  }

  changePage(pageIndex: number) {
    if (this.filters.CurrentTab) {
      this.filters.CurrentTab.Pagination.Index = pageIndex;
      this.filters.SearchBody.Skip = (pageIndex - 1) * this.filters.SearchBody.Top;
      this.filters.SearchBody.SearchIn = this.filters.CurrentTab.TabsType;
      this.filters.SearchBody.Top = this.getPageSize();
      window.scroll(0, 0);
      this.search();
    }
  }

  changeTab(tab: ILibraryTab) {
    this.filters.CurrentTab = tab;
    this.filters.SearchBody.SearchIn = tab.TabsType;
    this.filters.SearchBody.Top = this.getPageSize();
    this.filters.SearchBody.Skip = (tab.Pagination.Index - 1) * this.filters.SearchBody.Top;
    this.search();
  }

  onlyLicenseChange(checked: boolean) {
    this.filters.OnlyLicense.Checked = checked;
    this.filters.SearchBody.OnlyLicense = checked;
    this.clearPageIndex();
    this.search();
  }

  async setFavorites() {
    const courses = this.filters.TabGroups.find((x) =>
      x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Courses)
    )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Courses)?.Courses;
    const texts = this.filters.TabGroups.find((x) =>
      x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Texts)
    )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Texts)?.Texts;

    const tasks = this.filters.TabGroups.find((x) =>
      x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Assignments)
    )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Assignments)?.Tasks;

    const activities = this.filters.TabGroups.find((x) =>
      x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Activities)
    )?.Tabs.find((x) => x.TabsType === ContentTypeEnum.Activities)?.Activities;

    if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Activities && activities?.Items.length) {
      await firstValueFrom(this.courseFavoriteMapper.setActivitiesFavorite(activities.Items));
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Assignments && tasks?.length) {
      this.courseFavoriteMapper.setTasksFavorite(tasks);
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Courses && courses?.Items.length) {
      await firstValueFrom(this.courseFavoriteMapper.setCoursesFavorite(courses.Items));
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Images) {
      // debugger;
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Videos) {
      // debugger;
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Audios) {
      // debugger;
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.Documents) {
      // debugger;
    } else if (this.filters.SearchBody.SearchIn === ContentTypeEnum.GeoGebras) {
      // debugger;
    } else if (texts) {
      this.courseFavoriteMapper.setTextsFavorite(texts);
    }
  }

  query() {
    this.clearPageIndex();
    this.filters.SearchBody.Query = this.filters.Query === '' ? '*' : this.filters.Query;
    this.search();
  }

  clearQuery() {
    this.filters.Query = '';
    this.query();
  }

  search() {
    this.dropdownService.closeDropdown();
    this.filters.Loading = true;
    this.setQueryString();

    this.postAzureSearch()
      .pipe(
        catchError(() => {
          this.filters.Loading = false;
          return EMPTY;
        }),
        tap((data) => {
          this.replaceTabs(data);
          this.filters.Facets = this.courseLibraryMapper.mapFacetsFromApi(data.facets);
          this.filters.SearchCount = data.searchCount;
        }),
        switchMap(() => this.setFavorites())
      )
      .subscribe(() => {
        this.filters.Loading = false;
        this.data.next(this.filters);
      });
  }

  private replaceTabs(data: INextCourseSearchResponse2) {
    this.courseTabsMapper.replaceCoursesTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Courses))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Courses
      ),
      '?portalclick=courseslibrary'
    );
    this.courseTabsMapper.replaceTasksTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Assignments))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Assignments
      ),
      '?portalclick=assignmentslibrary'
    );
    this.courseTabsMapper.replaceActivitiesTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Activities))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Activities
      ),
      '?portalclick=activitieslibrary'
    );
    this.courseTabsMapper.replaceTextsTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Texts))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Texts
      ),
      '?portalclick=pageslibrary'
    );

    this.courseTabsMapper.replaceImagesTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Images))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Images
      )
    );
    this.courseTabsMapper.replaceVideosTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Videos))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Videos
      )
    );
    this.courseTabsMapper.replaceAudiosTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Audios))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Audios
      )
    );
    this.courseTabsMapper.replaceDocumentsTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.Documents))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.Documents
      )
    );
    this.courseTabsMapper.replaceGeoGebrasTab(
      data,
      this.filters.TabGroups.find((x) => x.Tabs.find((d) => d.TabsType === ContentTypeEnum.GeoGebras))?.Tabs.find(
        (x) => x.TabsType === ContentTypeEnum.GeoGebras
      )
    );
  }

  changeFacet(facet: ILibraryFacets) {
    if (facet.Type === FacetTypeEnum.AssignmentTypes) {
      this.filters.SearchBody.AssignmentTypes = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.CollaborationTypes) {
      this.filters.SearchBody.CollaborationTypes = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.Genres) {
      this.filters.SearchBody.Genres = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.Levels) {
      this.filters.SearchBody.Levels = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.Topics) {
      this.filters.SearchBody.Topics = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.Writers) {
      this.filters.SearchBody.Writers = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.Subjects) {
      this.filters.SearchBody.Subjects = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    } else if (facet.Type === FacetTypeEnum.Periods) {
      this.filters.SearchBody.Periods = facet.Items.filter((item) => item.Selected === true).map((item) => {
        return item.Value;
      });
    }
    this.clearPageIndex();
    this.search();
  }

  toggleFacetOpen(facet: ILibraryFacets) {
    facet.Open = !facet.Open;
    this.setQueryString();
  }

  clearPageIndex() {
    for (const group of this.filters.TabGroups) {
      for (const tab of group.Tabs) {
        tab.Pagination.Index = 1;
      }
    }
    if (this.filters.CurrentTab) {
      this.filters.CurrentTab.Pagination.Index = 1;
    }
    this.filters.SearchBody.Skip = 0;
  }

  reset() {
    this.filters.Query = '';
    this.filters.SearchBody.Query = '*';
    this.resetFacets();
    this.search();
  }

  resetFacets() {
    if (this.filters.Facets) {
      for (const facet of this.filters.Facets) {
        facet.Open = false;
        for (const item of facet.Items) {
          item.Selected = false;
        }
      }
    }

    this.clearPageIndex();
    this.filters.SearchBody.Skip = 0;
    this.filters.SearchBody.AssignmentTypes = [];
    this.filters.SearchBody.CollaborationTypes = [];
    this.filters.SearchBody.Genres = [];
    this.filters.SearchBody.Levels = [];
    this.filters.SearchBody.Topics = [];
    this.filters.SearchBody.Writers = [];
    this.filters.SearchBody.Subjects = [];
  }

  getPageSize() {
    if (
      this.filters.SearchBody.SearchIn === ContentTypeEnum.Courses ||
      this.filters.SearchBody.SearchIn === ContentTypeEnum.Activities
    ) {
      return 24;
    } else {
      return 10;
    }
  }
}
