import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LinkTypeEnum } from '@portal-app/enums/link-type.enum';
import { INavbarItem } from '@portal-app/interfaces/project/navbar.interface';
import { NavbarMapper } from '@portal-app/mapper/shared/navbar.mapper';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { BreakpointsService } from '@portal-app/services/shared/breakpoints.service';
import { NavbarService } from '@portal-app/services/shared/navbar.service';
import { PageService } from '@portal-app/services/shared/page/page.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'portal-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  isLoading = true;
  navbar?: INavbarItem[];
  dropdownsEnabled?: boolean;

  constructor(
    private appInitService: AppInitService,
    private navbarService: NavbarService,
    private navbarMapper: NavbarMapper,
    private breakpointsService: BreakpointsService,
    private pageService: PageService
  ) {}

  ngOnInit() {
    this.breakpointsService.large$.subscribe((data) => {
      this.dropdownsEnabled = data;
    });

    const menuId = this.appInitService.siteConfiguration.NavbarId;
    if (menuId) {
      this.navbarService
        .getNavbar(menuId)
        .pipe(take(1))
        .subscribe(
          (navbar) => {
            this.navbar = this.navbarMapper.mapNavbarFromApi(navbar);
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            this.pageService.showError(window.location.pathname, error.status);
          }
        );
    }
  }

  get LinkTypeEnum() {
    return LinkTypeEnum;
  }

  isLinkActive(item: INavbarItem) {
    return item.Url === window.location.pathname;
  }

  isDropdownActive(items: INavbarItem[]) {
    return items.map((elem) => elem.Url).find((elem) => elem === window.location.pathname) ? true : false;
  }
}
