import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable()
export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    if (params.interpolateParams) {
      const key = 'default';
      const param = params.interpolateParams[key as keyof object];
      if (param === undefined) {
        return '';
      }
      return param || params.key;
    }
    return params.key;
  }
}
