<form [formGroup]="form" *ngIf="loungeService.teamEdit$ | async as teamEdit">
  <div class="main">
    <lru-team-details-form [form]="form"></lru-team-details-form>
  </div>
  <div class="footer">
    <lru-button [variant]="'lounge'" [theme]="'white'" [hollow]="true" (click)="onClickClose()()">Annuller</lru-button>
    <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" (click)="onClickSave(teamEdit.id)"
      ><ng-container
        *ngIf="(loadingService.loading$(sharedLoadingKeys.saveDetailsToTeam) | async) === false; else loading"
        >Gem</ng-container
      ></lru-button
    >
  </div>
  <lru-team-header
    [closeFunc]="onClickClose()"
    [header]="'Rediger ' + teamEdit.title"
    [subHeader]="'Skriv navn og trin'"
  ></lru-team-header>
</form>

<ng-template #loading>
  <lru-loading-animation
    [variant]="loadingAnimationVariants.BUTTON"
    *ngIf="loadingService.loading$(sharedLoadingKeys.saveDetailsToTeam) | async"
  ></lru-loading-animation>
</ng-template>
