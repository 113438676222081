import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { ToastService } from '../../../modules/toast/services/toast.service';
import { actionsThatError } from './loading.reducers';

/** Effects for loading.state.ts */
@Injectable()
export class LoadingEffects {
  /** When error occur in one of specified functions show error toast */
  showErrorMessagesAsToasts$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...actionsThatError),
        tap((action) => this.toastService.displayMessage(`Fejlbesked: ${action.error.message}`))
      );
    },
    { dispatch: false }
  );

  /** @ignore */
  constructor(private actions$: Actions, private toastService: ToastService) {}
}
