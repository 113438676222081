import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { GoogleTagManagerService } from '@lru/felib';
import { AudioPlayerTypeEnum } from '@portal-app/enums/audio-player-type.enum';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import {
  IAudioPlayerMark,
  IAudioSelectionPlayer,
  IAudioSentenceButtons,
  ISelection,
} from '@portal-app/interfaces/project/layout-audio.interface';

import { LayoutAudioService } from '@portal-app/services/shared/layout-audio.service';
import { SelectionService } from '@portal-app/services/shared/selection.service';
import { PlyrEvent } from 'plyr';

@Component({
  selector: 'portal-audio-selection-player[src][type][marks][selections][sentenceButtons]',
  templateUrl: './audio-selection-player.component.html',
  styleUrls: ['./audio-selection-player.component.scss'],
})
export class AudioSelectionPlayerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() src!: string;
  @Input() type!: AudioPlayerTypeEnum;
  @Input() marks!: IAudioPlayerMark[];
  @Input() selections!: ISelection[];
  @Input() sentenceButtons!: IAudioSentenceButtons;
  @Input() autoPlay = false;
  @Input() highlight = false;
  @Input() autofocus = false;

  player?: Plyr;
  source?: Plyr.Source;
  loadedInterval?: NodeJS.Timeout;
  playingInterval?: NodeJS.Timeout;
  item?: IAudioSelectionPlayer;
  loaded = false;

  options: Plyr.Options = {
    autoplay: false,
    invertTime: false,
    disableContextMenu: true,
    settings: ['quality', 'speed', 'loop'],
    controls: ['play'],
  };

  constructor(
    public layoutAudioService: LayoutAudioService,
    private selectionService: SelectionService,
    private gtmService: GoogleTagManagerService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    if (this.src) {
      this.source = {
        src: this.src,
        type: 'audio/mp3',
      };
    } else {
      this.source = undefined;
    }
    this.item = {
      Type: this.type,
      Marks: this.marks ? this.marks : [],
      Selections: this.selections,
      AutoPlay: this.autoPlay,
      Highlight: this.highlight,
      SentenceButtons: this.sentenceButtons,
    };
  }

  ngOnChanges() {
    if (this.item) {
      this.item = {
        Type: this.type,
        Marks: this.marks ?? [],
        Selections: this.selections,
        AutoPlay: this.autoPlay,
        Highlight: this.highlight,
        SentenceButtons: this.sentenceButtons,
      };
    }
    if (this.item) {
      if (this.item.Type === AudioPlayerTypeEnum.Full || this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
        this.item.Selections?.forEach((selection) => {
          if (this.item) {
            this.selectionService.resetSelection(selection.Target, this.item.Type);
          }
        });
      }
      if (this.item.Highlight || this.item.AutoPlay) {
        this.prepare();
      }
    }
  }

  async ngOnDestroy() {
    this.clearIntervals();
    if (this.item) {
      if (this.item.Type === AudioPlayerTypeEnum.Full || this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
        this.item.Selections?.forEach((selection) => {
          if (this.item) {
            this.selectionService.resetSelection(selection.Target, this.item.Type);
          }
        });
      }
      if (this.player) {
        await this.layoutAudioService.removePlayer(this.player, this.src);
      }
      if (this.item.Type === AudioPlayerTypeEnum.Full) {
        this.layoutAudioService.resetFull();
      }
      if (
        this.item.Type === AudioPlayerTypeEnum.PartialDesktop ||
        this.item.Type === AudioPlayerTypeEnum.PartialTouch
      ) {
        await this.layoutAudioService.togglePartialDestroyed(true);
      }
    }
  }

  clearIntervals() {
    if (this.playingInterval) {
      clearInterval(this.playingInterval);
    }
    if (this.loadedInterval) {
      clearInterval(this.loadedInterval);
    }
  }

  plyrInit(event: Plyr) {
    this.loadedInterval = setInterval(async () => {
      if (event.duration) {
        if (this.loadedInterval) {
          clearInterval(this.loadedInterval);
        }
        this.loaded = true;
        this.player = event;

        await this.layoutAudioService.addPlayer(this.player, this.src);
        if (this.item) {
          if (this.item.Highlight || this.item.AutoPlay) {
            this.prepare();
          } else if (this.item.Marks) {
            this.setSentenceButtonsEnabled(this.item.Marks, this.player.currentTime);
          }

          if (this.autofocus) {
            const element = this.elementRef.nativeElement.firstChild as HTMLElement | undefined;
            if (element) {
              element.focus();
            }
          }
        }
      }
    }, 100);
  }

  async plyrPlay(event: PlyrEvent) {
    await this.layoutAudioService.pauseOtherPlayers(this.src);

    if (this.item) {
      this.playingInterval = setInterval(() => {
        this.prepare();
      }, 100);

      if (this.item.Type === AudioPlayerTypeEnum.Full) {
        this.gtmService.pushTagWithUserType(GTMEvent.Play_full);
      } else if (this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
        this.gtmService.pushTagWithUserType(GTMEvent.Play_selected);
      }
    }

    this.layoutAudioService.handlePlyrEvents(event);
  }

  setSentenceButtonsEnabled(marks: IAudioPlayerMark[], currentTime: number) {
    if (this.item) {
      const previous = marks
        .map((item) => {
          return parseInt(item.Sentence.time, 10) / 1000;
        })
        .filter((item) => item < currentTime);
      const next = marks
        .map((item) => {
          return parseInt(item.Sentence.time, 10) / 1000;
        })
        .find((item) => item > currentTime);

      this.item.SentenceButtons = {
        PreviousEnabled: previous.length ? true : false,
        NextEnabled: next ? true : false,
      };
    }
  }

  prepare() {
    if (this.item && this.player) {
      const currentTime = Math.round((this.player?.currentTime || 0) * 1000);
      if (
        this.item.AutoPlay &&
        !this.player.playing &&
        (this.item.Type === AudioPlayerTypeEnum.Full || this.item.Type === AudioPlayerTypeEnum.PartialDesktop)
      ) {
        this.item.AutoPlay = false;
        this.player.play();
      }
      if (this.item.Marks) {
        this.setSentenceButtonsEnabled(this.item.Marks, this.player.currentTime);
        if (this.item.Type === AudioPlayerTypeEnum.Full || this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
          this.selectionService.highlightWord(
            this.item.Marks,
            currentTime,
            this.player.playing,
            this.item.Type,
            this.item.Selections
          );
        }
      }
    }
  }

  plyrPause(event: PlyrEvent) {
    this.clearIntervals();
    if (this.item && (this.item.Highlight || this.item.AutoPlay)) {
      this.prepare();

      if (event.detail.plyr.duration !== 0 && event.detail.plyr.duration !== event.detail.plyr.currentTime) {
        if (this.item.Type === AudioPlayerTypeEnum.Full) {
          this.gtmService.pushTagWithUserType(GTMEvent.Pause_full);
        } else if (this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
          this.gtmService.pushTagWithUserType(GTMEvent.Pause_selected);
        }
      } else {
        if (this.item.Type === AudioPlayerTypeEnum.Full) {
          this.gtmService.pushTagWithUserType(GTMEvent.Ended_full);
        } else if (this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
          this.gtmService.pushTagWithUserType(GTMEvent.Ended_selected);
        }
      }
    }

    this.layoutAudioService.handlePlyrEvents(event);
  }

  plyrEnded(event: PlyrEvent) {
    this.clearIntervals();
    if (this.item && this.player) {
      this.player.currentTime = 0;
      if (this.item.Type === AudioPlayerTypeEnum.Full) {
        this.item.Selections?.forEach((selection) => {
          if (this.item) {
            this.selectionService.resetSelection(selection.Target, this.item.Type);
          }
        });
        if (this.item.Marks) {
          this.setSentenceButtonsEnabled(this.item.Marks, this.player.currentTime);
        }
      } else {
        this.prepare();
      }
    }
  }

  onClickPreviousMark() {
    if (this.item && this.player && this.item?.Marks) {
      const found = this.item.Marks.map((item) => {
        return parseInt(item.Sentence.time, 10) / 1000;
      }).filter((item) => item < (this.player?.currentTime || 0));
      if (found.length) {
        this.player.currentTime = found[found.length - 1];
        if (this.player.playing && found.length > 1) {
          // this ensures that it jumps to previous sentence instead of just restarting the same sentence every time "previous" button is pressed while playing.
          this.player.currentTime = found[found.length - 2];
        } else {
          this.player.currentTime = found[found.length - 1];
        }
        const currentTime = Math.round(this.player.currentTime * 1000);
        this.selectionService.highlightWord(
          this.item.Marks,
          currentTime,
          this.player.playing,
          this.item.Type,
          this.item.Selections
        );
      }
      this.setSentenceButtonsEnabled(this.item.Marks, this.player.currentTime);
      if (this.item.Type === AudioPlayerTypeEnum.Full) {
        this.gtmService.pushTagWithUserType(GTMEvent.Backwards_full);
      } else if (this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
        this.gtmService.pushTagWithUserType(GTMEvent.Backwards_selected);
      }
    }
  }

  onClickNextMark() {
    if (this.item && this.player && this.item?.Marks) {
      const found = this.item.Marks.map((item) => {
        return parseInt(item.Sentence.time, 10) / 1000;
      }).find((item) => item > (this.player?.currentTime || 0));
      if (found !== undefined) {
        this.player.currentTime = found;
        let increase = 0;
        while (this.player.currentTime < found) {
          increase += 0.0001;
          this.player.currentTime = this.player.currentTime + increase;
        }
        const currentTime = Math.round(this.player.currentTime * 1000);
        this.selectionService.highlightWord(
          this.item.Marks,
          currentTime,
          this.player.playing,
          this.item.Type,
          this.item.Selections
        );
      }
      this.setSentenceButtonsEnabled(this.item.Marks, this.player.currentTime);
      if (this.item.Type === AudioPlayerTypeEnum.Full) {
        this.gtmService.pushTagWithUserType(GTMEvent.Forward_full);
      } else if (this.item.Type === AudioPlayerTypeEnum.PartialDesktop) {
        this.gtmService.pushTagWithUserType(GTMEvent.Forward_selected);
      }
    }
  }
}
