import { Component, Input } from '@angular/core';
import { IBookTag } from '@portal-app/interfaces/book/project/book.interface';

@Component({
  selector: 'portal-book-difficulties[items]',
  templateUrl: './book-difficulties.component.html',
  styleUrls: ['./book-difficulties.component.scss'],
})
export class BookDifficultiesComponent {
  @Input() items!: IBookTag[];
}
