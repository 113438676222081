import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ILink } from '../../interfaces/project/link.interface';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private data = new ReplaySubject<ILink[]>(1);
  data$ = this.data.asObservable();

  setBreadcrumbs(items?: ILink[]) {
    if (items) {
      this.data.next(items);
    }
  }
}
