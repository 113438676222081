import { IZIndexConfig } from '../z-index-config.interface';

export interface ZIndexState {
  config: IZIndexConfig;
  currentZIndex: number;
  items: ZIndexItem[];
}

export interface ZIndexItem {
  id: string;
  zIndex: number;
}

export const initialZIndexState: ZIndexState = {
  config: {},
  currentZIndex: 0,
  items: [],
};
