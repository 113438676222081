/**
 * counter ensures that each id becomes unique since the same number wont be used twice
 */
let counter = 0;

/**
 * generates a custom unique id
 * @param prefix id prefix
 * @returns custom unique id
 */
export function createUniqueId(prefix?: string): string {
  return `${prefix || 'uuid'}-${counter++}`;
}
