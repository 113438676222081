import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ZIndexService } from '../../../z-index/services/z-index.service';
import { ToastService } from '../../public-api';
import { toastHorizontalPosition, toastVerticalPosition } from '../../toast-types';
import { toastAnimateInOut } from '../../toast.animations';

/**
 * Toast messages
 */
@Component({
  selector: 'lru-toast-message-list',
  templateUrl: './toast-message-list.component.html',
  styleUrls: ['./toast-message-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toastAnimateInOut],
})
export class ToastMessageListComponent implements OnDestroy {
  /** style.gap */
  appliedSpacingBetweenToasts?: string;

  /** style.padding */
  appliedSpacingFromWindow?: string;

  /** attr.data-horizontal-position.
   * Used to make it possible to add specific styling for this. */
  appliedHorizontalPosition?: toastHorizontalPosition;

  /** attr.data-vertical-position.
   * Used to make it possible to add specific styling for this. */
  appliedVerticalPosition?: toastVerticalPosition;

  /** attr.data-variant.
   * Used to make it possible to add specific styling for this. */
  appliedVariant?: string;

  /** Array of toast messages to display */
  public readonly messages$ = this.toastService.messages$;

  /** z-index for toasts container */
  public readonly zIndex$ = this.zIndexService.item$('felib-toast');

  /** Get toast config */
  private toastConfigSubscription = this.toastService.config$.subscribe((data) => {
    this.appliedHorizontalPosition = data.horizontalPosition;
    this.appliedVerticalPosition = data.verticalPosition;
    this.appliedSpacingBetweenToasts = data.spacingBetweenToasts ? `${data.spacingBetweenToasts}px` : undefined;
    this.appliedSpacingFromWindow = data.spacingFromWindow ? `${data.spacingFromWindow}px` : undefined;
    this.appliedVariant = data.variant;
  });

  /**
   * @ignore
   */
  constructor(private toastService: ToastService, private zIndexService: ZIndexService) {}

  /** pause countdown for toast to go away when hovering it with cursor */
  public onMouseenter(event: MouseEvent): void {
    // Only pause the toast messages if we're not animating.
    // The mouseenter event is sometimes fired when animating elements pass the cursor, but the mouseleave event isn't.
    if (!(<Element>event.target).classList.contains('ng-animating')) {
      this.toastService.pause();
    }
  }

  /** resume countdown for toast to go away when removing cursor from it */
  public onMouseleave(): void {
    this.toastService.continue();
  }

  ngOnDestroy(): void {
    this.toastConfigSubscription.unsubscribe();
  }
}
