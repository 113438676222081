import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModule } from '@portal-app/modules/shared/base/base.module';
import { LibrarySearchModule } from '../../shared/library-search/library-search.module';
import { LayoutAudioModule } from '../layout-audio/layout-audio.module';
import { HeaderBookComponent } from './components/header-book/header-book.component';
import { HeaderCourseComponent } from './components/header-course/header-course.component';
import { HeaderDefaultComponent } from './components/header-default/header-default.component';
import { HeaderComponent } from './components/header/header.component';
import { MegaMenuComponent } from './components/mega-menu/mega-menu.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UserComponent } from './components/user/user.component';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderDefaultComponent,
    HeaderBookComponent,
    HeaderCourseComponent,
    NavbarComponent,
    MegaMenuComponent,
    UserComponent,
  ],
  imports: [CommonModule, BaseModule, LibrarySearchModule, LayoutAudioModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
