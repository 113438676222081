export enum UmbSettingWidthEnum {
  fullWidth = 'full-width',
  large = 'large',
  medium = 'medium',
  small = 'small',
}

export enum UmbSettingAlignEnum {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
