import { IQueryStrings } from '@portal-app/interfaces/project/query-strings.interface';

export const QueryStrings: IQueryStrings = {
  About: { da: 'om', en: 'about' },
  Activities: { da: 'aktiviteter', en: 'activities' },
  AnnualPlan: { da: 'aarsplan', en: 'annualplan' },
  AssignmentTypes: { da: 'opgavetyper', en: 'assignmenttypes' },
  Assignments: { da: 'opgaver-aktiviteter', en: 'assignments' },
  AssetTopics: { da: 'emner', en: 'assettopics' },
  Audios: { da: 'lyd', en: 'audios' },
  Categories: { da: 'emner', en: 'categories' },
  Class: { da: 'klasse', en: 'class' },
  Classes: { da: 'klasser', en: 'classes' },
  CommonGoals: { da: 'faelles-maal', en: 'commongoals' },
  CollaborationTypes: { da: 'samarbejdsformer', en: 'collaborationtypes' },
  Courses: { da: 'forloeb', en: 'courses' },
  DifficultyGroups: { da: 'laeseniveauer', en: 'difficultygroups' },
  Documents: { da: 'dokumenter', en: 'documents' },
  Downloads: { da: 'downloads', en: 'downloads' },
  Edit: { da: 'edit', en: 'edit' },
  Gallery: { da: 'galleri', en: 'gallery' },
  Genres: { da: 'genre', en: 'genres' },
  Geogebras: { da: 'geogebras', en: 'geogebras' },
  Grades: { da: 'klassetrin', en: 'grades' },
  HasAudioBook: { da: 'audio-bog', en: 'audiobook' },
  Illustrations: { da: 'illustrationer', en: 'illustrations' },
  Images: { da: 'billeder', en: 'images' },
  Languages: { da: 'sprog', en: 'languages' },
  Levels: { da: 'klassetrin', en: 'levels' },
  Link: { da: 'link', en: 'link' },
  List: { da: 'liste', en: 'list' },
  OnlyFavourite: { da: 'kun-favoritter', en: 'onlyfavorite' },
  OnlyLicense: { da: 'kun-licens', en: 'onlylicense' },
  Open: { da: 'aabne', en: 'open' },
  Page: { da: 'side', en: 'page' },
  Query: { da: 'soegning', en: 'query' },
  ReadingLevels: { da: 'laeseniveau', en: 'readinglevels' },
  SeriesTitle: { da: 'serier', en: 'series' },
  Student: { da: 'elev', en: 'student' },
  SubTab: { da: 'under-tab', en: 'subtab' },
  Subjects: { da: 'fag', en: 'subjects' },
  Tab: { da: 'tab', en: 'tab' },
  Texts: { da: 'tekster', en: 'texts' },
  Topics: { da: 'emner', en: 'topics' },
  Videos: { da: 'videoer', en: 'videos' },
  Writers: { da: 'forfattere', en: 'writers' },
  Year: { da: 'aar', en: 'year' },
  Periods: { da: 'periode', en: 'period' },
};
