import { Injectable, OnDestroy } from '@angular/core';
import { share, Subject } from 'rxjs';

/** A service for interacting with the localstorage in the browser */
@Injectable({ providedIn: 'root' })
export class StorageService implements OnDestroy {
  /** {@link Subject} that is used for sending emitted events from the localstorage eventlistener to */
  private localStorageChanged = new Subject<StorageEvent>();
  /** Observable that will emit a {@link StorageEvent} when the localstorage has changed */
  localStorageChanged$ = this.localStorageChanged.pipe(share({ resetOnRefCountZero: true }));

  /** In the constructor we adds an event listener to the window that listens to changes to the local storage. If storage changes we emit value to our {@link localStorageChanged} */
  constructor() {
    window.addEventListener('storage', (event) => this.localStorageChanged.next(event), true);
  }

  /** Retrieves a string with the value from the localstorage.
   * @param key The key of the key/value pair to retrieve
   * @use {@link getObject} for returning a JSON object
   * @returns Will return undefined if key doesn't exist */
  get(key: string): string | undefined {
    return localStorage.getItem(key) || undefined;
  }

  /** Retrieve object from local storage. Keep in mind that this method will not return a true class with access to methods etc.
   * @param key The key of the key/value pair to retrieve
   * @returns Returns the object from the localstorage. Will return undefined if key doesn't exist */
  getObject<T>(key: string): T | undefined {
    const jsonString = localStorage.getItem(key);

    if (jsonString == null) {
      return;
    }

    return <T>JSON.parse(jsonString);
  }

  /** Create / set the key/value pair with strings as values in the local storage
   * @param key The key to save the string under in the storage
   * @param value The string to store
   * @use {@link setObject} for objects */
  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  /** Create / set the key/value pair with objects as values in the local storage
   * @param key The key to save the object under in the storage
   * @param value The object to store
   * @use {@link set} for simple strings */
  setObject(key: string, value: object): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /** Delete a key/value pair from the local storage
   * @param key The key of the key/value pair to delete */
  delete(key: string): void {
    localStorage.removeItem(key);
  }

  /** Clear all key/value pairs in the local storage
   *
   * Warning: this will clear the whole localstorage
   * @use {@link delete} for removing single key/value pairs */
  deleteAll(): void {
    localStorage.clear();
  }

  /** Unsubscribes from the event listener we put on the window */
  ngOnDestroy(): void {
    window.removeEventListener('storage', (event) => this.localStorageChanged.next(event), false);
  }
}
