import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GoogleTagManagerService, UserService } from '@lru/felib';
import { ContentTypeEnum } from '@portal-app/enums/content-type.enum';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import { IBookTile_template } from '@portal-app/interfaces/project/section.interface';
import { BookContinueService } from '@portal-app/services/book/book-continue.service';
import { BookFinishedService } from '@portal-app/services/book/book-finished.service';
import { ExtendedDialogService } from '@portal-app/services/shared/extended-dialog.service';

import { ISliderSettings } from '@portal-app/modules/shared/slider/interfaces/slider-settings.interface';
import { firstValueFrom } from 'rxjs';
import { PageTypeEnum } from '../../../../../enums/page-type.enum';
import { SelectImpressionsModalComponent } from '../select-impressions-modal/select-impressions-modal.component';

@Component({
  selector: 'portal-book-tile[item][settings][type]',
  templateUrl: './book-tile.component.html',
  styleUrls: ['./book-tile.component.scss'],
})
export class BookTileComponent implements OnInit {
  @Input() item?: IBookTile_template;
  @Input() showProgressbar = false;
  @Input() settings?: ISliderSettings;
  @Input() type?: SectionTypeEnum;
  user = this.userService.isLoggedIn;
  isBookFinished = false;

  constructor(
    private bookFinishedService: BookFinishedService,
    private dialog: ExtendedDialogService,
    private bookContinueService: BookContinueService,
    private userService: UserService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    this.isBookFinished = this.item?.Progress === 100;
  }

  /**
   * Remove a book from either the continue list or the finish book list
   */
  async removeFromList() {
    if (this.item?.Id) {
      if (this.settings?.Type === SectionTypeEnum.BookContinue) {
        await firstValueFrom(this.bookContinueService.setBookContinue(this.item.Id, '', 0));
        this.gtmService.pushTagWithUserType(GTMEvent.Overview_continuebook_remove, { book: this.item.Title });
      } else {
        await firstValueFrom(this.bookFinishedService.setBookFinished(this.item.Id, false));
      }

      if (this.settings?.Type === SectionTypeEnum.FinishedBooks) {
        this.gtmService.pushTagWithUserType(GTMEvent.Overview_readbook_remove, {
          book: this.item.Title,
        });
      }
    }
  }

  onImpressionsClick() {
    const dialogConfig = new MatDialogConfig<IBookTile_template>();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = true;
    dialogConfig.data = this.item;
    this.dialog.open(SelectImpressionsModalComponent, dialogConfig);
  }

  get SectionTypeEnum() {
    return SectionTypeEnum;
  }

  get ContentTypeEnum() {
    return ContentTypeEnum;
  }

  menuOpened() {
    document.documentElement.classList.add('cdk-dropdown');
  }

  menuClosed() {
    document.documentElement.classList.remove('cdk-dropdown');
  }

  onTileClick() {
    this.gtmService.pushTagWithUserType(GTMEvent.Open_book, {
      book: this.item?.Title,
      type: this.type,
    });
  }

  get PageTypeEnum() {
    return PageTypeEnum;
  }
}
