import { Injectable } from '@angular/core';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { PsLibraryFilterEnum } from '../../enums/ps/ps-library-filter.enum';
import { IPsLibrary } from '../../interfaces/ps/project/ps-library.interface';
import { IUmbPsLibrary } from '../../interfaces/ps/umbraco/umb-ps-library.interface';
import { PsArticleMapper } from './ps-article.mapper';
import { PsAssignmentMapper } from './ps-assignment.mapper';

@Injectable({
  providedIn: 'root',
})
export class PsLibraryMapper {
  constructor(private psAssignmentMapper: PsAssignmentMapper, private psArticleMapper: PsArticleMapper) {}

  mapLibraryFromApi(data: IUmbPsLibrary): IPsLibrary {
    return {
      Classes: data.classTags?.length
        ? data.classTags.map((item) => ({
            Id: item.id,
            Title: item.title,
            Selected: item.selected,
            Type: PsLibraryFilterEnum.Class,
            Count: item.itemsCount,
          }))
        : [],
      Subjects: data.subjectTags?.length
        ? data.subjectTags.map((item) => ({
            Id: item.id,
            Title: item.title,
            Selected: item.selected,
            BackgroundColor: item.backgroundColor,
            TextColor: item.textColor,
            Type: PsLibraryFilterEnum.Subject,
            Count: item.itemsCount,
          }))
        : [],
      Topics: data.topicTags?.length
        ? data.topicTags.map((item) => ({
            Id: item.id,
            Title: item.title,
            Selected: item.selected,
            Type: PsLibraryFilterEnum.Topic,
            Count: item.itemsCount,
          }))
        : [],
      Assignments: data.assignments?.length
        ? this.psAssignmentMapper.mapPsAssignmentsFromApi(data.assignments, 250)
        : [],
      Articles: data.articles?.length ? this.psArticleMapper.mapPsArticlesFromApi(data.articles) : [],
      Pagination: {
        CollectionSize: data.pagination.collectionSize,
        Index: data.pagination.pageIndex,
        PageSize: data.pagination.pageSize,
        Type: PageTypeEnum.PsArticleLibrary,
      },
    };
  }
}
