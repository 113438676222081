<button
  [attr.data-variant]="variant"
  [attr.data-theme]="theme"
  [attr.data-size]="size"
  [attr.data-hollow]="hollow"
  [class]="classes"
  [disabled]="disabled"
  [attr.type]="type"
  [style.padding]="paddingPX"
>
  <ng-content></ng-content>
</button>
