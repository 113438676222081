export const GTMJS = 'gtm.js';

export const Page = 'page';

export const LoungeCreateTeam = 'lounge-create-team-start';

export const LoungeCreateTeamDone = 'lounge-create-team-done';

export const LoungeEditTeam = 'lounge-edit-team-start';

export const LoungeEditTeamDone = 'lounge-edit-team-done';

export const LoungeEditTeacher = 'lounge-edit-teacher-start';

export const LoungeEditTeacherDone = 'lounge-edit-teacher-done';

export const LoungeRemoveTeacherDone = 'lounge-remove-teacher-done';

export const LoungeEditTeamSettingsDone = 'lounge-edit-teamsettings-done';

export const LoungeDeleteTeamDone = 'lounge-delete-team-done';

export const LoungeSort = 'lounge-sort';
