<h1>Dine hold</h1>
<div class="hr"></div>
<div class="buttons">
  <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" [hollow]="true" (click)="onClickCreateTeam()"
    >Opret hold</lru-button
  >
</div>
<lru-modal
  [isVisible]="loungeModalService.modalActive || false"
  (isVisibleChange)="onModalVisibilityChange($event)"
  [variant]="'lounge'"
  [height]="(loungeModalService.modalHeight$ | async) ?? undefined"
>
  <lru-team-creation></lru-team-creation>
</lru-modal>
<div class="sort">
  <lru-dropdown
    [variant]="'lounge'"
    [sameWidth]="false"
    [spacingFromWindow]="10"
    [spacingFromButton]="10"
    horizontalDirection="left"
    #sort
  >
    <ng-container button>
      <lru-button [variant]="'lounge'" [theme]="'white'">
        <span>Sorter</span>
        <lru-icon [icon]="{ path: 'felib-sort', size: 12 }"></lru-icon>
      </lru-button>
    </ng-container>
    <ng-container>
      <ul class="sort-content">
        <li>
          <lru-button
            [variant]="'lounge'"
            [theme]="'white'"
            [class.active]="(sortMode$ | async) === sortModeEnum.alphabetically"
            (click)="onClickSort(sortModeEnum.alphabetically); sort.onCloseClick()"
          >
            <span>Alfabetisk</span>
            <lru-icon
              *ngIf="(sortMode$ | async) === sortModeEnum.alphabetically"
              [icon]="{ path: 'felib-check', size: 12 }"
            ></lru-icon>
          </lru-button>
        </li>
        <li>
          <lru-button
            [variant]="'lounge'"
            [theme]="'white'"
            [class.active]="(sortMode$ | async) === sortModeEnum.creationDate"
            (click)="onClickSort(sortModeEnum.creationDate); sort.onCloseClick()"
          >
            <span>Nyeste først</span>
            <lru-icon
              *ngIf="(sortMode$ | async) === sortModeEnum.creationDate"
              [icon]="{ path: 'felib-check', size: 12 }"
            ></lru-icon>
          </lru-button>
        </li>
        <li>
          <lru-button
            [variant]="'lounge'"
            [theme]="'white'"
            [class.active]="(sortMode$ | async) === sortModeEnum.updatedDate"
            (click)="onClickSort(sortModeEnum.updatedDate); sort.onCloseClick()"
          >
            <span>Senest ændret</span>
            <lru-icon
              *ngIf="(sortMode$ | async) === sortModeEnum.updatedDate"
              [icon]="{ path: 'felib-check', size: 12 }"
            ></lru-icon>
          </lru-button>
        </li>
      </ul>
    </ng-container>
  </lru-dropdown>
</div>
<ng-container *ngIf="(loadingService.loading$(sharedLoadingKeys.teams) | async) === false; else loading">
  <ng-container *ngIf="teams$ | async as teams; else loading">
    <div class="teams" *ngIf="teams.length > 0">
      <lru-team-box *ngFor="let item of teams" [model]="item"></lru-team-box>
    </div>
    <p *ngIf="teams.length === 0">Du har ikke oprettet nogen hold.</p>
  </ng-container>
</ng-container>

<ng-template #loading>
  <lru-loading-animation *ngIf="loadingService.loading$(sharedLoadingKeys.teams) | async"></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.teams) | async as error">{{ error }}</p>
</ng-template>
