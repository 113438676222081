import { Component } from '@angular/core';
import { AudioPlayerTypeEnum } from '@portal-app/enums/audio-player-type.enum';
import { LayoutAudioService } from '@portal-app/services/shared/layout-audio.service';

@Component({
  selector: 'portal-layout-audio-partial',
  templateUrl: './layout-audio-partial.component.html',
  styleUrls: ['./layout-audio-partial.component.scss'],
})
export class LayoutAudioPartialComponent {
  public data$ = this.layoutAudioService.data$;

  constructor(public layoutAudioService: LayoutAudioService) {}

  get AudioPlayerTypeEnum() {
    return AudioPlayerTypeEnum;
  }
}
