<div class="main">
  <lru-team-picker-teacher></lru-team-picker-teacher>
</div>
<div class="footer">
  <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" (click)="onClickSave()">
    <ng-container
      *ngIf="(loadingService.loading$(sharedLoadingKeys.teamSaveTeachersToTeam) | async) === false; else loading"
      >Gem ({{ (loungeService.selectedTeachersList$ | async)?.length }} valgte)</ng-container
    >
  </lru-button>
</div>
<lru-team-header
  *ngIf="teamEdit"
  [closeFunc]="onClickClose()"
  [header]="'Del ' + teamEdit.title + ' med lærere'"
  [subHeader]="'Tilføj og fjern lærere fra holdet'"
>
</lru-team-header>

<ng-template #loading>
  <lru-loading-animation
    [variant]="loadingAnimationVariants.BUTTON"
    *ngIf="loadingService.loading$(sharedLoadingKeys.teamSaveTeachersToTeam) | async"
  ></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.teamSaveTeachersToTeam) | async as error">{{ error }}</p>
</ng-template>
