<div class="main">
  <lru-team-picker></lru-team-picker>
</div>
<div class="footer">
  <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" (click)="onClickSave()">
    <ng-container
      *ngIf="(loadingService.loading$(sharedLoadingKeys.teamSaveStudentsToTeam) | async) === false; else loading"
      >Gem ({{ (loungeService.selectedUsersList$ | async)?.length }} valgte)</ng-container
    >
  </lru-button>
</div>
<lru-team-header
  [closeFunc]="onClickClose()"
  [header]="'Rediger elever'"
  [subHeader]="'Tilføj og fjern elever fra holdet'"
></lru-team-header>

<ng-template #loading
  ><lru-loading-animation [variant]="loadingAnimationVariants.BUTTON"></lru-loading-animation
></ng-template>
