import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PageTypeEnum } from '../../../../../enums/page-type.enum';
import { IDropdown } from '../../../../../interfaces/project/dropdown.interface';
import { BookLibraryService } from '../../../../../services/book/book-library.service';
import { CourseLibraryService } from '../../../../../services/course/course-library.service';
import { AppInitService } from '../../../../../services/shared/app-init.service';
import { BreakpointsService } from '../../../../../services/shared/breakpoints.service';
import { DropdownService } from '../../../../../services/shared/dropdown.service';

@Component({
  selector: 'portal-dropdown[ariaLabel]',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() id: string = this.generateUUID();
  @Input() darkOverlay = false;
  @Input() zIndex!: number;
  @Input() buttonClasses!: string;
  @Input() contentClasses!: string;
  @Input() pageType!: string;
  @Input() libraryUrl!: string;
  @Input() useArrow = false;
  @Input() iconSize = 10;
  @Input() ariaLabel!: string;
  @Input() useCustomAriaLabel = false;
  dropdownOpen?: IDropdown;
  large = false;

  constructor(
    private dropdownService: DropdownService,
    private breakpointsService: BreakpointsService,
    private router: Router,
    private appInitService: AppInitService,
    private courseLibraryService: CourseLibraryService,
    private bookLibraryService: BookLibraryService
  ) {}

  ngOnInit() {
    this.dropdownService.open$.subscribe((value) => {
      this.dropdownOpen = value;
    });

    this.breakpointsService.large$.subscribe((data) => {
      if (data && this.dropdownOpen?.Id === 'search') {
        this.dropdownService.closeDropdown();
      }
      this.large = data;
    });
  }

  onToggleDropdown() {
    if (this.id === 'search') {
      this.breakpointsService.large$.pipe(take(1)).subscribe((data) => {
        if (data) {
          if (this.pageType !== PageTypeEnum.CourseLibrary && this.pageType !== PageTypeEnum.BookLibrary) {
            this.router.navigate([this.libraryUrl]);
          } else {
            // set focus on searchInput
          }
        }
      });
      this.breakpointsService.underLarge$.pipe(take(1)).subscribe((data) => {
        if (data) {
          if (this.pageType !== PageTypeEnum.CourseLibrary && this.appInitService.isFagportal) {
            this.displaySearchOnFagportal();
          } else if (
            (this.appInitService.isSuperbog || this.appInitService.isSuperreader) &&
            this.pageType !== PageTypeEnum.BookLibrary
          ) {
            this.displaySearchOnBoodAndReader();
          }
        }
      });
    } else {
      // Remove search field modal
      this.dropdownService.toggleDropdown(this.id, this.darkOverlay);
    }
  }

  /**
   * On Superbog and Superreader we display the search input field as an modal, unless the user is on the library page
   */
  private displaySearchOnBoodAndReader() {
    if (!this.bookLibraryService.filters.FiltersLoaded) {
      this.bookLibraryService.initializeFilters();
    }

    if (this.libraryUrl !== window.location.pathname) {
      this.displaySearchField();
    }
  }

  /**
   * On Fagportal we don't have the search input field on the library page on small devices.
   * We display the search input field as an modal when ever the search icon in the header is clicked.
   */
  private displaySearchOnFagportal() {
    if (!this.courseLibraryService.filters.FiltersLoaded) {
      this.courseLibraryService.initializeFilters();
    }
    this.displaySearchField();
  }

  /**
   * Displaying the search input field after the loader is finish
   */
  private displaySearchField() {
    this.dropdownService.toggleDropdown(this.id, this.darkOverlay, this.zIndex);
  }

  generateUUID() {
    let d = new Date().getTime(); // Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) {
        // Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
}
