<portal-header
  *ngIf="appInitService.siteConfiguration.PortalId"
  [attr.data-behind]="behind"
  [pageType]="pageType"
  [headerType]="headerType"
  [behind]="behind"
>
</portal-header>
<div id="content">
  <router-outlet></router-outlet>
</div>
<portal-footer *ngIf="appInitService.siteConfiguration.PortalId" class="screen"></portal-footer>
<portal-loader class="screen"></portal-loader>
<portal-dropdown-overlay class="screen"></portal-dropdown-overlay>
<lru-modal-outlet></lru-modal-outlet>
<lru-dropdown-outlet></lru-dropdown-outlet>
<lru-toast-message-list></lru-toast-message-list>
