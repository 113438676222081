import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { LoadingAnimationVariant } from '../../../../../components/loading-animation/enum/loading-animation-variant.enum';
import { LoadingService } from '../../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '../../../../../services/loading/shared-loading-keys.enums';
import { LoungeModalService } from '../../../services/lounge-modal.service';
import { LoungeService } from '../../../services/lounge.service';

/** A component used for editing team details */
@Component({
  selector: 'lru-team-edit-details',
  templateUrl: './team-edit-details.component.html',
  styleUrls: ['./team-edit-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditDetailsComponent implements OnInit {
  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** @see {@link LoadingAnimationVariant} */
  loadingAnimationVariants = LoadingAnimationVariant;

  /** FormControl used for handling title of team */
  private title = new FormControl('', {
    validators: [Validators.required, Validators.minLength(2), Validators.maxLength(25)],
    updateOn: 'blur',
  });

  /** FormControl used for handling level of team */
  private level = new FormControl('', { validators: [Validators.required], updateOn: 'blur' });

  /** FormGroup consisting of {@link title} and {@link level}. Used for validating and handling values. */
  form: FormGroup = new FormGroup({
    title: this.title,
    level: this.level,
  });

  /** @ignore */
  constructor(
    public loungeService: LoungeService,
    public loadingService: LoadingService,
    private loungeModalService: LoungeModalService
  ) {}

  /** Retrieve the team to edit from the store and set values from the team in the forms */
  async ngOnInit(): Promise<void> {
    const teamEdit = await firstValueFrom(this.loungeService.teamEdit$);

    if (teamEdit) {
      this.title.setValue(teamEdit.title);
      this.level.setValue(teamEdit.levelIds[0]);
    }
  }

  /** Function that asks {@link LoungeModalService} to set modal page to none.
   * @returns A function that is used for inputting to the header and called from there. */
  onClickClose(): Function {
    return () => this.loungeModalService.setPageToNone();
  }

  /** Checks validity of forms and asks {@LoungeService} to save details to the team. If forms aren't valid mark forms as touched and therefore show error messages.
   * @param teamId The id of the team to save the details to */
  onClickSave(teamId: string): void {
    if (this.form.valid && this.title.value && this.level.value) {
      this.loungeService.teamSaveDetailsToTeam(
        teamId,
        this.title.value,
        [this.level.value],
        SharedLoadingKeys.saveDetailsToTeam
      );
    } else {
      this.form.markAllAsTouched();
    }
  }
}
