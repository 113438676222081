<svg class="loading-animation-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
  <circle
    cx="50"
    cy="50"
    fill="none"
    [attr.r]="radius"
    stroke="currentColor"
    [attr.stroke-dasharray]="circleCutout + ' ' + circleCutout * 2"
    stroke-linecap="round"
    [attr.stroke-width]="strokeWidth"
  />
</svg>
