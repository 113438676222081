import { createReducer, on } from '@ngrx/store';
import {
  levelLoadLevels,
  levelLoadLevelsSuccess,
  levelLoadLevelsFailure,
  dlpLoadClassesWithStudentsFailure,
  dlpLoadTeachersFailure,
  teamAddTeacherFailure,
  teamAddTeachersFailure,
  teamCreateTeamFailure,
  teamDeleteTeamFailure,
  teamEditDetailsFailure,
  teamLoadSingleTeamForEditFailure,
  teamLoadStudentsFailure,
  teamLoadTeamsFailure,
  teamRemoveTeacherSelfFailure,
  teamSaveDetailsToTeamFailure,
  teamSaveStudentsToTeamFailure,
  teamLoadTeams,
  teamLoadTeamsSuccess,
  dlpLoadClassesWithStudents,
  dlpLoadClassesWithStudentsSuccess,
  teamCreateTeam,
  teamCreateTeamSuccess,
  teamEditAssignTeachers,
  teamEditAssignTeachersSuccess,
  teamAddTeachers,
  teamAddTeachersSuccess,
  teamLoadStudents,
  teamLoadStudentsSuccess,
  teamSaveDetailsToTeam,
  teamSaveDetailsToTeamSuccess,
  teamLoadSingleTeamForEdit,
  teamEditTeam,
  teamSaveStudentsToTeam,
  teamSaveStudentsToTeamSuccess,
} from '../../../modules/lounge/store/lounge.actions';
import { startLoading, finishLoading, loadFailed } from './loading.actions';

import { initialLoadingState, LoadingState, LoadStateEnum } from './loading.state';

export const loadingFeatureKey = 'loadingState';

// Add actions to this list that should automatically start loading. Actions needs to have "loadingKey: string" in their props.
const actionsThatStartLoad = [
  levelLoadLevels,
  teamLoadTeams,
  dlpLoadClassesWithStudents,
  teamCreateTeam,
  teamEditAssignTeachers,
  teamAddTeachers,
  teamLoadStudents,
  teamSaveDetailsToTeam,
  teamLoadSingleTeamForEdit,
  teamSaveStudentsToTeam,
];

// Add actions to this list that should automatically finish loading. Actions needs to have "loadingKey: string" in their props.
const actionsThatFinishLoad = [
  levelLoadLevelsSuccess,
  teamLoadTeamsSuccess,
  dlpLoadClassesWithStudentsSuccess,
  teamCreateTeamSuccess,
  teamEditAssignTeachersSuccess,
  teamAddTeachersSuccess,
  teamLoadStudentsSuccess,
  teamSaveDetailsToTeamSuccess,
  teamEditTeam,
  teamSaveStudentsToTeamSuccess,
];

// Add actions to this list that should automatically add the returned error to the store. Actions needs to have "loadingKey: string" and "error: Error" in their props.
export const actionsThatError = [
  levelLoadLevelsFailure,
  dlpLoadTeachersFailure,
  dlpLoadClassesWithStudentsFailure,
  teamLoadTeamsFailure,
  teamCreateTeamFailure,
  teamLoadStudentsFailure,
  teamSaveStudentsToTeamFailure,
  teamDeleteTeamFailure,
  teamEditDetailsFailure,
  teamSaveDetailsToTeamFailure,
  teamRemoveTeacherSelfFailure,
  teamAddTeacherFailure,
  teamAddTeachersFailure,
  teamLoadSingleTeamForEditFailure,
];

export const loadingReducer = createReducer(
  initialLoadingState,
  on(startLoading, ...actionsThatStartLoad, (state, action) =>
    assignCallStateToState(state, action.loadingKey, LoadStateEnum.LOADING)
  ),
  on(finishLoading, ...actionsThatFinishLoad, (state, action) =>
    assignCallStateToState(state, action.loadingKey, LoadStateEnum.LOADED)
  ),
  on(loadFailed, ...actionsThatError, (state, action) => assignCallStateToState(state, action.loadingKey, action.error))
);

function assignCallStateToState(
  state: LoadingState,
  loadingKey: string,
  callState: LoadStateEnum | Error
): LoadingState {
  const newCallStates = state.callStates.filter((x) => x.loadingKey !== loadingKey);

  return {
    ...state,
    callStates: [...newCallStates, { loadingKey, callState }],
  };
}
