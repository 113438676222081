import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { FacetTypeEnum } from '@portal-app/enums/facet-type.enum';
import {
  ILibraryFacet,
  ILibraryFacets,
  ISearchAutoComplete,
} from '@portal-app/interfaces/course/project/course-library.interface';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { INextBookSearchAutoCompleteResponse } from '../../interfaces/book/next/next-book-library.interface';
import { INextSearchResponseFacets } from '../../interfaces/next/next-library';
import { BookMapper } from './book.mapper';

@Injectable({
  providedIn: 'root',
})
export class BookLibraryMapper {
  constructor(
    private helperService: HelperService,
    private bookMapper: BookMapper,
    private translationService: TranslateService
  ) {}

  mapFacetsFromApi(facets: INextSearchResponseFacets[]) {
    const result: ILibraryFacets[] = [];
    if (facets.length) {
      for (const facet of facets) {
        if (FacetTypeEnum[facet.type as keyof typeof FacetTypeEnum] && facet.items.length) {
          // 2021-11-30
          // for some reason it should not be possible to select books with no audio book
          // Oskars decision. Frederik and Olivia does not agree with this
          facet.items = facet.items.filter(
            (item) =>
              (facet.type === FacetTypeEnum.HasAudioBook && item.value.toLowerCase() === 'true') ||
              facet.type !== FacetTypeEnum.HasAudioBook
          );
          result.push({
            Title: `FacetType.${facet.type}`,
            UrlTitle: QueryStrings[facet.type as keyof typeof QueryStrings]?.en,
            Type: FacetTypeEnum[facet.type as keyof typeof FacetTypeEnum],
            Items: facet.items.map((item) => {
              let facetResult: ILibraryFacet;
              if (facet.type === FacetTypeEnum.Illustrations) {
                facetResult = {
                  Title: this.translationService.instant(`IllustrationTag.${item.value}`),
                  Value: item.key,
                  Count: item.count,
                  Selected: item.selected,
                };
              } else if (facet.type === FacetTypeEnum.HasAudioBook) {
                facetResult = {
                  Title: this.translationService.instant('Label.HasAudioBook'),
                  Value: item.key,
                  Count: item.count,
                  Selected: item.selected,
                };
              } else {
                facetResult = {
                  Title: item.value,
                  Value: item.key,
                  Count: item.count,
                  Selected: item.selected,
                };
              }
              return facetResult;
            }),
            Open: this.helperService.getParameterByQueryStringAndSuffix(
              QueryStrings[facet.type as keyof typeof QueryStrings],
              QueryStrings.Open
            )
              ? true
              : false,
          });
        }
      }
    }
    return result;
  }

  mapAutoCompleteFromApi(query: string, autocomplete: INextBookSearchAutoCompleteResponse) {
    const result: ISearchAutoComplete = {
      Suggestions: [],
      Courses: [],
      Books: [],
      BookCollections: [],
    };
    if (autocomplete.autocompleteItems) {
      for (const item of autocomplete.autocompleteItems) {
        if (item.queryPlusText !== query) {
          result.Suggestions.push(item.queryPlusText);
        }
      }
    }
    if (autocomplete.suggestions) {
      for (const item of autocomplete.suggestions) {
        if (item.book) {
          const book = this.bookMapper.mapBookFromApi2(item.book);
          result.Books.push(book);
        }
      }
    }

    return result;
  }
}
