import { Component, Input } from '@angular/core';
import { IBookTile } from '@portal-app/interfaces/project/section.interface';
import { ISliderSettings } from '@portal-app/modules/shared/slider/interfaces/slider-settings.interface';

@Component({
  selector: 'portal-book-card-preview[item][settings]',
  templateUrl: './book-card-preview.component.html',
  styleUrls: ['./book-card-preview.component.scss'],
})
export class BookCardPreviewComponent {
  @Input() item!: IBookTile;
  @Input() settings!: ISliderSettings;
}
