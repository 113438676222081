import { CdkPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { throttle } from 'throttle-debounce';
import { v4 as uuidv4 } from 'uuid';
import { getLastItemFromArray } from '../../../../utils/array';
import { focusableElements } from '../../../../utils/query-selectors';
import { ZIndexService } from '../../../z-index/services/z-index.service';
import { DropdownConfig } from '../../dropdown-config';
import {
  dropdownAxis,
  dropdownHorizontalDirection,
  dropdownVerticalDirection,
  dropdownVisibility,
} from '../../dropdown-types';
import { DropdownService } from '../../dropdown.service';

/** A component for displaying dropdowns.
 * The content passed will be the content of the dropdown.
 * The dropdownOpen is controlled by the `isOpen` attribute.
 * If the dropdown is hidden (or displayed) by user interaction an `isOpenChange` event will be fired.
 * The component isn't rendered in place, but moved to the <app-dropdown-outlet> component automatically to prevent issues with semantics and style bleeding. */
@Component({
  selector: 'lru-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnDestroy, AfterViewInit {
  /** Should the dropdown be open to begin with? */
  @Input() isOpen: boolean = false;

  /** Enforce openFunc different from what is specified in the config {@link DropdownConfig} */
  @Input() openFunc?: Function;

  /** Enforce closeFunc different from what is specified in the config {@link DropdownConfig} */
  @Input() closeFunc?: Function;

  /** Enforce sameWidth different from what is specified in the config {@link DropdownConfig} */
  @Input() sameWidth: boolean;

  /** Enforce sameHeight different from what is specified in the config {@link DropdownConfig} */
  @Input() sameHeight: boolean;

  /** Enforce horizontalDirection different from what is specified in the config {@link DropdownConfig} */
  @Input() horizontalDirection: dropdownHorizontalDirection;

  /** Enforce verticalDirection different from what is specified in the config {@link DropdownConfig} */
  @Input() verticalDirection: dropdownVerticalDirection;

  /** Enforce toleranceWidth different from what is specified in the config {@link DropdownConfig} */
  @Input() toleranceWidth: number;

  /** Enforce toleranceHeight different from what is specified in the config {@link DropdownConfig} */
  @Input() toleranceHeight: number;

  /** Enforce spacingFromButton different from what is specified in the config {@link DropdownConfig} */
  @Input() spacingFromButton: number;

  /** Enforce spacingFromWindow different from what is specified in the config {@link DropdownConfig} */
  @Input() spacingFromWindow: number;

  /** Enforce popOver different from what is specified in the config {@link DropdownConfig} */
  @Input() popOver: boolean;

  /** Enforce axis different from what is specified in the config {@link DropdownConfig} */
  @Input() axis: dropdownAxis;

  /** Enforce throttleTime different from what is specified in the config {@link DropdownConfig} */
  @Input() throttleTime: number;

  /** Enforce fillWidth different from what is specified in the config {@link DropdownConfig} */
  @Input() fillWidth: boolean;

  /** Enforce fillHeight different from what is specified in the config {@link DropdownConfig} */
  @Input() fillHeight: boolean;

  /** Enforce maxWidth different from what is specified in the config {@link DropdownConfig} */
  @Input() maxWidth?: number;

  /** Enforce maxHeight different from what is specified in the config {@link DropdownConfig} */
  @Input() maxHeight?: number;

  /** Enforce variant different from what is specified in the config {@link DropdownConfig} */
  @Input() variant?: string;

  /** Enforce ariaLabel different from what is specified in the config {@link DropdownConfig} */
  @Input() ariaLabel?: string;

  /** Pixel width the dropdown should have if possible */
  @Input() width?: number;

  /** Pixel height the dropdown should have if possible */
  @Input() height?: number;

  /** add data-id */
  @Input() id?: string;

  /** Used by the dropdown service to get a reference of the internal dropdown portal. */
  @ViewChild(CdkPortal) readonly portal?: CdkPortal;

  /** The element reference to the dropdown content */
  @ViewChild('domDropdownContent') private domDropdownContent: ElementRef | undefined;

  /** The element reference to the dropdown button */
  @ViewChild('domOpenDropdownButton') domOpenDropdownButton!: ElementRef<HTMLButtonElement>;

  /** style.top. Generated by setDropdownStyling() */
  appliedTop: string | null = null;

  /** style.bottom. Generated by setDropdownStyling() */
  appliedBottom: string | null = null;

  /** style.left. Generated by setDropdownStyling() */
  appliedLeft: string | null = null;

  /** style.right. Generated by setDropdownStyling() */
  appliedRight: string | null = null;

  /** style.max-width. Generated by setDropdownStyling() */
  appliedMaxWidth: string | null = null;

  /** style.max-height. Generated by setDropdownStyling() */
  appliedMaxHeight: string | null = null;

  /** style.width. Generated by setDropdownStyling() */
  appliedWidth: string | null = null;

  /** style.height. Generated by setDropdownStyling() */
  appliedHeight: string | null = null;

  /** style.visibility. Generated by setDropdownStyling() */
  appliedVisibility: dropdownVisibility = 'hidden';

  /** attr.data-horizontal-direction.
   * Generated by setDropdownStyling().
   * Used to make it possible to add specific styling for this. */
  appliedHorizontalDirection: dropdownHorizontalDirection | null = null;

  /** attr.data-vertical-direction.
   * Generated by setDropdownStyling().
   * Used to make it possible to add specific styling for this. */
  appliedVerticalDirection: dropdownVerticalDirection | null = null;

  /** attr.data-fill-width.
   * Generated by setDropdownStyling().
   * Used to make it possible to add specific styling for this. */
  appliedFillWidth: boolean | null = null;

  /** attr.data-fill-height.
   * Generated by setDropdownStyling().
   * Used to make it possible to add specific styling for this. */
  appliedFillHeight: boolean | null = null;

  /** A reference to the last focus event target. Used to prevent unnecessary focus jumps. */
  private lastFocusEventTarget: EventTarget | null = null;

  /** unique id for this component */
  private uuid = uuidv4();

  /** z-index for dropdown content */
  public readonly zIndex$ = this.zIndexService.item$(this.uuid);

  /** @ignore */
  constructor(
    private window: Window,
    public dropdownService: DropdownService,
    private changeDetectorRef: ChangeDetectorRef,
    private zIndexService: ZIndexService,
    private config?: DropdownConfig
  ) {
    const defaultConfig = new DropdownConfig();

    this.sameWidth = this.config?.sameWidth ?? defaultConfig.sameWidth;
    this.sameHeight = this.config?.sameHeight ?? defaultConfig.sameHeight;
    this.horizontalDirection = this.config?.horizontalDirection ?? defaultConfig.horizontalDirection;
    this.verticalDirection = this.config?.verticalDirection ?? defaultConfig.verticalDirection;
    this.toleranceWidth = this.config?.toleranceWidth ?? defaultConfig.toleranceWidth;
    this.toleranceHeight = this.config?.toleranceHeight ?? defaultConfig.toleranceHeight;
    this.spacingFromButton = this.config?.spacingFromButton ?? defaultConfig.spacingFromButton;
    this.spacingFromWindow = this.config?.spacingFromWindow ?? defaultConfig.spacingFromWindow;
    this.popOver = this.config?.popOver ?? defaultConfig.popOver;
    this.axis = this.config?.axis ?? defaultConfig.axis;
    this.throttleTime = this.config?.throttleTime ?? defaultConfig.throttleTime;
    this.fillWidth = this.config?.fillWidth ?? defaultConfig.fillWidth;
    this.fillHeight = this.config?.fillHeight ?? defaultConfig.fillHeight;
    this.maxWidth = this.config?.maxWidth ?? defaultConfig.maxWidth;
    this.maxHeight = this.config?.maxHeight ?? defaultConfig.maxHeight;
    this.variant = this.config?.variant ?? defaultConfig.variant;
    this.openFunc = this.config?.openFunc ?? defaultConfig.openFunc;
    this.closeFunc = this.config?.closeFunc ?? defaultConfig.closeFunc;
  }

  /** Calls {@link setState} if modal is open */
  ngAfterViewInit(): void {
    if (this.isOpen) {
      this.setState();
    }
  }

  /**
   * Interval for checking if button has changed position.
   * Used by {@link listenForPositionChanges}
   *  */
  positionChangeInterval?: NodeJS.Timeout;

  /**
   * Old dropdown button rect to compare against to see if the button has changed position.
   * Used by {@link listenForPositionChanges} */
  currentRect?: DOMRect;

  /**
   * While the dropdown is open we need to check often if the dropdown button has changed position.
   * If we dont do this then dropdown button and dropdown content could come out of sync if the DOM changes.
   * If for example the page gets more content, then a scrollbar might get displayed that shifts all content on the page, then the button for the dropdown will move and then dropdown content needs to recalculate its position.
   */
  listenForPositionChanges(): void {
    if (this.isOpen) {
      if (this.positionChangeInterval) {
        clearInterval(this.positionChangeInterval);
      }

      this.positionChangeInterval = setInterval(() => {
        const rect = this.domOpenDropdownButton.nativeElement.getBoundingClientRect();

        if (this.currentRect && JSON.stringify(rect) !== JSON.stringify(this.currentRect)) {
          this.setDropdownStyling();
        }

        this.currentRect = rect;
      }, 50);
    } else if (this.positionChangeInterval) {
      clearInterval(this.positionChangeInterval);
    }
  }

  /** When dropdown is no longer in the DOM then we close dropdown and clears the interval that monitors the position of dropdown content relative to its button if its open. */
  async ngOnDestroy(): Promise<void> {
    await this.dropdownService.closeDropdown();
    if (this.positionChangeInterval) {
      clearInterval(this.positionChangeInterval);
    }
  }

  /** Sets state of dropdown in {@link DropdownService} and applies dropdown styling. If open set focus to dropdown. */
  private setState(): void {
    this.dropdownService.setState(this, this.isOpen);
    if (this.isOpen) {
      setTimeout(() => {
        this.focusOnDropdown();
      });
    }

    this.listenForPositionChanges();
    this.setDropdownStyling();
  }

  /** Setter for {@link isOpen} */
  closeDropdown(): void {
    this.isOpen = false;
    this.changeDetectorRef.detectChanges();
    if (this.closeFunc) {
      this.closeFunc();
    }
  }

  /** Calls {@link DropdownService.closeDropdown} */
  async onCloseClick(): Promise<void> {
    await this.dropdownService.closeDropdown();
  }

  /** Traps focus within the dropdown as long as it's open. Called from the dropdown service. */
  trapFocus(eventTarget: EventTarget): void {
    const isValidFocusElement = this.domDropdownContent?.nativeElement.contains(eventTarget);

    if (!isValidFocusElement) {
      if (eventTarget instanceof HTMLElement && eventTarget.classList.contains('focus-trap')) {
        if (eventTarget.classList.contains('focus-trap-begin')) {
          // If no timeout is set, we're constantly brought back to the last focus item for some strange reason
          setTimeout(() => {
            this.focusOnLastDescendant();
          });
        } else if (eventTarget.classList.contains('focus-trap-end')) {
          this.focusOnFirstDescendant();
        }
      } else if (this.lastFocusEventTarget === eventTarget) {
        return;
      }

      this.lastFocusEventTarget = eventTarget;

      this.focusOnDropdown();
    }
  }

  /** Sets focus on the most relevant element in the dropdown. */
  private focusOnDropdown(): void {
    const dropdownElement = this.domDropdownContent?.nativeElement;

    if (!dropdownElement) {
      return;
    }

    // Try to find an element with the autofocus attribute
    const autofocusElement = dropdownElement.querySelector('[autofocus]');

    if (autofocusElement) {
      autofocusElement.focus();
      return;
    }

    // Try to find any focusable element
    dropdownElement.querySelector(focusableElements)?.focus();
  }

  /** Sets focus on the first focusable element in the dropdown. */
  private focusOnFirstDescendant(): void {
    this.domDropdownContent?.nativeElement.querySelector(focusableElements)?.focus();
  }

  /** Sets focus on the last focusable element in the dropdown. */
  private focusOnLastDescendant(): void {
    const lastElement = getLastItemFromArray(
      this.domDropdownContent?.nativeElement.querySelectorAll(focusableElements) || []
    );

    if (lastElement instanceof HTMLElement) {
      lastElement.focus();
    }
  }

  /** Convert number to px value
   * @param value number
   * @returns px value */
  private px(value: number): string {
    return `${value}px`;
  }

  /**
   * Resets all applied styling for dropdown content and hides it.
   * This is used before calculating new position for dropdown content, to ensure no old values are removes.
   */
  private resetDropdownStyling(): void {
    this.appliedTop = null;
    this.appliedBottom = null;
    this.appliedLeft = null;
    this.appliedRight = null;
    this.appliedMaxWidth = null;
    this.appliedMaxHeight = null;
    this.appliedWidth = null;
    this.appliedHeight = null;
    this.appliedFillWidth = null;
    this.appliedFillHeight = null;
    this.appliedVisibility = 'hidden';
  }

  /** Figure out how to dropdown content should be placed based on a multitude of factors like screen-size or inputs  */
  private setDropdownStyling(): void {
    if (this.isOpen) {
      this.resetDropdownStyling();

      const rect = this.domOpenDropdownButton.nativeElement.getBoundingClientRect();
      let buttonOffsetTop: number = rect.top;
      let buttonOffsetBottom: number = this.window.innerHeight - (rect.top + rect.height);
      let buttonOffsetLeft: number = rect.left;
      let buttonOffsetRight: number = document.body.clientWidth - (rect.left + rect.width);

      this.appliedHorizontalDirection = this.horizontalDirection;
      this.appliedVerticalDirection = this.verticalDirection;

      if (this.fillWidth) {
        this.appliedFillWidth = true;
      } else if (this.sameWidth) {
        this.toleranceWidth = rect.width;
      }

      if (this.fillHeight) {
        this.appliedFillHeight = true;
      } else if (this.sameHeight) {
        this.toleranceHeight = rect.height;
      }

      // check if dirrection is possible or reverse it - start
      if (
        this.appliedHorizontalDirection === 'left' &&
        rect.left + (this.axis === 'vertical' ? rect.width : 0) - (this.spacingFromButton + this.spacingFromWindow) <
          this.toleranceWidth
      ) {
        if (buttonOffsetRight - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceWidth) {
          this.appliedFillWidth = true;
        } else {
          this.appliedHorizontalDirection = 'right';
        }
      } else if (
        this.appliedHorizontalDirection === 'right' &&
        buttonOffsetRight +
          (this.axis === 'vertical' ? rect.width : 0) -
          (this.spacingFromButton + this.spacingFromWindow) <
          this.toleranceWidth
      ) {
        if (buttonOffsetLeft - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceWidth) {
          this.appliedFillWidth = true;
        } else {
          this.appliedHorizontalDirection = 'left';
        }
      } else if (this.width && this.width - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceWidth) {
        this.appliedFillWidth = true;
      }

      if (
        this.appliedVerticalDirection === 'up' &&
        buttonOffsetTop - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceHeight
      ) {
        if (buttonOffsetBottom - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceHeight) {
          this.appliedFillHeight = true;
        } else {
          this.appliedVerticalDirection = 'down';
        }
      } else if (
        this.appliedVerticalDirection === 'down' &&
        buttonOffsetBottom - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceHeight
      ) {
        if (buttonOffsetTop - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceHeight) {
          this.appliedFillHeight = true;
        } else {
          this.appliedVerticalDirection = 'up';
        }
      } else if (
        this.height &&
        this.height - (this.spacingFromButton + this.spacingFromWindow) < this.toleranceHeight
      ) {
        this.appliedFillHeight = true;
      }
      // check if dirrection is possible or reverse it - end

      // width start
      if (this.appliedFillWidth) {
        this.appliedLeft = this.px(this.spacingFromWindow);
        this.appliedRight = this.px(this.spacingFromWindow);
      } else {
        let width =
          (this.appliedHorizontalDirection === 'left' ? buttonOffsetLeft : buttonOffsetRight) -
          this.spacingFromWindow -
          (this.axis === 'horizontal' ? this.spacingFromButton : 0) +
          (this.popOver || this.axis === 'vertical' ? rect.width : 0);

        if (this.maxWidth && width > this.maxWidth) {
          width = this.maxWidth;
        }

        if (width < this.toleranceWidth || (this.width && this.width < this.toleranceWidth)) {
          this.appliedFillWidth = true;
        } else {
          if (this.sameWidth) {
            this.appliedMaxWidth = this.px(rect.width);
            this.appliedWidth = '100%';
          } else {
            if (this.width) {
              this.appliedMaxWidth = this.px(this.width);
              this.appliedWidth = '100%';
            } else {
              this.appliedMaxWidth = this.px(width);
            }
          }
        }

        if (this.axis === 'horizontal') {
          if (this.appliedHorizontalDirection === 'left') {
            this.appliedRight = this.px(buttonOffsetRight + this.spacingFromButton + (!this.popOver ? rect.width : 0));
          } else if (this.appliedHorizontalDirection === 'right') {
            this.appliedLeft = this.px(buttonOffsetLeft + this.spacingFromButton + (!this.popOver ? rect.width : 0));
          }
        } else if (this.axis === 'vertical') {
          if (this.appliedHorizontalDirection === 'left') {
            this.appliedRight = this.px(buttonOffsetRight);
          } else if (this.appliedHorizontalDirection === 'right') {
            this.appliedLeft = this.px(buttonOffsetLeft);
          }
        }
      }
      // width end

      // height start
      if (this.appliedFillHeight) {
        this.appliedTop = this.px(this.spacingFromWindow);
        this.appliedBottom = this.px(this.spacingFromWindow);
      } else {
        let height =
          (this.appliedVerticalDirection === 'down' ? buttonOffsetBottom : buttonOffsetTop) -
          this.spacingFromWindow -
          (this.axis === 'vertical' ? this.spacingFromButton : 0) +
          (this.popOver || this.axis === 'horizontal' ? rect.height : 0);

        if (this.maxHeight && height > this.maxHeight) {
          height = this.maxHeight;
        }

        if (height < this.toleranceHeight || (this.height && this.height < this.toleranceHeight)) {
          this.appliedFillHeight = true;
        } else {
          if (this.sameHeight) {
            this.appliedMaxHeight = this.px(rect.height);
            this.appliedHeight = '100%';
          } else {
            if (this.height) {
              this.appliedMaxHeight = this.px(this.height);
              this.appliedHeight = '100%';
            } else {
              this.appliedMaxHeight = this.px(height);
            }
          }
        }

        if (this.axis === 'horizontal') {
          if (this.appliedVerticalDirection === 'up') {
            this.appliedBottom = this.px(buttonOffsetBottom);
          } else if (this.appliedVerticalDirection === 'down') {
            this.appliedTop = this.px(buttonOffsetTop);
          }
        } else if (this.axis === 'vertical') {
          if (this.appliedVerticalDirection === 'up') {
            this.appliedBottom = this.px(
              buttonOffsetBottom + this.spacingFromButton + (!this.popOver ? rect.height : 0)
            );
          } else if (this.appliedVerticalDirection === 'down') {
            this.appliedTop = this.px(buttonOffsetTop + this.spacingFromButton + (!this.popOver ? rect.height : 0));
          }
        }
      }
      // height end

      this.appliedVisibility = 'visible';
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Toggle between open/close state for dropdown.
   * Sets dropdown to have the highest z-index to ensure it is on top.
   * Triggers {@link openFunc} or {@link closeFunc} if specified
   */
  onClickToggleDropdown(): void {
    this.isOpen = !this.isOpen;
    this.setState();
    if (this.isOpen) {
      this.zIndexService.setItem(this.uuid);
      if (this.openFunc) {
        this.openFunc();
      }
    } else {
      if (this.closeFunc) {
        this.closeFunc();
      }
    }
  }

  /** When the window is scrolled it will need to recalculate where dropdown content should be placed. */
  @HostListener('window:scroll') onScroll(): void {
    throttle(this.throttleTime, () => {
      this.setDropdownStyling();
    })();
  }

  /** When the window is resized it will need to recalculate where dropdown content should be placed. */
  @HostListener('window:resize') onResize(): void {
    throttle(this.throttleTime, () => {
      this.setDropdownStyling();
    })();
  }
}
