<ng-container *ngIf="{ data: (data$ | async) } as sub">
  <div
    *ngIf="sub.data.Recorded"
    class="audio-element recorded"
    [class.expanded]="sub.data.RecordedExpanded && !sub.data.Partial && !sub.data.FullExpanded"
    [class.disabled]="sub.data.Partial || sub.data.FullExpanded"
  >
    <div class="flex space-3 vertical-center grow-self">
      <button
        class="btn"
        (click)="layoutAudioService.toggleRecordedExpanded(true, true)"
        [attr.aria-label]="'Audio.ExpandRecordedAudio' | translate"
      >
        <i class="icon icon-30" [inlineSVG]="'play-filled.svg'"></i>
      </button>
      <label class="grow-self">{{ 'LayoutAudioRecorded.AudioBook' | translate }}</label>
    </div>
    <div class="flex vertical-bottom grow-self">
      <portal-audio-hls-player
        [src]="sub.data.Recorded.Src"
        [showSecondsControl]="sub.data.Recorded.ShowSecondsControl"
        [autoPlay]="sub.data.Recorded.AutoPlay"
      >
      </portal-audio-hls-player>
    </div>
  </div>
</ng-container>
