import { createReducer, on } from '@ngrx/store';
import { sortComparer } from '../../../utils/array.helper';
import { IClassWithUsers, IUserPermissionWithSelected, ITeamEdit } from './interfaces/team-edit.interface';
import * as LoungeActions from './lounge.actions';
import { initialLoungeState } from './lounge.state';

export const loungeFeatureKey = 'loungeState';

export const loungeReducer = createReducer(
  initialLoungeState,
  on(LoungeActions.levelLoadLevelsSuccess, (state, action) => {
    return {
      ...state,
      fullLevel: action.fullLevel,
    };
  }),
  on(LoungeActions.markTeacherForSelectionSuccess, (state, action) => {
    const newlist = JSON.parse(JSON.stringify(state.dlpTeachersList)) as IUserPermissionWithSelected[];

    const _teacher = newlist.find((t) => t.user.id === action.teacherId);

    if (_teacher) {
      _teacher.user.selected = action.selected;
      if (action.selected) {
        _teacher.permission = action.permission;
      } else {
        _teacher.permission = {
          canCreateSubTeam: false,
          canDeleteSubTeam: false,
          canDeleteTeam: false,
          canEditSubTeam: false,
          canEditTeam: false,
          canViewSubTeam: false,
          isOwner: false,
        };
      }
    }

    return { ...state, dlpTeachersList: newlist };
  }),
  on(LoungeActions.markClassForSelectionSuccess, (state, action) => {
    const newlist = JSON.parse(JSON.stringify(state.dlpClassWithStudentsList)) as IClassWithUsers[];

    const classWithUsers = newlist.find((c) => c.class.id === action.classId);

    if (classWithUsers) {
      classWithUsers.class.selected = action.selected;
      classWithUsers.users.forEach((s) => {
        if (!action.searchTerm) {
          s.selected = action.selected;
        } else if (s.name.toLocaleLowerCase().includes(action.searchTerm.toLocaleLowerCase())) {
          s.selected = action.selected;
        }
      });
    }

    return { ...state, dlpClassWithStudentsList: newlist };
  }),
  on(LoungeActions.markStudentForSelectionSuccess, (state, action) => {
    const newlist = JSON.parse(JSON.stringify(state.dlpClassWithStudentsList)) as IClassWithUsers[];

    const _class = newlist.find((c) => c.class.id === action.classId)?.class;

    if (_class) {
      const user = newlist.find((c) => c.class.id === action.classId)?.users.find((s) => s.id === action.userId);

      if (user) {
        user.selected = action.selected;

        if (!action.selected) {
          _class.selected = action.selected;
        } else {
          const notAllUsersSelected = newlist
            .find((c) => c.class.id === action.classId)
            ?.users.find((s) => s.selected === false);

          _class.selected = notAllUsersSelected ? false : true;
        }
      }
    }

    return { ...state, dlpClassWithStudentsList: newlist };
  }),
  on(LoungeActions.removeSelectionsFromStudentsSuccess, (state) => {
    let newList: IClassWithUsers[] | undefined = undefined;

    if (state.dlpClassWithStudentsList) {
      newList = JSON.parse(JSON.stringify(state.dlpClassWithStudentsList)) as IClassWithUsers[];

      newList.forEach((x) => {
        x.class.selected = false;
        x.users.forEach((x) => (x.selected = false));
      });
    }

    return { ...state, dlpClassWithStudentsList: newList };
  }),
  on(LoungeActions.removeSelectionsFromTeachersSuccess, (state) => {
    let newList: IUserPermissionWithSelected[] | undefined = undefined;

    if (state.dlpTeachersList) {
      newList = JSON.parse(JSON.stringify(state.dlpTeachersList)) as IUserPermissionWithSelected[];

      newList.forEach((x) => {
        x.user.selected = false;
      });
    }

    return { ...state, dlpTeachersList: newList };
  }),
  on(LoungeActions.dlpLoadTeachersSuccess, (state, action) => {
    const teachersSorted: IUserPermissionWithSelected[] = [...action.teachers];

    teachersSorted.sort((a, b) => sortComparer(a.user.name.toLowerCase(), b.user.name.toLowerCase(), true));
    return {
      ...state,
      dlpTeachersList: teachersSorted,
    };
  }),
  on(LoungeActions.dlpLoadClassesWithStudentsSuccess, (state, action) => {
    return {
      ...state,
      dlpClassWithStudentsList: action.classes,
    };
  }),
  on(LoungeActions.teamLoadTeamsSuccess, (state, action) => {
    return {
      ...state,
      teamWithTeachersList: action.teamList,
    };
  }),
  on(LoungeActions.teamEditTeam, (state, action) => {
    const teamEdit: ITeamEdit = { ...action.team, students: [] };

    return {
      ...state,
      teamEdit: teamEdit,
    };
  }),
  on(LoungeActions.teamEditTeamCancel, (state) => {
    return {
      ...state,
      teamEdit: undefined,
    };
  }),
  on(LoungeActions.teamEditAssignTeachersSuccess, (state, action) => {
    return {
      ...state,
      dlpTeachersList: action.teachers,
    };
  }),
  on(LoungeActions.teamEditAssignStudentsSuccess, (state, action) => {
    return {
      ...state,
      dlpClassWithStudentsList: action.classWithSelectedUsersList,
    };
  }),
  on(LoungeActions.teamLoadStudentsSuccess, (state, action) => {
    const teamEdit = state.teamEdit ? { ...state.teamEdit, students: action.studentList } : undefined;

    return {
      ...state,
      teamEdit: teamEdit,
    };
  }),
  on(LoungeActions.teamDeleteTeam, (state, action) => {
    const newTeamList = state.teamWithTeachersList?.filter((x) => x.id !== action.teamId);

    return {
      ...state,
      teamWithTeachersList: newTeamList,
    };
  }),
  on(LoungeActions.teamRemoveTeacherSelfSuccess, (state, action) => {
    const newTeamWithTeachersList = state.teamWithTeachersList?.filter((team) => team.id !== action.team.id);

    return {
      ...state,
      teamWithTeachersList: newTeamWithTeachersList,
    };
  })
);
