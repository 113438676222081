import { Injectable } from '@angular/core';
import { INextBook, INextBook2 } from '@portal-app/interfaces/book/next/next-book';
import { IBookTile } from '@portal-app/interfaces/project/section.interface';
import { AssetMapper } from '../shared/asset.mapper';
import { BookDifficultiesMapper } from './book-difficulties.mapper';
import { BookImpressionMapper } from './book-impression.mapper';

@Injectable({
  providedIn: 'root',
})
export class BookMapper {
  constructor(
    private bookDifficultiesMapper: BookDifficultiesMapper,
    private bookImpressionMapper: BookImpressionMapper,
    private assetMapper: AssetMapper
  ) {}

  mapBookFromApi(book: INextBook, isFinished?: boolean): IBookTile {
    return {
      Id: book.Id,
      Slug: book.Slug,
      HasAudio: book.HasAudioBook,
      Image: book.CoverImage,
      IsFavorite: book.IsFavorite,
      Title: book.Title,
      Friends: book.Users,
      Progress: isFinished ? 100 : book?.Progress,
      Difficulties: this.bookDifficultiesMapper.mapBookDifficultyFromApi(book.Difficulties),
      Impressions: book.Impressions ?? [],
    };
  }

  mapBookFromApi2(book: INextBook2, isFinished?: boolean): IBookTile {
    return {
      Id: book.id,
      Slug: book.slug,
      HasAudio: book.hasAudioBook,
      Image: this.assetMapper.map(book.coverImage),
      IsFavorite: book.isFavorite,
      Title: book.title,
      Friends: book.users,
      Progress: isFinished ? 100 : book?.progress,
      Difficulties: this.bookDifficultiesMapper.mapBookDifficultyFromApi2(book.difficulties),
      Impressions: this.bookImpressionMapper.mapList(book.impressions),
    };
  }

  mapBooksFromApi(books: INextBook[], isFinished?: boolean) {
    return books.map((x) => this.mapBookFromApi(x, isFinished));
  }

  mapBooksFromApi2(books: INextBook2[], isFinished?: boolean) {
    return books.map((x) => this.mapBookFromApi2(x, isFinished));
  }
}
