import { Component, Input, OnInit } from '@angular/core';
import { ILink } from '../../../../../interfaces/project/link.interface';
import { BreadcrumbsService } from '../../../../../services/shared/breadcrumbs.service';
import { BreakpointsService } from '../../../../../services/shared/breakpoints.service';

@Component({
  selector: 'portal-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  medium = false;
  items: ILink[] = [];
  @Input() isHeader = false;

  constructor(private breadcrumbsService: BreadcrumbsService, private breakpointsService: BreakpointsService) {}

  ngOnInit() {
    this.breadcrumbsService.data$.subscribe((data) => {
      this.items = data;
    });
    this.breakpointsService.medium$.subscribe((data) => {
      this.medium = data;
    });
  }

  getRootUrl() {
    if (!this.medium && this.items.length) {
      const item = this.items[this.items.length - 1];
      if (item.Url) {
        return item.Url;
      }
    }
    return '/';
  }
}
