import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Headline component for lounge team modals
 */
@Component({
  selector: 'lru-team-header',
  templateUrl: './team-header.component.html',
  styleUrls: ['./team-header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamHeaderComponent {
  /** Optional primary headline.
   * If not specified then primary headline will not be displayed. */
  @Input() header?: string;

  /** Optional secondary headline.
   * If not specified then secondary headline will not be displayed. */
  @Input() subHeader?: string;

  /** Optional function that should be executed when clicking close button.
   * If not specified then close button will not be displayed. */
  @Input() closeFunc?: Function;

  /** Optional function that should be executed when clicking back button.
   * If not specified then back button will not be displayed. */
  @Input() backFunc?: Function;

  /** Click on back button. Will execute {@link backFunc} input if supplied */
  onClickBack(): void {
    if (this.backFunc) {
      this.backFunc();
    }
  }

  /** Click on close button. Will execute {@link closeFunc} input if supplied */
  onClickClose(): void {
    if (this.closeFunc) {
      this.closeFunc();
    }
  }
}
