import { Injectable } from '@angular/core';
import { ApiService } from '@lru/felib';
import { IUmbNavbar } from '@portal-app/interfaces/umbraco/umb-navbar.interface';

@Injectable({
  providedIn: 'root',
})
export class MegaMenuService {
  constructor(private api: ApiService) {}

  getMegaMenu(menuId: number) {
    return this.api.getUmbraco<IUmbNavbar>(`alineaportal/menu/fetch/${menuId}`);
  }
}
