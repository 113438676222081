import { combineLatest, distinctUntilKeyChanged, Observable, Unsubscribable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ServerInteractionStateType } from '../services/core/enums/server-interaction-state-type.enum';
import { serverInteractionStateLoading } from '../services/core/fixtures/server-interaction-state.fixtures';
import {
  initialServerInteractionState,
  ServerInteractionState,
} from '../services/core/interfaces/server-interaction-state.interface';

/**
 * Returns the current value from an observable instantly
 */
export function getObservableValueSync<T>(observable: Observable<T>): T {
  let returnValue: T | undefined = undefined;

  observable.pipe(take(1)).subscribe((data: T) => (returnValue = data));

  return <T>(<unknown>returnValue);
}

/**
 * Combines the given server interaction state observables to a single observable representing their collective state.
 *
 * Any error takes precedence over everything else.
 * Loading is returned if anything is loading OR some items have been loaded and some haven't yet started loaded.
 * Success is only returned once everything is loaded.
 */
export function combineServerInteractionStateObservables(
  observables: Observable<ServerInteractionState>[]
): Observable<ServerInteractionState> {
  return combineLatest(observables).pipe(
    map((serverInteractionStates) => {
      const filtered = serverInteractionStates.filter((serverInteractionState) => serverInteractionState);
      const initial = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.INITIAL
      );
      const loading = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.LOADING
      );
      const success = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.SUCCESS
      );
      const error = filtered.filter(
        (serverInteractionState) => serverInteractionState.state === ServerInteractionStateType.ERROR
      );

      if (error.length > 0) {
        return error[0];
      }

      if (loading.length > 0) {
        return loading[0];
      }

      if (initial.length > 0 && success.length > 0) {
        // Fake loading state, since some items have loaded and some haven't yet started loading
        return serverInteractionStateLoading;
      }

      if (success.length === filtered.length) {
        return success[0];
      }

      return initialServerInteractionState;
    }),
    distinctUntilKeyChanged('state')
  );
}

/**
 * Unsubscribe array of Unsubscribable
 * @param args Array of Unsubscribable
 */
export function unsubscribe(...args: (Unsubscribable | undefined)[]): void {
  if (args) {
    args.forEach((subscription) => subscription?.unsubscribe());
  }
}
