import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { softBezier } from '../../animations/beziers';

const transitionTiming = `.3s ${softBezier}`;
const animationMarginOut = '-80px';
const animationMarginIn = '0px';

export const toastAnimateInOut = trigger('animateInOut', [
  transition('* => *', [
    query(
      ':leave',
      [
        stagger(100, [
          animate(
            transitionTiming,
            style({ opacity: 0, transform: 'translateY(-66.67%) scale(0)', marginBottom: animationMarginOut })
          ),
        ]),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({ opacity: 0, marginBottom: animationMarginOut }),
        stagger(100, [animate(transitionTiming, style({ opacity: 1, marginBottom: animationMarginIn }))]),
      ],
      {
        optional: true,
      }
    ),
  ]),
]);
