import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { firstValueFrom, withLatestFrom } from 'rxjs';
import { LoadingService } from '.././../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '.././../../../../services/loading/shared-loading-keys.enums';
import { LoadingAnimationVariant } from '../../../../../components/loading-animation/enum/loading-animation-variant.enum';
import { UserService } from '../../../../../services/core/user/user.service';
import { AddTeacher } from '../../../interfaces/endpoint/team.interface';
import { LoungeModalService } from '../../../services/lounge-modal.service';
import { LoungeService } from '../../../services/lounge.service';
import { ITeamEdit } from '../../../store/interfaces/team-edit.interface';

/** A component for assigning teachers to a team */
@Component({
  selector: 'lru-team-edit-assign-teachers[teamEdit]',
  templateUrl: './team-edit-assign-teachers.component.html',
  styleUrls: ['./team-edit-assign-teachers.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditAssignTeachersComponent {
  /** The team to assign teachers to */
  @Input() teamEdit!: ITeamEdit;

  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** @see {@link LoadingAnimationVariant} */
  loadingAnimationVariants = LoadingAnimationVariant;

  /** @ignore */
  constructor(
    public loungeService: LoungeService,
    private userService: UserService,
    public loadingService: LoadingService,
    private loungeModalService: LoungeModalService
  ) {}

  /** Saves selected teachers to the team */
  async onClickSave(): Promise<void> {
    const [selectedTeachers, teamEdit, currentUser] = await firstValueFrom(
      this.loungeService.selectedTeachersList$.pipe(
        withLatestFrom(this.loungeService.teamEdit$, this.userService.user$)
      )
    );

    if (selectedTeachers && teamEdit && currentUser) {
      const currentTeacher = teamEdit.teachers.find((t) => t.user.id === currentUser.userId);

      if (!currentTeacher) {
        // TODO
        alert('hovsa...current teacher findes ikke');
        return;
      }

      const teachers: AddTeacher[] = selectedTeachers.map((x) => {
        return { ...currentTeacher?.permission, userId: x.user.id } as AddTeacher;
      });

      teachers.push({ ...currentTeacher?.permission, userId: currentTeacher?.user.id });

      this.loungeService.teamSaveTeachersToTeam(teamEdit.id, teachers, SharedLoadingKeys.teamSaveTeachersToTeam);
    }
  }

  /** Function that asks {@link LoungeModalService} to set modal page to none.
   * @returns A function that is used for inputting to the header and called from there. */
  onClickClose(): Function {
    return () => this.loungeModalService.setPageToNone();
  }
}
