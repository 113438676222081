import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'portal-checkbox[checked]',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  @Input() checked!: boolean;
  @Output() changed = new EventEmitter<boolean>();

  onChange(value: boolean) {
    this.changed.emit(value);
  }
}
