import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, share } from 'rxjs';

/** Service for adding functionality to mouse and keyboard events */
@Injectable({
  providedIn: 'root',
})
export class MouseKeyboardService implements OnDestroy {
  /** DOM element currently having .js-focus class */
  private focus?: HTMLElement;
  /** DOM element currently hacing .js-hover class */
  private hover?: HTMLElement;

  /** Observable that outputs all key up events */
  keyUp$ = fromEvent<KeyboardEvent>(document, 'keyup').pipe(share());

  /** Private subscription for adding the FELIB functionality even if {@link keyUp$} is not subscribed to in dependent projects */
  private keyUpSubscription = this.keyUp$.subscribe((event) => {
    if (event.key === 'Tab') {
      let target = event.target as HTMLElement;

      this.removeHover();
      this.addFocus(target);
    }
  });

  /** Observable that outputs all key down events */
  keyDown$ = fromEvent<KeyboardEvent>(document, 'keydown').pipe(share());

  /** Private subscription for adding the FELIB functionality even if {@link keyDown$} is not subscribed to in dependent projects */
  private keyDownSubscription = this.keyDown$.subscribe();

  /** Used by {@link clickOrTouch$} to make it easy to listen for touch and click events */
  private touchStart = fromEvent<TouchEvent>(document, 'touchstart');

  /** Used by {@link clickOrTouch$} to make it easy to listen for touch and click events */
  private click = fromEvent<MouseEvent>(document, 'click');

  /** Observable that outputs all click or touchStart events */
  clickOrTouch$ = merge(this.touchStart, this.click).pipe(share());

  /** Private subscription for adding the FELIB functionality even if {@link clickOrTouch$} is not subscribed to in dependent projects */
  private clickOrTouchSubscription = this.clickOrTouch$.subscribe((event) => {
    this.removeFocus();
    this.removeHover();
  });

  /** Angular OnDestroy lifecycle to unsubscribe from subscriptions */
  ngOnDestroy(): void {
    this.clickOrTouchSubscription?.unsubscribe();
    this.keyDownSubscription?.unsubscribe();
    this.keyUpSubscription?.unsubscribe();
  }

  /**
   * Give DOM element .js-focus class
   * @param target DOM element
   */
  public addFocus(target: HTMLElement): void {
    this.removeFocus();
    this.focus = target;
    this.focus.classList.add('js-focus');
  }

  /**
   * Remove .js-focus class from focus element, if there is a focus element
   */
  public removeFocus(): void {
    this.focus?.classList.remove('js-focus');
  }

  /**
   * Add .js-hover class to DOM element and set it as {@link hover} element
   * @param element Element to give .js-hover and set as {@link hover} element
   * @param scrollIntoView should the browser scroll so the hover element is within viewport
   */
  public addHover(element: HTMLElement, scrollIntoView?: boolean): void {
    this.removeHover();
    if (scrollIntoView) {
      element.scrollIntoView(false);
    }

    element.classList.add('js-hover');
    this.hover = element;
  }

  /** remove .js-hover class from {@link hover} and set object to undefined */
  public removeHover(): void {
    if (this.hover) {
      this.hover.classList.remove('js-hover');
      this.hover = undefined;
    }
  }

  /**
   * Get next or first item in array of items
   * @param items DOM elements
   * @param wrapAround Should it wrap aroound when at the last item?
   * @returns DOM element or undefined
   */
  selectNext(items: HTMLElement[], wrapAround: boolean): HTMLElement | undefined {
    let element: HTMLElement | undefined = undefined;

    if (items?.length) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.classList.contains('js-hover') && i < items.length) {
          element = items[i + 1];
        }
      }

      if (!element) {
        if (wrapAround) {
          element = items[0];
        } else {
          element = items[items.length - 1];
        }
      }
    }

    return element;
  }

  /**
   * Get previous or last item in array of items
   * @param items DOM elements
   * @param wrapAround Should it wrap aroound when at the first item?
   * @returns DOM element or undefined
   */
  selectPrev(items: HTMLElement[], wrapAround: boolean): HTMLElement | undefined {
    let element: HTMLElement | undefined = undefined;

    if (items?.length) {
      for (let i = items.length - 1; i >= 0; i--) {
        const item = items[i];

        if (item.classList.contains('js-hover') && i > 0) {
          element = items[i - 1];
        }
      }

      if (!element) {
        if (wrapAround) {
          element = items[items.length - 1];
        } else {
          element = items[0];
        }
      }
    }

    return element;
  }
}
