import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { firstValueFrom, withLatestFrom } from 'rxjs';
import { LoadingService } from '.././../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '.././../../../../services/loading/shared-loading-keys.enums';
import { LoadingAnimationVariant } from '../../../../../components/loading-animation/enum/loading-animation-variant.enum';
import { LoungeModalService } from '../../../services/lounge-modal.service';
import { LoungeService } from '../../../services/lounge.service';

/** A component for assigning students to a team */
@Component({
  selector: 'lru-team-edit-assign-students',
  templateUrl: './team-edit-assign-students.component.html',
  styleUrls: ['./team-edit-assign-students.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditAssignStudentsComponent {
  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** @see {@link LoadingAnimationVariant} */
  loadingAnimationVariants = LoadingAnimationVariant;

  /** @ignore */
  constructor(
    public loungeService: LoungeService,
    public loadingService: LoadingService,
    private loungeModalService: LoungeModalService
  ) {}

  /** Saves selected students to the team */
  async onClickSave(): Promise<void> {
    const [selectedUsers, teamEdit] = await firstValueFrom(
      this.loungeService.selectedUsersList$.pipe(withLatestFrom(this.loungeService.teamEdit$))
    );

    if (selectedUsers && teamEdit) {
      this.loungeService.teamSaveStudentsToTeam(
        teamEdit.id,
        selectedUsers.map((x) => x.id),
        SharedLoadingKeys.teamSaveStudentsToTeam
      );
    }
  }

  /** Function that asks {@link LoungeModalService} to set modal page to none.
   * @returns A function that is used for inputting to the header and called from there. */
  onClickClose(): Function {
    return () => this.loungeModalService.setPageToNone();
  }
}
