import { Component, Input } from '@angular/core';
import { GoogleTagManagerService, UserService } from '@lru/felib';
import { TranslateService } from '@ngx-translate/core';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';

import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { PageTypeEnum } from '../../../../../enums/page-type.enum';
import { ISelectable } from '../../../../../interfaces/project/section.interface';
import { BookCollectionService } from '../../../../../services/book/book-collection.service';

@Component({
  selector: 'portal-manage-book-collection[id][title]',
  templateUrl: './manage-book-collection.component.html',
  styleUrls: ['./manage-book-collection.component.scss'],
})
export class ManageBookCollectionComponent {
  @Input() id!: string;
  @Input() title!: string;
  @Input() headerText?: string;

  isTeacher: boolean = this.userService.isTeacher;
  collections?: ISelectable[];

  constructor(
    private userService: UserService,
    private bookCollectionService: BookCollectionService,
    private toastrService: ToastrService,
    private gtmService: GoogleTagManagerService,
    private translateService: TranslateService
  ) {}

  menuOpened() {
    document.documentElement.classList.add('cdk-dropdown');
  }

  menuClosed() {
    document.documentElement.classList.remove('cdk-dropdown');
  }

  /**
   * Gets data for all collections and their books and sets up a map of which collections contain the current book.
   */
  initializeBookCollections() {
    if (this.collections?.length) {
      return;
    }
    this.bookCollectionService.getBookTileAvailableCollections().subscribe((collections) => {
      this.collections = collections.map((item) => {
        return {
          Slug: item.Id,
          Title: item.Title,
          Selected: false,
        };
      });
      this.collections.forEach((collection) => {
        this.fetchCollectionStatus(collection);
      });
    });
  }

  /**
   * Fetches data for the given collection, optionally clearing the cache and fetching new data from the server.
   * When a collection is fetched, the local variable determining wether the book is present in the collection is updated.
   */
  fetchCollectionStatus(item: ISelectable) {
    this.bookCollectionService.getBooksInCollection(item.Slug).subscribe((books) => {
      if (books.find((elem) => elem.Id === this.id)) {
        item.Selected = true;
      }
    });
  }

  async onAddToCollectionClick(collection: ISelectable) {
    await firstValueFrom(this.bookCollectionService.addBookToCollection(collection.Slug, this.id));
    collection.Selected = true;
    this.toastrService.success(
      this.translateService.instant('ManageBookCollection.AddedToCollection', {
        bookTitle: this.title,
        collectionTitle: collection.Title,
      })
    );
    this.fetchCollectionStatus(collection);

    this.gtmService.pushTagWithUserType(GTMEvent.Add_to_collection, {
      book: this.title,
    });
  }

  async onRemoveFromCollectionClick(collection: ISelectable) {
    await firstValueFrom(this.bookCollectionService.removeBookFromCollection(collection.Slug, this.id));
    collection.Selected = false;
    this.toastrService.success(
      this.translateService.instant('ManageBookCollection.RemoveFromCollection', {
        bookTitle: this.title,
        collectionTitle: collection.Title,
      })
    );

    this.fetchCollectionStatus(collection);

    this.gtmService.pushTagWithUserType(GTMEvent.Remove_from_collection, {
      book: this.title,
    });
  }

  onClickCreateCollection() {
    this.gtmService.pushTagWithUserType(GTMEvent.Collection_create, {
      book: this.title,
    });
  }

  get PageTypeEnum() {
    return PageTypeEnum;
  }
}
