import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { EMPTY, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { INextBookCollection } from '../../interfaces/book/next/next-book-collection.interface';
import { AppInitService } from '../shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class BookCollectionFavoriteService {
  private data = new ReplaySubject<INextBookCollection[]>(1);
  data$ = this.data.asObservable();

  constructor(private api: ApiService, private userService: UserService, private appInitService: AppInitService) {}

  private loaded = false;
  initialize() {
    const subject = new ReplaySubject<boolean>(1);
    if (!this.loaded) {
      this.fetchAndUpdate()
        .pipe(
          catchError((error: HttpErrorResponse) => {
            subject.next(false);
            return EMPTY;
          })
        )
        .subscribe((data) => {
          if (data) {
            subject.next(true);
            this.loaded = true;
          } else {
            subject.next(false);
          }
        });
    } else {
      subject.next(true);
    }
    return subject;
  }

  fetchAndUpdate() {
    const subject = new ReplaySubject<boolean>(1);
    if (
      this.userService.isLoggedIn &&
      this.userService.isTeacher &&
      (this.appInitService.isSuperbog || this.appInitService.isSuperreader)
    ) {
      this.api
        .getNext<INextBookCollection[]>(
          `BookBox/GetFavoriteBookBoxes?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}`
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            subject.next(false);
            return EMPTY;
          })
        )
        .subscribe((data) => {
          subject.next(true);
          this.data.next(data || []);
        });
    } else {
      this.data.next([]);
      subject.next(true);
    }
    return subject;
  }

  addCollectionToFavorites(id: string) {
    return this.api.postNext<string>(`BookBoxFavorite/AddBookBoxToFavorites/${id}`).pipe(
      map((result: string) => {
        this.fetchAndUpdate();
        return result;
      })
    );
  }

  deleteCollectionFromFavorites(id: string) {
    return this.api.deleteNext<string>(`BookBoxFavorite/DeleteBookBoxFromFavorites/${id}`).pipe(
      map((result: string) => {
        this.fetchAndUpdate();
        return result;
      })
    );
  }
}
