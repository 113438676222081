import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { startLoading, finishLoading } from './store/loading.actions';
import { selectError, selectInit, selectLoaded, selectLoading } from './store/loading.selectors';
import { LoadingState } from './store/loading.state';

/** Service for handling loading and check state of specific loading. */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  /**
   * @ignore
   */
  constructor(private store: Store<LoadingState>) {}

  /** Check if loading is in initialization state.
   * @param loadingKey Key that is used for saving and retrieving the specific loading state.
   * @returns true if loading is in the status of initialization, false if not. */
  init$ = (loadingKey: string): Observable<boolean> => this.store.select(selectInit(loadingKey));
  /** Check if loading is in loading state.
   * @param loadingKey Key that is used for saving and retrieving the specific loading state.
   * @returns true if loading is in the status of loading, false if not. */
  loading$ = (loadingKey: string): Observable<boolean> => this.store.select(selectLoading(loadingKey));
  /** Check if loaded is in loaded state.
   * @param loadingKey Key that is used for saving and retrieving the specific loading state.
   * @returns true if loading is in the status of loaded, false if not. */
  loaded$ = (loadingKey: string): Observable<boolean> => this.store.select(selectLoaded(loadingKey));
  /** Retrieve error if loading has failed
   * @param loadingKey Key that is used for saving and retrieving the specific loading state.
   * @returns a string with the error. If loading hasn't failed then it will return undefined. */
  error$ = (loadingKey: string): Observable<string | undefined> => this.store.select(selectError(loadingKey));

  /** Starts the specific loading and sets the status to loading state.
   * @param loadingKey Key that is used for saving and retrieving the specific loading state. */
  start(loadingKey: string): void {
    this.store.dispatch(startLoading({ loadingKey }));
  }

  /** Stops the specific loading and sets the status to loaded state.
   * @param loadingKey Key that is used for saving and retrieving the specific loading state.   */
  stop(loadingKey: string): void {
    this.store.dispatch(finishLoading({ loadingKey }));
  }
}
