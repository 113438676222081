import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@lru/felib';
import { INextToken } from '@lru/felib/lib/services/core/user/interfaces/next-token.interface';
import { ReplaySubject } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  startRefresh(url: string) {
    const subject = new ReplaySubject<boolean>(1);
    if (this.userService.isLoggedIn && !this.userService.doNotTrack) {
      let expired = false;
      const expiresAt = this.userService.expiresAt;
      if (!expiresAt) {
        expired = true;
      } else {
        const expiresAtDate = new Date(expiresAt);
        const now = new Date();
        const expiresIn = expiresAtDate.getTime() - now.getTime();
        if (expiresIn < 0) {
          expired = true;
        }
      }
      if (expired) {
        // we only call api if it has expired
        try {
          fetch(
            `${environment.internalBackendUrl()}Login/RefreshToken?userid=${this.userService.userId}&contextid=${
              this.userService.contextIdentifier
            }`,
            {
              method: 'GET',
              credentials: 'include',
            }
          )
            .then((response) => {
              if (response.status === 503) {
                return;
              } else if (response.status === 200) {
                return response.json();
              } else {
                throw response;
              }
            })
            .then(
              (token: INextToken) => {
                if (token) {
                  this.userService.setToken(token);
                }
                subject.next(true);
              },
              (error: HttpErrorResponse) => {
                this.userService.logout();
              }
            );
        } catch (e) {
          subject.next(true);
        }
      } else {
        subject.next(true);
      }
    } else {
      subject.next(true);
    }
    return subject;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.startRefresh(req.url).pipe(
      first(),
      mergeMap((success) => {
        return next.handle(req);
      })
    );
  }
}
