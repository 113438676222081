import { Component, OnInit } from '@angular/core';
import { IDropdown } from '@portal-app/interfaces/project/dropdown.interface';
import { DropdownService } from '@portal-app/services/shared/dropdown.service';

@Component({
  selector: 'portal-dropdown-overlay',
  templateUrl: './dropdown-overlay.component.html',
  styleUrls: ['./dropdown-overlay.component.scss'],
})
export class DropdownOverlayComponent implements OnInit {
  dropdownOpen?: IDropdown;

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    this.dropdownService.open$.subscribe((v) => {
      if (v?.ZIndex) {
        v.ZIndex = v.ZIndex - 1;
      }
      this.dropdownOpen = v;
    });
  }
}
