/**
 * loading store
 */
export interface LoadingState {
  callStates: CallStateWithInstance[];
}

/**
 * loading store
 */
export const initialLoadingState: LoadingState = {
  callStates: [],
};

/**
 * loading store
 */
export interface CallStateWithInstance {
  loadingKey: string;
  callState: LoadStateEnum | Error;
}

/**
 * loading store
 */
export const enum LoadStateEnum {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}
