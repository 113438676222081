import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, LoadingService } from '@lru/felib';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LoadingKey } from '@portal-app/enums/loading-keys.enum';
import { PeriodSelectorEnum } from '@portal-app/enums/period-selector.enum';
import { INextBookCollection } from '@portal-app/interfaces/book/next/next-book-collection.interface';
import { IBookTile } from '@portal-app/interfaces/project/section.interface';
import { BookCollectionMapper } from '@portal-app/mapper/book/book-collection.mapper';
import { BookMapper } from '@portal-app/mapper/book/book.mapper';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { periodDateHelper } from '../dashboard-helper.service';
import { BookTeacherDashboardStudentBooksResponse } from '../interfaces/book-teacher-dashboard.interface';
import * as TeacherDashboardStoreActions from './teacher-dashboard.actions';
import {
  ITeacherClassStudentItem,
  ITeacherDashboardState,
  ITeamsAndClassesResponse,
} from './teacher-dashboard.reducer';
import { selectGetSelectedClass, selectorGetSelectedPeriodToDate } from './teacher-dashboard.selectors';

@Injectable()
export class TeacherDashboardStoreEffects {
  constructor(
    private bookMapper: BookMapper,
    private actions$: Actions,
    private apiService: ApiService,
    private appInitService: AppInitService,
    private store: Store<ITeacherDashboardState>,
    private bookCollectionMapper: BookCollectionMapper,
    private loadingService: LoadingService
  ) {}

  /** Get classes for institution */
  GetTeacherClasses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.GetTeamsAndClasses),
      tap(() => this.loadingService.start(LoadingKey.Page)),
      mergeMap(() =>
        this.apiService
          .getLounge<ITeamsAndClassesResponse>(`v1/team/listwithclasses?productId=${this.productId()}`)
          .pipe(
            map((data: ITeamsAndClassesResponse) =>
              TeacherDashboardStoreActions.GetTeamsAndClassesSuccess({
                data: data.result,
              })
            ),
            tap(() => this.loadingService.stop(LoadingKey.Page))
          )
      ),
      catchError((error: HttpErrorResponse) => {
        this.loadingService.stop(LoadingKey.Page);
        return of(
          TeacherDashboardStoreActions.GetTeacherClassesFailureAction({
            error,
          })
        );
      })
    )
  );

  /**
   * Get students in a selected class.
   * Fetch student by class id & institutionId
   * @param data.id - class ID
   * @param data.institutionId - institutionId is based on the teacher login
   */
  GetStudentsInClass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.GetStudentsInClassAction),
      concatLatestFrom(() => this.store.select(selectorGetSelectedPeriodToDate)),
      tap(() => this.loadingService.start(LoadingKey.Page)),
      mergeMap(([data, beginDate]) => {
        return this.apiService
          .postNext<ITeacherClassStudentItem[]>(`BookProgress/GetUsersBookInteractionState`, {
            TeamId: data.selectedClass.classId,
            ProjectGroupId: this.projectGroupId(),
            IsDlpClass: data.selectedClass.isDlpClass,
            LevelIds: data.selectedClass.levelIds,
            StartDate: beginDate,
            EndDate: '',
          })
          .pipe(
            map((studentsData) => {
              this.loadingService.stop(LoadingKey.Page);
              return TeacherDashboardStoreActions.GetStudentsInClassSuccessAction({
                studentsData,
              });
            }),
            catchError((error: HttpErrorResponse) => {
              this.loadingService.stop(LoadingKey.Page);
              return of(
                TeacherDashboardStoreActions.GetStudentsInClassFailureAction({
                  error,
                })
              );
            })
          );
      })
    )
  );

  GetBookCollections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.GetBookCollectionAction),
      concatLatestFrom(() => this.store.select(selectGetSelectedClass)),
      mergeMap(([data, _class]) =>
        this.apiService
          .postNext<INextBookCollection[]>(`BookBox/GetBookBoxesByTeamId`, {
            TeamId: _class?.classId,
            ProjectGroupId: this.projectGroupId(),
            IsDlpClass: _class?.isDlpClass,
          })
          .pipe(
            map((_bookCollectionData) => {
              const bookCollectionData = this.bookCollectionMapper.mapBookCollectionFromApi(_bookCollectionData);
              return TeacherDashboardStoreActions.GetBookCollectionSuccessAction({ bookCollectionData });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                TeacherDashboardStoreActions.GetBookCollectionFailureAction({
                  error,
                })
              )
            )
          )
      )
    )
  );

  GetBooksForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.GetBooksForUser),
      concatLatestFrom(() => this.store.select(selectorGetSelectedPeriodToDate)),
      mergeMap(([data, period]) =>
        this.apiService
          .getNext<BookTeacherDashboardStudentBooksResponse>(
            `Book/GetBooksCompletedAndInProgressByStudentId/${
              data.userId
            }?projectGroupId=${this.projectGroupId()}&startDate=${period}`
          )
          .pipe(
            map((studentBooks) => {
              const booksInProgress = this.bookMapper.mapBooksFromApi(studentBooks.BooksInProgress);

              let booksFinished: IBookTile[] = [];

              if (studentBooks.BooksCompleted && studentBooks.BooksCompleted.length) {
                booksFinished = this.bookMapper.mapBooksFromApi(studentBooks.BooksCompleted);
              }

              return TeacherDashboardStoreActions.GetBooksForUserSuccess({
                booksInProgress,
                booksFinished,
              });
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                TeacherDashboardStoreActions.GetStudentsInClassFailureAction({
                  error,
                })
              )
            )
          )
      )
    )
  );

  SetBookPeriodTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.SetBookPeriodTime),
      concatLatestFrom(() => [this.store.select(selectGetSelectedClass)]),
      mergeMap(([data, _class]) => [
        TeacherDashboardStoreActions.GetStudentsInClassAction({
          selectedClass: {
            classId: _class?.classId || '',
            isDlpClass: _class?.isDlpClass || false,
            levelIds: _class?.levelIds || [],
          },
        }),
      ])
    )
  );

  SetBookPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.SetBookPeriod),
      map((data) => {
        return TeacherDashboardStoreActions.SetOnlyBookPeriodTime({
          data: periodDateHelper(data.data),
        });
      })
    )
  );

  SelectBookPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TeacherDashboardStoreActions.SelectBookPeriod),
      map((data) => {
        return TeacherDashboardStoreActions.SetBookPeriodTime({
          data: periodDateHelper(data.data as PeriodSelectorEnum),
        });
      })
    )
  );

  private projectGroupId() {
    return this.appInitService.siteConfiguration.ProjectGroup;
  }

  private productId() {
    return this.appInitService.siteConfiguration.Id;
  }
}
