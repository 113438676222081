import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, TemplateRef } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExtendedDialogService extends MatDialog {
  public open<T>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig): MatDialogRef<T> {
    window.scrollLocked = true;

    const dialogRef = super.open(componentOrTemplateRef, config);

    dialogRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        document.documentElement.classList.add('cdk-global-scrollblock');
      });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        document.documentElement.classList.remove('cdk-global-scrollblock');
        window.scrollLocked = false;
      });

    return dialogRef;
  }
}
