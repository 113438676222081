import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HeroMenuItem } from '@portal-app/modules/shared/hero/components/hero/interfaces/hero-menu-item.interface';
import { PageService } from '@portal-app/services/shared/page/page.service';
import { AppInitService } from '../services/shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class SetPageForRoute implements CanActivate {
  constructor(private pageService: PageService, private appInitService: AppInitService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.appInitService.siteConfiguration) {
      const url = state.url.split('/');
      let id = url[url.length - 1].split('?')[0];
      const localStorageValue = localStorage.getItem('frontpageLevel');

      const frontpageLevel = localStorageValue ? (JSON.parse(localStorageValue) as HeroMenuItem) : '';

      if (this.appInitService.isFagportal && frontpageLevel && !id && frontpageLevel.Url !== id) {
        this.router.navigate([frontpageLevel.Url]);
        return false;
      }

      if (!id) {
        if (this.appInitService.siteConfiguration.FrontpageId) {
          id = this.appInitService.siteConfiguration.FrontpageId.toString();
        } else {
          this.pageService.showError(id, 404);
        }
      }

      this.pageService.setPageSettings({
        pageId: id,
        pageUrl: id,
        fromError: false,
      });
    }
    return true;
  }
}
