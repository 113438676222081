export enum ContentTypeEnum {
  Courses = 'Courses',
  Texts = 'Pages',
  Assignments = 'Assignments',
  Activities = 'Activities',
  Books = 'Books',
  Collections = 'Collections',
  Gallery = 'Gallery',
  List = 'List',
  AnnualPlan = 'AnnualPlan',
  About = 'About',
  SharedGoals = 'SharedGoals',
  Downloads = 'Downloads',
  Images = 'Images',
  Videos = 'Videos',
  Audios = 'Audios',
  Documents = 'Documents',
  GeoGebras = 'GeoGebras',
  PsArticles = 'PsArticles',
  PsAssignments = 'PsAssignments',
  PSThemes = 'PsThemes',
}
