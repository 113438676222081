import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  private small = new BehaviorSubject<boolean>(false);
  small$ = this.small.asObservable();

  private medium = new BehaviorSubject<boolean>(false);
  medium$ = this.medium.asObservable();

  private large = new BehaviorSubject<boolean>(false);
  large$ = this.large.asObservable();

  private xlarge = new BehaviorSubject<boolean>(false);
  xlarge$ = this.xlarge.asObservable();

  private underLarge = new BehaviorSubject<boolean>(false);
  underLarge$ = this.underLarge.asObservable();

  constructor(private breakpointObserver: BreakpointObserver, private mediaMatcher: MediaMatcher) {
    const breakpoints: string[] = ['(min-width: 0)', '(min-width: 768px)', '(min-width: 992px)', '(min-width: 1200px)'];

    if (this.mediaMatcher.matchMedia(breakpoints[3]).matches) {
      this.xlarge.next(true);
      this.large.next(true);
      this.medium.next(true);
      this.small.next(true);
    } else if (this.mediaMatcher.matchMedia(breakpoints[2]).matches) {
      this.xlarge.next(false);
      this.large.next(true);
      this.medium.next(true);
      this.small.next(true);

      this.underLarge.next(false);
    } else if (this.mediaMatcher.matchMedia(breakpoints[1]).matches) {
      this.xlarge.next(false);
      this.large.next(false);
      this.medium.next(true);
      this.small.next(true);

      this.underLarge.next(true);
    } else if (this.mediaMatcher.matchMedia(breakpoints[0]).matches) {
      this.xlarge.next(false);
      this.large.next(false);
      this.medium.next(false);
      this.small.next(true);

      this.underLarge.next(true);
    }

    this.breakpointObserver.observe(breakpoints).subscribe((result) => {
      if (result.matches) {
        if (result.breakpoints[breakpoints[3]]) {
          this.xlarge.next(true);
          this.large.next(true);
          this.medium.next(true);
          this.small.next(true);
        } else if (result.breakpoints[breakpoints[2]]) {
          this.xlarge.next(false);
          this.large.next(true);
          this.medium.next(true);
          this.small.next(true);

          this.underLarge.next(false);
        } else if (result.breakpoints[breakpoints[1]]) {
          this.xlarge.next(false);
          this.large.next(false);
          this.medium.next(true);
          this.small.next(true);

          this.underLarge.next(true);
        } else if (result.breakpoints[breakpoints[0]]) {
          this.xlarge.next(false);
          this.large.next(false);
          this.medium.next(false);
          this.small.next(true);

          this.underLarge.next(true);
        }
      }
    });
  }
}
