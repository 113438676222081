import { Component, OnInit } from '@angular/core';
import { UserService } from '@lru/felib';
import { IUser } from '@lru/felib/lib/services/core/user/interfaces/user.interface';

@Component({
  selector: 'portal-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  user?: IUser;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.user = this.userService.user;
  }

  onLogout() {
    this.userService.logout();
  }
}
