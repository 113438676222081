import { Injectable } from '@angular/core';
import { debounce } from 'throttle-debounce';
import { UserService } from '../core/user/user.service';
import { GTMJS } from './gtm-events.const';
import { GTMProperties } from './gtm-properties.interface';
import { GTMTag } from './gtm-tag.interface';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private id?: string;
  private isLoaded = false;
  private debouncedFunc = debounce(1500, (event: string, properties?: GTMProperties) =>
    this.pushTagWithUserType(event, properties)
  );

  constructor(private userService: UserService) {}

  pushTagWithUserType(event: string, properties?: GTMProperties): void {
    let obj = {
      event: event,
      visitorType: this.userService.userType,
    };

    if (properties) {
      obj = { ...obj, ...properties };
    }

    this.pushTag(obj);
  }

  pushDebouncedTagWithUserType(event: string, properties?: GTMProperties): void {
    this.debouncedFunc(event, properties);
  }

  setId(id: string): void {
    this.id = id;
  }

  addToDOM(): void {
    if (this.isLoaded || !this.id) {
      return;
    }

    const doc = this.browserGlobals.documentRef();

    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: GTMJS,
    });

    const script = doc.createElement('script');

    script.id = 'GTMscript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${this.id}`;

    doc.head.insertBefore(script, doc.head.firstChild);
    this.isLoaded = true;
  }

  pushTag(tag: GTMTag): void {
    if (!this.isLoaded) {
      this.addToDOM();
    }

    this.pushOnDataLayer(tag);
  }

  private pushOnDataLayer(obj: GTMTag): void {
    const dataLayer = this.getDataLayer();

    dataLayer.push(obj);
  }

  private browserGlobals = {
    windowRef() {
      return window;
    },
    documentRef() {
      return document;
    },
  };

  private getDataLayer(): object[] {
    const window = this.browserGlobals.windowRef();

    window.dataLayer = window.dataLayer || [];
    return window.dataLayer;
  }
}
