import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ApiService } from '@lru/felib';
import { IUmbMetaData } from '@portal-app/interfaces/umbraco/umb-meta-data.interface';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private api: ApiService,
    private appInitService: AppInitService,
    private title: Title,
    private meta: Meta
  ) {}

  getMetaData(pageId: string) {
    return this.api.getUmbraco<IUmbMetaData>(
      `v4/alineaportal/getseometa/${pageId}/${this.appInitService.siteConfiguration.PortalId}`
    );
  }

  setMetaData(data: IUmbMetaData) {
    if (data.metaTitle) {
      if (data.pageType === PageTypeEnum.FrontPage) {
        this.title.setTitle(data.productName ? `${data.productName} - ${data.metaTitle}` : data.metaTitle);
      } else if (data.productName) {
        this.title.setTitle(`${data.metaTitle} - ${data.productName}`);
      } else {
        this.title.setTitle(data.metaTitle);
      }
    } else if (data.productName) {
      this.title.setTitle(data.productName);
    }

    let image = '';
    if (data.defaultImage) {
      image = data.defaultImage;
    } else if (data.logoImage) {
      image = data.logoImage;
    }

    data.metaTitle = data.metaTitle ?? '';
    data.metaDescription = data.metaDescription ?? '';

    this.meta.updateTag({
      name: 'keywords',
      content: data.keywords ? data.keywords.join(', ') : '',
    });
    this.meta.updateTag({ name: 'description', content: data.metaDescription });

    this.meta.updateTag({
      name: 'twitter:card',
      content: 'summary_large_image',
    });
    this.meta.updateTag({ name: 'twitter:title', content: data.metaTitle });
    this.meta.updateTag({
      name: 'twitter:text:title',
      content: data.metaTitle,
    });
    this.meta.updateTag({
      name: 'twitter:description',
      content: data.metaDescription,
    });
    this.meta.updateTag({ name: 'twitter:image', content: image });
    this.meta.updateTag({ name: 'twitter:image:alt', content: data.metaTitle });

    this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({ property: 'og:title', content: data.metaTitle });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({
      property: 'og:image:alt',
      content: image ? data.metaTitle : '',
    });
    this.meta.updateTag({
      property: 'og:description',
      content: data.metaDescription,
    });

    this.meta.updateTag({
      name: 'robots',
      content: data.excludeFromSearchEngines ? 'noindex' : 'all',
    });
  }

  setGoogleVerificationId(id?: string) {
    if (id) {
      this.meta.updateTag({ property: 'google-site-verification', content: id });
    }
  }
}
