<ul *ngIf="items" class="flex space-2 vertical-center">
  <li>
    <a [routerLink]="getRootUrl()" class="btn square">
      <i class="icon icon-24" *ngIf="isHeader && !medium" [inlineSVG]="'back.svg'"></i>
      <i class="icon icon-18" *ngIf="!isHeader || medium" [inlineSVG]="'home.svg'"></i>
    </a>
  </li>
  <ng-container *ngIf="!isHeader || medium">
    <ng-container *ngFor="let item of items">
      <li>
        <i class="icon icon-10 chevron-margin" [inlineSVG]="'chevron-right.svg'"></i>
      </li>
      <li>
        <ng-container *ngIf="item.Url">
          <a class="btn square light" [routerLink]="'/' + item.Url">{{ item.Title }}</a>
        </ng-container>
        <ng-container *ngIf="!item.Url">
          <label>{{ item.Title }}</label>
        </ng-container>
      </li>
    </ng-container>
  </ng-container>
</ul>
