import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/core/api/api.service';
import {
  ILoungePostBodyContentSearch_Status,
  ILoungeResponseContentSearch_Status,
} from '../interfaces/endpoint/content-search-status.interface';
import {
  ILoungePostBodyDlp_UsersInClasses,
  ILoungeResponseDlp_Classes,
  ILoungeResponseDlp_CurrentUser,
  ILoungeResponseDlp_TeachersInInstitution,
  ILoungeResponseDlp_UsersInClass,
  ILoungeResponseDlp_UsersInClasses,
} from '../interfaces/endpoint/dlp.interface';
import { ILoungeResponseLevel_GetListOfLevelsLevelGroupsAndEducationGroups } from '../interfaces/endpoint/level.interface';
import { ILoungeResponseTemplate } from '../interfaces/endpoint/response-template.interface';
import {
  ILoungePostBodySubTeam_CreateSubTeam,
  ILoungePostBodySubTeam_SaveStudentsToSubTeam,
  ILoungePutBodySubTeam_UpdateSubTeam,
  ILoungeResponseSubTeam_CreateSubTeam,
  ILoungeResponseSubTeam_GetAllSubTeamsInTeam,
  ILoungeResponseSubTeam_GetIconsAvailableToSubTeams,
  ILoungeResponseSubTeam_GetSingleSubTeam,
  ILoungeResponseSubTeam_SaveStudentsToSubTeam,
  ILoungeResponseSubTeam_UpdateSubTeam,
} from '../interfaces/endpoint/sub-team.interface';
import {
  ILoungePostBodyTeam_AddTeachersToTeam,
  ILoungePostBodyTeam_AddTeacherToTeam,
  ILoungePostBodyTeam_CreateTeam,
  ILoungePostBodyTeam_SaveStudentsToTeam,
  ILoungePutBodyTeam_UpdateTeam,
  ILoungeResponseTeam_AddTeachersToTeam,
  ILoungeResponseTeam_AddTeacherToTeam,
  ILoungeResponseTeam_CreateTeam,
  ILoungeResponseTeam_GetSingleTeam,
  ILoungeResponseTeam_GetStudentsInTeam,
  ILoungeResponseTeam_GetTeamsAndClassesAvailableToUser,
  ILoungeResponseTeam_GetTeamsAvailableToUser,
  ILoungeResponseTeam_GetTeamsWhereUserIsStudent,
  ILoungeResponseTeam_SaveStudentsToTeam,
  ILoungeResponseTeam_StudentsInSubTeams,
  ILoungeResponseTeam_UpdateTeam,
} from '../interfaces/endpoint/team.interface';

@Injectable({
  providedIn: 'root',
})
export class LoungeApiService {
  /** @ignore */
  constructor(private apiService: ApiService) {}

  //#region DLP (classes, teachers, students) ########################################################
  dlpGetCurrentUser(): Observable<ILoungeResponseDlp_CurrentUser> {
    return this.apiService.getLounge<ILoungeResponseDlp_CurrentUser>(`v1/dlp/user`);
  }

  dlpGetClasses(): Observable<ILoungeResponseDlp_Classes> {
    return this.apiService.getLounge<ILoungeResponseDlp_Classes>(`v1/dlp/classes`);
  }

  dlpGetStudentsInClass(classId: string): Observable<ILoungeResponseDlp_UsersInClass> {
    return this.apiService.getLounge<ILoungeResponseDlp_UsersInClass>(`v1/dlp/class/${classId}/students`);
  }

  dlpGetClassesWithStudents(): Observable<ILoungeResponseDlp_UsersInClasses> {
    return this.apiService.getLounge<ILoungeResponseDlp_UsersInClasses>(`v1/dlp/classes/users`);
  }

  dlpGetStudentsInClasses(classIds: string[]): Observable<ILoungeResponseDlp_UsersInClasses> {
    const postBody: ILoungePostBodyDlp_UsersInClasses = {
      dlpClassIds: classIds,
    };

    return this.apiService.postLounge<ILoungeResponseDlp_UsersInClasses>(`v1/dlp/classes/students`, postBody);
  }

  dlpGetAllTeachersInInstitution(): Observable<ILoungeResponseDlp_TeachersInInstitution> {
    return this.apiService.getLounge<ILoungeResponseDlp_TeachersInInstitution>(`v1/dlp/teachers`);
  }
  //#endregion DLP (classes, teachers, students) ########################################################

  //#region LEVEL ########################################################
  levelGetListOfLevelsLevelGroupsAndEducationGroups(): Observable<ILoungeResponseLevel_GetListOfLevelsLevelGroupsAndEducationGroups> {
    return this.apiService.getLounge<ILoungeResponseLevel_GetListOfLevelsLevelGroupsAndEducationGroups>(`v1/level`);
  }
  //#endregion LEVEL ########################################################

  //#region SUBTEAM ########################################################
  subTeamGetSingleSubTeam(subTeamId: string): Observable<ILoungeResponseSubTeam_GetSingleSubTeam> {
    return this.apiService.getLounge<ILoungeResponseSubTeam_GetSingleSubTeam>(`v1/subteam/${subTeamId}`);
  }

  subTeamGetAllSubTeamsInTeam(teamId: string): Observable<ILoungeResponseSubTeam_GetAllSubTeamsInTeam> {
    return this.apiService.getLounge<ILoungeResponseSubTeam_GetAllSubTeamsInTeam>(`v1/subteam/${teamId}/list`);
  }

  subTeamCreateSubTeam(
    teamId: string,
    postBody: ILoungePostBodySubTeam_CreateSubTeam
  ): Observable<ILoungeResponseSubTeam_CreateSubTeam> {
    return this.apiService.postLounge<ILoungeResponseSubTeam_CreateSubTeam>(`v1/subteam/${teamId}`, postBody);
  }

  subTeamUpdateSubTeam(
    teamId: string,
    subTeamId: string,
    putBody: ILoungePutBodySubTeam_UpdateSubTeam
  ): Observable<ILoungeResponseSubTeam_UpdateSubTeam> {
    return this.apiService.putLounge<ILoungeResponseSubTeam_UpdateSubTeam>(
      `v1/subteam/${teamId}/${subTeamId}`,
      putBody
    );
  }

  subTeamDeleteSubTeam(teamId: string, subTeamId: string): Observable<ILoungeResponseTemplate> {
    return this.apiService.deleteLounge<ILoungeResponseTemplate>(`v1/subteam/${teamId}/${subTeamId}`);
  }

  subTeamSaveStudentsToSubTeam(
    teamId: string,
    subTeamId: string,
    postBody: ILoungePostBodySubTeam_SaveStudentsToSubTeam
  ): Observable<ILoungeResponseSubTeam_SaveStudentsToSubTeam> {
    return this.apiService.postLounge<ILoungeResponseSubTeam_SaveStudentsToSubTeam>(
      `v1/subteam/${teamId}/${subTeamId}/students`,
      postBody
    );
  }

  subTeamGetIconsAvailableToSubTeams(): Observable<ILoungeResponseSubTeam_GetIconsAvailableToSubTeams> {
    return this.apiService.getLounge<ILoungeResponseSubTeam_GetIconsAvailableToSubTeams>(`v1/subteam/icons`);
  }
  //#endregion SUBTEAM ########################################################

  //#region TEAM ########################################################
  teamGetSingleTeam(teamId: string): Observable<ILoungeResponseTeam_GetSingleTeam> {
    return this.apiService.getLounge<ILoungeResponseTeam_GetSingleTeam>(`v1/team/${teamId}`);
  }

  teamUpdateTeam(teamId: string, putBody: ILoungePutBodyTeam_UpdateTeam): Observable<ILoungeResponseTeam_UpdateTeam> {
    return this.apiService.putLounge<ILoungeResponseTeam_UpdateTeam>(`v1/team/${teamId}`, putBody);
  }

  teamDeleteTeam(teamId: string): Observable<ILoungeResponseTemplate> {
    return this.apiService.deleteLounge<ILoungeResponseTemplate>(`v1/team/${teamId}`);
  }

  teamGetTeamsAvailableToUser(productId: string): Observable<ILoungeResponseTeam_GetTeamsAvailableToUser> {
    return this.apiService.getLounge<ILoungeResponseTeam_GetTeamsAvailableToUser>(
      `v1/team/list?productId=${productId}`
    );
  }

  teamGetTeamsAndDlpClassesAvailableToUser(
    productId: string
  ): Observable<ILoungeResponseTeam_GetTeamsAndClassesAvailableToUser> {
    return this.apiService.getLounge<ILoungeResponseTeam_GetTeamsAndClassesAvailableToUser>(
      `v1/team/listwithclasses?productId=${productId}`
    );
  }

  teamGetTeamsWhereUserIsStudent(productId: string): Observable<ILoungeResponseTeam_GetTeamsWhereUserIsStudent> {
    return this.apiService.getLounge<ILoungeResponseTeam_GetTeamsWhereUserIsStudent>(
      `v1/team/student-in/list?productId=${productId}`
    );
  }

  teamCreateTeam(postBody: ILoungePostBodyTeam_CreateTeam): Observable<ILoungeResponseTeam_CreateTeam> {
    return this.apiService.postLounge<ILoungeResponseTeam_CreateTeam>(`v1/team`, postBody);
  }

  teamSaveStudentsToTeam(
    teamId: string,
    postBody: ILoungePostBodyTeam_SaveStudentsToTeam
  ): Observable<ILoungeResponseTeam_SaveStudentsToTeam> {
    return this.apiService.postLounge<ILoungeResponseTeam_SaveStudentsToTeam>(`v1/team/${teamId}/students`, postBody);
  }

  teamGetStudentsInTeam(teamId: string): Observable<ILoungeResponseTeam_GetStudentsInTeam> {
    return this.apiService.getLounge<ILoungeResponseTeam_GetStudentsInTeam>(`v1/team/${teamId}/students`);
  }

  teamGetStudentsInSubTeams(teamId: string): Observable<ILoungeResponseTeam_StudentsInSubTeams> {
    return this.apiService.getLounge<ILoungeResponseTeam_StudentsInSubTeams>(`v1/team/${teamId}/students-in-subteams`);
  }

  teamAddTeacher(
    teamId: string,
    postBody: ILoungePostBodyTeam_AddTeacherToTeam
  ): Observable<ILoungeResponseTeam_AddTeacherToTeam> {
    return this.apiService.postLounge<ILoungeResponseTeam_AddTeacherToTeam>(`v1/team/${teamId}/teacher`, postBody);
  }

  teamRemoveTeacher(teamId: string, userId: string): Observable<ILoungeResponseTemplate> {
    return this.apiService.deleteLounge<ILoungeResponseTemplate>(`v1/team/${teamId}/teacher/${userId}`);
  }

  teamRemoveSelf(teamId: string): Observable<ILoungeResponseTemplate> {
    return this.apiService.deleteLounge<ILoungeResponseTemplate>(`v1/team/${teamId}/teacher/self`);
  }

  teamAddTeachers(
    teamId: string,
    postBody: ILoungePostBodyTeam_AddTeachersToTeam
  ): Observable<ILoungeResponseTeam_AddTeachersToTeam> {
    return this.apiService.postLounge<ILoungeResponseTeam_AddTeachersToTeam>(`v1/team/${teamId}/teachers`, postBody);
  }
  //#endregion TEAM ########################################################

  //#region CONTENT SEARCH STATUS ########################################################

  /**
   * content search status
   * @param postBody
   * @returns
   */
  contentSearchStatus(postBody: ILoungePostBodyContentSearch_Status): Observable<ILoungeResponseContentSearch_Status> {
    return this.apiService.postLounge<ILoungeResponseContentSearch_Status>(`v1/contentsearchstatus`, postBody);
  }

  //#endregion TEAM ########################################################
}
