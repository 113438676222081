import { Injectable } from '@angular/core';
import { UserService } from '@lru/felib';
import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { ContentTypeEnum } from '@portal-app/enums/content-type.enum';
import { FacetTypeEnum } from '@portal-app/enums/facet-type.enum';
import { INextCourseSearchAutoCompleteResponse } from '@portal-app/interfaces/course/next/next-course-library.interface';
import { ILibraryFacets, ISearchAutoComplete } from '@portal-app/interfaces/course/project/course-library.interface';
import { CourseFavoriteApiService } from '@portal-app/services/course/course-favorite-api.service';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { LevelService } from '@portal-app/services/shared/level.service';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { INextSearchResponseFacets } from '../../interfaces/next/next-library';
import { AssetMapper } from '../shared/asset.mapper';
import { CourseMapper } from './course.mapper';

@Injectable({
  providedIn: 'root',
})
export class CourseLibraryMapper {
  constructor(
    private helperService: HelperService,
    private appInitService: AppInitService,
    private courseMapper: CourseMapper,
    private courseFavoriteApiService: CourseFavoriteApiService,
    private userService: UserService,
    private levelService: LevelService,
    private translate: TranslateService,
    private assetMapper: AssetMapper
  ) {}

  getFacetItemTitle(title: string, type: string): string {
    if (type === FacetTypeEnum.CollaborationTypes) {
      return this.translate.instant(`CollaborationType.${title}`, {
        default: title,
      });
    } else if (type === FacetTypeEnum.Genres) {
      return this.translate.instant(`GenreType.${title}`, { default: title });
    } else {
      return title;
    }
  }

  mapFacetsFromApi(facets: INextSearchResponseFacets[]): ILibraryFacets[] {
    const result: ILibraryFacets[] = [];
    if (facets.length) {
      for (const facet of facets) {
        if (FacetTypeEnum[facet.type as keyof typeof FacetTypeEnum] && facet.items.length) {
          result.push({
            Title: this.translate.instant(`FacetType.${facet.type}`, {
              default: facet.type,
            }),
            UrlTitle: QueryStrings[facet.type as keyof typeof QueryStrings]?.en,
            Type: FacetTypeEnum[facet.type as keyof typeof FacetTypeEnum],
            Items: facet.items.map((item) => ({
              Title: this.getFacetItemTitle(item.value, facet.type),
              Value: item.key,
              Count: item.count,
              Selected: item.selected,
            })),
            Open: this.helperService.getParameterByQueryStringAndSuffix(
              QueryStrings[facet.type as keyof typeof QueryStrings],
              QueryStrings.Open
            )
              ? true
              : false,
          });
        }
      }
    }
    return result;
  }

  mapAutoCompleteFromApi(query: string, autocomplete: INextCourseSearchAutoCompleteResponse): ISearchAutoComplete {
    const result: ISearchAutoComplete = {
      Suggestions: [],
      Courses: [],
      Books: [],
      BookCollections: [],
    };
    if (autocomplete.autocompleteItems) {
      for (const item of autocomplete.autocompleteItems) {
        if (item.queryPlusText !== query) {
          result.Suggestions.push(item.queryPlusText);
        }
      }
    }

    if (autocomplete.suggestions) {
      for (const item of autocomplete.suggestions) {
        if (item.course) {
          result.Courses.push({
            Asset: this.assetMapper.map(item.course.asset),
            Id: item.course.id,
            Levels: this.levelService.convertLevelsToString(item.course.levels),
            StatusLabel: item.course.statusLabel,
            Title: item.course.title,
            Url: `${this.appInitService.siteConfiguration.BasePath}${item.course.url}`,
            IsLocked: item.locked,
            FavoriteLoaded: false,
            ObjectType: ContentTypeEnum.Courses,
            IsActive: this.courseMapper.getCourseIsActive(item.course.status),
          });
        }
      }
    }

    if (this.userService.isLoggedIn && result.Courses.length) {
      const ids = result.Courses.map((item) => item.Id);
      this.courseFavoriteApiService
        .getFavoriteCourseByIds(ids)
        .pipe(
          catchError((e) => {
            result.Courses.map((item) => (item.FavoriteLoaded = true));
            return EMPTY;
          })
        )
        .subscribe((data) => {
          if (data) {
            for (const item of result.Courses) {
              item.IsFavorite = data.find((elem) => elem.Id === item.Id)?.IsFavorite;
              item.FavoriteLoaded = true;
            }
          }
        });
    }

    return result;
  }
}
