import { Pipe, PipeTransform } from '@angular/core';
import { LevelDto } from '../../../../interfaces/endpoint/dtos/dto-level.interface';
import { IClassWithUsers } from '../../../../store/interfaces/team-edit.interface';

/** Find classes that contains users that includes the searchTerm in their name
 * or find classes that is a level in searchLevels */
@Pipe({ name: 'searchClassWithUsers' })
export class SearchClassWithUsersPipe implements PipeTransform {
  /** Takes a list of IClassWithUsers and filters them by searchTerm and searchLevels and then output a filtered list of IClassWithUsers
   * @param values List of classes with users
   * @param searchTerm User name query
   * @param searchLevels Class level query
   * @returns List of classes */
  transform(values: IClassWithUsers[], searchTerm: string, searchLevels: LevelDto[]): IClassWithUsers[] {
    if (!searchTerm && searchLevels.length === 0) {
      return values;
    }

    let classWithUsersList: IClassWithUsers[] = [];

    if (searchLevels.length > 0) {
      const classesToAdd = values.filter((x) => searchLevels.some((y) => y.id === x.class.levelId));

      if (classesToAdd.length > 0) {
        classWithUsersList.push(...classesToAdd);
      }
    }

    const valuesToSearchIn = searchLevels.length > 0 ? classWithUsersList : values;

    if (searchTerm) {
      classWithUsersList = [];
      valuesToSearchIn.forEach((element) => {
        const users = element.users.filter((x) => x.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));

        if (users.length > 0) {
          if (!classWithUsersList.some((x) => x.class.id === element.class.id)) {
            classWithUsersList.push({ class: element.class, users: users });
          }
        }
      });
    }

    return classWithUsersList;
  }
}
