import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { UserService } from '../../user/user.service';

/** Interceptor that catches all HTTP requests and add authentication headers to the request. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /** @ignore */
  constructor(private userService: UserService) {}

  /** @ignore */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.addAuth(request).pipe(
      mergeMap((request) => next.handle(request)),
      catchError((x) => throwError(() => x))
    );
  }

  /** Adds authentication headers on the HTTP request.
   * @param request The intercepted request
   * @returns The intercepted request with the added authentication headers */
  private addAuth(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    return this.userService.user$.pipe(
      switchMap((user) => {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json; charset=utf-8',
          },
        });
        if (user) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${user.accessToken}`,
            AuthenticationToken: user.authenticationToken,
            ContextIdentifier: user.contextIdentifier,
            InstitutionNumber: user.institutionNumber,
          });

          request = request.clone({ headers });
        }

        return of(request);
      })
    );
  }
}
