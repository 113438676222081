<div class="flex space-2">
  <portal-book-tile [item]="item" [settings]="settings" [type]="settings.Type"></portal-book-tile>
  <div class="content">
    <label class="headline-5 title"
      >{{ item.Title }} <i *ngIf="item.HasAudio" class="icon icon-14 icon-audio" [inlineSVG]="'audio-book.svg'"></i
    ></label>
    <div class="ws-10"></div>
    <portal-book-difficulties [items]="item.Difficulties"></portal-book-difficulties>
  </div>
</div>
