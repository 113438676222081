import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { DropdownOutletComponent } from './components/dropdown-outlet/dropdown-outlet.component';
import { DropdownConfig } from './dropdown-config';
import { IDropdownConfig } from './dropdown-config.interface';

@NgModule({
  declarations: [DropdownComponent, DropdownOutletComponent],
  imports: [CommonModule, PortalModule, ButtonModule],
  exports: [DropdownComponent, DropdownOutletComponent],
  providers: [{ provide: Window, useValue: window }],
})
export class DropdownModule {
  static forRoot(config: IDropdownConfig): ModuleWithProviders<DropdownModule> {
    return {
      ngModule: DropdownModule,
      providers: [{ provide: DropdownConfig, useValue: config }],
    };
  }

  static forChild(config: IDropdownConfig): ModuleWithProviders<DropdownModule> {
    return {
      ngModule: DropdownModule,
      providers: [{ provide: DropdownConfig, useValue: config }],
    };
  }
}
