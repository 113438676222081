export enum GTMEvent {
  GTMJS = 'gtm.js',
  Page = 'page',

  // Toggle Component
  Toggle = 'Toggle',

  // Audio player
  Open_recorded = 'Open_recorded',
  Close_recorded = 'Closed_recorded',
  Play_recorded = 'Play_recorded',
  Pause_recorded = 'Pause_recorded',
  Ended_recorded = 'Ended_recorded',
  Seeked_recorded = 'Seeked_recorded',
  Forward_recorded = 'Forward_recorded',
  Backwards_recorded = 'Backwards_recorded',

  Play_selected = 'Play_selected',
  Pause_selected = 'Pause_selected',
  Ended_selected = 'Ended_selected',
  Forward_selected = 'Forward_selected',
  Backwards_selected = 'Backwards_selected',

  Play_full = 'Play_full',
  Pause_full = 'Pause_full',
  Ended_full = 'Ended_full',
  Forward_full = 'Forward_full',
  Backwards_full = 'Backwards_full',
  Audio_started = 'Audio_started',
  Audio_paused = 'Audio_paused',
  Audio_download = 'Audio_download',

  // Favourite
  Favourite_set = 'favourite-set',
  Favourite_remove = 'favourite-remove',

  // Images
  Image_fullscreen = 'image-fullscreen',

  // Course
  Course_more_info_open = 'course-moreinfo-open',
  Course_more_info_open_course = 'course-moreinfo-opencourse',

  // Library
  Media_accordion = 'media-accordion',
  Media_open = 'media-open',

  Library_filters_modal = 'library-filters-modal',
  Library_set_filter = 'library-set-filter',

  Library_search_autocomplete = 'library-search-autocomplete',

  // AnnualPlan
  Annual_plan_open_course = 'annualplan-open-course',
  Annual_plan_open_course_open = 'annualplan-open-course-open',
  Annual_plan_print = 'annualplan-print',

  // Student Answers
  Student_answers_choose_class = 'student-answers-choose-class',
  Student_answers_choose_student = 'student-answers-choose-student',
  Student_answers_print = 'student-answers-print',
  Student_answers_see_results = 'student-answers-see-results',
  Student_answers_choose_see_students = 'student-answers-choose-see-students',

  // Superbog

  // Læsekasse
  Collection_open = 'collection-open',
  Collection_change_cover = 'collection-change-cover',
  Collection_assign_class = 'collection-assign-class',
  Collection_unassign_class = 'collection-unassign-class',
  Collection_save = 'collection-save',
  Collection_create = 'collection-create',
  Collection_delete = 'collection-delete',
  Collection_show_institution = 'collection-show-institution',
  Collection_hide_institution = 'collection-hide-institution',

  // Filters
  Filter_add_tag = 'filter-add-tag',
  Filter_remove_all = 'filter-remove-all',
  Filter_remove_tag = 'filter-remove-tag',

  // Book
  Open_book = 'open-book',
  Remove_from_collection = 'remove-from-collection',
  Add_to_collection = 'add-to-collection',

  // Bog info
  Bookinfo_openbook = 'bookinfo-openbook',

  // Reader
  Reader_contenttable = 'reader-contenttable',
  Reader_progressbar = 'reader-progressbar',
  Reader_nextpage = 'reader-nextpage',
  Reader_previouspage = 'reader-previouspage',
  Reader_recommendation_tag = 'reader-recommendation-tag',
  Reader_recommendation_tag_remove = 'reader-recommendation-tag-remove',
  Reader_close_book = 'reader-close-book',
  Reader_changesize = 'reader-changesize',
  Reader_changefont = 'reader-changefont',
  Reader_linespace = 'reader-linespace',
  Reader_changecolor = 'reader-changecolor',
  Reader_changepagetype = 'reader-changepagetype',

  // Forside
  Overview_readbook_remove = 'overview-readbook-remove',
  Overview_continuebook_remove = 'overview-continuebook-remove',
  Overview_readbook_recommendation_tag = 'overview-readbook-recommendation-tag',
  Overview_readbook_recommendation_tag_remove = 'overview-readbook-recommendation-tag-remove',

  // Lærer dashboard
  Dashboard_class = 'dashboard-class',
  Dashboard_student = 'dashboard-student',
  Dashboard_date = 'dashboard-date',

  // PS Opgaver
  filter_accordion = 'filter-accordion',
  filter_subject = 'filter-subject',
  filter_topic = 'filter-topic',
  filter_class = 'filter-class',
  filter_remove_all = 'filter-remove-all',
  Assignment_accordion = 'assignment-accordion',
  Assignment_open = 'assignment-open',
  Assignment_close = 'assignment-close',
  Assignment_copylink = 'assignment-copylink',
  Assignment_download_all = 'assignment-downloadall',
  Assignment_download = 'assignment-download',
  Article_print = 'article-print',
}
