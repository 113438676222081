import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';
import { ToastMessageListComponent } from './components/toast-message-list/toast-message-list.component';
import { ToastService } from './services/toast.service';
import { ToastEffects } from './store/toast.effects';
import { toastFeatureKey, toastReducer } from './store/toast.reducers';
import { ToastConfig } from './toast-config';
import { IToastConfig } from './toast-config.interface';

@NgModule({
  declarations: [ToastMessageComponent, ToastMessageListComponent],
  imports: [
    CommonModule,
    StoreModule.forFeature(toastFeatureKey, toastReducer),
    EffectsModule.forFeature([ToastEffects]),
    ButtonModule,
    IconModule,
  ],
  exports: [ToastMessageComponent, ToastMessageListComponent],
  providers: [ToastService],
})
export class ToastModule {
  static forRoot(config: IToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [{ provide: ToastConfig, useValue: config }],
    };
  }

  static forChild(config: IToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [{ provide: ToastConfig, useValue: config }],
    };
  }
}
