import { Injectable } from '@angular/core';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { PsLibraryFilterEnum } from '../../enums/ps/ps-library-filter.enum';
import { IPsAssignment } from '../../interfaces/ps/project/ps-assignment.interface';
import { IUmbPsAssignment } from '../../interfaces/ps/umbraco/umb-ps-assignment.interface';
import { HelperService } from '../../services/shared/helper.service';
import { HtmlService } from '../../services/shared/html.service';

@Injectable({
  providedIn: 'root',
})
export class PsAssignmentMapper {
  constructor(private htmlService: HtmlService, private helperService: HelperService) {}

  mapPsAssignmentFromApi(assignment: IUmbPsAssignment, truncate?: number): IPsAssignment {
    return {
      Id: assignment.id,
      Topics: assignment.topics?.length
        ? assignment.topics.map((elem) => ({
            Id: elem.id,
            Title: elem.title,
            Type: PsLibraryFilterEnum.Topic,
          }))
        : [],
      Authors: assignment.authors?.length
        ? assignment.authors.map((elem) => ({
            Id: elem.id,
            Title: elem.title,
            Type: PsLibraryFilterEnum.Author,
          }))
        : [],
      AudioFilesCount: assignment.audioFilesCount,
      AudioFiles: assignment.audioFiles?.length
        ? assignment.audioFiles.map((elem) => ({
            Svg: this.helperService.getFileIconSvg(elem.fileExtension),
            FileName: elem.fileName,
            Title: elem.title,
            Url: elem.url,
            Caption: elem.caption,
            CopyrightText: elem.copyrightText,
          }))
        : [],
      Documents: assignment.documents?.length
        ? assignment.documents.map((elem) => ({
            Svg: this.helperService.getFileIconSvg(elem.fileExtension),
            FileName: elem.fileName,
            Title: elem.title,
            Url: elem.url,
            Caption: elem.caption,
            CopyrightText: elem.copyrightText,
          }))
        : [],
      IsFavorite: assignment.isFavorite,
      Levels: assignment.levels?.length
        ? assignment.levels.map((elem) => ({
            Id: elem.id,
            Title: elem.title,
            Type: PsLibraryFilterEnum.Level,
          }))
        : [],
      PublishDate: assignment.publishDate ? new Date(assignment.publishDate) : undefined,
      Subject: assignment.subject
        ? {
            Id: assignment.subject.id,
            Title: assignment.subject.title,
            BackgroundColor: assignment.subject.backgroundColor,
            TextColor: assignment.subject.textColor,
            Type: PsLibraryFilterEnum.Subject,
          }
        : undefined,
      TeacherDocuments: assignment.teacherDocuments?.length
        ? assignment.teacherDocuments.map((elem) => ({
            Svg: this.helperService.getFileIconSvg(elem.fileExtension),
            FileName: elem.fileName,
            Title: elem.title,
            Url: elem.url,
            Caption: elem.caption,
            CopyrightText: elem.copyrightText,
          }))
        : [],
      Teaser: this.htmlService.fixHtml(this.htmlService.truncateHTML(assignment.teaser, truncate)),
      Themes: assignment.themes?.length
        ? assignment.themes.map((elem) => ({
            Id: elem.id,
            Title: elem.title,
            Type: PsLibraryFilterEnum.Theme,
          }))
        : [],
      Title: assignment.title,
      Thumbnail: assignment.thumbnail,
      Url: assignment.customUrl
        ? `/${this.helperService.getCustomUrl(assignment.customUrl)!}`
        : `/${PageTypeEnum.PsAssignment}/${assignment.id}`,
      Open: false,
      DownloadUrl: assignment.downloadUrl,
      HasFiles:
        (assignment.audioFiles?.length || assignment.documents?.length || assignment.teacherDocuments?.length) > 0,
    };
  }

  mapPsAssignmentsFromApi(assignments?: IUmbPsAssignment[], truncate?: number): IPsAssignment[] {
    const result: IPsAssignment[] = [];
    assignments?.forEach((article) => {
      result.push(this.mapPsAssignmentFromApi(article, truncate));
    });
    return result;
  }
}
