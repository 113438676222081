import { Injectable } from '@angular/core';
import { ApiService } from '@lru/felib';
import {
  INextCourseSearchActivity,
  INextCourseSearchActivityObject2,
} from '@portal-app/interfaces/course/next/next-course-library.interface';
import { map } from 'rxjs';
import { AppInitService } from '../shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityFavoriteService {
  constructor(private api: ApiService, private initService: AppInitService) {}

  private contentType = 'Activity';

  getFavoriteActivitiesByProjectGroupId() {
    // // this api call was supposed to be generic but its not. its only for activities
    return this.api
      .getNext<INextCourseSearchActivityObject2[] | undefined>(
        `Favorite/GetFavoriteContent?id=${this.initService.siteConfiguration.ProjectGroup}`
      )
      .pipe(map((activities) => this.mapGetFavoriteContentResponse(activities)));
  }

  markFavorite(id: string) {
    return this.api.postNext<string>(`Favorite/AddContentToFavorites?id=${id}&contentType=${this.contentType}`);
  }

  removeFavorite(id: string) {
    return this.api.deleteNext<string>(`Favorite/DeleteContentFromFavorites?id=${id}&contentType=${this.contentType}`);
  }

  private mapGetFavoriteContentResponse(activities?: INextCourseSearchActivityObject2[]) {
    if (!activities) {
      return;
    }

    const list: INextCourseSearchActivity[] = [];
    activities.forEach((activity) => {
      list.push({
        activity: {
          id: activity.Id,
          duration: { hours: activity.Duration.Hours, minutes: activity.Duration.Minutes },
          label: activity.Label,
          levels: activity.Levels,
          title: activity.Title,
          topics: activity.Topics,
          types: activity.Types,
          url: activity.Url,
          asset: activity.Asset
            ? {
                path: activity.Asset.Path,
                type: activity.Asset.Type,
                basePath: activity.Asset.BasePath,
                changeFormat: activity.Asset.ChangeFormat,
                copyright: activity.Asset.Copyright,
                description: activity.Asset.Description,
                dimensions: activity.Asset.Dimensions
                  ? { width: activity.Asset.Dimensions.Width, height: activity.Asset.Dimensions.Height }
                  : undefined,
                fileName: activity.Asset.FileName,
                fileSize: activity.Asset.FileSize,
                fileType: activity.Asset.FileType,
                id: activity.Asset.Id,
                slug: activity.Asset.Slug,
                title: activity.Asset.Title,
              }
            : undefined,
          writers: activity.Writers,
          description: activity.Description,
        },
        hasLicense: false,
        isShowcase: false,
      });
    });

    return list;
  }
}
