import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, InlineSVGModule.forRoot({ baseUrl: '/assets/svg/' }), HttpClientModule],
  exports: [IconComponent],
})
export class IconModule {}
