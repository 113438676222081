<lru-icon *ngIf="message.icon" [icon]="message.icon"></lru-icon>
<label class="toast-message-message">{{ message.message }}</label>
<lru-button
  class="close"
  [variant]="'lounge'"
  [theme]="'icon-button'"
  *ngIf="message.canDismiss"
  (click)="onDismissClick()"
>
  <lru-icon [icon]="{ path: 'felib-x-mark', size: 12 }"></lru-icon>
</lru-button>
