<button (click)="closeModal()" class="close">
  <i class="icon icon-26" [inlineSVG]="'x-mark-filled.svg'"></i>
</button>
<h2 class="headline-2">{{ 'SelectImpressionsModal.WhatDoYouThink' | translate }}</h2>
<div class="ws-35"></div>
<p>{{ 'SelectImpressionsModal.ChooseMax' | translate }} {{ total }}</p>
<div class="ws-10"></div>
<div class="flex space-1 wrap">
  <ng-container *ngIf="impressions">
    <ng-container *ngFor="let impression of impressions; let i = index">
      <div class="checkbox-element">
        <input
          id="epub-selected-impressions-{{ i }}"
          name="epub-selected-impressions"
          type="checkbox"
          value="{{ impression.Slug }}"
          [checked]="impression.Selected === true"
          (change)="checkImpression($event, impression)"
        />
        <label tabindex="0" for="epub-selected-impressions-{{ i }}">
          {{ impression.Icon }}
          <span>{{ impression.Title }}</span>
        </label>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="ws-35"></div>
<div class="flex center">
  <button class="btn light" (click)="closeModal()">{{ 'SelectImpressionsModal.Ok' | translate }}</button>
</div>
