import { Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { getObservableValueSync } from '../../../utils/observables';
import { createUniqueId } from '../../../utils/uuid';
import { ZIndexService } from '../../z-index/services/z-index.service';
import { ToastMessageInstance } from '../interfaces/toast-message-instance.interface';
import { ToastMessage } from '../interfaces/toast-message.interface';
import * as ToastActions from '../store/toast.actions';
import { addMessage, continueMessageExpiration, pauseMessageExpiration, removeMessage } from '../store/toast.actions';
import { selectAllMessages, selectConfig } from '../store/toast.selectors';
import { ToastState } from '../store/toast.state';
import { ToastConfig } from '../toast-config';

/** A service for handling toast messages */
@Injectable({ providedIn: 'root' })
export class ToastService {
  /** Observable of all toast messages */
  public messages$ = this.toastStore.select(selectAllMessages);
  /** Observable of ToastConfig */
  public config$ = this.toastStore.select(selectConfig);

  /** Handles DependencyInjection and dispatching of either incoming or default config */
  constructor(
    private toastStore: Store<ToastState>,
    private zIndexService: ZIndexService,
    @Optional() private config?: ToastConfig
  ) {
    const defaultConfig = new ToastConfig();

    this.toastStore.dispatch(
      ToastActions.setConfig({
        config: {
          horizontalPosition: this.config?.horizontalPosition ?? defaultConfig.horizontalPosition,
          verticalPosition: this.config?.verticalPosition ?? defaultConfig.verticalPosition,
          durationInMilliseconds: this.config?.durationInMilliseconds ?? defaultConfig.durationInMilliseconds,
          spacingBetweenToasts: this.config?.spacingBetweenToasts ?? defaultConfig.spacingBetweenToasts,
          spacingFromWindow: this.config?.spacingFromWindow ?? defaultConfig.spacingFromWindow,
          variant: this.config?.variant ?? defaultConfig.variant,
        },
      })
    );
  }

  /** Displays the given toast message and returns the ID created to represent the message.
   * @param message either a string or configured {@link ToastMessage} object
   * @returns ID that represent the created message */
  public displayMessage(message: string | ToastMessage): string {
    this.zIndexService.setItem('felib-toast');
    if (typeof message === 'string') {
      message = { message: message, type: 'info', canDismiss: true };
    }

    const preparedMessage = this.prepareMessageForStore(message);

    this.toastStore.dispatch(addMessage({ message: preparedMessage }));

    return preparedMessage.id;
  }

  /** Dispatches an action to remove the specific message
   * @param id the id of the specific message to remove */
  public removeMessage(id: string): void {
    this.toastStore.dispatch(removeMessage({ id }));
  }

  /** Dispatches an action to pause all message expirations */
  public pause(): void {
    this.toastStore.dispatch(pauseMessageExpiration());
  }

  /** Dispatches an action to continue all message expirations */
  public continue(): void {
    this.toastStore.dispatch(continueMessageExpiration());
  }

  /** Prepares the message for the store by adding specific icons and colors dependent on the specific type of message
   * @param message The message to prepare for the store
   * @returns A {@link ToastMessageInstance} object created from the param. */
  private prepareMessageForStore(message: ToastMessage): ToastMessageInstance {
    const id = createUniqueId('toast-message');

    if (!message.icon) {
      if (message.type === 'success') {
        message.icon = {
          path: 'felib-validation-true-icon',
          colors: {
            color: 'white',
          },
        };
      } else if (message.type === 'info') {
        message.icon = {
          path: 'felib-check',
        };
      } else if (message.type === 'warning') {
        message.icon = {
          path: 'felib-check',
        };
      } else if (message.type === 'error') {
        message.icon = {
          path: 'felib-validation-false-icon',
          colors: {
            color: 'white',
          },
        };
      }
    }

    let messageInstance: ToastMessageInstance = { ...message, id: id, icon: message.icon };

    if (messageInstance.duration === undefined) {
      messageInstance.duration = getObservableValueSync(this.config$).durationInMilliseconds!;
    }

    return messageInstance;
  }
}
