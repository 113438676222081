import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { GoogleTagManagerService } from '../../../../services/gtm/google-tag-manager.service';
import { LoungeCreateTeam, LoungeSort } from '../../../../services/gtm/gtm-events.const';
import { LoadingService } from '../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '../../../../services/loading/shared-loading-keys.enums';
import { StorageService } from '../../../../services/storage/storage.service';
import { sortComparer } from '../../../../utils/array.helper';
import { LoungeModalPages } from '../../enums/lounge-modal-pages.enum';
import { SortMode } from '../../enums/sort-mode.enum';
import { TeamWithTeachersDto } from '../../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { LoungeModalService } from '../../services/lounge-modal.service';
import { LoungeService } from '../../services/lounge.service';

/** A component to give an overview over all teams */
@Component({
  selector: 'lru-team-overview',
  templateUrl: './team-overview.component.html',
  styleUrls: ['./team-overview.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamOverviewComponent implements OnInit {
  /** @see {@link SortMode} */
  sortModeEnum = SortMode;

  /** {@link BehaviorSubject} for handling sorting mode of the loaded teams */
  sortMode$ = new BehaviorSubject<SortMode>(SortMode.alphabetically);

  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** Teams from the store sorted according to the sorting mode */
  teams$ = combineLatest([this.loungeService.teamTeamWithTeachersList$, this.sortMode$]).pipe(
    map(([data, sortMode]) => this.sort(sortMode, data))
  );

  /** local storage key for saving sorting mode */
  private teamOverviewSort = 'teamOverviewSort';

  /** @ignore */
  constructor(
    private storageService: StorageService,
    public loungeService: LoungeService,
    public loadingService: LoadingService,
    public loungeModalService: LoungeModalService,
    private gtmService: GoogleTagManagerService
  ) {}

  /** Retrieves the sort mode from the localstorage if it exists and if not use creationDate and applies it to {@link sortMode$} */
  ngOnInit(): void {
    this.sortMode$.next((this.storageService.get(this.teamOverviewSort) as SortMode) || SortMode.creationDate);
  }

  onClickCreateTeam(): void {
    this.loungeModalService.setPage(LoungeModalPages.studentPicker);
    this.gtmService.pushTagWithUserType(LoungeCreateTeam);
  }

  /** Applies the selected sort mode to {@link sortMode$} and saves the value in the localstorage */
  onClickSort(sortMode: SortMode): void {
    this.sortMode$.next(sortMode);
    this.storageService.set(this.teamOverviewSort, sortMode.toString());
    this.gtmService.pushTagWithUserType(LoungeSort, { sort: sortMode });
  }

  /** Method linked to event emitter from the modal.
   * @param showModal whether or not to show the team creation modal */
  onModalVisibilityChange(showModal: boolean): void {
    if (!showModal) {
      this.loungeModalService.setPageToNone();
    }
  }

  /** Sorting method for team with teachers
   * @param sortMode The sorting mode to sort the teams in
   * @param input The teams to sort
   * @returns The teams now sorted according to the sorting mode */
  private sort(sortMode: SortMode, input?: TeamWithTeachersDto[]): TeamWithTeachersDto[] | undefined {
    if (!input) {
      return undefined;
    }

    const models = [...input];

    if (sortMode) {
      if (sortMode === SortMode.alphabetically) {
        models.sort((a, b) => sortComparer(a.title.toLowerCase(), b.title.toLowerCase(), true));
      } else if (sortMode === SortMode.creationDate) {
        models.sort((a, b) => sortComparer(a.created, b.created));
      } else if (sortMode === SortMode.updatedDate) {
        models.sort((a, b) => sortComparer(a.updated, b.updated));
      }
    }

    return models;
  }
}
