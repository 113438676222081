import { isDevMode } from '@angular/core';

export interface IBackendUrlConfig {
  internalBackendUrlFallBack?: Function;
  loginUsingMvcDotNet?: boolean;
  internalBackendUrl?: string;
}

/** Config for API and login logic */
export class BackendUrlConfig implements IBackendUrlConfig {
  /** Determines the url for the backend of the project
   * @param loginUsingMvcDotNet should we use C# MVC for login or Node server?
   * @returns Url to use for API backend */
  internalBackendUrlFallBack = (loginUsingMvcDotNet?: boolean): string => {
    /** Møller made this.
     * He is unsure why typeof loginUsingMvcDotNet === 'boolean' is there */
    let loginDotNet = typeof loginUsingMvcDotNet === 'boolean' ? loginUsingMvcDotNet : this.loginUsingMvcDotNet;

    return isDevMode()
      ? loginDotNet
        ? 'https://localhost:44375/'
        : 'https://localhost:8081/'
      : `${window.location.origin}/`;
  };

  /** default is that C# MVC is used as backend */
  loginUsingMvcDotNet = true;

  /** default url for backend */
  internalBackendUrl = '';
}
