<div class="flex vertical-center space-1">
  <button *ngIf="showSecondsControl" (click)="onClickRewindSeconds()" class="btn small-width rewind-seconds">
    <i class="icon icon-24" [inlineSVG]="'rewind-10-sec.svg'"></i>
  </button>
  <div
    *ngIf="source"
    class="plyr-container"
    plyr
    [plyrPlaysInline]="true"
    [plyrSources]="[source]"
    [plyrOptions]="options"
    plyrType="audio"
    (plyrInit)="plyrInit($event)"
    (plyrPlay)="plyrPlay($event)"
    (plyrPause)="plyrPause($event)"
    (plyrSeeked)="plyrSeeked($event)"
    [plyrDriver]="useHls ? hlsDriver : undefined"
  ></div>
  <button *ngIf="showSecondsControl" (click)="onClickForwardSeconds()" class="btn small-width forward-seconds">
    <i class="icon icon-24" [inlineSVG]="'forward-10-sec.svg'"></i>
  </button>
</div>
