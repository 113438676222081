import { Injectable } from '@angular/core';
import { ContentTypeEnum } from '@portal-app/enums/content-type.enum';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import { INextActivitySearchResponse } from '@portal-app/interfaces/activity/activity-search-response.interface';
import { INextCourseSearchActivity } from '@portal-app/interfaces/course/next/next-course-library.interface';
import { IActivityList, ITabActivity } from '@portal-app/interfaces/course/project/course-tabs.interface';
import { HtmlService } from '@portal-app/services/shared/html.service';
import { environment } from '@portal-env/environment';

@Injectable({
  providedIn: 'root',
})
export class ActivityMapper {
  constructor(private htmlService: HtmlService) {}

  mapActivitiesToTab2(activities: ITabActivity[], hasFavorite?: boolean) {
    const item: IActivityList = {
      SliderSettings: {
        Type: SectionTypeEnum.ActivityList,
        MaxSlidesPerView: 5,
      },
      Items: activities,
    };

    return item;
  }

  mapActivitiesFromApi(activities: INextActivitySearchResponse[], hasFavorite?: boolean, trackingUrl?: string) {
    const result: ITabActivity[] = [];

    if (!activities) {
      // dont trust the api
      return result;
    }
    for (const item of activities) {
      result.push({
        Id: item.id,
        Asset: item.asset,
        Duration: item.duration,
        Description: this.htmlService.fixHtml(item.description),
        Title: item.title,
        Url: `${environment.activityUrl()}${item.url}${trackingUrl || ''}`,
        FavoriteLoaded: true,
        ObjectType: ContentTypeEnum.Activities,
        IsFavorite: hasFavorite ? true : false,
        Types: item.types,
      });
    }

    this.mapActivitiesToTab2(result, hasFavorite);

    return result;
  }

  mapActivitiesToTab(activities: INextCourseSearchActivity[], hasFavorite?: boolean, trackingUrl?: string) {
    const result: ITabActivity[] = [];
    activities.forEach((activityWrapper) => {
      const item: ITabActivity = {
        Id: activityWrapper.activity.id,
        Title: activityWrapper.activity.title,
        Url: `${environment.activityUrl()}${activityWrapper.activity.url}${trackingUrl || ''}`,
        Description: activityWrapper.activity.description,
        Label: activityWrapper.activity.label,
        Duration: activityWrapper.activity.duration,
        Asset: activityWrapper.activity.asset,
        Topics: activityWrapper.activity.topics,
        Types: activityWrapper.activity.types,
        Writers: activityWrapper.activity.writers,
        Levels: activityWrapper.activity.levels,
        IsLocked: activityWrapper.locked,
        IsFavorite: hasFavorite ? true : false,
        FavoriteLoaded: hasFavorite ? true : false,
        ObjectType: ContentTypeEnum.Activities,
      };

      result.push(item);
    });
    const item: IActivityList = {
      SliderSettings: {
        Type: SectionTypeEnum.ActivityList,
        MaxSlidesPerView: 5,
      },
      Items: result,
    };

    this.mapActivitiesToTab2(result, hasFavorite);

    return item;
  }
}
