import { ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { filter, firstValueFrom } from 'rxjs';

import { LevelDto } from '../../../interfaces/endpoint/dtos/dto-level.interface';
import { LoungeService } from './../../../services/lounge.service';

/** A component for showing a form for team details */
@Component({
  selector: 'lru-team-details-form[form]',
  templateUrl: './team-details-form.component.html',
  styleUrls: ['./team-details-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TeamDetailsFormComponent implements OnInit {
  /** The FormGroup consisting of title and level */
  @Input() form!: FormGroup;

  /** the selected level */
  selectedLevel?: { text: string; id: string };

  /** @ignore */
  constructor(public loungeService: LoungeService, private changeDetector: ChangeDetectorRef) {}

  /** Retrieve all levels and if the level formcontrol already contains a level mark it. Used for when editing teams so the level from the team is already prefilled in the form */
  async ngOnInit(): Promise<void> {
    const level = (await firstValueFrom(this.loungeService.fullLevel$.pipe(filter((x) => !!x))))?.levels.find(
      (x) => x.id === this.level?.value
    );

    if (level) {
      this.onClickLevel(level);
      this.changeDetector.markForCheck();
    }
  }

  /** Method for selecting the level
   * @param level the level to select */
  onClickLevel(level: LevelDto): void {
    this.selectedLevel = {
      text: level.mediumForm,
      id: level.id,
    };

    this.level?.setValue(level.id);
  }

  /** Getter for retrieving the FormControl for title from the FormGroup */
  get title(): AbstractControl | null {
    return this.form.get('title');
  }

  /** Getter for retrieving the FormControl for level from the FormGroup */
  get level(): AbstractControl | null {
    return this.form.get('level');
  }
}
