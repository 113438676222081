import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ZIndexService } from './services/z-index.service';
import { zIndexFeatureKey, zIndexReducer } from './store/z-index.reducers';
import { ZIndexConfig } from './z-index-config';
import { IZIndexConfig } from './z-index-config.interface';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(zIndexFeatureKey, zIndexReducer)],
  providers: [ZIndexService],
})
export class ZIndexModule {
  static forRoot(config: IZIndexConfig): ModuleWithProviders<ZIndexModule> {
    return {
      ngModule: ZIndexModule,
      providers: [{ provide: ZIndexConfig, useValue: config }],
    };
  }

  static forChild(config: IZIndexConfig): ModuleWithProviders<ZIndexModule> {
    return {
      ngModule: ZIndexModule,
      providers: [{ provide: ZIndexConfig, useValue: config }],
    };
  }
}
