export enum FacetTypeEnum {
  AssignmentTypes = 'AssignmentTypesEnum',
  CollaborationTypes = 'CollaborationTypes',
  Genres = 'Genres',
  Levels = 'Levels',
  Topics = 'Topics',
  Writers = 'Writers',
  Grades = 'Grades',
  ReadingLevels = 'ReadingLevels',
  DifficultyGroups = 'DifficultyGroups',
  Categories = 'Categories',
  Languages = 'Languages',
  Illustrations = 'Illustrations',
  Classes = 'Classes',
  Subjects = 'Subjects',
  HasAudioBook = 'HasAudioBook',
  SeriesTitle = 'SeriesTitle',
  AssetTopics = 'AssetTopics',
  Periods = 'Periods',
}
