import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '../../../../../services/loading/shared-loading-keys.enums';
import { LevelDto } from '../../../interfaces/endpoint/dtos/dto-level.interface';
import { LoungeService } from '../../../services/lounge.service';
import { IClassWithUsers } from '../../../store/interfaces/team-edit.interface';

/** A component for being able to select classes and students for teams */
@Component({
  selector: 'lru-team-picker',
  templateUrl: './team-picker.component.html',
  styleUrls: ['./team-picker.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamPickerComponent {
  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** Property for storing the levels the user has selected */
  selectedLevels: LevelDto[] = [];

  /** Property for storing the inputted search term */
  searchTerm: string = '';

  /** @ignore */
  constructor(public loungeService: LoungeService, public loadingService: LoadingService) {}

  /** Asks {@link LoungeService} to mark the student for selection.
   * @param classId the id of the class the student belongs in
   * @param studentId the id of the student to mark
   * @param checked whether to mark or unmark the student */
  onClickUser(classId: string, studentId: string, checked: boolean): void {
    this.loungeService.markStudentForSelection(classId, studentId, checked);
  }

  /** Asks {@link LoungeService} to mark the whole class for selection. Take into account the searchTerm so it only marks the students in the class that matches the searchTerm.
   * @param classId the id of the class to select
   * @param checked whether to mark or unmark the class */
  onClickClass(classId: string, checked: boolean): void {
    this.loungeService.markClassForSelection(classId, checked, this.searchTerm);
  }

  /** Method for marking or unmarking levels from user input
   * @param level the level to mark
   * @param checked whether to mark or unmark the level */
  onClickLevel(level: LevelDto, checked: boolean): void {
    if (checked) {
      this.selectedLevels = [...this.selectedLevels, level];
    } else {
      this.selectedLevels = this.selectedLevels.filter((elem) => elem.id !== level.id);
    }
  }

  /** Method for getting strings for the UI to show the levels
   * @returns string with name of level  */
  getLevelsLabel(): string {
    if (this.selectedLevels?.length) {
      return this.selectedLevels.map((item) => item.mediumForm).join(', ');
    } else {
      return 'Vælg klassetrin';
    }
  }

  /** Method used by level checkboxes indicating whether the level is selected or not
   * @param id the id of the level
   * @returns a boolean value wheter a level is selected */
  isLevelSelected(id: string): boolean {
    return this.selectedLevels.find((item) => item.id === id) ? true : false;
  }

  /** Returns a value indicating if the level is semi / indeterminate selected which means only some of the subitems are selected and not all
   * @param classWithUsers The class that needs to be checked if it is semichecked
   * @returns a boolean value whether a level is indeterminate selected */
  isClassSemiSelected(classWithUsers: IClassWithUsers): boolean {
    return !classWithUsers.class.selected && classWithUsers.users.some((classWithUsers) => classWithUsers.selected);
  }

  /** Tracker for loaded data so it doesn't need to recalculate and redraw already existing classes in the UI */
  classTrackBy(index: number, item: IClassWithUsers): string {
    return item.class.id;
  }
}
