import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ModalComponent } from './components/modal/modal.component';
import { ModalOutletComponent } from './components/modal-outlet/modal-outlet.component';
import { ModalConfig } from './modal-config';
import { IModalConfig } from './modal-config.interface';

@NgModule({
  declarations: [ModalComponent, ModalOutletComponent],
  imports: [CommonModule, PortalModule],
  exports: [ModalComponent, ModalOutletComponent],
  providers: [{ provide: Window, useValue: window }],
})
export class ModalModule {
  static forRoot(config: IModalConfig): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [{ provide: ModalConfig, useValue: config }],
    };
  }

  static forChild(config: IModalConfig): ModuleWithProviders<ModalModule> {
    return {
      ngModule: ModalModule,
      providers: [{ provide: ModalConfig, useValue: config }],
    };
  }
}
