import { IModalConfig } from './modal-config.interface';

/**
 * Settings for modals
 */
export class ModalConfig implements IModalConfig {
  /** Width of modal if its available .. if not it will just fill as much as possible */
  width = undefined;

  /** Height of modal if its available .. if not it will just fill as much as possible */
  height = undefined;

  /** Data-attribute to make it easier to add custom styling */
  variant = undefined;

  /** should there be a transition when modal opens/closes or should it be instantly? */
  useTransition = true;

  /** should there be a overlay on the entire browser window to give more focus to the modal? */
  showBackgroundOverLay = true;

  /** Spacing from modal to browser window */
  spacingFromWindow = '20px';
}
