import { Component, HostBinding, Input, Optional } from '@angular/core';
import { LoungeConfig } from '../lounge/lounge-config';
import { IReplaceSvg } from '../lounge/lounge-config.interface';
import { IIcon } from './icon.interface';
import { iconRotate } from './types/icon-rotate';
import { iconSize } from './types/icon-size';

/** Component that can be used to inject svg icons.
 * "baseUrl" in icon.module.ts defines where it looks for SVG */
@Component({
  selector: 'lru-icon[icon]',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  /** instance of icon */
  @Input() icon!: IIcon;

  /** Property to store which SVGs to replace */
  private replaceSvgs: IReplaceSvg[];

  /** Handles loading Dependency Injected config and if no config is supplied use the default config */
  constructor(@Optional() loungeConfig: LoungeConfig) {
    const defaultConfig = new LoungeConfig();

    this.replaceSvgs = loungeConfig?.replaceSvgs || defaultConfig.replaceSvgs;
  }

  /** Get path for svg.
   * If path is in list of of replaceSvgs then the path will be replaced with the new path specified in replaceSvgs item. */
  get iconPath(): string | undefined {
    return this.replaceSvgs.find((x: IReplaceSvg) => x.old === this.icon.path)?.new || this.icon.path;
  }

  /** Get color of icon. Defaults to 'currentColor' if no color is specified. */
  @HostBinding('style.color')
  get styleColor(): string {
    return this.icon.colors?.color || 'currentColor';
  }

  /** Set css variable for foreground color if specified */
  @HostBinding('style.--svg-foreground')
  get styleColorForeground(): string | undefined {
    return this.icon.colors?.foreground;
  }

  /** Set css variable for background color if specified */
  @HostBinding('style.--svg-background')
  get styleColorBackground(): string | undefined {
    return this.icon.colors?.background;
  }

  /** Get size of icon in pixel. Default to 16px if no size is specified. */
  @HostBinding('attr.data-size')
  get dataSize(): iconSize {
    return this.icon.size ? this.icon.size : 16;
  }

  /** Get rotation information for icon. Default to 0deg if no rotation is specified. */
  @HostBinding('attr.data-rotate')
  get dataRotate(): iconRotate {
    return this.icon.rotate ? this.icon.rotate : 0;
  }
}
