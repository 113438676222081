import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedLoadingKeys } from '../../../../../services/loading/shared-loading-keys.enums';
import { LoungeModalService } from '../../../services/lounge-modal.service';
import { LoungeService } from '../../../services/lounge.service';
import { ITeamEdit } from '../../../store/interfaces/team-edit.interface';

/** Component for being able to remove yourself from the team */
@Component({
  selector: 'lru-team-edit-remove-teacher-self[teamEdit]',
  templateUrl: './team-edit-remove-teacher-self.component.html',
  styleUrls: ['./team-edit-remove-teacher-self.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditRemoveTeacherSelfComponent {
  /** A team with teachers and students */
  @Input() teamEdit!: ITeamEdit;

  /** @ignore */
  constructor(private loungeModalService: LoungeModalService, private loungeService: LoungeService) {}

  /** Function that asks {@link LoungeModalService} to set modal page to none.
   * @returns A function that is used for inputting to the header and called from there. */
  onClickClose(): Function {
    return () => this.loungeModalService.setPageToNone();
  }

  /** Ask {@link LoungeService} to remove the user themself from the team */
  onClickRemoveTeacherSelf(): void {
    this.loungeService.teamRemoveTeacherSelf(this.teamEdit, SharedLoadingKeys.teamRemoveTeacherSelf);
  }
}
