import { IToastConfig } from './toast-config.interface';
import { toastHorizontalPosition, toastVerticalPosition } from './toast-types';

/**
 * Settings for toast popups
 */
export class ToastConfig implements IToastConfig {
  /** Where on the horizontal line of the window should toasts be displayed? */
  horizontalPosition: toastHorizontalPosition = 'right';

  /** Where on the vertical line of the window should toasts be displayed? */
  verticalPosition: toastVerticalPosition = 'bottom';

  /** When should toast messages go away? */
  durationInMilliseconds = 5000;

  /** How many pixels space should there be between toast messages? */
  spacingBetweenToasts = 20;

  /** How many pixels space should there be from toasts to the browser window? */
  spacingFromWindow = 20;

  /** Data-attribute to make it easier to add custom styling */
  variant = undefined;
}
