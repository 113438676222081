import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { environment } from '@portal-env/environment';
import { switchMap } from 'rxjs/operators';
import { PageTypeEnum } from '../../enums/page-type.enum';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  enabled = false;
  loading = false;
  loaded = false;
  cssLoaded = false;
  data?: string;

  constructor(private appSiteService: AppInitService, private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(switchMap((x) => this.handleLoadOrInjectFooter(x as RouterEvent))).subscribe();
  }

  async handleLoadOrInjectFooter(data: RouterEvent) {
    if (data instanceof RoutesRecognized) {
      const pageType = data.state.root.firstChild?.data.pageType as PageTypeEnum;
      if (pageType === PageTypeEnum.Book) {
        this.enabled = false;
      } else {
        this.enabled = true;
        if (this.loaded && !this.loading) {
          await this.injectFooter();
        } else if (!this.loaded && !this.loading) {
          this.loading = true;
          await this.loadFooter();
        }
      }
    }
  }

  private async loadFooter() {
    const portalId = this.appSiteService.siteConfiguration.PortalId;

    if (portalId !== undefined) {
      const url = `${environment.apIUmbBaseUrl}js/footer/?t=${portalId}`;
      await fetch(url)
        .then((response) => {
          return response.text();
        })
        .then(async (text) => {
          this.data = text.replace('{{id}}', portalId);
          await this.injectFooter();
        });
    }
  }

  private async injectFooter() {
    if (this.data !== undefined && !this.loaded) {
      (await eval(this.data)) as Promise<any>;

      const footerHTMLCollection = document.getElementsByTagName('footer');
      if (footerHTMLCollection.length) {
        const interval = setInterval(() => {
          if (getComputedStyle(footerHTMLCollection[0], undefined).display === 'flex') {
            this.cssLoaded = true;
            this.loaded = true;
            this.loading = false;
            clearInterval(interval);
          }
        }, 50);
      }
    }
  }
}
