import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '../../../../../services/loading/shared-loading-keys.enums';
import { LoungeService } from '../../../services/lounge.service';
import { IUserPermissionWithSelected } from '../../../store/interfaces/team-edit.interface';

/** A component for being able to select teachers for teams */
@Component({
  selector: 'lru-team-picker-teacher',
  templateUrl: './team-picker-teacher.component.html',
  styleUrls: ['./team-picker-teacher.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamPickerTeacherComponent {
  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** Property for storing the inputted search term */
  searchTerm = '';

  /** @ignore */
  constructor(public loungeService: LoungeService, public loadingService: LoadingService) {}

  // onClickPermission(teacherId: string, permissionId: string, checked: boolean): void {
  //   // not sure why we add this to store, but since we do I wont make it into a formcontrol
  //   // this.loungeService.markPermissionForSelection(teacherId, permissionId, checked);
  //   console.log(`onClickPermission(${teacherId}: string, ${permissionId}: string, ${checked}: boolean)`);
  // }

  /** Asks {@link LoungeService} to mark the teacher for selection in the store */
  onClickTeacher(teacherId: string, checked: boolean): void {
    this.loungeService.markTeacherForSelection(teacherId, checked);
  }

  /** Tracker for loaded data so it doesn't need to recalculate and redraw already existing teachers in the UI */
  teacherTrackBy(index: number, item: IUserPermissionWithSelected): string {
    return item.user.id;
  }
}
