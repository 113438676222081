<div class="gradient"></div>
<div class="container full-width horizontal-space">
  <portal-dropdown
    *ngIf="hasMegaMenu || !large"
    [id]="'nav'"
    [buttonClasses]="'btn transparent'"
    [darkOverlay]="false"
    [zIndex]="200"
    [ariaLabel]="'Dropdown.Type.HeaderMegaMenu' | translate"
  >
    <ng-container button>
      <i *ngIf="dropdownOpen?.Id !== 'nav' && !large" class="icon icon-16" [inlineSVG]="'menu.svg'"></i>
      <i *ngIf="dropdownOpen?.Id === 'nav' && !large" class="icon icon-16" [inlineSVG]="'x-mark.svg'"></i>
      <i *ngIf="dropdownOpen?.Id !== 'nav' && large" class="icon icon-20" [inlineSVG]="'chevron-down.svg'"></i>
      <i *ngIf="dropdownOpen?.Id === 'nav' && large" class="icon icon-20" [inlineSVG]="'chevron-up.svg'"></i>
    </ng-container>
    <ng-container content>
      <portal-navbar *ngIf="!large"></portal-navbar>
      <portal-mega-menu></portal-mega-menu>
    </ng-container>
  </portal-dropdown>
  <ng-container *ngIf="logo">
    <button class="product btn" routerLink="/">
      <span class="logo">
        <img [src]="logo.mobile" *ngIf="logo.mobile" class="mobile" [class.mobile-only]="!logo.default" />
        <img [src]="logo.default" *ngIf="logo.default" class="default" [class.default-only]="!logo.mobile" />
      </span>
      <ng-container *ngIf="siteName">
        <span class="divider" [class.fagportaler]="!isSuperBogOrReader"></span>
        <h1>{{ siteName }}</h1>
      </ng-container>
    </button>
  </ng-container>

  <div class="grow"></div>
  <div id="navbar">
    <portal-navbar></portal-navbar>
  </div>

  <portal-dropdown
    *ngIf="libraryUrl"
    [id]="'search'"
    [buttonClasses]="'btn square'"
    [contentClasses]="''"
    [darkOverlay]="true"
    [libraryUrl]="libraryUrl"
    [zIndex]="200"
    [pageType]="pageType"
    [useCustomAriaLabel]="true"
    [ariaLabel]="'Label.SearchInBlank' | translate : { name: 'ContentType.TheLibrary' | translate | lowercase }"
  >
    <ng-container button>
      <i class="icon icon-20" [inlineSVG]="'search.svg'"></i>
    </ng-container>
    <ng-container content>
      <portal-library-search
        [type]="isFagportal ? PageTypeEnum.CourseLibrary : PageTypeEnum.BookLibrary"
        [libraryUrl]="libraryUrl"
      ></portal-library-search>
    </ng-container>
  </portal-dropdown>
  <button
    *ngIf="isLoggedIn && favoriteUrl"
    class="btn square"
    [routerLink]="favoriteUrl"
    tabindex="0"
    [attr.aria-label]="'Label.SeeAllBlank' | translate : { name: 'ContentType.Favorites' | translate | lowercase }"
  >
    <i class="icon icon-20" [inlineSVG]="'heart.svg'"></i>
  </button>
  <div *ngIf="showLogin">
    <portal-user *ngIf="isLoggedIn"></portal-user>
    <button *ngIf="!isLoggedIn" class="login btn transparent" (click)="onLogin()">
      {{ 'Actions.LogIn' | translate }}
    </button>
  </div>
</div>
