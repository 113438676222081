<ng-container *ngIf="!isLoading">
  <ul>
    <ng-container *ngFor="let item of navbar">
      <ng-container *ngIf="item.Type === LinkTypeEnum.Dropdown">
        <li>
          <portal-dropdown
            [buttonClasses]="{
              'active-link': isDropdownActive(item.Items),
              item: true
            }"
            [contentClasses]="dropdownsEnabled ? 'default-dropdown-theme' : ''"
            [ariaLabel]="'Dropdown.Type.HeaderNavigation' | translate"
          >
            <ng-container button>
              <span>{{ item.Title }}</span>
            </ng-container>
            <ng-container content>
              <ul>
                <li *ngFor="let child of item.Items">
                  <a
                    *ngIf="child.Type === LinkTypeEnum.Internal || child.Type === LinkTypeEnum.Module"
                    class="sub-item selectable"
                    routerLink="{{ child.Url }}"
                    [class.active-link]="isLinkActive(child)"
                    tabindex="0"
                  >
                    <span>{{ child.Title }}</span>
                  </a>
                  <a
                    *ngIf="child.Type === LinkTypeEnum.External"
                    class="sub-item selectable"
                    target="{{ child.Target }}"
                    rel="noopener"
                    href="{{ child.Url }}"
                    tabindex="0"
                  >
                    <span>{{ child.Title }}</span>
                  </a>
                </li>
              </ul>
            </ng-container>
          </portal-dropdown>
        </li>
      </ng-container>
      <ng-container *ngIf="item.Type !== LinkTypeEnum.Dropdown">
        <li>
          <a
            *ngIf="item.Type === LinkTypeEnum.Internal || item.Type === LinkTypeEnum.Module"
            class="item"
            routerLink="{{ item.Url }}"
            [class.active-link]="isLinkActive(item)"
            tabindex="0"
          >
            {{ item.Title }}
          </a>
          <a
            *ngIf="item.Type === LinkTypeEnum.External"
            class="item"
            target="{{ item.Target }}"
            rel="noopener"
            href="{{ item.Url }}"
            tabindex="0"
            >{{ item.Title }}</a
          >
        </li>
      </ng-container>
    </ng-container>
  </ul>
</ng-container>
