import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, map, Observable, share, tap, withLatestFrom } from 'rxjs';
import { UserService } from '../../../services/core/user/user.service';
import { GoogleTagManagerService } from '../../../services/gtm/google-tag-manager.service';
import { LoungeEditTeacher } from '../../../services/gtm/gtm-events.const';
import { SharedLoadingKeys } from '../../../services/loading/shared-loading-keys.enums';
import { FullLevelDto } from '../interfaces/endpoint/dtos/dto-level.interface';
import { TeamWithTeachersDto } from '../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { AddTeacher, ILoungePostBodyTeam_CreateTeam } from '../interfaces/endpoint/team.interface';
import { IClassWithUsers, ITeamEdit, IUserPermissionWithSelected } from '../store/interfaces/team-edit.interface';
import * as LoungeActions from '../store/lounge.actions';
import * as LoungeSelectors from '../store/lounge.selectors';
import { LoungeState } from '../store/lounge.state';

/** Service for handling data between application and Lounge API */
@Injectable({ providedIn: 'root' })
export class LoungeService {
  /** Retrieve full level from the store and if nothing is found send a request to retrieve it from API  */
  fullLevel$: Observable<FullLevelDto | undefined> = this.loungeStore.select(LoungeSelectors.selectFullLevel).pipe(
    share(),
    distinctUntilChanged(),
    tap((x) => {
      if (!x) {
        this.loungeStore.dispatch(LoungeActions.levelLoadLevels({ loadingKey: SharedLoadingKeys.fullLevel }));
      }
    })
  );

  /** Retrieve selected teachers from the store */
  selectedTeachersList$ = this.loungeStore.select(LoungeSelectors.selectSelectedTeachersList);

  /** Retrieve teachers from the store without current user and if nothing is found send a request to retrieve it from the API */
  dlpTeachersListWithoutCurrentUser$: Observable<IUserPermissionWithSelected[] | undefined> = this.loungeStore
    .select(LoungeSelectors.selectDlpTeachersList)
    .pipe(
      share(),
      distinctUntilChanged(),
      tap((x) => {
        if (!x) {
          this.dlpLoadTeachers(SharedLoadingKeys.teachers);
        }
      }),
      withLatestFrom(this.userService.user$),
      map(([data, user]) => data?.filter((t) => t.user.id !== user?.userId))
    );

  /** Retrieve selected users from the store */
  selectedUsersList$ = this.loungeStore.select(LoungeSelectors.selectSelectedUsersList);

  /** Retrieve classes with students from the store and if nothing is found send a request to retrieve it from the API */
  dlpClassWithStudentsList$: Observable<IClassWithUsers[] | undefined> = this.loungeStore
    .select(LoungeSelectors.selectDlpClassWithUsersList)
    .pipe(
      share(),
      distinctUntilChanged(),
      tap((x) => {
        if (!x) {
          this.dlpLoadClassesWithStudents(SharedLoadingKeys.dlpClasses);
        }
      })
    );

  /** Retrieve teams with teachers from the store and if nothing is found send a request to retrieve it from the API */
  teamTeamWithTeachersList$: Observable<TeamWithTeachersDto[] | undefined> = this.loungeStore
    .select(LoungeSelectors.selectTeamWithTeachersList)
    .pipe(
      share(),
      distinctUntilChanged(),
      tap((x) => {
        if (!x) {
          this.teamLoadTeams(SharedLoadingKeys.teams);
        }
      })
    );

  /** Retrieve the current team under edit mode from the store */
  teamEdit$: Observable<ITeamEdit | undefined> = this.loungeStore.select(LoungeSelectors.selectTeamEdit);

  /** Dispatch an action to load classes with students from the API to the store
   * @param loadingKey */
  private dlpLoadClassesWithStudents(loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.dlpLoadClassesWithStudents({ loadingKey }));
  }

  /** Dispatch an action to load teachers from the API to the store
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  dlpLoadTeachers(loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.dlpLoadTeachers({ loadingKey }));
  }

  /** Dispatch an action to mark the student for selection in the store
   * @param classId The id of the student's class
   * @param studentId The id of the student to mark
   * @param checked The checked status of the selection */
  markStudentForSelection(classId: string, studentId: string, checked: boolean): void {
    this.loungeStore.dispatch(LoungeActions.markStudentForSelection({ classId, userId: studentId, selected: checked }));
  }

  /** Dispatch an action to mark the class (and all students that are valid according to the searchTerm) for selection in the store
   * @param classId The id of the class to mark
   * @param checked The checked status of the selection
   * @param searchTerm The searchTerm from the user input so only students that apply to the searchTerm will be checked */
  markClassForSelection(classId: string, checked: boolean, searchTerm: string): void {
    this.loungeStore.dispatch(LoungeActions.markClassForSelection({ classId, selected: checked, searchTerm }));
  }

  /** Dispatch an action to mark the teacher for selection in the store
   * @param teacherId The id of the teacher to mark
   * @param checked The checked status of the selection */
  markTeacherForSelection(teacherId: string, checked: boolean): void {
    this.loungeStore.dispatch(LoungeActions.markTeacherForSelection({ teacherId, selected: checked }));
  }

  /** Unmark all selected students in the store */
  removeStudentsFromSelection(): void {
    this.loungeStore.dispatch(LoungeActions.removeSelectionsFromStudents());
  }

  /** Unmark all selected teachers in the store */
  removeTeachersFromSelection(): void {
    this.loungeStore.dispatch(LoungeActions.removeSelectionsFromTeachers());
  }

  /** Dispatch an action to save teachers to a team
   * @param teamId The id of the team to add the teachers to
   * @param teachers List of one or more teachers to add to the team
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamSaveTeachersToTeam(teamId: string, teachers: AddTeacher[], loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamAddTeachers({ teamId, teachers, loadingKey }));
  }

  /** Dispatch an action to save students to a team
   * @param teamId The id of the team to add the students to
   * @param studentIds List of one or more student ids to add to the team
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamSaveStudentsToTeam(teamId: string, studentIds: string[], loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamSaveStudentsToTeam({ teamId, studentIds, loadingKey }));
  }

  /** Dispatch an action to save details to a team
   * @param teamId The id of the team to save the details to
   * @param title The new title to add to the team
   * @param levelIds Combined list of ids from all the levels that should be on the team.
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamSaveDetailsToTeam(teamId: string, title: string, levelIds: string[], loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamSaveDetailsToTeam({ teamId, title, levelIds, loadingKey }));
  }

  /** Dispatch an action to load all teams from the API
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamLoadTeams(loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamLoadTeams({ loadingKey }));
  }

  /** Navigates by adding '?edit=' + the team id to the end of the URL.
   * @param team The team to navigate to */
  teamEditNavigate(team: TeamWithTeachersDto): void {
    this.router.navigateByUrl(window.location.pathname + '?edit=' + team.id);
  }

  /** Dispatch an action to cancel the editing of the team */
  teamEditCancel(): void {
    this.loungeStore.dispatch(LoungeActions.teamEditTeamCancel());
  }

  /** Dispatch an action to load and fill the store with already selected students from the team so we're ready to display them in the UI */
  teamEditAssignStudents(): void {
    this.loungeStore.dispatch(LoungeActions.teamEditAssignStudents());
  }

  /** Dispatch an action to load and fill the store with already selected teachers from the team so we're ready to display them in the UI
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamEditAssignTeachers(loadingKey: string): void {
    this.gtmService.pushTagWithUserType(LoungeEditTeacher);
    this.loungeStore.dispatch(LoungeActions.teamEditAssignTeachers({ loadingKey }));
  }

  /** Dispatch an action to display the delete team modal */
  teamDeleteModal(): void {
    this.loungeStore.dispatch(LoungeActions.teamDeleteModal());
  }

  /** Dispatch an action to delete the team
   * @param teamId id of the team to delete
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamDelete(teamId: string, loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamDeleteTeam({ teamId, loadingKey }));
  }

  /** Dispatch an action to create a team
   * @param team the team to create
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamCreate(team: ILoungePostBodyTeam_CreateTeam, loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamCreateTeam({ team, loadingKey }));
  }

  /** Dispatch an action to open the edit details of team modal */
  teamEditDetailsModal(): void {
    this.loungeStore.dispatch(LoungeActions.teamEditDetailsModal());
  }

  /** Dispatch an action to open the remove yourself from the team modal */
  teamRemoveTeacherSelfModal(): void {
    this.loungeStore.dispatch(LoungeActions.teamRemoveTeacherSelfModal());
  }

  /** Dispatch an action to remove the current user from the team
   * @param team the team to remove the current user from
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamRemoveTeacherSelf(team: TeamWithTeachersDto, loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamRemoveTeacherSelf({ team, loadingKey }));
  }

  /** Dispatch an action to add a single teacher to a team
   * @param teamId the id of the team to add the teacher to
   * @param teacher the teacher to add to the team
   * @param loadingKey The key used for checking and changing status of the UI loading component
   * @deprecated Should probably be removed and be replaced by just using the {@link teamSaveTeachersToTeam} */
  teamAddTeacher(teamId: string, teacher: AddTeacher, loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamAddTeacher({ teamId, teacher, loadingKey }));
  }

  /** Dispatch an action to load a team for editing
   * @param teamId id of the team to load
   * @param loadingKey The key used for checking and changing status of the UI loading component */
  teamLoadSingleTeamForEdit(teamId: string, loadingKey: string): void {
    this.loungeStore.dispatch(LoungeActions.teamLoadSingleTeamForEdit({ teamId, loadingKey }));
  }

  /** @ignore */
  constructor(
    private loungeStore: Store<LoungeState>,
    private router: Router,
    private userService: UserService,
    private gtmService: GoogleTagManagerService
  ) {}
}
