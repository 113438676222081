import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentTypeEnum } from '@portal-app/enums/content-type.enum';
import {
  INextCourseSearchCourse,
  INextCourseSearchCourse2,
} from '@portal-app/interfaces/course/next/next-course-library.interface';
import { ICourseTile } from '@portal-app/interfaces/course/project/course.interface';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { HtmlService } from '@portal-app/services/shared/html.service';
import { LevelService } from '@portal-app/services/shared/level.service';
import { AssetMapper } from '../shared/asset.mapper';

@Injectable({
  providedIn: 'root',
})
export class CourseMapper {
  constructor(
    private appInitService: AppInitService,
    private helperService: HelperService,
    private levelService: LevelService,
    private htmlService: HtmlService,
    private translate: TranslateService,
    private assetMapper: AssetMapper
  ) {}

  mapCoursesFromApi(courses: INextCourseSearchCourse[], hasFavorite?: boolean, trackingUrl?: string): ICourseTile[] {
    const result: ICourseTile[] = [];
    if (!courses) {
      // dont trust the api
      return result;
    }
    for (const item of courses) {
      result.push({
        Id: item.Course.Id,
        Asset: item.Course.Asset,
        Description: this.htmlService.fixHtml(item.Course.Description),
        Levels: this.levelService.convertLevelsToString(item.Course.Levels),
        StatusLabel: item.Course.StatusLabel,
        Title: item.Course.Title,
        TitleMobile: this.helperService.truncateString(item.Course.Title, 45),
        Url: `${this.appInitService.siteConfiguration.BasePath}${item.Course.Url}${trackingUrl || ''}`,
        IsLocked: item.Locked,
        FavoriteLoaded: true,
        ObjectType: ContentTypeEnum.Courses,
        LessonOrDurationLabel: this.helperService.getLessonOrDurationLabel(item.Course.Duration),
        IsActive: this.getCourseIsActive(item.Course.Status),
        IsFavorite: hasFavorite,
        PromiseOfLearning: item.Course.PromiseOfLearning
          ? {
              Title: this.translate.instant('Label.WhenYouAreFinishedWithTheBlank', {
                name: this.translate.instant('ContentType.TheCourse').toLowerCase(),
              }),
              Description: this.htmlService.fixHtml(item.Course.PromiseOfLearning) ?? '',
            }
          : undefined,
      });
    }

    return result;
  }

  mapCoursesFromApi2(courses: INextCourseSearchCourse2[], hasFavorite?: boolean, trackingUrl?: string): ICourseTile[] {
    const result: ICourseTile[] = [];

    if (!courses) {
      // dont trust the api
      return result;
    }
    for (const item of courses) {
      result.push({
        Id: item.course.id,
        Asset: this.assetMapper.map(item.course.asset),
        Description: this.htmlService.fixHtml(item.course.description),
        Levels: this.levelService.convertLevelsToString(item.course.levels),
        StatusLabel: item.course.statusLabel,
        Title: item.course.title,
        TitleMobile: this.helperService.truncateString(item.course.title, 45),
        Url: `${this.appInitService.siteConfiguration.BasePath}${item.course.url}${trackingUrl || ''}`,
        IsLocked: item.locked,
        FavoriteLoaded: true,
        ObjectType: ContentTypeEnum.Courses,
        LessonOrDurationLabel: this.helperService.getLessonOrDurationLabel(item.course.duration),
        IsActive: this.getCourseIsActive(item.course.status),
        IsFavorite: hasFavorite,
        PromiseOfLearning: item.course.promiseOfLearning
          ? {
              Title: this.translate.instant('Label.WhenYouAreFinishedWithTheBlank', {
                name: this.translate.instant('ContentType.TheCourse').toLowerCase(),
              }),
              Description: this.htmlService.fixHtml(item.course.promiseOfLearning) ?? '',
            }
          : undefined,
      });
    }

    return result;
  }

  getCourseIsActive(status: string) {
    return !(status === 'Draft' || status === 'InActive');
  }
}
