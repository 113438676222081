import { Component, EventEmitter, Input, Output } from '@angular/core';
import { iconSize } from '../icon/types/icon-size';

/** Component that should be used for all types of checkboxes. */
@Component({
  selector: 'lru-checkbox[checked]',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
  /** Should the checkbox be checked to begin with? */
  @Input() checked!: boolean;

  /** This is for checkbox parent of child list of checkboxes where not all children are checked.
   * It will add a line in the checkbox instead of checkmark icon. */
  @Input() semiChecked?: boolean;

  /** Size of icon within the checkbox */
  @Input() size: iconSize = 16;

  /** makes it possible to make custom styling */
  @Input() variant?: string;

  /** Emits checked changes so the place that contains the checkbox can run custom logic when it happens  */
  @Output() changed = new EventEmitter<boolean>();

  /** When chexkbox is clicked */
  onChange(value: boolean): void {
    this.changed.emit(value);
  }

  /** When enter is pressed it should cound as click on the checkbox */
  onEnterKeydown(): void {
    this.onChange(!this.checked);
  }
}
