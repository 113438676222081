import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApiService,
  AuthInterceptor,
  CoreModule,
  DropdownModule,
  InitService,
  ModalModule,
  ToastModule,
  ZIndexModule,
} from '@lru/felib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from '@portal-app/app-routing.module';
import { AppComponent } from '@portal-app/app.component';
import { DropdownOverlayComponent } from '@portal-app/components/dropdown-overlay/dropdown-overlay.component';
import { FooterComponent } from '@portal-app/components/footer/footer.component';
import { GlobalErrorHandler } from '@portal-app/modules/app/core/errors/global-error-handler';
import { HeaderModule } from '@portal-app/modules/app/header/header.module';
import { BaseModule } from '@portal-app/modules/shared/base/base.module';
import { WindowScrollService } from '@portal-app/services/shared/window-scroll.service';
import { environment } from '@portal-env/environment';
import { NgResizeObserverPonyfillModule } from 'ng-resize-observer';
import { ToastrModule } from 'ngx-toastr';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { firstValueFrom } from 'rxjs';
import { LoaderComponent } from './components/loader/loader.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ProjectTypeEnum } from './enums/project-type.enum';
import { MissingTranslationHelper } from './helpers/missing-translation.helper';
import { RefreshTokenInterceptor } from './interceptors/refreshToken.interceptor';
import { IError } from './interfaces/project/error.interface';
import { UmbLanguageType } from './interfaces/umbraco/umb-language.type';
import { TeacherDashboardStoreEffects } from './modules/routes/book-teacher-dashboard/store/teacher-dashboard.effects';
import { NextImagePipe } from './pipes/next-image.pipe';
import { AppInitService } from './services/shared/app-init.service';
import { PageService } from './services/shared/page/page.service';
import { ScriptService } from './services/shared/script.service';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, DropdownOverlayComponent, FooterComponent, LoaderComponent],
  imports: [
    SpinnerComponent,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ModalModule.forRoot({
      width: '630px',
      // maxWidth: 'calc(100vw - 40px)',
      // maxHeight: 'calc(100vh - 40px)',
      useTransition: true,
    }),
    DropdownModule.forRoot({}),
    NgResizeObserverPonyfillModule,
    HeaderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      onActivateTick: true,
      enableHtml: true,
      progressBar: false,
      timeOut: 2000,
      extendedTimeOut: 2000,
    }),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([TeacherDashboardStoreEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    BaseModule,
    NgbModule,
    NoopAnimationsModule,
    CoreModule.forRoot({
      apiConfig: {
        apiLoungeBaseUrl: environment.apiLoungeBaseUrl,
        apiNextBaseUrl: environment.apiNextBaseUrl,
        apiNextSearchBaseUrl: environment.apiNextSearchBaseUrl,
        apiUmbracoBaseUrl: environment.apIUmbBaseUrl,
        internalBackendUrl: environment.internalBackendUrl(),
        loginUsingMvcDotNet: environment.loginUsingMvcDotNet,
        localHostname: environment.localHostName,
      },
    }),
    ToastModule.forRoot({}),
    ZIndexModule.forRoot({
      zIndex: 600,
    }),
  ],
  providers: [
    {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHelper,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitService, AppInitService, PageService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    ApiService,
    WindowScrollService,
    NextImagePipe,
    ScriptService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function initializeApp(initService: InitService, appInitService: AppInitService, pageService: PageService) {
  return async () => {
    await initService.initializeLogin();
    return await appInitService.Init().catch(async (error: IError) => {
      await firstValueFrom(
        appInitService.setLang(environment.fallbackLanguage as UmbLanguageType, ProjectTypeEnum.Fagportal)
      );
      if (error?.StatusCode) {
        console.warn(`C# error: ${error}`);
        await pageService.showError(window.location.pathname, error.StatusCode);
      } else if (error?.Message) {
        console.warn(`C# error: ${error}`);
        await pageService.showError(window.location.pathname, 500);
      } else {
        await pageService.showError(window.location.pathname, 503);
      }
    });
  };
}
