/**
 * Interface for getting first and last element of array
 */
export interface ArrayExtremes<T> {
  /** first item from array */
  first: T | undefined;
  /** last item from array */
  last: T | undefined;
}

/**
 * Returns true if the given arrays contains exactly the same items at the same indexes.
 */
export function arrayEqualsArray(array1: any[], array2: any[]): boolean {
  let equals = false;

  if (array1.length === array2.length) {
    for (let i = 0; i < array1.length; i++) {
      equals = array2.indexOf(array1[i]) === i;

      if (!equals) {
        break;
      }
    }
  }

  return equals;
}

/**
 * Returns the first and last item of the given array.
 */
export function getArrayExtremes<T>(array: T[]): ArrayExtremes<T> {
  return { first: array[0], last: array[array.length - 1] };
}

/**
 * Creates an array with the given length filled with the given value.
 */
export function createFillerArray<Type>(length: number, fillValue: Type): Type[] {
  const array = [];

  while (length--) {
    array.push(fillValue);
  }

  return array;
}

/**
 * Removes the given item from the given array.
 * Returns `true` if something was removed.
 */
export function removeFromArray(array: any[], item: any): boolean {
  const index = array.indexOf(item);

  if (index !== -1) {
    array.splice(index, 1);
    return true;
  }

  return false;
}

/**
 * Removes any items from the given array matching the given filter.
 * Returns `true` if something was removed.
 */
export function removeFromArrayByFilter<T>(array: any[], filter: (item: T) => boolean): boolean {
  const index = array.findIndex(filter);

  if (index !== -1) {
    array.splice(index, 1);
    // Remove the next matching element, if any, before returning true
    return removeFromArrayByFilter(array, filter) || true;
  }

  return false;
}

/**
 * Returns the last item from the given array or undefined if the array is empty.
 */
export function getLastItemFromArray<Type>(array: Type[]): Type | undefined {
  return array[array.length - 1];
}
