import { Injectable } from '@angular/core';
import { INextAsset, INextAsset2 } from '@portal-app/interfaces/next/next-asset.interface';

@Injectable({
  providedIn: 'root',
})
export class AssetMapper {
  map(asset2?: INextAsset2): INextAsset | undefined {
    if (!asset2) {
      return;
    }

    return {
      Id: asset2.id,
      Title: asset2.title,
      Description: asset2.description,
      Copyright: asset2.copyright,
      FileType: asset2.fileType,
      Path: asset2.path,
      Type: asset2.type,
      Dimensions: asset2.dimensions ? { Width: asset2.dimensions.width, Height: asset2.dimensions.height } : undefined,
      BasePath: asset2.basePath,
      ChangeFormat: asset2.changeFormat,
      Slug: asset2.slug,
      FileName: asset2.fileName,
      FileSize: asset2.fileSize,
    };
  }
}
