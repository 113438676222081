<ng-container *ngIf="loungeService.dlpTeachersListWithoutCurrentUser$ | async as teachers; else loading"
  ><div class="search">
    <input type="text" placeholder="Søg i lærere" [(ngModel)]="searchTerm" />
    <lru-icon [icon]="{ path: 'felib-search', size: 14 }"></lru-icon>
  </div>

  <div class="classes">
    <mat-accordion data-variant="lounge" *ngIf="teachers.length > 0; else noTeachers">
      <mat-expansion-panel
        hideToggle
        *ngFor="let teacher of teachers | searchUserPermissionWithSelectedList : searchTerm; trackBy: teacherTrackBy"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <lru-checkbox
              [checked]="teacher.user.selected"
              [size]="12"
              variant="lounge"
              (changed)="onClickTeacher(teacher.user.id, $event)"
              >{{ teacher.user.name }}</lru-checkbox
            >
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-container>
<ng-template #loading>
  <lru-loading-animation
    *ngIf="loadingService.loading$(sharedLoadingKeys.teachersWithoutCurrentUser) | async"
  ></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.teachersWithoutCurrentUser) | async as error">{{ error }}</p>
</ng-template>

<ng-template #noTeachers>
  <p>Kunne ikke finde nogle lærere</p>
</ng-template>
