import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UmbracoService } from '../../../services/core/umbraco/umbraco.service';
import { UserService } from '../../../services/core/user/user.service';
import { GoogleTagManagerService } from '../../../services/gtm/google-tag-manager.service';
import {
  LoungeCreateTeamDone,
  LoungeDeleteTeamDone,
  LoungeEditTeacherDone,
  LoungeEditTeam,
  LoungeEditTeamDone,
  LoungeEditTeamSettingsDone,
  LoungeRemoveTeacherDone,
} from '../../../services/gtm/gtm-events.const';
import { SharedLoadingKeys } from '../../../services/loading/shared-loading-keys.enums';
import { ToastService } from '../../toast/services/toast.service';
import { LoungeModalPages } from '../enums/lounge-modal-pages.enum';
import { PermissionDto } from '../interfaces/endpoint/dtos/dto-permission.interface';
import { TeamWithTeachersDto } from '../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { ILoungePostBodyTeam_AddTeachersToTeam } from '../interfaces/endpoint/team.interface';
import { LoungeApiService } from '../services/lounge-api.service';
import { LoungeModalService } from '../services/lounge-modal.service';
import { LoungeService } from '../services/lounge.service';

import {
  IClassWithSelected,
  IClassWithUsers,
  IUserPermissionWithSelected,
  IUserWithSelected,
} from './interfaces/team-edit.interface';
import * as LoungeActions from './lounge.actions';
import { LoungeState } from './lounge.state';

@Injectable()
export class LoungeEffects {
  levelLoadLevels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.levelLoadLevels),
      exhaustMap((action) =>
        this.loungeApiService.levelGetListOfLevelsLevelGroupsAndEducationGroups().pipe(
          map((response) =>
            LoungeActions.levelLoadLevelsSuccess({
              fullLevel: response.result,
              loadingKey: action.loadingKey,
            })
          ),
          catchError((error) => of(LoungeActions.levelLoadLevelsFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  dlpLoadTeachers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.dlpLoadTeachers),
      exhaustMap((action) =>
        this.loungeApiService.dlpGetAllTeachersInInstitution().pipe(
          map((data) => {
            const teacherList: IUserPermissionWithSelected[] = [];

            data.result.forEach((x) => {
              const teacher: IUserPermissionWithSelected = {
                permission: {
                  canCreateSubTeam: false,
                  canDeleteSubTeam: false,
                  canDeleteTeam: false,
                  canEditSubTeam: false,
                  canEditTeam: false,
                  canViewSubTeam: false,
                  isOwner: false,
                },
                user: {
                  firstName: x.firstName,
                  id: x.id,
                  lastName: x.lastName,
                  name: x.name,
                  role: x.role,
                  selected: false,
                },
              };

              teacherList.push(teacher);
            });
            return teacherList;
          }),
          map((data) =>
            LoungeActions.dlpLoadTeachersSuccess({
              teachers: data,
            })
          ),
          catchError((error) => of(LoungeActions.dlpLoadTeachersFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  dlpLoadClassesWithStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.dlpLoadClassesWithStudents),
      mergeMap((action) =>
        this.loungeApiService.dlpGetClassesWithStudents().pipe(
          map((data) => {
            const classWithUsersList: IClassWithUsers[] = data.result
              .filter((x) => x.users.length > 0)
              .map((x) => {
                const _class: IClassWithSelected = {
                  id: x.class.id,
                  levelId: x.class.levelId,
                  name: x.class.name,
                  selected: false,
                };
                const classWithUsers: IClassWithUsers = {
                  class: _class,
                  users: x.users.map((x) => {
                    const user: IUserWithSelected = {
                      id: x.id,
                      firstName: x.firstName,
                      lastName: x.lastName,
                      name: x.name,
                      role: x.role,
                      selected: false,
                    };

                    return user;
                  }),
                };

                return classWithUsers;
              });

            return classWithUsersList;
          }),
          map((data) =>
            LoungeActions.dlpLoadClassesWithStudentsSuccess({
              loadingKey: action.loadingKey,
              classes: data,
            })
          ),
          catchError((error) =>
            of(LoungeActions.dlpLoadClassesWithStudentsFailure({ error, loadingKey: action.loadingKey }))
          )
        )
      )
    )
  );

  markTeacherForSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.markTeacherForSelection),
      withLatestFrom(this.loungeService.teamEdit$, this.userService.user$),
      map(([action, teamEdit, currentUser]) => {
        const _currentTeacher = teamEdit?.teachers.find((t) => t.user.id === currentUser?.userId);
        const _permission: PermissionDto = JSON.parse(JSON.stringify(_currentTeacher?.permission));

        return LoungeActions.markTeacherForSelectionSuccess({
          teacherId: action.teacherId,
          selected: action.selected,
          permission: _permission,
        });
      })
    )
  );

  markClassForSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.markClassForSelection),
      map((action) =>
        LoungeActions.markClassForSelectionSuccess({
          classId: action.classId,
          selected: action.selected,
          searchTerm: action.searchTerm,
        })
      )
    )
  );

  markStudentForSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.markStudentForSelection),
      map((action) =>
        LoungeActions.markStudentForSelectionSuccess({
          classId: action.classId,
          userId: action.userId,
          selected: action.selected,
        })
      )
    )
  );

  removeSelectionsForStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.removeSelectionsFromStudents),
      map((_) => LoungeActions.removeSelectionsFromStudentsSuccess())
    )
  );

  removeSelectionsFromTeachers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.removeSelectionsFromTeachers),
      map((_) => LoungeActions.removeSelectionsFromTeachersSuccess())
    )
  );

  teamLoadTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamLoadTeams),
      mergeMap((action) =>
        this.loungeApiService.teamGetTeamsAvailableToUser(this.umbracoService.productId).pipe(
          map((response) =>
            LoungeActions.teamLoadTeamsSuccess({ loadingKey: action.loadingKey, teamList: response.result })
          ),
          catchError((error) => of(LoungeActions.teamLoadTeamsFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  teamSaveStudentsToTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamSaveStudentsToTeam),
      mergeMap((action) =>
        this.loungeApiService.teamSaveStudentsToTeam(action.teamId, { studentIds: action.studentIds }).pipe(
          tap((_) => {
            this.toastService.displayMessage('Elever gemt til holdet');
            this.loungeModalService.setPageToNone();
            this.loungeStore.dispatch(
              LoungeActions.teamLoadSingleTeamForEdit({ loadingKey: action.loadingKey, teamId: action.teamId })
            );
          }),
          map((_) =>
            LoungeActions.teamSaveStudentsToTeamSuccess({ loadingKey: action.loadingKey, teamId: action.teamId })
          ),
          catchError((error) =>
            of(LoungeActions.teamSaveStudentsToTeamFailure({ error, loadingKey: action.loadingKey }))
          )
        )
      )
    )
  );

  teamSaveStudentsToTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamSaveStudentsToTeamSuccess),
      tap(() => this.gtmService.pushTagWithUserType(LoungeEditTeamDone)),
      map(() => LoungeActions.teamLoadTeams({ loadingKey: SharedLoadingKeys.teams }))
    )
  );

  removeTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamDeleteTeam),
      mergeMap((action) =>
        this.loungeApiService.teamDeleteTeam(action.teamId).pipe(
          map(() => LoungeActions.teamDeleteTeamSuccess({ teamId: action.teamId })),
          catchError((error) => of(LoungeActions.teamDeleteTeamFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  removeTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamDeleteTeamSuccess),
      tap((_) => {
        this.toastService.displayMessage('Holdet er slettet');
        this.gtmService.pushTagWithUserType(LoungeDeleteTeamDone);
      }),
      map((_) => LoungeActions.teamEditTeamCancel())
    )
  );

  editTeamDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamSaveDetailsToTeam),
      mergeMap((action) =>
        this.loungeApiService
          .teamUpdateTeam(action.teamId, {
            title: action.title,
            levelIds: action.levelIds,
          })
          .pipe(
            map((_) =>
              LoungeActions.teamSaveDetailsToTeamSuccess({ loadingKey: action.loadingKey, teamId: action.teamId })
            ),
            catchError((error) =>
              of(LoungeActions.teamSaveDetailsToTeamFailure({ error, loadingKey: action.loadingKey }))
            )
          )
      )
    )
  );

  editTeamDetailsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamSaveDetailsToTeamSuccess),
      tap((action) => {
        this.loungeModalService.setPageToNone();
        this.toastService.displayMessage('Ændringer gemt');
        this.loungeStore.dispatch(
          LoungeActions.teamLoadSingleTeamForEdit({ loadingKey: action.loadingKey, teamId: action.teamId })
        );
        this.gtmService.pushTagWithUserType(LoungeEditTeamSettingsDone);
      }),
      map((_) => LoungeActions.teamLoadTeams({ loadingKey: SharedLoadingKeys.teams }))
    )
  );

  teamCreateTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamCreateTeam),
      mergeMap((action) =>
        this.loungeApiService.teamCreateTeam(action.team).pipe(
          map((response) =>
            LoungeActions.teamCreateTeamSuccess({ loadingKey: action.loadingKey, teamId: response.result })
          ),
          catchError((error) => of(LoungeActions.teamCreateTeamFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  teamCreateTeamSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamCreateTeamSuccess),
      tap((_) => {
        this.toastService.displayMessage('Hold oprettet');
        this.loungeModalService.setPageToNone();
        this.gtmService.pushTagWithUserType(LoungeCreateTeamDone);
      }),
      map((_) => LoungeActions.teamLoadTeams({ loadingKey: SharedLoadingKeys.teams }))
    )
  );

  teamEditTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamEditTeam),
      map((action) =>
        LoungeActions.teamLoadStudents({ loadingKey: SharedLoadingKeys.teamStudents, teamId: action.team.id })
      )
    )
  );

  teamEditTeamCancel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoungeActions.teamEditTeamCancel),
        tap((_) => {
          this.router.navigateByUrl(window.location.pathname);
          this.loungeModalService.setPageToNone();
        })
      ),
    { dispatch: false }
  );

  teamEditAssignTeachers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamEditAssignTeachers),
      tap(() => this.loungeModalService.setPage(LoungeModalPages.shareWithTeachers)),
      switchMap((action) =>
        combineLatest([
          of(action),
          this.loungeService.dlpTeachersListWithoutCurrentUser$.pipe(
            filter((x) => !!x),
            take(1)
          ),
          this.loungeService.teamEdit$.pipe(
            filter((x) => !!x),
            take(1)
          ),
        ])
      ),
      map(([action, teachersList, teamEdit]) => {
        const clone = JSON.parse(JSON.stringify(teachersList)) as IUserPermissionWithSelected[];

        teamEdit?.teachers.forEach((selectedTeacher) => {
          const selectedUser = clone.find((teacher) => teacher.user.id === selectedTeacher.user.id);

          if (selectedUser) {
            selectedUser.user.selected = true;
          }
        });
        return LoungeActions.teamEditAssignTeachersSuccess({
          teachers: clone,
          loadingKey: action.loadingKey,
        });
      })
    )
  );

  teamEditAssignStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamEditAssignStudents),
      tap(() => {
        this.gtmService.pushTagWithUserType(LoungeEditTeam);
        this.loungeModalService.setPage(LoungeModalPages.studentPicker);
      }),
      switchMap((_) =>
        combineLatest([
          this.loungeService.dlpClassWithStudentsList$.pipe(
            filter((x) => !!x),
            take(1)
          ),
          this.loungeService.teamEdit$.pipe(
            filter((x) => !!x),
            take(1)
          ),
        ])
      ),
      map(([classWithStudentsList, teamEdit]) => {
        const clone = JSON.parse(JSON.stringify(classWithStudentsList)) as IClassWithUsers[];

        teamEdit?.students.forEach((selectedStudent) => {
          const selectedUser = clone
            .find((classDto) => classDto.users.find((users) => users.id === selectedStudent.id))
            ?.users.find((x) => x.id === selectedStudent.id);

          if (selectedUser) {
            selectedUser.selected = true;
          }
        });

        clone.forEach((x) => {
          if (!x.users.find((u) => u.selected === false)) {
            x.class.selected = true;
          }
        });

        return LoungeActions.teamEditAssignStudentsSuccess({
          classWithSelectedUsersList: clone,
        });
      })
    )
  );

  teamLoadStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamLoadStudents),
      mergeMap((action) =>
        this.loungeApiService.teamGetStudentsInTeam(action.teamId).pipe(
          map((response) => {
            return LoungeActions.teamLoadStudentsSuccess({
              loadingKey: action.loadingKey,
              studentList: response.result,
            });
          }),
          catchError((error) => of(LoungeActions.teamLoadStudentsFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  teamDeleteModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoungeActions.teamDeleteModal),
        tap(() => this.loungeModalService.setPage(LoungeModalPages.deleteTeam))
      ),
    { dispatch: false }
  );

  teamEditDetailsModal = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoungeActions.teamEditDetailsModal),
        tap(() => this.loungeModalService.setPage(LoungeModalPages.detailsInput))
      ),
    { dispatch: false }
  );

  teamRemoveTeacherSelfModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoungeActions.teamRemoveTeacherSelfModal),
        tap(() => this.loungeModalService.setPage(LoungeModalPages.removeTeacherSelf))
      ),
    { dispatch: false }
  );

  teamRemoveTeacherSelf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamRemoveTeacherSelf),
      mergeMap((action) =>
        this.loungeApiService.teamRemoveSelf(action.team.id).pipe(
          map((_) => LoungeActions.teamRemoveTeacherSelfSuccess({ team: action.team })),
          catchError((error) =>
            of(LoungeActions.teamRemoveTeacherSelfFailure({ error, loadingKey: action.loadingKey }))
          )
        )
      )
    )
  );

  teamRemoveTeacherSelfSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoungeActions.teamRemoveTeacherSelfSuccess),
        tap((_) => {
          this.loungeModalService.setPageToNone();
          this.router.navigateByUrl(window.location.pathname);
          this.gtmService.pushTagWithUserType(LoungeRemoveTeacherDone);
        })
      ),
    { dispatch: false }
  );

  teamAddTeachers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamAddTeachers),
      mergeMap((action) => {
        const postBody: ILoungePostBodyTeam_AddTeachersToTeam = {
          teachers: action.teachers,
        };

        return this.loungeApiService.teamAddTeachers(action.teamId, postBody).pipe(
          tap((_) => {
            this.toastService.displayMessage('Lærere opdateret');
            this.loungeModalService.setPageToNone();
          }),
          map((_) => LoungeActions.teamAddTeachersSuccess({ loadingKey: action.loadingKey, teamId: action.teamId })),
          catchError((error) => of(LoungeActions.teamAddTeachersFailure({ error, loadingKey: action.loadingKey })))
        );
      })
    )
  );

  teamAddTeachersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamAddTeachersSuccess),
      tap(() => this.gtmService.pushTagWithUserType(LoungeEditTeacherDone)),
      map((action) => LoungeActions.teamLoadSingleTeamForEdit({ loadingKey: action.loadingKey, teamId: action.teamId }))
    )
  );

  teamAddTeachersSuccessLoadTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamAddTeachersSuccess),
      map(() => LoungeActions.teamLoadTeams({ loadingKey: SharedLoadingKeys.teams }))
    )
  );

  teamAddTeacher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamAddTeacher),
      mergeMap((action) => {
        return this.loungeApiService.teamAddTeacher(action.teamId, action.teacher).pipe(
          switchMap((_) => [
            LoungeActions.teamAddTeacherSuccess({ loadingKey: action.loadingKey, teamId: action.teamId }),
          ]),
          catchError((error) => of(LoungeActions.teamAddTeacherFailure({ error, loadingKey: action.loadingKey })))
        );
      })
    )
  );

  teamAddTeacherSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamAddTeacherSuccess),
      switchMap((action) =>
        this.loungeApiService.teamGetTeamsAvailableToUser(this.umbracoService.productId).pipe(
          map((response) => {
            this.loungeStore.dispatch(
              LoungeActions.teamEditTeam({
                loadingKey: action.loadingKey,
                team: response.result.find((t) => t.id === action.teamId) || ({} as TeamWithTeachersDto),
              })
            );
            return LoungeActions.teamLoadTeamsSuccess({ loadingKey: action.loadingKey, teamList: response.result });
          }),
          catchError((error) => of(LoungeActions.teamLoadTeamsFailure({ error, loadingKey: action.loadingKey })))
        )
      )
    )
  );

  teamLoadSingleTeamForEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoungeActions.teamLoadSingleTeamForEdit),
      mergeMap((action) =>
        this.loungeApiService.teamGetSingleTeam(action.teamId).pipe(
          map((response) => LoungeActions.teamEditTeam({ loadingKey: action.loadingKey, team: response.result })),
          catchError((error) => {
            this.router.navigateByUrl(window.location.pathname);
            return of(LoungeActions.teamLoadSingleTeamForEditFailure({ error, loadingKey: action.loadingKey }));
          })
        )
      )
    )
  );

  /** @ignore */
  constructor(
    private actions$: Actions,
    private loungeApiService: LoungeApiService,
    private loungeStore: Store<LoungeState>,
    private loungeService: LoungeService,
    private router: Router,
    private toastService: ToastService,
    private umbracoService: UmbracoService,
    private userService: UserService,
    private loungeModalService: LoungeModalService,
    private gtmService: GoogleTagManagerService
  ) {}
}
