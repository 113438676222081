import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlyrModule } from 'ngx-plyr3';
import { BaseModule } from '../base/base.module';
import { AudioHlsPlayerComponent } from './components/audio-hls-player/audio-hls-player.component';
import { AudioMp3PlayerComponent } from './components/audio-mp3-player/audio-mp3-player.component';
import { AudioSelectionPlayerComponent } from './components/audio-selection-player/audio-selection-player.component';

@NgModule({
  declarations: [AudioHlsPlayerComponent, AudioMp3PlayerComponent, AudioSelectionPlayerComponent],
  imports: [CommonModule, BaseModule, PlyrModule],
  exports: [AudioHlsPlayerComponent, AudioMp3PlayerComponent, AudioSelectionPlayerComponent],
})
export class AudioPlayerModule {}
