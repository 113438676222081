<div class="main">
  <p>Hold og data vil ikke være tilgængeligt for dig.</p>
</div>
<div class="footer">
  <lru-button [variant]="'lounge'" [theme]="'white'" [hollow]="true" (click)="onClickClose()()">Annuller</lru-button>
  <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" (click)="onClickRemoveTeacherSelf()"
    >Ja, fjern mig fra holdet</lru-button
  >
</div>
<lru-team-header [closeFunc]="onClickClose()" [header]="'Fjern dig fra ' + teamEdit.title"></lru-team-header>
