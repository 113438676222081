import { createAction, props } from '@ngrx/store';

export interface ErrorWithLoadingKey {
  error: Error;
  loadingKey: string;
}

const modulePrefix = '[LoadingModule]';

export const startLoading = createAction(`${modulePrefix} Start load`, props<{ loadingKey: string }>());

export const finishLoading = createAction(`${modulePrefix} Stop load`, props<{ loadingKey: string }>());

export const loadFailed = createAction(`${modulePrefix} Load failed`, props<ErrorWithLoadingKey>());
