import { Injectable } from '@angular/core';
import { INextBookCollection } from '@portal-app/interfaces/book/next/next-book-collection.interface';
import { ICollectionTile } from '@portal-app/interfaces/project/section.interface';

@Injectable({
  providedIn: 'root',
})
export class BookCollectionMapper {
  mapBookCollectionFromApi(collections: INextBookCollection[]): ICollectionTile[] {
    const result: ICollectionTile[] = [];
    if (collections?.length) {
      for (const collection of collections) {
        result.push({
          Id: collection.Id,
          Author: {
            Id: collection.Creator.Id,
            Name: collection.Creator.Name,
            Type: collection.Type,
          },
          Image: {
            Id: collection.CoverImage.Id,
            Title: collection.CoverImage.Title,
            Description: collection.CoverImage.Description,
            Copyright: collection.CoverImage.Copyright,
            FileType: collection.CoverImage.FileType,
            Path: collection.CoverImage.Path,
            Type: collection.CoverImage.Type,
            Dimensions: collection.CoverImage.Dimensions,
          },
          Title: collection.Title,
          Icon: '',
          Owner: collection.Owner,
          Classes: undefined,
          IsFavorite: collection.IsFavorite,
        });
      }
    }
    return result;
  }
}
