<div #domHeader>
  <portal-header-default
    *ngIf="headerType === HeaderTypeEnum.Default"
    class="screen"
    [pageType]="pageType"
    [attr.data-project-type]="projectType"
    [attr.data-behind]="behind"
    [attr.data-gradient]="gradient"
    [attr.data-scrolled]="scrolled"
  ></portal-header-default>
  <portal-header-book *ngIf="headerType === HeaderTypeEnum.Book" class="screen"></portal-header-book>
  <portal-header-course *ngIf="headerType === HeaderTypeEnum.Course" class="screen"></portal-header-course>
</div>
