import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

export type theme = 'primary-student' | 'primary-teacher' | 'white' | 'icon-button' | undefined;
type size = 'small' | 'medium' | 'large';
type type = 'button' | 'reset' | 'submit';

/** Component that should be used for all types of buttons */
@Component({
  selector: 'lru-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  /** What kind of button is it? */
  @Input() theme: theme = undefined;

  /** Makes it possible to make custom styling */
  @Input() variant?: string;

  /** Button size */
  @Input() size: size = 'medium';

  /** Extra classes to be added to the button */
  @Input() classes?: string;

  /** Should the button be disabled? */
  @Input() disabled: boolean = false;

  /** Is it hollow variant of the button? (color is on border instead of background) */
  @Input() hollow: boolean = false;

  /** Button type */
  @Input() type: type = 'button';

  /** The padding to be used around the button */
  @Input() padding?: number;

  /** Converts {@link padding} from a number to a string with 'px' after the number. */
  get paddingPX(): string | undefined {
    return this.padding ? `${this.padding}px` : undefined;
  }
}
