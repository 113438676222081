import { FullLevelDto } from '../interfaces/endpoint/dtos/dto-level.interface';
import { TeamWithTeachersDto } from '../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { IClassWithUsers, IUserPermissionWithSelected, ITeamEdit } from './interfaces/team-edit.interface';

export interface LoungeState {
  fullLevel?: FullLevelDto;
  dlpTeachersList?: IUserPermissionWithSelected[];
  dlpClassWithStudentsList?: IClassWithUsers[];
  teamWithTeachersList?: TeamWithTeachersDto[];
  teamEdit?: ITeamEdit;
}

export const initialLoungeState: LoungeState = {
  fullLevel: undefined,
  dlpTeachersList: undefined,
  dlpClassWithStudentsList: undefined,
  teamWithTeachersList: undefined,
  teamEdit: undefined,
};
