<div
  class="dropdown-button-container"
  (click)="onClickToggleDropdown()"
  [attr.data-id]="id"
  [attr.data-open]="isOpen"
  [attr.aria-label]="ariaLabel"
  [attr.data-axis]="axis"
  [attr.data-horizontal-direction]="appliedHorizontalDirection"
  [attr.data-vertical-direction]="appliedVerticalDirection"
  [attr.data-variant]="variant"
  [attr.data-pop-over]="popOver"
  [attr.data-spacing-from-button]="spacingFromButton"
  [style.zIndex]="zIndex$ | async"
  #domOpenDropdownButton
>
  <ng-content select="[button]"></ng-content>
</div>
<ng-template cdk-portal>
  <ng-container *ngIf="zIndex$ | async as zIndex">
    <div *ngIf="isOpen" class="dropdown-overlay" [style.zIndex]="zIndex - 1" (click)="onCloseClick()"></div>
    <div
      [style.zIndex]="zIndex"
      *ngIf="isOpen"
      class="dropdown-wrapper"
      [attr.data-id]="id"
      [attr.data-axis]="axis"
      [attr.data-horizontal-direction]="appliedHorizontalDirection"
      [attr.data-vertical-direction]="appliedVerticalDirection"
      [attr.data-fill-width]="appliedFillWidth"
      [attr.data-fill-height]="appliedFillHeight"
      [attr.data-variant]="variant"
      [attr.data-pop-over]="popOver"
      [attr.data-spacing-from-window]="spacingFromWindow"
      [attr.data-spacing-from-button]="spacingFromButton"
      [style.top]="appliedTop"
      [style.bottom]="appliedBottom"
      [style.left]="appliedLeft"
      [style.right]="appliedRight"
      [style.max-height]="appliedMaxHeight"
      [style.max-width]="appliedMaxWidth"
      [style.width]="appliedWidth"
      [style.height]="appliedHeight"
      [style.visibility]="appliedVisibility"
    >
      <!-- Used to trap focus inside of the dropdown -->
      <div tabindex="0" class="focus-trap focus-trap-begin"></div>
      <div class="dropdown-content" #domDropdownContent>
        <div class="dropdown-close" (click)="onCloseClick()">
          <ng-content select="[close]"></ng-content>
        </div>
        <ng-content *ngIf="dropdownService.open$ | async"></ng-content>
      </div>
      <!-- Used to trap focus inside of the dropdown -->
      <div tabindex="0" class="focus-trap focus-trap-end"></div></div
  ></ng-container>
</ng-template>
