<div class="search">
  <input type="text" placeholder="Søg i elever" [(ngModel)]="searchTerm" />
  <lru-icon [icon]="{ path: 'felib-search', size: 14 }"></lru-icon>
</div>

<lru-dropdown
  [variant]="'lounge'"
  [sameWidth]="true"
  [spacingFromWindow]="10"
  #dropdownLevel
  *ngIf="(loungeService.fullLevel$ | async)?.levels as levels; else levelsLoading"
>
  <ng-container button>
    <lru-button [variant]="'lounge'" [theme]="'white'">
      <span>{{ getLevelsLabel() }}</span>
      <lru-icon [icon]="{ path: 'felib-chevron-down', size: 16 }" class="arrow"></lru-icon>
    </lru-button>
  </ng-container>
  <ng-container>
    <ul>
      <li *ngFor="let item of levels">
        <lru-checkbox
          [checked]="isLevelSelected(item.id)"
          [size]="12"
          variant="lounge"
          (changed)="onClickLevel(item, $event)"
          >{{ item.mediumForm }}</lru-checkbox
        >
      </li>
    </ul>
  </ng-container>
</lru-dropdown>

<div class="classes" *ngIf="loungeService.dlpClassWithStudentsList$ | async as classes; else classesLoading">
  <mat-accordion
    data-variant="lounge"
    *ngIf="classes | searchClassWithUsers : searchTerm : selectedLevels as filteredClasses"
  >
    <mat-expansion-panel hideToggle *ngFor="let classDto of filteredClasses; trackBy: classTrackBy">
      <mat-expansion-panel-header *ngIf="classDto.users.length > 0">
        <mat-panel-title>
          <lru-checkbox
            [checked]="classDto.class.selected"
            [semiChecked]="isClassSemiSelected(classDto)"
            [size]="12"
            variant="lounge"
            (changed)="onClickClass(classDto.class.id, $event)"
          >
            {{ classDto.class.name }}
          </lru-checkbox>
          <lru-icon [icon]="{ path: 'felib-chevron-down', size: 16 }" class="expansion-panel-arrow"></lru-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ul>
        <li *ngFor="let user of classDto.users">
          <lru-checkbox
            [checked]="user.selected"
            [size]="12"
            variant="lounge"
            (changed)="onClickUser(classDto.class.id, user.id, $event)"
            >{{ user.name }}</lru-checkbox
          >
        </li>
      </ul>
    </mat-expansion-panel>
    <div class="no-items">Intet fundet ud fra de valgte kriterier</div>
  </mat-accordion>
</div>

<ng-template #classesLoading>
  <lru-loading-animation *ngIf="loadingService.loading$(sharedLoadingKeys.dlpClasses) | async"></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.dlpClasses) | async as error">{{ error }}</p>
</ng-template>
<ng-template #levelsLoading>
  <lru-loading-animation *ngIf="loadingService.loading$(sharedLoadingKeys.fullLevel) | async"></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.fullLevel) | async as error">{{ error }}</p>
</ng-template>
