import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { LoadingService } from '@lru/felib';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { MouseAndKeyboardService } from '@portal-app/services/shared/mouse-and-keyboard.service';
import { HeaderTypeEnum } from './enums/header-type.enum';
import { LoadingKey } from './enums/loading-keys.enum';
import { PageTypeEnum } from './enums/page-type.enum';
import { DropdownService as DropdownServiceOld } from './services/shared/dropdown.service';
import { HeaderService } from './services/shared/header.service';
import { LayoutAudioService } from './services/shared/layout-audio.service';
import { PageService } from './services/shared/page/page.service';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  behind?: boolean;
  pageType?: PageTypeEnum;
  headerType?: HeaderTypeEnum;

  constructor(
    public appInitService: AppInitService,
    private router: Router,
    private mouseAndKeyboardService: MouseAndKeyboardService,
    private layoutAudioService: LayoutAudioService,
    private dropdownServiceOld: DropdownServiceOld,
    private renderer: Renderer2,
    private loadingService: LoadingService,
    private elementRef: ElementRef,
    private pageService: PageService,
    private headerService: HeaderService
  ) {}

  ngOnInit() {
    this.headerService.behind$.subscribe((behind) => {
      this.behind = behind;
    });

    const interval = setInterval(() => {
      if (getComputedStyle(this.elementRef.nativeElement).display === 'flex') {
        clearInterval(interval);
        this.renderer.setAttribute(this.elementRef.nativeElement, 'class', 'loaded');
      }
    }, 50);

    document.body.style.paddingRight = `${window.scrollbarWidth}px`;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.start(LoadingKey.Page);
        this.layoutAudioService.removeAll();
        this.dropdownServiceOld.closeDropdown();
      }
      if (event instanceof NavigationEnd) {
        this.mouseAndKeyboardService.init();
        this.loadingService.stop(LoadingKey.Page);
      }

      // Set loading state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel) {
        this.loadingService.stop(LoadingKey.Page);
      }
      if (event instanceof NavigationError) {
        this.loadingService.stop(LoadingKey.Page);
      }

      if (event instanceof RoutesRecognized) {
        this.pageType = event.state.root.firstChild?.data.pageType as PageTypeEnum;
        if (!this.pageType) {
          return;
        }
        this.headerService.setBehind(this.pageType);
        switch (event.state.root.firstChild?.data.pageType) {
          case PageTypeEnum.Book:
            this.headerType = HeaderTypeEnum.Book;
            break;
          case PageTypeEnum.CourseFrontpage:
            this.headerType = HeaderTypeEnum.Course;
            break;
          default:
            this.headerType = HeaderTypeEnum.Default;
        }
        this.pageService.contentPage$.subscribe((page) => {
          if (this.pageType) {
            this.headerService.setBehind(this.pageType, page?.hero ? true : false);
          }
        });
      }
    });
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyup(e: KeyboardEvent) {
    this.mouseAndKeyboardService.handleKeyup(e);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(e: KeyboardEvent) {
    this.mouseAndKeyboardService.handleKeydown(e);
  }

  @HostListener('document:touchstart', ['$event.target'])
  @HostListener('document:click', ['$event.target'])
  documentClick(target: HTMLElement) {
    this.mouseAndKeyboardService.documentClick(target);
  }
}
