import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { PeriodSelectorEnum } from '@portal-app/enums/period-selector.enum';
import { IBookTile, ICollectionTile } from '@portal-app/interfaces/project/section.interface';
import { ISelectedClass, ITeacherClassStudentItem, ITeamsAndClasses } from './teacher-dashboard.reducer';

const modulePrefix = '[TeacherDashboard]';

export const GetTeamsAndClasses = createAction(`${modulePrefix} GetTeamsAndClasses`);

export const SelectTeacherClassIdAction = createAction(
  `${modulePrefix} SelectTeacherClassId`,
  props<{ selectedClass: ISelectedClass }>()
);

export const SetSelectedStudent = createAction(
  `${modulePrefix} Set Selected Student`,
  props<{ student?: ITeacherClassStudentItem }>()
);

export const SelectedBookTapNavigationAction = createAction(
  `${modulePrefix} SelectedBookTabNavigation`,
  props<{ bookTab: string }>()
);

export const SelectBookPeriod = createAction(`${modulePrefix} change period book selection`, props<{ data: string }>());

export const SetBookPeriod = createAction(`${modulePrefix} only set the period`, props<{ data: PeriodSelectorEnum }>());

export const SetOnlyBookPeriodTime = createAction(
  `${modulePrefix} Only begin date for book period`,
  props<{ data: string }>()
);

export const SetBookPeriodTime = createAction(`${modulePrefix} begin date for book period`, props<{ data: string }>());

export const GetTeamsAndClassesSuccess = createAction(
  `${modulePrefix} GetTeamsAndClassesSuccess`,
  props<{ data: ITeamsAndClasses }>()
);

export const GetTeacherClassesFailureAction = createAction(
  `${modulePrefix} GetTeacherClassesFailure`,
  props<{ error: HttpErrorResponse }>()
);

export const GetBookCollectionAction = createAction('[GetBookCollection] GetBookCollection');

export const GetBookCollectionSuccessAction = createAction(
  'GetBookCollectionSuccessAction',
  props<{ bookCollectionData: ICollectionTile[] }>()
);
export const GetBookCollectionFailureAction = createAction(
  '[GetBookCollectionFailureAction] GetBookCollectionFailureAction',
  props<{ error: HttpErrorResponse }>()
);
export const GetStudentsInClassSuccessAction = createAction(
  'GetStudentsInClassSuccessAction',
  props<{ studentsData: ITeacherClassStudentItem[] }>()
);

export const GetStudentsInClassFailureAction = createAction(
  'GetStudentsInClassFailureAction',
  props<{ error: HttpErrorResponse }>()
);

export const ToggleBookCollectionFavouriteAction = createAction(
  '[TeacherDashboard] toggleBookCollectionFavourite',
  props<{ data: boolean; id: string }>()
);

export const ToggleBookContinueFavouriteAction = createAction(
  '[TeacherDashboard] toggle favourite for books continue',
  props<{ data: boolean; id: string }>()
);

export const ToggleBookFinishedFavouriteAction = createAction(
  '[TeacherDashboard] toggle favourite for finished books',
  props<{ data: boolean; id: string }>()
);

export const GetStudentsInClassAction = createAction(
  'GetStudentsInClassAction',
  props<{ selectedClass: ISelectedClass }>()
);

export const GetBooksForUser = createAction('GetBooksForUser', props<{ userId: string }>());

export const GetBooksForUserSuccess = createAction(
  'GetBooksForUserSuccess',
  props<{ booksInProgress: IBookTile[]; booksFinished: IBookTile[] }>()
);
