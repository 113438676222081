<ng-container *ngIf="{ messages: messages$ | async, zIndex: zIndex$ | async } as sub">
  <ul
    class="toast-message-list"
    [@animateInOut]="sub.messages?.length ?? 0"
    [attr.data-horizontal-position]="appliedHorizontalPosition"
    [attr.data-vertical-position]="appliedVerticalPosition"
    [style.gap]="appliedSpacingBetweenToasts"
    [style.padding]="appliedSpacingFromWindow"
    [attr.data-variant]="appliedVariant"
    [style.zIndex]="sub.zIndex"
  >
    <li
      *ngFor="let message of sub.messages"
      class="toast-message-list-item"
      (mouseenter)="onMouseenter($event)"
      (mouseleave)="onMouseleave()"
    >
      <lru-toast-message [message]="message"></lru-toast-message>
    </li>
  </ul>
</ng-container>
