<div class="buttons">
  <lru-button [variant]="'lounge'" [theme]="'icon-button'" *ngIf="backFunc" (click)="onClickBack()">
    <lru-icon [icon]="{ path: 'felib-chevron-down', size: 16, rotate: 90 }"></lru-icon>
  </lru-button>
  <lru-button class="close" [variant]="'lounge'" [theme]="'icon-button'" *ngIf="closeFunc" (click)="onClickClose()">
    <lru-icon [icon]="{ path: 'felib-x-mark', size: 12 }"></lru-icon>
  </lru-button>
</div>
<div class="headlines">
  <h1 *ngIf="header">{{ header }}</h1>
  <p *ngIf="subHeader">{{ subHeader }}</p>
</div>
