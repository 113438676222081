import { createReducer, on } from '@ngrx/store';
import * as ZIndexActions from './z-index.actions';
import { initialZIndexState } from './z-index.state';

export const zIndexFeatureKey = 'zIndexState';

export const zIndexReducer = createReducer(
  initialZIndexState,
  on(ZIndexActions.setConfig, (state, action) => {
    return {
      ...state,
      currentZIndex: action.config.zIndex ?? 0,
      config: action.config,
    };
  }),
  on(ZIndexActions.setItem, (state, action) => {
    return {
      ...state,
      currentZIndex: state.currentZIndex + 2,
      items: [
        ...state.items.filter((x) => x.id !== action.id),
        {
          id: action.id,
          zIndex: state.currentZIndex + 2,
        },
      ],
    };
  })
);
