import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { IUmbNavbar } from '@portal-app/interfaces/umbraco/umb-navbar.interface';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  private data = new ReplaySubject<IUmbNavbar>(1);
  data$ = this.data.asObservable();

  private libraryUrl = new ReplaySubject<string>(1);
  libraryUrl$ = this.libraryUrl.asObservable();

  private favoritesUrl = new ReplaySubject<string>(1);
  favoritesUrl$ = this.favoritesUrl.asObservable();

  private courseStudentAnswersUrl = new ReplaySubject<string>(1);
  courseStudentAnswersUrl$ = this.courseStudentAnswersUrl.asObservable();

  private courseAnnualPlanUrl = new ReplaySubject<string>(1);
  courseAnnualPlanUrl$ = this.courseAnnualPlanUrl.asObservable();

  private psArticleLibraryUrl = new ReplaySubject<string>(1);
  psArticleLibraryUrl$ = this.psArticleLibraryUrl.asObservable();

  private psAssignmentLibraryUrl = new ReplaySubject<string>(1);
  psAssignmentLibraryUrl$ = this.psAssignmentLibraryUrl.asObservable();

  constructor(private api: ApiService, private userService: UserService) {}

  getNavbar(menuId: number) {
    this.api
      .getUmbraco<IUmbNavbar>(`alineaportal/menu/fetch/${menuId}/${this.userService.userType}`)
      .subscribe((data) => {
        this.data.next(data);
      });
    return this.data;
  }

  setLibrary(url?: string) {
    if (url) {
      this.libraryUrl.next(url);
    }
  }

  setCourseStudentAnswers(url?: string) {
    if (url) {
      this.courseStudentAnswersUrl.next(url);
    }
  }

  setFavorites(url?: string) {
    if (url) {
      this.favoritesUrl.next(url);
    }
  }

  setCourseAnnualPlan(url?: string) {
    if (url) {
      this.courseAnnualPlanUrl.next(url);
    }
  }

  setPsArticleLibrary(url?: string) {
    if (url) {
      this.psArticleLibraryUrl.next(url);
    }
  }

  setPsAssignmentLibrary(url?: string) {
    if (url) {
      this.psAssignmentLibraryUrl.next(url);
    }
  }
}
