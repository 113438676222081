<div *ngIf="item" class="flex vertical-center space-1" tabindex="0">
  <button
    *ngIf="item.Marks?.length"
    [class.hidden]="!loaded"
    (click)="onClickPreviousMark()"
    class="btn small-width previous-mark"
    [attr.disabled]="!item.SentenceButtons?.PreviousEnabled || null"
  >
    <i class="icon icon-16" [inlineSVG]="'step-backward.svg'"></i>
  </button>
  <div
    *ngIf="source"
    [class.hidden]="!loaded"
    class="plyr-container"
    plyr
    [plyrPlaysInline]="true"
    [plyrSources]="[source]"
    [plyrOptions]="options"
    plyrType="audio"
    (plyrInit)="plyrInit($event)"
    (plyrPlay)="plyrPlay($event)"
    (plyrPause)="plyrPause($event)"
    (plyrEnded)="plyrEnded($event)"
  ></div>
  <button class="btn loading" [class.hidden]="loaded">
    <i class="icon icon-22" [inlineSVG]="'loading.svg'"></i>
  </button>
  <button
    *ngIf="item.Marks?.length"
    [class.hidden]="!loaded"
    (click)="onClickNextMark()"
    class="btn small-width next-mark"
    [attr.disabled]="!item.SentenceButtons?.NextEnabled || null"
  >
    <i class="icon icon-16" [inlineSVG]="'step-forward.svg'"></i>
  </button>
</div>
