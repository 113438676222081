import { DimensionsEnum } from '@portal-app/enums/dimensions.enum';

export const environment = {
  production: true,
  fallbackLanguage: 'da',
  isRealProduction: () => {
    return window.location.hostname.toLocaleLowerCase().endsWith('.alinea.dk');
  },
  internalBackendUrl: () => {
    return window.location.origin + '/';
  },
  loginUsingMvcDotNet: true,
  apiLoungeBaseUrl: 'https://lounge.dev.alineadigital.dk/api/',
  apiNextBaseUrl: 'https://web-next-api-dev.azurewebsites.net/api/',
  apiNextSearchBaseUrl: 'https://nextsearch-dev.azurewebsites.net/api/',
  apIUmbBaseUrl: 'https://mitalineaumb.dev.alineadigital.dk/api/',
  assetBasePath: 'https://cdn.alinea.dk/',
  dimensions: DimensionsEnum,
  showPapertrailResponse: true,
  activityUrl: () => {
    return window.location.hostname === 'exploro.fagportal.dev.alineadigital.dk'
      ? 'https://exploroportal.dev.alineadigital.dk'
      : 'https://portal.dev.alineadigital.dk';
  },
  localHostName: 'dansk.fagportal.dev.alineadigital.dk',
  // localHostName: 'superreader.dev.alineadigital.dk',
  // localHostName: 'superbog.dev.alineadigital.dk',
  // localHostName: 'ps.dev.alineadigital.dk',
};
// The idnUrl must be the Internationalized Domain Name (IDN) for forstå.dk in production wich is https://xn--forst-qra.dk.
