import { Injectable, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getObservableValueSync } from '../../../utils/observables';
import { ApiServiceConfig } from '../api/config/api-service-config';
import { UserTypeEnum } from '../enums/user-type.enum';
import { IIsbn } from './interfaces/isbn.interface';
import { INextToken } from './interfaces/next-token.interface';
import { IUser } from './interfaces/user.interface';

/** Service for handling the user and user data (login, logout, status etc.) */
@Injectable({ providedIn: 'root' })
export class UserService {
  /** A {@link BehaviorSubject} for handling the status of {@link loginStatus$} */
  private _loginStatus = new BehaviorSubject<boolean>(false);

  /** Observable for getting the login status of the user. If user is logged in returns true otherwise returns false */
  loginStatus$ = this._loginStatus.asObservable();

  /** A {@link BehaviorSubject} for handling the status of {@link user$} */
  private _user = new BehaviorSubject<IUser | undefined>(undefined);

  /** Observable for getting the logged in user. If not logged in returns undefined */
  user$ = this._user.asObservable();

  /** The URL for the internal backend */
  private _internalBackendUrl?: string;

  /** Whether or not to login using C# MVC */
  private _loginUsingMvcDotNet?: boolean;

  /** Handles normal DependencyInjection but also for {@link UserServiceConfig}.
   * If no config is supplied in the providers field in the module we use the default config.  */
  constructor(@Optional() apiServiceConfig?: ApiServiceConfig) {
    const defaultUserServiceConfig = new ApiServiceConfig();

    this._loginUsingMvcDotNet =
      typeof apiServiceConfig?.loginUsingMvcDotNet === 'boolean'
        ? apiServiceConfig?.loginUsingMvcDotNet
        : defaultUserServiceConfig.loginUsingMvcDotNet;
    this._internalBackendUrl =
      apiServiceConfig?.internalBackendUrl ||
      (defaultUserServiceConfig.internalBackendUrl !== ''
        ? defaultUserServiceConfig.internalBackendUrl
        : defaultUserServiceConfig.internalBackendUrlFallBack(apiServiceConfig?.loginUsingMvcDotNet));
  }

  /** The userLogin method will perform a user login through the MVC project login controller. */
  login(isbn: string = ''): void {
    const href = this._loginUsingMvcDotNet
      ? `${this._internalBackendUrl}login/login?returnUrl=${this.returnUrl}`
      : `${this._internalBackendUrl}auth/login?returnUrl=${this.returnUrl}&isbn=${isbn}`;

    window.location.replace(href);
  }

  /** The userLogin method will perform a user logout through the MVC project login controller. */
  logout(): void {
    localStorage.removeItem('token');
    const href = this._loginUsingMvcDotNet
      ? `${this._internalBackendUrl}login/beginlogout?returnUrl=${this.returnUrl}`
      : `${this._internalBackendUrl}authorize/logoff?returnUrl=${this.returnUrl}`;

    window.location.replace(href);
  }

  /** Will set the user as undefined */
  removeUser(): void {
    this._user.next(undefined);
    this._loginStatus.next(false);
  }

  /** Sets the user if input is correct otherwise removes user */
  setUser(user: IUser): void {
    if (user?.userId && user.userId !== '00000000-0000-0000-0000-000000000000') {
      this._user.next(user);
      this._loginStatus.next(true);
    } else {
      this.removeUser();
      this._loginStatus.next(false);
    }
  }

  /** Sets the ISBNs if user is logged in */
  setISBNs(isbns: IIsbn[]): void {
    if (this.user) {
      this.user.isbns = isbns;
      this._user.next(this.user);
    }
  }

  /** Sets the {@link INextToken} and applies values to {@link IUser.accessToken} and {@link IUser.expiresAt} */
  setToken(token: INextToken): void {
    if (this.user) {
      this.user.accessToken = token.AccessToken;
      this.user.expiresAt = token.AccessTokenExpiresAt;
      this._user.next(this.user);
    }
  }

  /* get helpers */

  /** Retrieves the user and returns undefined if not logged in */
  get user(): IUser | undefined {
    return getObservableValueSync(this.user$);
  }

  /** Retrieves the user type
   * @example
   * UserTypeEnum.TEACHERS
   * UserTypeEnum.STUDENTS
   * UserTypeEnum.NOT_LOGGED_IN */
  get userType(): string {
    if (this.user) {
      if (this.user.isTeacher) {
        return UserTypeEnum.TEACHERS;
      } else {
        return UserTypeEnum.STUDENTS;
      }
    } else {
      return UserTypeEnum.NOT_LOGGED_IN;
    }
  }

  /** Retrieves if we shouldn't track the user if logged in */
  get doNotTrack(): boolean {
    if (this.user) {
      return this.user?.doNotTrack;
    } else {
      return false;
    }
  }

  /** Checks if user is logged in */
  get isLoggedIn(): boolean {
    return this.user ? true : false;
  }

  /** Checks if user is a student if logged in */
  get isStudent(): boolean {
    return this.userType === UserTypeEnum.STUDENTS;
  }

  /** Checks if user is a teacher if logged in */
  get isTeacher(): boolean {
    return this.userType === UserTypeEnum.TEACHERS;
  }

  /** Checks if user is an Alinea user if logged in */
  get isAlineaUser(): boolean {
    if (this.user) {
      return this.user.isAlineaUser ? true : false;
    } else {
      return false;
    }
  }

  /** Retrieves the authentication token from the user if logged in */
  get authenticationToken(): string | undefined {
    if (this.user) {
      return this.user.authenticationToken;
    } else {
      return;
    }
  }

  /** Retrieves the access token from the user if logged in */
  get accessToken(): string | undefined {
    if (this.user) {
      return this.user.accessToken;
    } else {
      return;
    }
  }

  /** Retrieves the expires at from the user if logged in */
  get expiresAt(): string | undefined | number {
    if (this.user) {
      return this.user.expiresAt;
    } else {
      return;
    }
  }

  /** Retrieves the context identifier from the user if logged in */
  get contextIdentifier(): string | undefined {
    if (this.user) {
      return this.user.contextIdentifier;
    } else {
      return;
    }
  }

  /** Retrieves the institution number from the user if logged in */
  get institutionNumber(): string | undefined {
    if (this.user) {
      return this.user.institutionNumber;
    } else {
      return;
    }
  }

  /** Retrieves the user id from the user if logged in */
  get userId(): string | undefined {
    if (this.user) {
      return this.user.userId;
    } else {
      return;
    }
  }

  /** Retrieves the display name from the user if logged in */
  get displayName(): string | undefined {
    if (this.user) {
      return this.user.displayName;
    } else {
      return;
    }
  }

  /** Retrieves the institution name from the user if logged in */
  get institutionName(): string | undefined {
    if (this.user) {
      return this.user.institutionName;
    } else {
      return;
    }
  }

  /** Retrieves the initials from the user if logged in */
  get initials(): string | undefined {
    if (this.user) {
      return this.user.initials;
    } else {
      return;
    }
  }

  /** Retrieves the isbns from the user if logged in */
  get isbns(): IIsbn[] | undefined {
    if (this.user) {
      return this.user.isbns;
    } else {
      return [];
    }
  }

  /** Retrieves the institution id from the user if logged in */
  get institutionId(): string | undefined {
    if (this.user) {
      return this.user.institutionId;
    } else {
      return;
    }
  }

  /** Retrieves the url to return to after redirecting after login or logout */
  private get returnUrl(): string {
    const querystring = new URLSearchParams(window.location.search);

    querystring.delete('returnUrl');
    const q = querystring.toString() !== '' ? '?' + querystring.toString() : '';

    return `${window.location.origin}${window.location.pathname}${q}`;
  }
}
