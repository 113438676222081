import { Injectable } from '@angular/core';
import { IDropdown } from '@portal-app/interfaces/project/dropdown.interface';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  private open = new BehaviorSubject<IDropdown | undefined>(undefined);
  open$ = this.open.asObservable();

  dropdowns: string[] = [];

  openDropdown(id: string, darkOverlay?: boolean) {
    const dropdown: IDropdown = {
      Id: id,
      DarkOverlay: darkOverlay,
    };
    this.open.next(dropdown);
  }

  closeDropdown() {
    this.open.next(undefined);
  }

  toggleDropdown(id: string, darkOverlay?: boolean, zIndex?: number) {
    this.open.pipe(take(1)).subscribe((open: IDropdown | undefined) => {
      if (id === open?.Id && id !== '') {
        this.open.next(undefined);
      } else {
        const dropdown: IDropdown = {
          Id: id,
          DarkOverlay: darkOverlay,
          ZIndex: zIndex,
        };
        this.open.next(dropdown);
      }
    });
  }
}
