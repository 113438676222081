<ng-container *ngIf="{ data: (data$ | async) } as sub">
  <div
    *ngIf="sub.data.Partial"
    class="audio-element partial"
    [class.partial-desktop]="sub.data.Partial.Type === AudioPlayerTypeEnum.PartialDesktop"
    [class.partial-touch]="sub.data.Partial.Type === AudioPlayerTypeEnum.PartialTouch"
    [style.left]="sub.data.Partial.Selections[0].CoordinatesAndText?.X + 'px'"
    [style.top]="sub.data.Partial.Selections[0].CoordinatesAndText?.Y + 'px'"
  >
    <portal-audio-selection-player
      [src]="sub.data.Partial.Src"
      [type]="sub.data.Partial.Type"
      [marks]="sub.data.Partial.Marks"
      [selections]="sub.data.Partial.Selections"
      [autoPlay]="sub.data.Partial.AutoPlay"
      [highlight]="sub.data.Partial.Highlight"
      [sentenceButtons]="sub.data.Partial.SentenceButtons"
      [autofocus]="true"
    >
    </portal-audio-selection-player>
  </div>
</ng-container>
