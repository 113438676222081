import { Injectable } from '@angular/core';
import { ApiService, GoogleTagManagerService } from '@lru/felib';
import { TranslateService } from '@ngx-translate/core';
import { AudioPlayerTypeEnum } from '@portal-app/enums/audio-player-type.enum';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { TextToSpeechLanguageEnum } from '@portal-app/enums/text-to-speech-language.enum';
import { ILanguage } from '@portal-app/interfaces/project/input.interface';
import {
  IAudioHlsPlayer,
  IAudioPlayerMark,
  IAudioSelectionPlayer,
  ILayoutAudio,
  IPlayer,
  IPollyObject,
  ISelection,
} from '@portal-app/interfaces/project/layout-audio.interface';
import Plyr, { PlyrEvent } from 'plyr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SelectionService } from './selection.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutAudioService {
  private data = new BehaviorSubject<ILayoutAudio>({
    Players: [],
    Languages: [
      {
        Id: TextToSpeechLanguageEnum.da,
        Title: this.translateService.instant('LayoutAudio.Languages.Da'),
        Checked: true,
      },
      {
        Id: TextToSpeechLanguageEnum.en,
        Title: this.translateService.instant('LayoutAudio.Languages.En'),
        Checked: false,
      },
      {
        Id: TextToSpeechLanguageEnum.de,
        Title: this.translateService.instant('LayoutAudio.Languages.De'),
        Checked: false,
      },
      {
        Id: TextToSpeechLanguageEnum.fr,
        Title: this.translateService.instant('LayoutAudio.Languages.Fr'),
        Checked: false,
      },
    ],
    PartialDestroyed: true,
  });
  data$ = this.data.asObservable();

  constructor(
    private apiService: ApiService,
    private selectionService: SelectionService,
    private gtmService: GoogleTagManagerService,
    private translateService: TranslateService
  ) {}

  async addPlayer(instance: Plyr, src: string) {
    const layoutAudio = await firstValueFrom(this.data$);
    layoutAudio.Players.push({
      Instance: instance,
      Src: src,
    });
    this.data.next(layoutAudio);
  }

  async removePlayer(instance: Plyr, src: string) {
    instance.stop();

    const layoutAudio = await firstValueFrom(this.data$);
    layoutAudio.Players = layoutAudio.Players.filter((i) => i.Src !== src);
    this.data.next(layoutAudio);
  }

  async togglePartialDestroyed(destroyed: boolean) {
    const layoutAudio = await firstValueFrom(this.data$);
    layoutAudio.PartialDestroyed = destroyed;
    this.data.next(layoutAudio);
  }

  async setActiveLanguage(id: string) {
    if (Object.values(TextToSpeechLanguageEnum).includes(id as TextToSpeechLanguageEnum)) {
      const layoutAudio = await firstValueFrom(this.data$);
      layoutAudio.Languages = layoutAudio.Languages.map((item) => {
        return { Id: item.Id, Title: item.Title, Checked: item.Id === id };
      });
      this.data.next(layoutAudio);
    }
  }

  async pauseOtherPlayers(src: string) {
    const layoutAudio = await firstValueFrom(this.data$);
    const players = layoutAudio.Players.filter((item) => item.Src !== src);
    players.forEach((player) => player.Instance.pause());
  }

  async removeAll() {
    const layoutAudio = await firstValueFrom(this.data$);
    layoutAudio.Full = undefined;
    layoutAudio.Recorded = undefined;
    layoutAudio.Partial = undefined;
    this.data.next(layoutAudio);
  }

  async initializeFull(selections: ISelection[]) {
    const layoutAudio = await firstValueFrom(this.data$);
    const item: IAudioSelectionPlayer = {
      Type: AudioPlayerTypeEnum.Full,
      Enabled: true,
      Selections: selections,
    };
    layoutAudio.Full = item;
    layoutAudio.FullExpanded = false;
    this.data.next(layoutAudio);
  }

  async getFull() {
    let layoutAudio = await firstValueFrom(this.data$);
    this.data$
      .pipe(
        filter((item) => {
          return item.PartialDestroyed === true;
        }),
        take(1)
      )
      .subscribe((data) => {
        layoutAudio = data;
        if (layoutAudio.Full?.Src && !layoutAudio.FullExpanded) {
          layoutAudio.FullExpanded = true;
          layoutAudio.Full.AutoPlay = true;
          layoutAudio.RecordedExpanded = false;
          this.data.next(layoutAudio);
          return;
        }
        if (layoutAudio.Full?.Selections?.length) {
          const text: string[] = [];
          layoutAudio.Full.Selections.forEach((selection) => {
            text.push(selection.CoordinatesAndText.Text + '!');
          });
          const result = text.join(' ');
          const body = {
            Language: layoutAudio.Languages.find((item) => item.Checked === true)?.Id,
            InputText: result,
          };

          this.apiService.postNext<IPollyObject>('polly/TTS', body).subscribe({
            next: (polly: IPollyObject) => {
              const item = this.mapPollyToPlayer(
                polly,
                layoutAudio.Full?.Selections || [],
                AudioPlayerTypeEnum.Full,
                layoutAudio.Languages,
                true
              );
              layoutAudio.Full = item;
              layoutAudio.Full.AutoPlay = true;
              layoutAudio.FullExpanded = true;
              layoutAudio.RecordedExpanded = false;
              this.data.next(layoutAudio);
            },
            error: (polly: IPollyObject) => {
              layoutAudio.Full = undefined;
              this.data.next(layoutAudio);
            },
          });
        }
      });
  }

  async toggleFullExpanded(expanded: boolean, autoPlay: boolean) {
    const layoutAudio = await firstValueFrom(this.data$);
    layoutAudio.FullExpanded = expanded;
    layoutAudio.RecordedExpanded = false;
    if (layoutAudio.Full) {
      layoutAudio.Full.AutoPlay = autoPlay;
    }
    this.data.next(layoutAudio);
  }

  findPlayer(src: string, players: IPlayer[]) {
    return players.find((item) => item.Src === src);
  }

  async resetFull() {
    const layoutAudio = await firstValueFrom(this.data$);
    if (layoutAudio.Full?.Selections && layoutAudio.Full?.Src) {
      layoutAudio.Full.Highlight = false;
      layoutAudio.Full.AutoPlay = false;

      const player = this.findPlayer(layoutAudio.Full.Src, layoutAudio.Players);
      if (player) {
        layoutAudio.Full.SentenceButtons = {
          PreviousEnabled: false,
          NextEnabled: true,
        };
        player.Instance.pause();
        player.Instance.currentTime = 0;
        layoutAudio.Full.Selections.forEach((selection) => {
          if (layoutAudio.Full) {
            this.selectionService.resetSelection(selection.Target, layoutAudio.Full.Type);
          }
        });
      }
      this.data.next(layoutAudio);
    }
  }

  async removeFull() {
    const layoutAudio = await firstValueFrom(this.data$);
    layoutAudio.Full = undefined;
    this.data.next(layoutAudio);
  }

  async getPartial(selection?: ISelection) {
    if (!selection?.CoordinatesAndText.Text) {
      return;
    }
    const layoutAudio = await firstValueFrom(this.data$);
    const body = {
      Language: layoutAudio.Languages.find((item) => item.Checked === true)?.Id,
      InputText: selection.CoordinatesAndText.Text.replace('/', '.'), // polly takes "/" as a space so "A/S" becomes two words ... fixed by replacing it with "." just for polly.
    };
    this.apiService.postNext<IPollyObject>('polly/TTS', body).subscribe((polly: IPollyObject) => {
      if (layoutAudio.Full) {
        layoutAudio.Full.Enabled = false;
        layoutAudio.FullExpanded = false;
      }
      if (layoutAudio.Recorded) {
        if (layoutAudio.Recorded.Src) {
          const player = this.findPlayer(layoutAudio.Recorded.Src, layoutAudio.Players);
          if (player) {
            player.Instance.pause();
          }
        }
        layoutAudio.RecordedExpanded = false;
      }
      layoutAudio.Partial = this.mapPollyToPlayer(polly, [selection], selection.Type, layoutAudio.Languages, true);
      layoutAudio.PartialDestroyed = false;
      this.data.next(layoutAudio);
    });
  }

  async removePartial() {
    const layoutAudio = await firstValueFrom(this.data$);
    if (layoutAudio.Partial) {
      if (layoutAudio.Full) {
        layoutAudio.Full.Enabled = true;
        layoutAudio.Full.AutoPlay = false;
        layoutAudio.Full.Highlight = false;
      }
      layoutAudio.Partial = undefined;
      this.data.next(layoutAudio);
    }
  }

  async setRecorded(src: string) {
    const layoutAudio = await firstValueFrom(this.data$);
    const item: IAudioHlsPlayer = {
      Src: src,
      ShowSecondsControls: true,
    };
    layoutAudio.Recorded = item;
    this.data.next(layoutAudio);
  }

  async toggleRecordedExpanded(expanded: boolean, autoPlay: boolean) {
    const layoutAudio = await firstValueFrom(this.data$);
    await this.handleRecordedExpanded(layoutAudio, expanded, autoPlay);

    if (expanded) {
      this.gtmService.pushTagWithUserType(GTMEvent.Open_recorded);
    } else {
      this.gtmService.pushTagWithUserType(GTMEvent.Close_recorded);
    }
  }

  async toggleFullEnabled(enabled: boolean) {
    const layoutAudio = await firstValueFrom(this.data$);

    if (layoutAudio.Full) {
      layoutAudio.Full.Enabled = enabled;
      this.data.next(layoutAudio);
    }
  }

  async handlePlyrEvents(event: PlyrEvent) {
    let gtmEvent;
    switch (event.type) {
      case 'pause':
        gtmEvent = GTMEvent.Audio_paused;
        break;
      case 'play':
        gtmEvent = GTMEvent.Audio_started;
        break;
    }

    if (gtmEvent) {
      this.gtmService.pushTagWithUserType(gtmEvent, {
        file: event.detail.plyr.source,
      });
    }
  }

  private mapPollyToPlayer(
    polly: IPollyObject,
    selections: ISelection[],
    type: AudioPlayerTypeEnum,
    languages: ILanguage[],
    highlight: boolean
  ): IAudioSelectionPlayer {
    const src = polly.Audio[0];
    const marks: IAudioPlayerMark[] = [];
    polly.Marks.forEach((mark) => {
      if (mark.type === 'sentence') {
        marks.push({
          Sentence: mark,
          Words: [],
        });
      } else if (mark.type === 'word') {
        marks[marks.length - 1].Words.push(mark);
      }
    });

    const item: IAudioSelectionPlayer = {
      Enabled: true,
      Src: src,
      Type: type,
      Marks: marks,
      Selections: selections,
      Highlight: highlight,
      SentenceButtons: {
        NextEnabled: marks.length ? true : false,
        PreviousEnabled: false,
      },
    };
    return item;
  }

  private async handleRecordedExpanded(data: ILayoutAudio, expanded: boolean, autoPlay: boolean) {
    if (data.Full) {
      data.Full.Enabled = !expanded;
      data.FullExpanded = false;
      data.Full.AutoPlay = true;
    }
    if (data.Recorded) {
      data.RecordedExpanded = expanded;
      if (data.Recorded.Src) {
        const player = this.findPlayer(data.Recorded.Src, data.Players);
        if (player) {
          if (autoPlay) {
            await player.Instance.play();
          } else {
            player.Instance.pause();
          }
        }
      }
    } else {
      data.RecordedExpanded = false;
    }
    this.data.next(data);
  }
}
