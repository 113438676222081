import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { TeamWithTeachersDto } from '../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { IClassWithUsers, IUserPermissionWithSelected, IUserWithSelected } from './interfaces/team-edit.interface';
import { loungeFeatureKey } from './lounge.reducers';
import { LoungeState } from './lounge.state';

export const selectLoungeState = createFeatureSelector<LoungeState>(loungeFeatureKey);

export const selectFullLevel = createSelector(selectLoungeState, (state) => state.fullLevel);

export const selectDlpTeachersList = createSelector(selectLoungeState, (state) => state.dlpTeachersList);

export const selectSelectedTeachersList = createSelector(selectLoungeState, (state) => {
  const teachers: IUserPermissionWithSelected[] = [];

  state.dlpTeachersList?.forEach((x) => (x.user.selected ? teachers.push(x) : null));
  return teachers;
});

export const selectDlpClassWithUsersList = createSelector(selectLoungeState, (state) => state.dlpClassWithStudentsList);

export const selectSelectedUsersList = createSelector(selectLoungeState, (state) => {
  const users: IUserWithSelected[] = [];

  state.dlpClassWithStudentsList?.forEach((x) => x.users.forEach((x) => (x.selected ? users.push(x) : null)));
  return users;
});

export const selectDlpClassWithStudents = (
  classId: string
): MemoizedSelector<object, IClassWithUsers | undefined, DefaultProjectorFn<IClassWithUsers | undefined>> =>
  createSelector(selectLoungeState, (state) => state.dlpClassWithStudentsList?.find((x) => x.class.id === classId));

export const selectTeamWithTeachersList = createSelector(selectLoungeState, (state) => state.teamWithTeachersList);

export const selectTeamEdit = createSelector(selectLoungeState, (state) => state.teamEdit);

export const selectTeamWithTeachers = (
  id: string
): MemoizedSelector<object, TeamWithTeachersDto | undefined, DefaultProjectorFn<TeamWithTeachersDto | undefined>> =>
  createSelector(selectLoungeState, (state) => state.teamWithTeachersList?.find((x) => x.id === id));
