import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import {
  INextCourseSearchActivity,
  INextCourseSearchAssignment,
  INextCourseSearchAssignment2,
  INextCourseSearchCourse,
  INextCourseSearchMedia,
  INextCourseSearchPage,
  INextCourseSearchPage2,
  INextCourseSearchResponse2,
} from '@portal-app/interfaces/course/next/next-course-library.interface';
import { ILibraryTab, ILibraryTabGroup } from '@portal-app/interfaces/course/project/course-library.interface';
import { ITabMedia, ITabTask, ITabText } from '@portal-app/interfaces/course/project/course-tabs.interface';
import { INextFavoriteCourseTab } from '@portal-app/interfaces/course/project/interface.favorite-course';
import { INextAsset } from '@portal-app/interfaces/next/next-asset.interface';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { LevelService } from '@portal-app/services/shared/level.service';
import { environment } from '@portal-env/environment';
import { ContentTypeEnum } from '../../enums/content-type.enum';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { HelperService } from '../../services/shared/helper.service';
import { HtmlService } from '../../services/shared/html.service';
import { ActivityMapper } from '../activity/activity.mapper';
import { CourseMapper } from './course.mapper';

@Injectable({
  providedIn: 'root',
})
export class CourseTabsMapper {
  constructor(
    private appInitService: AppInitService,
    private courseMapper: CourseMapper,
    private activityMapper: ActivityMapper,
    private levelService: LevelService,
    private translate: TranslateService,
    private helperService: HelperService,
    private htmlService: HtmlService
  ) {}

  mapFavoriteTabsFromApi(
    courses: INextCourseSearchCourse[],
    texts: INextCourseSearchPage2[],
    tasks: INextCourseSearchAssignment2[],
    activities: INextCourseSearchActivity[]
  ): INextFavoriteCourseTab[] {
    const result: INextFavoriteCourseTab[] = [
      {
        UrlTitle: QueryStrings.Courses.en,
        TabsType: ContentTypeEnum.Courses,
        Length: courses.length,
        Courses: {
          SliderSettings: {
            Type: SectionTypeEnum.CourseList,
            MaxSlidesPerView: 5,
          },
          Items: this.courseMapper.mapCoursesFromApi(courses, true, '?portalclick=coursesfavorites'),
        },
        Pagination: {
          Type: PageTypeEnum.CourseLibrary,
          CollectionSize: courses.length,
          Index: 1,
          PageSize: 24,
        },
        IsAvailable: true,
      },
      {
        UrlTitle: QueryStrings.Texts.en,
        TabsType: ContentTypeEnum.Texts,
        Texts: this.mapTextsFromApi2(texts, true, '?portalclick=pagesfavorites'),
        Length: texts.length,
        Pagination: {
          Type: PageTypeEnum.CourseLibrary,
          CollectionSize: texts.length,
          Index: 1,
          PageSize: 10,
        },
        IsAvailable: true,
      },
      {
        UrlTitle: QueryStrings.Activities.en,
        TabsType: ContentTypeEnum.Activities,
        Activities: this.activityMapper.mapActivitiesToTab(activities, true, '?portalclick=assignmentsfavorites'),
        Length: activities.length,
        Pagination: {
          Type: PageTypeEnum.CourseLibrary,
          CollectionSize: activities.length,
          Index: 1,
          PageSize: 10,
        },
        IsAvailable: true,
      },
      {
        UrlTitle: QueryStrings.Assignments.en,
        TabsType: ContentTypeEnum.Assignments,
        Tasks: this.mapTasksFromApi2(tasks, true, '?portalclick=assignmentsfavorites'),
        Length: tasks.length,
        Pagination: {
          Type: PageTypeEnum.CourseLibrary,
          CollectionSize: tasks.length,
          Index: 1,
          PageSize: 10,
        },
        IsAvailable: true,
      },
    ];

    return result;
  }

  mapLibraryTabsFromApi(azureSearch: INextCourseSearchResponse2): ILibraryTabGroup[] {
    const result: ILibraryTabGroup[] = [
      {
        Title: this.translate.instant('CourseTabs.Library'),
        Tabs: [
          {
            UrlTitle: QueryStrings.Texts.en,
            TabsType: ContentTypeEnum.Texts,
            Texts: this.mapTextsFromApi(azureSearch.library.pages, undefined, '?portalclick=pageslibrary'),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.pages.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.pages.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Images.en,
            TabsType: ContentTypeEnum.Images,
            Media: this.mapMediaFromApi(azureSearch.library.images, ContentTypeEnum.Images),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.images.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.images.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Videos.en,
            TabsType: ContentTypeEnum.Videos,
            Media: this.mapMediaFromApi(azureSearch.library.videos, ContentTypeEnum.Videos),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.videos.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.videos.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Audios.en,
            TabsType: ContentTypeEnum.Audios,
            Media: this.mapMediaFromApi(azureSearch.library.audios, ContentTypeEnum.Audios),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.audios.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.audios.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Documents.en,
            TabsType: ContentTypeEnum.Documents,
            Media: this.mapMediaFromApi(azureSearch.library.documents, ContentTypeEnum.Documents),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.documents.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.documents.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Geogebras.en,
            TabsType: ContentTypeEnum.GeoGebras,
            Media: this.mapMediaFromApi(azureSearch.library.geoGebras, ContentTypeEnum.GeoGebras),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.geoGebras.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.geoGebras.isAvailable,
          },
        ],
      },
      {
        Title: this.translate.instant('CourseTabs.Course'),
        Tabs: [
          {
            UrlTitle: QueryStrings.Courses.en,
            TabsType: ContentTypeEnum.Courses,
            Courses: {
              SliderSettings: {
                Type: SectionTypeEnum.CourseList,
                MaxSlidesPerView: 5,
              },
              Items: this.courseMapper.mapCoursesFromApi2(
                azureSearch.courses.courses,
                undefined,
                '?portalclick=courseslibrary'
              ),
            },
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.courses.count,
              Index: 1,
              PageSize: 24,
            },
            IsAvailable: azureSearch.searchCount.courses.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Activities.en,
            TabsType: ContentTypeEnum.Activities,
            Activities: this.activityMapper.mapActivitiesToTab(
              azureSearch.courses.activities,
              undefined,
              '?portalclick=activitieslibrary'
            ),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.activities.count,
              Index: 1,
              PageSize: 24,
            },
            IsAvailable: azureSearch.searchCount.activities.isAvailable,
          },
          {
            UrlTitle: QueryStrings.Assignments.en,
            TabsType: ContentTypeEnum.Assignments,
            Tasks: this.mapTasksFromApi(azureSearch.courses.assignments, undefined, '?portalclick=assignmentslibrary'),
            Pagination: {
              Type: PageTypeEnum.CourseLibrary,
              CollectionSize: azureSearch.searchCount.assignments.count,
              Index: 1,
              PageSize: 10,
            },
            IsAvailable: azureSearch.searchCount.assignments.isAvailable,
          },
        ],
      },
    ];
    return result;
  }

  mapTextsFromApi(texts: INextCourseSearchPage[], hasFavorite?: boolean, trackingUrl?: string): ITabText[] {
    const result: ITabText[] = [];
    for (const text of texts) {
      result.push({
        Id: text.page.id,
        Title: text.page.title,
        Genres: text.page.genres.length
          ? text.page.genres.map((item) =>
              this.translate.instant(`GenreType.${item}`, {
                default: item,
              })
            )
          : [],
        Levels: this.levelService.convertLevelsToString(text.page.levels),
        Authors: text.page.writers ? text.page.writers : [],
        CourseTitle: text.page.courseTitle,
        NormalPages: text.page.normalPageCount,
        ShowNormalPages: text.page.showNormalPages,
        Period: text.page.period === 'Ingen' ? undefined : text.page.period,
        Edition: text.page.edition,
        Url:
          text.page.pageType === 'ActivityPage'
            ? `${environment.activityUrl()}${text.page.url}${trackingUrl || ''}`
            : `${this.appInitService.siteConfiguration.BasePath}${text.page.url}${trackingUrl || ''}`,
        IsLocked: text.locked,
        FavoriteLoaded: hasFavorite ? true : false,
        ObjectType: ContentTypeEnum.Texts,
        IsFavorite: hasFavorite ? true : false,
        Highlights: text.page.highlights,
        Teaser: this.htmlService.fixHtml(text.page.descriptionPreview),
      });
    }
    return result;
  }
  mapTextsFromApi2(texts: INextCourseSearchPage2[], hasFavorite?: boolean, trackingUrl?: string): ITabText[] {
    const result: ITabText[] = [];
    for (const text of texts) {
      result.push({
        Id: text.Page.Id,
        Title: text.Page.Title,
        Genres: text.Page.Genres.length
          ? text.Page.Genres.map((item) =>
              this.translate.instant(`GenreType.${item}`, {
                default: item,
              })
            )
          : [],
        Levels: this.levelService.convertLevelsToString(text.Page.Levels),
        Authors: text.Page.Writers ? text.Page.Writers : [],
        CourseTitle: text.Page.CourseTitle,
        NormalPages: text.Page.NormalPageCount,
        ShowNormalPages: text.Page.ShowNormalPages,
        Period: text.Page.Period === 'Ingen' ? undefined : text.Page.Period,
        Edition: text.Page.Edition,
        Url:
          text.Page.PageType === 'ActivityPage'
            ? `${environment.activityUrl()}${text.Page.Url}${trackingUrl || ''}`
            : `${this.appInitService.siteConfiguration.BasePath}${text.Page.Url}${trackingUrl || ''}`,
        IsLocked: text.Locked,
        FavoriteLoaded: hasFavorite ? true : false,
        ObjectType: ContentTypeEnum.Texts,
        IsFavorite: hasFavorite ? true : false,
        Highlights: text.Page.Highlights,
      });
    }
    return result;
  }

  mapTasksFromApi(tasks: INextCourseSearchAssignment[], hasFavorite?: boolean, trackingUrl?: string): ITabTask[] {
    const result: ITabTask[] = [];
    for (const task of tasks) {
      const item: ITabTask = {
        Id: task.assignment.id,
        Title: task.assignment.title,
        CourseTitle: task.assignment.courseTitle,
        CollaborationTitle: this.translate.instant(`CollaborationType.${task.assignment.collaborationType}`, {
          default: task.assignment.collaborationType,
        }),
        Types: task.assignment.types,
        Url: `${this.appInitService.siteConfiguration.BasePath}${task.assignment.url}${trackingUrl || ''}`,
        IsLocked: task.locked,
        FavoriteLoaded: hasFavorite ? true : false,
        ObjectType: ContentTypeEnum.Assignments,
        IsFavorite: hasFavorite ? true : false,
      };
      if (item.CollaborationTitle !== 'Ingen samarbejdsform') {
        item.CollaborationIcon = `collaboration-${task.assignment.collaborationType.toLowerCase()}.svg`;
      }
      result.push(item);
    }
    return result;
  }
  mapTasksFromApi2(tasks: INextCourseSearchAssignment2[], hasFavorite?: boolean, trackingUrl?: string): ITabTask[] {
    const result: ITabTask[] = [];
    for (const task of tasks) {
      const item: ITabTask = {
        Id: task.Assignment.Id,
        Title: task.Assignment.Title,
        CourseTitle: task.Assignment.CourseTitle,
        CollaborationTitle: this.translate.instant(`CollaborationType.${task.Assignment.CollaborationType}`, {
          default: task.Assignment.CollaborationType,
        }),
        Types: task.Assignment.Types,
        Url: `${this.appInitService.siteConfiguration.BasePath}${task.Assignment.Url}${trackingUrl || ''}`,
        IsLocked: task.Locked,
        FavoriteLoaded: hasFavorite ? true : false,
        ObjectType: ContentTypeEnum.Assignments,
        IsFavorite: hasFavorite ? true : false,
      };
      if (item.CollaborationTitle !== 'Ingen samarbejdsform') {
        item.CollaborationIcon = `collaboration-${task.Assignment.CollaborationType.toLowerCase()}.svg`;
      }
      result.push(item);
    }
    return result;
  }

  mapMediaFromApi(medias: INextCourseSearchMedia[], type: ContentTypeEnum): ITabMedia[] {
    const result: ITabMedia[] = [];
    let aspectRatio16By9 = false;
    let expandable = false;
    if (type === ContentTypeEnum.Images) {
      aspectRatio16By9 = true;
      expandable = true;
    } else if (type === ContentTypeEnum.Videos) {
      aspectRatio16By9 = true;
      expandable = true;
    } else if (type === ContentTypeEnum.Audios) {
      aspectRatio16By9 = true;
      expandable = true;
    } else if (type === ContentTypeEnum.Documents) {
      aspectRatio16By9 = false;
      expandable = false;
    } else if (type === ContentTypeEnum.GeoGebras) {
      aspectRatio16By9 = true;
      expandable = true;
    }

    for (const media of medias) {
      let duration: string | undefined;
      if (media.durationInSeconds) {
        const strPadLeft = (durationString: number, pad: string, length: number) => {
          return (new Array(length + 1).join(pad) + durationString).slice(-length);
        };
        const minutes = Math.floor(media.durationInSeconds / 60);
        const seconds = media.durationInSeconds - minutes * 60;
        const final = strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);
        duration = this.translate.instant(`Label.LengthBlank`, {
          number: final,
        });
      }
      let svg: string | undefined;
      if (type === ContentTypeEnum.GeoGebras) {
        media.path = `https://www.geogebra.org/material/iframe/id/${media.geoGebraId}`;
      } else if (type === ContentTypeEnum.Images) {
        media.path = media.thumbnailPath; // this sucks balls
      } else if (type === ContentTypeEnum.Documents) {
        svg = this.helperService.getFileIconSvg(media.type);
      }
      const item: ITabMedia = {
        ThumbnailPath: media.thumbnailPath,
        FavoriteLoaded: true, // not enabled
        Genres: media.genres ? media.genres : [],
        Id: media.assetId, // used for favorites
        Keywords: media.keywords ? media.keywords : [],
        Levels: this.levelService.convertLevelsToString(media.levels),
        ObjectType: type, // used for favorites
        Path: media.path,
        Subjects: media.subjects ? media.subjects : [],
        Title: media.title,
        Copyright: media.copyright,
        Duration: duration,
        IsFavorite: false, // used for favorites
        IsLocked: media.locked,
        Open: false,
        AspectRatio16By9: aspectRatio16By9,
        Expandable: expandable,
        Image:
          type === ContentTypeEnum.Images && media.basePath
            ? ({
                BasePath: media.basePath,
                Dimensions: {
                  Height: media.dimensions?.height,
                  Width: media.dimensions?.width,
                },
                Path: undefined,
                Type: 'Image',
              } as INextAsset)
            : undefined,
        Svg: svg,
      };
      result.push(item);
    }
    return result;
  }

  replaceCoursesTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab, trackingUrl?: string) {
    if (tab?.Courses) {
      tab.Courses.Items = this.courseMapper.mapCoursesFromApi2(azureSearch.courses.courses, undefined, trackingUrl);
    }

    if (tab) {
      tab.Pagination.CollectionSize = azureSearch.searchCount.courses.count;
    }
  }

  replaceActivitiesTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab, trackingUrl?: string) {
    if (tab) {
      tab.Activities = this.activityMapper.mapActivitiesToTab(azureSearch.courses.activities, undefined, trackingUrl);
      tab.Pagination.CollectionSize = azureSearch.searchCount.activities.count;
    }
  }

  replaceTextsTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab, trackingUrl?: string) {
    if (tab) {
      tab.Texts = this.mapTextsFromApi(azureSearch.library.pages, undefined, trackingUrl);
      tab.Pagination.CollectionSize = azureSearch.searchCount.pages.count;
    }
  }

  replaceTasksTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab, trackingUrl?: string) {
    if (tab) {
      tab.Tasks = this.mapTasksFromApi(azureSearch.courses.assignments, undefined, trackingUrl);
      tab.Pagination.CollectionSize = azureSearch.searchCount.assignments.count;
    }
  }

  replaceImagesTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab) {
    if (tab) {
      tab.Media = this.mapMediaFromApi(azureSearch.library.images, ContentTypeEnum.Images);
      tab.Pagination.CollectionSize = azureSearch.searchCount.images.count;
    }
  }

  replaceVideosTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab) {
    if (tab) {
      tab.Media = this.mapMediaFromApi(azureSearch.library.videos, ContentTypeEnum.Videos);
      tab.Pagination.CollectionSize = azureSearch.searchCount.videos.count;
    }
  }

  replaceAudiosTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab) {
    if (tab) {
      tab.Media = this.mapMediaFromApi(azureSearch.library.audios, ContentTypeEnum.Audios);
      tab.Pagination.CollectionSize = azureSearch.searchCount.audios.count;
    }
  }

  replaceDocumentsTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab) {
    if (tab) {
      tab.Media = this.mapMediaFromApi(azureSearch.library.documents, ContentTypeEnum.Documents);
      tab.Pagination.CollectionSize = azureSearch.searchCount.documents.count;
    }
  }

  replaceGeoGebrasTab(azureSearch: INextCourseSearchResponse2, tab?: ILibraryTab) {
    if (tab) {
      tab.Media = this.mapMediaFromApi(azureSearch.library.geoGebras, ContentTypeEnum.GeoGebras);
      tab.Pagination.CollectionSize = azureSearch.searchCount.geoGebras.count;
    }
  }
}
