import { Injectable } from '@angular/core';
import { ICssColors } from './css-color.interfaces';
import { cssColorVariant } from './css-color.types';

/** Service used to add css variables and their variations to the site */
@Injectable({
  providedIn: 'root',
})
export class CssColorService {
  /** Adds CSS variables to the sites HTML element
   * @param colors array of colors that should be added as CSS variables */
  setCssColors(colors: ICssColors): void {
    colors.items.forEach((color) => {
      const rgb = this.hexToRgb(color.hex100);

      if (rgb) {
        document.documentElement.style.setProperty(
          `--${colors.prefix ? colors.prefix + '-' : ''}${color.name}${color.add100Suffix ? '-100' : ''}`,
          rgb
        );
      }

      color.variants?.forEach((variant) => {
        const variantHex = this.lightenDarkenHexColor(color.hex100, this.convertCssColorVariantToPercentage(variant));
        const variantRgb = this.hexToRgb(variantHex);

        if (variantRgb) {
          document.documentElement.style.setProperty(
            `--${colors.prefix ? colors.prefix + '-' : ''}${color.name}-${variant}`,
            variantRgb
          );
        }
      });
    });
  }

  /** Converts HEX value to RGB value
   * @param hex A HEX value to convert to RGB
   * @returns a string consisting of RGB values */
  hexToRgb(hex?: string): string | undefined {
    if (!hex) {
      return;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : undefined;
  }

  /**
   * ligten or darken a HEX color
   * @param color hex value like "#ff0000" or "ff0000"
   * @param percent plus percentage makes it lighter. minus percentage makes it darker.
   * @returns hex color
   */
  private lightenDarkenHexColor(color: string, percent: number): string {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);

    /* eslint-disable no-bitwise */
    const r = (num >> 16) + amt;
    const b = ((num >> 8) & 0x00ff) + amt;
    const g = (num & 0x0000ff) + amt;
    /* eslint-enable no-bitwise */

    return (
      '#' +
      (
        0x1000000 +
        (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
        (b < 255 ? (b < 1 ? 0 : b) : 255) * 0x100 +
        (g < 255 ? (g < 1 ? 0 : g) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  /**
   * Convert variant into a value that lightenDarkenHexColor() can understand
   * @param variant naming based on naming standard for color themíng
   * @returns percent value. 10 = 10% ligther. -10 = 10% darker.
   */
  private convertCssColorVariantToPercentage(variant: cssColorVariant): number {
    return 100 - variant;
  }
}
