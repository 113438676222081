import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/core/user/user.service';
import { LoadingService } from '../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '../../../../services/loading/shared-loading-keys.enums';
import { LoungeService } from '../../services/lounge.service';

/** A component for showing a team in the edit status */
@Component({
  selector: 'lru-team-edit[id]',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditComponent implements OnInit, OnDestroy {
  /** Id of the team */
  @Input() id!: string;

  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** @ignore */
  constructor(
    public loungeService: LoungeService,
    public userService: UserService,
    public loadingService: LoadingService,
    private route: ActivatedRoute
  ) {}

  /** Finds the id of the team either from the input {@link id} or from the query parameters. Tells {@link LoungeService} to start loading the team */
  ngOnInit(): void {
    const id: string = this.id || this.route.snapshot.queryParams.edit;

    this.loungeService.teamLoadSingleTeamForEdit(id, SharedLoadingKeys.editTeam);
  }

  /** On component destroy we want to ask {@link LoungeService} to cancel the edit of th team */
  ngOnDestroy(): void {
    this.loungeService.teamEditCancel();
  }
}
