import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingAnimationComponent } from './components/loading-animation.component';

@NgModule({
  declarations: [LoadingAnimationComponent],
  imports: [CommonModule],
  exports: [LoadingAnimationComponent],
})
export class LoadingAnimationModule {}
