import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { EMPTY, fromEvent } from 'rxjs';
import { map, share, startWith } from 'rxjs/operators';

@Injectable()
export class WindowScrollService {
  constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: object) {}

  getScrollY() {
    if (isPlatformBrowser(this.platformId)) {
      return fromEvent(window, 'scroll').pipe(
        startWith(0),
        map((event) => {
          return window.scrollY || this.document.documentElement.scrollTop;
        }),
        share()
      );
    } else {
      // in non-browser environments, provide an empty observable so you can safely subscribe to scroll$
      return EMPTY;
    }
  }
}
