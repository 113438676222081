import { Pipe, PipeTransform } from '@angular/core';
import { INextAsset } from '@portal-app/interfaces/next/next-asset.interface';
import { IDimensionElement, IDimensionRatio } from '@portal-app/interfaces/project/dimension.interface';
import { environment } from '@portal-env/environment';

@Pipe({
  name: 'nextImage',
})
export class NextImagePipe implements PipeTransform {
  transform(asset?: INextAsset, width?: number, height?: number, mode?: string, fileType?: string) {
    if (!asset || asset.Type !== 'Image') {
      return '';
    }
    let assetDimensionsWidth;
    let assetDimensionsHeight;
    const getPercentageChange = (oldNumber: number, newNumber: number) => {
      const decreaseValue = oldNumber - newNumber;
      return 1 - decreaseValue / oldNumber;
    };

    if (asset.Dimensions) {
      if (!width && height) {
        const difference = getPercentageChange(asset.Dimensions.Height, height);
        assetDimensionsWidth = asset.Dimensions.Width * difference;
      }
      if (width && !height) {
        const difference = getPercentageChange(asset.Dimensions.Width, width);
        assetDimensionsHeight = asset.Dimensions.Height * difference;
      }
      if (!width && !height) {
        assetDimensionsWidth = asset.Dimensions.Width;
        assetDimensionsHeight = asset.Dimensions.Height;
      }
    }

    const useMode = mode ? (mode === 'max' ? 'max' : 'crop') : 'max';
    const useWidth = width ? width : assetDimensionsWidth;
    const useHeight = height ? height : assetDimensionsHeight;
    if (!useWidth || !useHeight) {
      return '';
    }
    const currentRatio = useHeight / useWidth;
    const closestRatio = environment.dimensions.reduce((a: IDimensionRatio, b: IDimensionRatio) => {
      return Math.abs(b.Ratio - currentRatio) < Math.abs(a.Ratio - currentRatio) ? b : a;
    });
    const availableDimensions = environment.dimensions.find((e) => e.Ratio === closestRatio.Ratio)?.Dimensions;
    if (!availableDimensions) {
      return '';
    }
    const closestDimensions = availableDimensions.reduce((a: IDimensionElement, b: IDimensionElement) => {
      const part1 = Math.abs(b.Width - useWidth);
      const part2 = Math.abs(a.Width - useWidth);
      const result = part1 < part2 && b.Width >= useWidth ? b : a;
      return result;
    });

    const path = asset.BasePath
      ? asset.BasePath
      : !asset.Path?.includes('http') && asset.Path
      ? environment.assetBasePath + asset.Path
      : asset.Path;

    fileType = (asset.FileType ? asset.FileType : 'jpg').toLowerCase();

    return `${path!}/${useMode}/${closestDimensions.Width}x${closestDimensions.Height}.${fileType}`;
  }
}
