import { Injectable } from '@angular/core';
import { AudioPlayerTypeEnum } from '@portal-app/enums/audio-player-type.enum';
import {
  IAudioPlayerMark,
  ICoordinates,
  ICoordinatesAndText,
  ISelection,
} from '@portal-app/interfaces/project/layout-audio.interface';

@Injectable({
  providedIn: 'root',
})
export class SelectionService {
  clearSelection(w?: Window) {
    const selection = w?.getSelection();
    if (selection) {
      if (selection?.empty) {
        // Chrome
        selection.empty();
      } else if (selection?.removeAllRanges) {
        // Firefox
        selection.removeAllRanges();
      }
    }
  }

  getSelection(
    type: AudioPlayerTypeEnum,
    w: Window,
    selection?: Selection,
    target?: HTMLBodyElement
  ): ISelection | undefined {
    if (
      target &&
      selection &&
      selection?.anchorNode !== null &&
      selection?.type !== 'None' &&
      selection?.toString() !== ''
    ) {
      try {
        if (type === AudioPlayerTypeEnum.PartialDesktop) {
          this.expandToWord(selection);
        }
        const result: ISelection = {
          Type: type,
          Target: target,
          Selection: selection,
          CoordinatesAndText: this.getCoordinatesAndText(
            selection.getRangeAt(0).commonAncestorContainer,
            selection,
            target,
            type
          ),
          Window: w,
        };
        return result;
      } catch (e) {
        console.warn(e);
        return;
      }
    } else {
      return;
    }
  }

  private expandToWord(selection: Selection): Selection {
    const rng2: Range = selection.getRangeAt(0);
    let startOffset = 0;
    if (rng2.startContainer.nodeValue !== null) {
      for (let i = rng2.startOffset; i >= 0; i--) {
        if (/\S/.exec(rng2.startContainer.nodeValue[i])) {
          startOffset++;
        } else {
          break;
        }
      }
    }
    let endOffset = 0;
    if (rng2.endContainer.nodeValue !== null) {
      for (let i = rng2.endOffset; i < rng2.endContainer.nodeValue.length; i++) {
        if (/\S/.exec(rng2.endContainer.nodeValue[i])) {
          endOffset++;
        } else {
          break;
        }
      }
    }
    startOffset = rng2.startOffset - startOffset;
    startOffset = startOffset < 0 ? 0 : rng2.startContainer.nodeValue === ' ' ? 1 : startOffset;
    endOffset = rng2.endOffset + endOffset;
    if (rng2.endContainer.nodeValue?.length) {
      endOffset =
        endOffset >= rng2.endContainer.nodeValue.length
          ? rng2.endContainer.nodeValue === ' '
            ? 0
            : rng2.endContainer.nodeValue.length
          : endOffset;
    }
    rng2.setStart(rng2.startContainer, startOffset);
    rng2.setEnd(rng2.endContainer, endOffset);
    selection.removeAllRanges();
    selection.addRange(rng2);
    return selection;
  }

  currentFrameAbsolutePosition(w: Window): ICoordinates {
    let currentWindow = w;
    let currentParentWindow: Window;
    const positions: ICoordinates[] = [];
    let rect;

    while (currentWindow !== w.top) {
      currentParentWindow = currentWindow.parent;
      for (const frame of Array.from(currentParentWindow.frames))
        if (frame === currentWindow) {
          for (const frameElement of Array.from(currentParentWindow.document.getElementsByTagName('iframe'))) {
            // TODO: be sure to test this
            if (frameElement.contentWindow === currentWindow) {
              rect = frameElement.getBoundingClientRect();
              positions.push({ X: rect.x, Y: rect.y });
            }
          }
          currentWindow = currentParentWindow;
          break;
        }
    }
    return positions.reduce(
      (accumulator, currentValue): ICoordinates => {
        return {
          X: accumulator.X + currentValue.X,
          Y: accumulator.Y + currentValue.Y,
        };
      },
      { X: 0, Y: 0 }
    );
  }

  private getCoordinatesAndText(
    node: Node,
    selection: Selection,
    target: HTMLElement,
    type: AudioPlayerTypeEnum
  ): ICoordinatesAndText {
    let text: string[] = [];
    const nodes: Node[] = [];
    const nonWhitespaceMatcher = /\S/;
    const backwards = this.isSelectionBackwards(selection);
    const words: HTMLElement[] = [];
    let x = 0;
    let y = 0;
    function getTextNodes(nodeInput: Node) {
      const parent: HTMLElement | null = nodeInput.parentElement;
      if (parent) {
        let special = false;
        if (
          nodeInput.nodeValue !== null &&
          selection.containsNode(nodeInput, true) &&
          (nodeInput.nodeName.toLowerCase() === 'input' || nodeInput.nodeName.toLowerCase() === 'textarea') &&
          nonWhitespaceMatcher.test(nodeInput.nodeValue)
        ) {
          special = true;
        }
        if (
          nodeInput.nodeValue !== null &&
          !special &&
          selection.containsNode(nodeInput, true) &&
          nodeInput.nodeType === 3 &&
          !parent.classList.contains('no-select') &&
          !parent.closest('.no-select') &&
          parent.tagName !== 'SCRIPT'
        ) {
          if (!nonWhitespaceMatcher.test(nodeInput.nodeValue) && parent.classList.contains('space')) {
            if (type === AudioPlayerTypeEnum.PartialDesktop) {
              parent.classList.add('partial');
            }
          } else {
            text.push(nodeInput.nodeValue);
            if (parent.classList.contains('word')) {
              if (type === AudioPlayerTypeEnum.PartialDesktop) {
                parent.classList.add('partial');
              }
              words.push(parent);
            }
            nodes.push(nodeInput);
            if (text.length === 1) {
              if (type !== AudioPlayerTypeEnum.Full) {
                if (selection.anchorNode === selection.focusNode) {
                  if (backwards) {
                    text[0] = text[0].substring(0, selection.anchorOffset).substring(selection.focusOffset);
                  } else {
                    text[0] = text[0].substring(0, selection.focusOffset).substring(selection.anchorOffset);
                  }
                } else {
                  if (backwards) {
                    text[0] = text[0].substring(selection.focusOffset);
                  } else {
                    text[0] = text[0].substring(selection.anchorOffset);
                  }
                }
              }
            }
          }
        } else {
          for (let i = 0, len = nodeInput.childNodes.length; i < len; ++i) {
            getTextNodes(nodeInput.childNodes[i]);
          }
        }
      }
    }
    getTextNodes(node);
    if (text.length > 0 && text.length !== 1) {
      let sel = selection.toString();
      let foc: string;
      sel = sel.substring(sel.lastIndexOf(' ') + 1, sel.length);
      if (selection.focusNode) {
        if (selection.focusNode.nodeType === 3) {
          foc = selection.focusNode.toString();
        } else {
          foc = selection.focusNode.nodeValue !== null ? selection.focusNode.nodeValue : '';
        }
        foc = foc.substring(0, foc.indexOf(' ') + 1);
      }

      if (backwards) {
        text[text.length - 1] = text[text.length - 1].substring(0, selection.anchorOffset);
      }
    }
    if (target.classList.contains('no-select') && !backwards) {
      text = text.slice(0, -1);
    }

    if (words.length) {
      const selectedWord = words[0];
      if (selectedWord.ownerDocument.defaultView) {
        const coordinates = this.currentFrameAbsolutePosition(selectedWord.ownerDocument.defaultView);
        y = coordinates.Y + selectedWord.getBoundingClientRect().top;
        x = coordinates.X + selectedWord.getBoundingClientRect().left + selectedWord.getBoundingClientRect().width / 2;
      }
    }

    let textResult = text
      .join(' ')
      .replace(/(?:\r\n|\r|\n)/g, ' ')
      .replace(/ +\./g, '. ')
      .replace(/\s\s+/g, ' ')
      .replace(/ !/g, '!')
      .replace(',.', '.')
      .trim();

    return {
      X: x,
      Y: y,
      Text: textResult,
    };
  }

  private isSelectionBackwards(selection: Selection) {
    let backwards = false;
    if (selection && selection.anchorNode && selection.focusNode) {
      if (!selection.isCollapsed) {
        const range = document.createRange();
        range.setStart(selection.anchorNode, selection.anchorOffset);
        range.setEnd(selection.focusNode, selection.focusOffset);
        backwards = range.collapsed;
        range.detach();
      }
    }
    return backwards;
  }

  private getDomWords(selection: ISelection, type: AudioPlayerTypeEnum) {
    return selection.Target.getElementsByClassName(
      type === AudioPlayerTypeEnum.PartialDesktop ? 'word partial' : 'word full'
    );
  }

  highlightWord(
    marks: IAudioPlayerMark[],
    currentTime: number,
    playing: boolean,
    type: AudioPlayerTypeEnum,
    selections?: ISelection[]
  ) {
    selections?.forEach((selection, index) => {
      if (!marks.length) {
        return;
      }
      const sentences = marks.filter((i) => Number(i.Sentence.time) <= currentTime);
      const selectedMark = sentences.length ? sentences[sentences.length - 1] : marks[0];
      if (!selectedMark.Words.length) {
        return;
      }
      const words = selectedMark.Words.filter((i) => Number(i.time) <= currentTime);
      const selectedWord = words.length ? words[words.length - 1] : selectedMark.Words[0];
      if (!selectedWord) {
        return;
      }
      let domWords = this.getDomWords(selection, type);
      if (!domWords.length) {
        if (
          (selection.Target.classList.contains('word') || selection.Target.classList.contains('partial')) &&
          selection.Target.parentElement
        ) {
          domWords = selection.Target.parentElement.getElementsByClassName('word partial');
        } else {
          return;
        }
      }
      for (const element of Array.from(domWords)) {
        element.classList.remove('full-highlight', 'full-paused', 'partial-highlight', 'partial-paused');
      }
      const markIndex = marks.findIndex((i) => i === selectedMark);
      if (markIndex === -1) {
        return;
      }
      let wordIndex = 0;
      if (markIndex > 0) {
        for (let i = 0; i < markIndex; i++) {
          const mark = marks[i];
          wordIndex = wordIndex + mark.Words.length;
        }
      }
      const selectedWordIndex = selectedMark.Words.findIndex((i) => i === selectedWord);
      wordIndex = wordIndex + selectedWordIndex;

      if (index) {
        for (let i = 0; i < index; i++) {
          const otherSelection = selections[i];
          const otherDomWords = this.getDomWords(otherSelection, type);
          wordIndex = wordIndex - otherDomWords.length;
        }
      }

      const domWord = domWords[wordIndex];
      if (!domWord) {
        return;
      }
      if (type === AudioPlayerTypeEnum.Full) {
        domWords[wordIndex].classList.add(playing ? 'full-highlight' : 'full-paused');
      } else if (type === AudioPlayerTypeEnum.PartialDesktop) {
        domWords[wordIndex].classList.add(playing ? 'partial-highlight' : 'partial-paused');
      }
    });
  }

  resetSelection(element: HTMLElement, type: AudioPlayerTypeEnum) {
    const textNodes = this.textNodesUnder(element);
    textNodes.forEach((textNode) => {
      const htmlNode = textNode.parentNode as HTMLElement;
      if (htmlNode.classList.contains('word') || htmlNode.classList.contains('space')) {
        if (type === AudioPlayerTypeEnum.Full) {
          htmlNode.classList.remove('full-highlight', 'full-paused');
        } else if (type === AudioPlayerTypeEnum.PartialDesktop) {
          htmlNode.classList.remove('partial', 'partial-highlight', 'partial-paused');
        }
      }
    });
  }

  private textNodesUnder(node: Node | null): Node[] {
    let all: Node[] = [];
    if (node?.firstChild) {
      for (node = node.firstChild; node; node = node.nextSibling) {
        if (
          (node.nodeType === 1 && node.nodeName === 'BR') ||
          (node.nodeType === 3 && node.nodeValue !== '\n' && node.nodeValue !== '\n\n' && node.parentNode)
        ) {
          all.push(node);
        } else {
          all = all.concat(this.textNodesUnder(node));
        }
      }
    }
    return all;
  }

  iterate(element: HTMLElement) {
    let children = Array.from(element.childNodes);
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      if (child.nodeType === 1) {
        // html element
        this.iterate(child as HTMLElement);
      } else if (
        child.nodeType === 3 &&
        child.nodeValue &&
        child.nodeValue !== '\n' &&
        child.nodeValue !== '\n\n' &&
        child.parentNode
      ) {
        // text element
        const newNode = document.createElement('SPAN');
        if (child.nodeValue === ' ') {
          newNode.classList.add('space');
        } else {
          newNode.classList.add('temp-words');
        }
        newNode.innerHTML = child.nodeValue;
        element.replaceChild(newNode, child);
      }
    }
  }

  prepareForSelection(element: HTMLElement) {
    if (element.getElementsByClassName('word').length) {
      return;
    }

    // 29

    // element.innerHTML = element.innerHTML.replace(
    //   /(?<!(<\/?[^>]*|&[^;]*))([^\s<]+)/g,
    //   '$1<span class="word">$2</span>'
    // );

    this.iterate(element);
    const tempWords = element.getElementsByClassName('temp-words');
    for (let i = tempWords.length - 1; i >= 0; i--) {
      const item = tempWords[i];
      let html: string[] = [];
      if (item.innerHTML) {
        const words = item.innerHTML.split(' ');
        words.forEach((word, index) => {
          if (word.length) {
            if (word === '-' || word === '–' || word.startsWith('!')) {
              html.push(`<span class="space">${word}</span>`);
            } else {
              html.push(`<span class="word">${word}</span>`);
            }
          } else {
            html.push(`<span class="space"> </span>`);
          }
        });
      }
      if (item.outerHTML) {
        item.outerHTML = html.join('<span class="space"> </span>');
      }
    }

    const shouldHaveBreak = Array.from(element.querySelectorAll('.rn_pagination,h1,h2,h3,h4,h5,h6,p,li,cite,a'))
      .map((i) => {
        const words1 = i.querySelectorAll('.word');
        return words1[words1.length - 1];
      })
      .filter((element) => {
        return element !== undefined;
      });

    const words = element.getElementsByClassName('word');

    for (var i = 0; i < words.length; i++) {
      const word = words[i];
      if (!word.closest('.sentence')) {
        word.parentElement?.classList.add('sentence');
      }
      if (word.innerHTML === word.parentElement?.title) {
        shouldHaveBreak.push(word);
      }
    }

    shouldHaveBreak.forEach((word) => {
      if (!['.', '!', '?'].some((char) => word.innerHTML.endsWith(char)) && !word.classList.contains('fixed')) {
        word.classList.add('fixed');
        word.innerHTML = `${word.innerHTML}<span class="selection-break">!</span>`;
      }
    });
  }
}
