<form [formGroup]="form">
  <div class="main">
    <lru-team-picker *ngIf="(loungeModalService.page$ | async) === pageEnum.studentPicker"></lru-team-picker>
    <lru-team-details-form
      *ngIf="(loungeModalService.page$ | async) === pageEnum.detailsInput"
      [form]="form"
    ></lru-team-details-form>
  </div>
  <div class="footer">
    <lru-button
      *ngIf="(loungeModalService.page$ | async) === pageEnum.studentPicker"
      [variant]="'lounge'"
      [theme]="'primary-teacher'"
      [disabled]="(loungeService.selectedUsersList$ | async)?.length === 0"
      (click)="goToDetailsPage()"
    >
      Næste ({{ (loungeService.selectedUsersList$ | async)?.length }} valgte)
    </lru-button>
    <ng-container *ngIf="(loungeModalService.page$ | async) === pageEnum.detailsInput">
      <lru-button [variant]="'lounge'" [theme]="'white'" (click)="onClickCancel()" [hollow]="true">Annuller</lru-button>
      <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" (click)="onClickSubmit()"
        ><ng-container *ngIf="(loadingService.loading$(sharedLoadingKeys.createTeam) | async) === false; else loading"
          >Opret nyt hold</ng-container
        >
      </lru-button>
    </ng-container>
  </div>
  <lru-team-header
    [closeFunc]="onClickClose()"
    [backFunc]="(loungeModalService.page$ | async) === pageEnum.detailsInput ? onClickBack() : undefined"
    [header]="'Opret nyt hold'"
    [subHeader]="
      (loungeModalService.page$ | async) === pageEnum.studentPicker
        ? 'Vælg klasser og elever fra skolens UNI-login data her'
        : 'Vælg navn og klassetrin på dit nye hold'
    "
  ></lru-team-header>
</form>

<ng-template #loading
  ><lru-loading-animation [variant]="loadingAnimationVariants.BUTTON"></lru-loading-animation
></ng-template>
