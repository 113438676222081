import { ToastMessageInstance } from '../interfaces/toast-message-instance.interface';
import { IToastConfig } from '../toast-config.interface';

export interface ToastState {
  config: IToastConfig;
  messages: ToastMessageInstance[];
}

export const initialToastState: ToastState = {
  // The messages are stored in an array and new messages are always added to
  // the end of the array to maintain consistency when displaying them.
  messages: [],
  config: {},
};
