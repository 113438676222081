import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadingAnimationModule } from '../../components/loading-animation/loading-animation.module';
import { SearchUserPermissionWithSelectedListPipe } from '../../utils/pipes/search-user-permission-with-selected-list.pipe';
import { ButtonModule } from '../button/button.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { DropdownModule } from '../dropdown/dropdown.module';
import { IconModule } from '../icon/icon.module';
import { ModalModule } from '../modal/modal.module';
import { ToastModule } from '../toast/toast.module';
import { BoxComponent } from './components/__shared/box/box.component';
import { TeamHeaderComponent } from './components/__shared/team-header/team-header.component';
import { TeamBoxComponent } from './components/team-box/team-box.component';
import { TeamCreationComponent } from './components/team-creation/team-creation.component';
import { TeamDetailsFormComponent } from './components/team-creation/team-details-form/team-details-form.component';
import { SearchClassWithUsersPipe } from './components/team-creation/team-picker/pipes/search-class-with-users.pipe';
import { TeamPickerComponent } from './components/team-creation/team-picker/team-picker.component';
import { TeamEditAssignStudentsComponent } from './components/team-edit/team-edit-assign-students/team-edit-assign-students.component';
import { TeamEditAssignTeachersComponent } from './components/team-edit/team-edit-assign-teachers/team-edit-assign-teachers.component';
import { TeamEditDeleteComponent } from './components/team-edit/team-edit-delete/team-edit-delete.component';
import { TeamEditDetailsComponent } from './components/team-edit/team-edit-details/team-edit-details.component';
import { TeamEditDropdownComponent } from './components/team-edit/team-edit-dropdown/team-edit-dropdown.component';
import { TeamEditRemoveTeacherSelfComponent } from './components/team-edit/team-edit-remove-teacher-self/team-edit-remove-teacher-self.component';
import { TeamEditComponent } from './components/team-edit/team-edit.component';
import { TeamPickerTeacherComponent } from './components/team-edit/team-picker-teacher/team-picker-teacher.component';
import { TeamOverviewComponent } from './components/team-overview/team-overview.component';
import { LoungeConfig } from './lounge-config';
import { ILoungeConfig } from './lounge-config.interface';
import { LoungeEffects } from './store/lounge.effects';
import { loungeFeatureKey, loungeReducer } from './store/lounge.reducers';

@NgModule({
  declarations: [
    TeamOverviewComponent,
    TeamBoxComponent,
    TeamCreationComponent,
    TeamPickerComponent,
    TeamDetailsFormComponent,
    TeamEditComponent,
    BoxComponent,
    TeamEditDropdownComponent,
    TeamEditAssignStudentsComponent,
    TeamHeaderComponent,
    TeamEditDeleteComponent,
    TeamEditDetailsComponent,
    SearchClassWithUsersPipe,
    SearchUserPermissionWithSelectedListPipe,
    TeamEditRemoveTeacherSelfComponent,
    TeamEditAssignTeachersComponent,
    TeamPickerTeacherComponent,
  ],
  imports: [
    IconModule,
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    ModalModule,
    StoreModule.forFeature(loungeFeatureKey, loungeReducer),
    EffectsModule.forFeature([LoungeEffects]),
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    ToastModule,
    CheckboxModule,
    ButtonModule,
    LoadingAnimationModule,
  ],
  exports: [TeamOverviewComponent, TeamEditComponent],
})
export class LoungeModule {
  static forRoot(config?: ILoungeConfig): ModuleWithProviders<LoungeModule> {
    return {
      ngModule: LoungeModule,
      providers: [{ provide: LoungeConfig, useValue: config }],
    };
  }

  static forChild(config?: ILoungeConfig): ModuleWithProviders<LoungeModule> {
    return {
      ngModule: LoungeModule,
      providers: [{ provide: LoungeConfig, useValue: config }],
    };
  }
}
