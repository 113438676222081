import { Injectable } from '@angular/core';
import { ISiteConfiguration } from './interfaces/site-configuration.interface';

/** Service for storing the site configuration and retrieving values from the configuration */
@Injectable({ providedIn: 'root' })
export class UmbracoService {
  /** Site configuration coming from Umbraco */
  private _siteconfiguration?: ISiteConfiguration;

  /** Set the site configuration */
  setConfiguration(config: ISiteConfiguration): void {
    this._siteconfiguration = config;
  }

  /** Retrieve the {@link ISiteConfiguration.productId} from the site configuration */
  get productId(): string {
    if (this._siteconfiguration) {
      return this._siteconfiguration.productId || '';
    } else {
      return '';
    }
  }
}
