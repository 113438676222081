<ng-container *ngIf="item && settings">
  <div class="tile">
    <ng-container *ngIf="!item.IsEmpty && !item.IsInform">
      <ng-container *ngIf="!settings.PreviewTile">
        <a [routerLink]="'/' + PageTypeEnum.BookInfo + '/' + item.Slug" (click)="onTileClick()">
          <ng-container [ngTemplateOutlet]="top" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
        </a>
        <ng-container [ngTemplateOutlet]="bottom" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </ng-container>

      <ng-container *ngIf="settings.PreviewTile">
        <ng-container [ngTemplateOutlet]="top" [ngTemplateOutletContext]="{ $implicit: item }"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item.IsEmpty">
      <ng-container [ngTemplateOutlet]="top" [ngTemplateOutletContext]="{ $implicit: item }"> </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #top let-item>
  <div class="image-container">
    <div class="loading" *ngIf="item.Loading"></div>
    <img class="blur" *ngIf="item.Image" [src]="item.Image | nextImage : 400" />
    <img class="front-img" *ngIf="item.Image" [src]="item.Image | nextImage : 400" />
    <div *ngIf="!item.IsEmpty" class="flex center vertical-center">
      <i *ngIf="item.Type === SectionTypeEnum.BookMyFavorites" class="icon icon-18" [inlineSVG]="'heart.svg'"></i>
      <i *ngIf="item.Type === SectionTypeEnum.ClassReads" class="icon icon-18" [inlineSVG]="'friend.svg'"></i>
    </div>
    <ng-container *ngIf="item.Title && item.IsEmpty">
      <div class="empty">
        <div class="title">{{ item.Title }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="item.IsInform">
      <a class="inform flex grow" [routerLink]="item.Slug">
        <div class="flex column center vertical-center text-align-center space-2">
          <i class="icon icon-20" [inlineSVG]="'plus-circle.svg'"></i>
          <div class="title">{{ item.Title }}</div>
        </div>
      </a>
    </ng-container>
  </div>
  <ng-container *ngIf="!settings?.PreviewTile && !item.IsEmpty">
    <div class="reading-progress" *ngIf="showProgressbar">
      <div class="reading-process-bar" [style.width.%]="item.Progress"></div>
    </div>
    <div class="ws-10"></div>
    <label class="headline-4" *ngIf="item.Title">
      <span class="flex space-3 vertical-center">
        <span>{{ item.Title }}</span
        ><i *ngIf="item.HasAudio" class="icon icon-14 icon-audio" [inlineSVG]="'audio-book.svg'"></i>
      </span>
    </label>
    <div class="ws-10"></div>
    <ng-container *ngIf="settings?.Type !== SectionTypeEnum.ClassReads">
      <portal-book-difficulties [items]="item.Difficulties"></portal-book-difficulties>
      <ng-container *ngIf="(item.Friends && item.Friends.length) || (item.Impressions && item.Impressions.length)">
        <div class="divider"></div>
      </ng-container>
      <div *ngIf="item.Friends && item.Friends.length" class="friends-list">
        <div *ngFor="let friend of item.Friends">
          <i class="icon icon-14" [inlineSVG]="'friend.svg'"></i>{{ friend }}
        </div>
      </div>
      <div *ngIf="item.Impressions && item.Impressions.length" class="impressions-wrapper">
        <div class="impression-label">{{ 'BookTile.IThink' | translate }}</div>
        <div *ngFor="let impression of item.Impressions">
          {{ impression.Icon }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #bottom let-item>
  <div *ngIf="item && user" class="actions">
    <portal-favorite
      [isFavorite]="item?.IsFavorite"
      [subType]="settings?.Type"
      [type]="ContentTypeEnum.Books"
      [id]="item.Id"
      [title]="item.Title"
    ></portal-favorite>
    <portal-manage-book-collection
      [id]="item.Id"
      [title]="item.Title"
      [headerText]="'BookTile.AddToBookbox' | translate"
    >
    </portal-manage-book-collection>
    <div *ngIf="settings?.RemoveTileFromList">
      <button
        [matMenuTriggerFor]="booktile"
        class="btn square action-button"
        (menuOpened)="menuOpened()"
        (menuClosed)="menuClosed()"
      >
        <i class="icon icon-20" [inlineSVG]="'dots.svg'"></i>
      </button>
      <mat-menu #booktile="matMenu" xPosition="before">
        <ul>
          <li class="sub-item selectable" *ngIf="isBookFinished" (click)="onImpressionsClick()">
            {{ 'BookTile.WhatDoYouThink' | translate }}
          </li>
          <li class="sub-item selectable" *ngIf="settings?.RemoveTileFromList" (click)="removeFromList()">
            {{ 'BookTile.Remove' | translate }}
          </li>
        </ul>
      </mat-menu>
    </div>
  </div>
</ng-template>
