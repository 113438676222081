<ng-container *ngIf="loungeService.teamEdit$ | async as team; else loading">
  <div class="header">
    <lru-button [variant]="'lounge'" [theme]="'icon-button'" (click)="loungeService.teamEditCancel()">
      <lru-icon [icon]="{ path: 'felib-chevron-down', size: 16, rotate: 90 }"></lru-icon>
    </lru-button>
    <h1>{{ team.title }}</h1>
    <lru-team-edit-dropdown [team]="team"></lru-team-edit-dropdown>
  </div>
  <div class="content">
    <div class="main">
      <lru-box [theme]="'primary-student'" [hollow]="true">
        <h1 header>{{ team.studentCount }} elever</h1>
        <ul content>
          <ng-container *ngIf="loadingService.loaded$(sharedLoadingKeys.teamStudents) | async; else studentsLoading"
            ><ng-container *ngIf="team.students.length > 0; else noStudents"
              ><li *ngFor="let student of team.students">
                {{ student.firstName }} {{ student.lastName }}
              </li></ng-container
            ></ng-container
          >
        </ul>
      </lru-box>
    </div>
    <div class="aside">
      <lru-box [theme]="'primary-student'" [hollow]="true">
        <h1 header>Delt med:</h1>
        <ul content>
          <li *ngFor="let teacher of team.teachers">{{ teacher.user.firstName }} {{ teacher.user.lastName }}</li>
        </ul>
      </lru-box>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <lru-loading-animation *ngIf="loadingService.loading$(sharedLoadingKeys.editTeam) | async"></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.editTeam) | async as error">{{ error }}</p>
</ng-template>

<ng-template #studentsLoading>
  <lru-loading-animation
    *ngIf="loadingService.loading$(sharedLoadingKeys.teamStudents) | async"
  ></lru-loading-animation>
  <p *ngIf="loadingService.error$(sharedLoadingKeys.teamStudents) | async as error">{{ error }}</p>
</ng-template>

<ng-template #noStudents>
  <p>Ingen elever tildelt holdet</p>
</ng-template>
