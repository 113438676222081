import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { loadingFeatureKey } from './loading.reducers';
import { LoadingState, LoadStateEnum } from './loading.state';

const selectLoadingState = createFeatureSelector<LoadingState>(loadingFeatureKey);

const selectLoadState = (
  loadingKey: string
): MemoizedSelector<object, LoadStateEnum | undefined, DefaultProjectorFn<LoadStateEnum | undefined>> =>
  createSelector(selectLoadingState, (state) => {
    const callState = state.callStates.find((x) => x.loadingKey === loadingKey)?.callState;

    if (!callState || (callState as Error).message !== undefined) {
      return undefined;
    }

    return callState as LoadStateEnum;
  });

export const selectInit = (loadingKey: string): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectLoadState(loadingKey), (state) => state === LoadStateEnum.INIT);

export const selectLoading = (loadingKey: string): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectLoadState(loadingKey), (state) => state === LoadStateEnum.LOADING);

export const selectLoaded = (loadingKey: string): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(selectLoadState(loadingKey), (state) => state === LoadStateEnum.LOADED);

export const selectError = (
  loadingKey: string
): MemoizedSelector<object, string | undefined, DefaultProjectorFn<string | undefined>> =>
  createSelector(selectLoadingState, (state) => {
    const callState = state.callStates.find((x) => x.loadingKey === loadingKey)?.callState;

    if ((callState as Error)?.message !== undefined) {
      return (callState as Error).message;
    }

    return undefined;
  });
