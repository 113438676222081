import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs';
import { LoadingAnimationVariant } from '../../../../components/loading-animation/enum/loading-animation-variant.enum';
import { LoadingService } from '../../../../services/loading/loading.service';
import { SharedLoadingKeys } from '../../../../services/loading/shared-loading-keys.enums';
import { LoungeModalPages } from '../../enums/lounge-modal-pages.enum';
import { LoungeModalService } from '../../services/lounge-modal.service';
import { LoungeService } from '../../services/lounge.service';
import { TeamCreationService } from './services/team-creation.service';

/** A component for team creation */
@Component({
  selector: 'lru-team-creation',
  templateUrl: './team-creation.component.html',
  styleUrls: ['./team-creation.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamCreationComponent implements OnDestroy {
  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** @see {@link LoadingAnimationVariant} */
  loadingAnimationVariants = LoadingAnimationVariant;

  /** @see {@link LoungeModalPages} */
  pageEnum = LoungeModalPages;

  /** FormControl used for handling title of team */
  title = new FormControl('', {
    validators: [Validators.required, Validators.minLength(2), Validators.maxLength(25)],
    updateOn: 'blur',
  });

  /** FormControl used for handling level of team */
  level = new FormControl('', {
    validators: [Validators.required],
    updateOn: 'blur',
  });

  /** FormGroup consisting of {@link title} and {@link level}. Used for validating and handling values. */
  form: FormGroup = new FormGroup({
    title: this.title,
    level: this.level,
  });

  /** Page subscription that resets the forms when the modal closes */
  private pageSubscription = this.loungeModalService.page$
    .pipe(tap((page) => (page === LoungeModalPages.none ? this.resetForm() : null)))
    .subscribe();

  /** @ignore */
  constructor(
    public teamCreationService: TeamCreationService,
    public loungeService: LoungeService,
    public loadingService: LoadingService,
    public loungeModalService: LoungeModalService
  ) {}

  /** Method that asks {@link LoungeModalService} to set page to none and also calls {@link resetForm} */
  onClickCancel(): void {
    this.loungeModalService.setPageToNone();
    this.resetForm();
  }

  /** Function that asks {@link LoungeModalService} to set modal page to none.
   * @returns A function that is used for inputting to the header and called from there. */
  onClickClose(): Function {
    return () => this.loungeModalService.setPageToNone();
  }

  /** Function that changes {@link step} to 'studentPicker'
   * @returns A function that is used for inputting to the header and called from there. */
  onClickBack(): Function {
    return () => this.loungeModalService.setPage(LoungeModalPages.studentPicker);
  }

  /** Changes {@link step} to 'detailsInput' */
  goToDetailsPage(): void {
    this.loungeModalService.setPage(LoungeModalPages.detailsInput);
  }

  /** Checks validity of forms and asks {@TeamCreationService} to create the team. If forms aren't valid mark forms as touched and therefore show error messages. */
  async onClickSubmit(): Promise<void> {
    if (this.form.valid && this.title.value) {
      const levelIds = this.level.value ? [this.level.value] : undefined;

      await this.teamCreationService.createTeam(this.title.value, levelIds);
    } else {
      this.form.markAllAsTouched();
    }
  }

  /** Unsubscribes from subscriptions */
  ngOnDestroy(): void {
    this.pageSubscription?.unsubscribe();
  }

  /** Reset all form controls and form groups. Method is needed since component is not destroyed when modal is closed. */
  private resetForm(): void {
    this.title.setValue('');
    this.level.setValue('');
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }
}
