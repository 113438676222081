<div>
  <h2>Holdnavn</h2>
  <div [formGroup]="form" class="form-control">
    <input formControlName="title" type="text" placeholder="Holdnavn" />
    <div *ngIf="title && title.invalid && title.errors && (title.dirty || title.touched)" class="error">
      <ng-container *ngIf="title.errors.required"><span>Holdnavn</span> er påkrævet.</ng-container>
      <ng-container *ngIf="title.errors.minlength"
        ><span>Holdnavn</span> skal mindst være 2 karakterer lang.</ng-container
      >
      <ng-container *ngIf="title.errors.maxlength"
        ><span>Holdnavn</span> kan maksimalt være 25 karakterer lang.</ng-container
      >
    </div>
  </div>
</div>
<div>
  <h2>Klassetrin</h2>
  <ng-container *ngIf="(loungeService.fullLevel$ | async)?.levels as levels">
    <lru-dropdown [variant]="'lounge'" [sameWidth]="true" [spacingFromWindow]="10" #dropdownLevel>
      <ng-container button>
        <lru-button [variant]="'lounge'" [theme]="'white'">
          <span>{{ this.selectedLevel?.text ? this.selectedLevel?.text : 'Vælg klassetrin' }}</span>
          <lru-icon [icon]="{ path: 'felib-chevron-down', size: 24 }" class="arrow"></lru-icon>
        </lru-button>
      </ng-container>
      <ng-container>
        <ul>
          <li *ngFor="let item of levels">
            <lru-button
              [variant]="'lounge'"
              [theme]="'white'"
              (click)="onClickLevel(item); dropdownLevel.onCloseClick()"
              [class.active]="item.id === selectedLevel?.id"
            >
              <span>{{ item.mediumForm }}</span>
            </lru-button>
          </li>
        </ul>
      </ng-container>
    </lru-dropdown>
    <div
      *ngIf="level && level.invalid && level.errors && (level.dirty || level.touched) && level.errors.required"
      class="error"
    >
      <span>Klassetrin</span> er påkrævet.
    </div>
  </ng-container>
</div>
