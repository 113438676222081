<div class="flex space-2">
  <div class="card">
    <div>
      <div *ngIf="!item.Asset" class="card-background"></div>
      <div
        *ngIf="item.Asset"
        class="card-background overlay"
        [lazyLoad]="item.Asset | nextImage : 100 : 140 : 'crop'"
      ></div>
      <div class="card-content fill flex vertical-bottom right">
        <i *ngIf="item.IsLocked" class="icon icon-18" [inlineSVG]="'lock.svg'"></i>
      </div>
    </div>
  </div>
  <div class="content">
    <label class="font-size-12" *ngIf="item.Levels">{{ item.Levels }}</label>
    <label class="headline-5 title">{{ item.Title }}</label>
    <div class="ws-5"></div>
    <div class="flex space-1 wrap">
      <label *ngIf="item.StatusLabel && item.IsActive" class="badge">{{ item.StatusLabel }}</label>
    </div>
    <ng-container *ngIf="item.Subjects">
      <div class="ws-15"></div>
      <div class="font-size-14 font-weight-bold color-grey-50">
        {{ 'ContentType.Subject' | translate }}
      </div>
      <div *ngFor="let subject of item.Subjects" class="font-size-14">
        {{ subject }}
      </div>
    </ng-container>
  </div>
</div>
