import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TeamWithTeachersDto } from '../../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { LoungeService } from '../../services/lounge.service';

/** Component for showing a team with few details */
@Component({
  selector: 'lru-team-box[model]',
  templateUrl: './team-box.component.html',
  styleUrls: ['./team-box.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamBoxComponent {
  /** A team with teachers used for displaying */
  @Input() model!: TeamWithTeachersDto;

  /** @ignore */
  constructor(private loungeService: LoungeService) {}

  /** Tells {@link LoungeService} that we want to navigate to edit a specific team */
  onClickEdit(team: TeamWithTeachersDto): void {
    this.loungeService.teamEditNavigate(team);
  }
}
