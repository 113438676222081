import { NgModule } from '@angular/core';
import { AssociatedAppPipe } from '@portal-app/pipes/associated-app.pipe';
import { BackgroundImagePipe } from '@portal-app/pipes/background-image.pipe';
import { NextImagePipe } from '@portal-app/pipes/next-image.pipe';
import { TruncatePipe } from '@portal-app/pipes/truncate.pipe';
import { UmbracoImageLowResolutionPipe } from '@portal-app/pipes/umbraco-image-low-resolution.pipe';
import { UmbracoImagePipe } from '@portal-app/pipes/umbraco-image.pipe';
import { DurationPipe } from '../duration.pipe';
import { LevelPipe } from '../level.pipe';
import { NextImage2Pipe } from '../next-image-2.pipe';
import { SafePipe } from '../safe.pipe';
import { UmbracoAlignPipe } from '../umbraco-align.pipe';
import { UmbracoWidthPipe } from '../umbraco-width.pipe';

@NgModule({
  declarations: [
    TruncatePipe,
    UmbracoImagePipe,
    UmbracoWidthPipe,
    UmbracoAlignPipe,
    BackgroundImagePipe,
    NextImagePipe,
    NextImage2Pipe,
    DurationPipe,
    LevelPipe,
    UmbracoImageLowResolutionPipe,
    AssociatedAppPipe,
    SafePipe,
  ],
  exports: [
    TruncatePipe,
    NextImagePipe,
    NextImage2Pipe,
    DurationPipe,
    LevelPipe,
    UmbracoImagePipe,
    UmbracoWidthPipe,
    UmbracoAlignPipe,
    UmbracoImageLowResolutionPipe,
    BackgroundImagePipe,
    AssociatedAppPipe,
    SafePipe,
  ],
})
export class SharedPipeModule {}
