import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';

import { GoogleTagManagerService } from '@lru/felib';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { LayoutAudioService } from '@portal-app/services/shared/layout-audio.service';
import { PlyrEvent } from 'plyr';
import { HlsjsPlyrDriver } from './hlsjs-plyr-driver';

@Component({
  selector: 'portal-audio-hls-player[src]',
  templateUrl: './audio-hls-player.component.html',
  styleUrls: ['./audio-hls-player.component.scss'],
})
export class AudioHlsPlayerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() src!: string;
  @Input() showSecondsControl = false;
  @Input() autoPlay = false;

  player?: Plyr;
  source?: Plyr.Source;
  hlsDriver = new HlsjsPlyrDriver(true);
  useHls = false;
  options: Plyr.Options = {
    autoplay: this.autoPlay,
    invertTime: false,
    disableContextMenu: true,
    settings: ['quality', 'speed', 'loop'],
    controls: ['play', 'progress', 'current-time', 'duration'],
    captions: { active: false, update: true, language: 'en' },
  };

  constructor(
    public layoutAudioService: LayoutAudioService,
    private helperService: HelperService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    if (window.device.desktop() && !this.helperService.isSafari()) {
      this.useHls = true;
    }
  }

  ngAfterViewInit() {
    if (this.useHls && this.hlsDriver) {
      this.hlsDriver.load(this.src);
    }
  }

  ngOnChanges() {
    if (this.src) {
      this.source = {
        src: this.src,
        type: 'application/x-mpegURL',
      };
    } else {
      this.source = undefined;
    }
  }

  async ngOnDestroy() {
    if (this.player) {
      await this.layoutAudioService.removePlayer(this.player, this.src);
    }
  }

  plyrInit(event: Plyr) {
    this.player = event;
    this.layoutAudioService.addPlayer(this.player, this.src);
  }

  async plyrPlay(event: PlyrEvent) {
    await this.layoutAudioService.pauseOtherPlayers(this.src);
    this.gtmService.pushTagWithUserType(GTMEvent.Play_recorded);
    this.layoutAudioService.handlePlyrEvents(event);
  }

  plyrPause(event: PlyrEvent) {
    if (event.detail.plyr.duration !== 0 && event.detail.plyr.duration !== event.detail.plyr.currentTime) {
      this.gtmService.pushTagWithUserType(GTMEvent.Pause_recorded);
    } else {
      this.gtmService.pushTagWithUserType(GTMEvent.Ended_recorded);
    }
    this.layoutAudioService.handlePlyrEvents(event);
  }

  plyrSeeked(event: PlyrEvent) {
    this.gtmService.pushDebouncedTagWithUserType(GTMEvent.Seeked_recorded);
  }

  onClickRewindSeconds() {
    if (this.player) {
      this.player.currentTime = this.player.currentTime - 10;
      this.gtmService.pushTagWithUserType(GTMEvent.Backwards_recorded);
    }
  }

  onClickForwardSeconds() {
    if (this.player) {
      this.player.currentTime = this.player.currentTime + 10;
      this.gtmService.pushTagWithUserType(GTMEvent.Forward_recorded);
    }
  }
}
