import { IDimensionRatio } from '@portal-app/interfaces/project/dimension.interface';

export const DimensionsEnum: IDimensionRatio[] = [
  {
    Ratio: 0.75,
    Dimensions: [
      {
        Width: 3840,
        Height: 2880,
      },
      {
        Width: 1920,
        Height: 1440,
      },
      {
        Width: 960,
        Height: 720,
      },
      {
        Width: 480,
        Height: 360,
      },
    ],
  },
  {
    Ratio: 1,
    Dimensions: [
      {
        Width: 600,
        Height: 600,
      },
      {
        Width: 300,
        Height: 300,
      },
    ],
  },
  {
    Ratio: 0.5625,
    Dimensions: [
      {
        Width: 3840,
        Height: 2160,
      },
      {
        Width: 1920,
        Height: 1080,
      },
      {
        Width: 960,
        Height: 540,
      },
      {
        Width: 480,
        Height: 270,
      },
    ],
  },
  {
    Ratio: 1.5,
    Dimensions: [
      {
        Width: 1920,
        Height: 2880,
      },
      {
        Width: 960,
        Height: 1440,
      },

      {
        Width: 480,
        Height: 720,
      },
      {
        Width: 240,
        Height: 360,
      },
    ],
  },
];
