<div class="content">
  <label class="students-count">{{ model.studentCount }} elever</label>
  <h2 class="title">{{ model.title }}</h2>
  <div class="hr"></div>
  <label class="shared-with-title">Delt med:</label>
  <ul>
    <li *ngFor="let teachers of model.teachers">{{ teachers.user.firstName }} {{ teachers.user.lastName }}</li>
  </ul>
</div>
<div class="buttons">
  <lru-button [variant]="'lounge'" [theme]="'primary-teacher'" (click)="onClickEdit(model)">Rediger</lru-button>
</div>
