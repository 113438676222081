import { Injectable } from '@angular/core';
import { ApiService } from '@lru/felib';
import { IUmbFavoritePayload, IUmbFavoriteResponse } from '@portal-app/interfaces/umbraco/umb-favorite.interface';

@Injectable({
  providedIn: 'root',
})
export class PsFavoriteService {
  constructor(private apiService: ApiService) {}

  addFavorite(id: string) {
    const body: IUmbFavoritePayload = {
      contentId: id,
      isFavorite: true,
    };
    return this.apiService.postUmbraco<IUmbFavoriteResponse>('psportal/content/favourite/save', body);
  }

  deleteFavorite(contentId: string) {
    return this.apiService.deleteUmbraco<IUmbFavoriteResponse>('psportal/content/favourite/remove/' + contentId);
  }
}
