import { HttpClient, HttpContext, HttpContextToken, HttpParams } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiServiceConfig } from './config/api-service-config';

/** Service that supplies access to the {@link HttpClient} but handles basic stuff as prefixing right baseURL and adding credentials. */
@Injectable({ providedIn: 'root' })
export class ApiService {
  /** The URL for the internal backend */
  private _internalBackendUrl: string;
  /** The URL for Umbraco API */
  private _apiUmbracoBaseUrl: string;
  /** The URL for Next API */
  private _apiNextBaseUrl: string;
  /** The URL for NextSearch API */
  private _apiNextSearchBaseUrl: string;
  /** The URL for Lounge API */
  private _apiLoungeBaseUrl: string;
  /** The URL for Matematikfessor gateway */
  private _apiBFFGatewayBaseUrl: string;

  /** Handles normal DependencyInjection but also for {@link ApiServiceConfig}.
   * If no config is supplied in the providers field in the module we use the default config.  */
  constructor(private httpClient: HttpClient, @Optional() config?: ApiServiceConfig) {
    const defaultConfig = new ApiServiceConfig();

    this._apiLoungeBaseUrl = config?.apiLoungeBaseUrl || defaultConfig.apiLoungeBaseUrl;
    this._apiNextBaseUrl = config?.apiNextBaseUrl || defaultConfig.apiNextBaseUrl;
    this._apiBFFGatewayBaseUrl = config?.apiBFFGatewayBaseUrl || defaultConfig.apiBFFGatewayBaseUrl;
    this._apiNextSearchBaseUrl = config?.apiNextSearchBaseUrl || defaultConfig.apiNextSearchBaseUrl;
    this._apiUmbracoBaseUrl = config?.apiUmbracoBaseUrl || defaultConfig.apiUmbracoBaseUrl;
    this._internalBackendUrl =
      config?.internalBackendUrl || defaultConfig.internalBackendUrlFallBack(config?.loginUsingMvcDotNet);
  }

  /** Used for sending a GET request to Lounge
   * @param url the url to append to the {@link _apiLoungeBaseUrl} coming from the {@link ApiServiceConfig}
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  public getLounge<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this._apiLoungeBaseUrl, url, httpParams);
  }

  /** Used for sending a GET request to Next
   * @param url the url to append to the {@link _apiNextBaseUrl} coming from the {@link ApiServiceConfig}
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  public getNext<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this._apiNextBaseUrl, url, httpParams);
  }

  /** Used for sending a GET request to NextSearch
   * @param url the url to append to the {@link _apiNextSearchBaseUrl} coming from the {@link ApiServiceConfig}
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  public getNextSearch<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this._apiNextSearchBaseUrl, url, httpParams);
  }

  /** Used for sending a GET request to Umbraco
   * @param url the url to append to the {@link _apiUmbracoBaseUrl} coming from the {@link ApiServiceConfig}
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  public getUmbraco<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this._apiUmbracoBaseUrl, url, httpParams);
  }

  /** Used for sending a GET request to the InternalBackend
   * @param url the url to append to the {@link _internalBackendUrl} coming from the {@link ApiServiceConfig}
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  public getInternalBackend<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.get<T>(this._internalBackendUrl, url, httpParams);
  }

  /** Used for sending a GET request to the InternalBackend with the header withCredentials set as true
   * @param url the url to append to the {@link _internalBackendUrl} coming from the {@link ApiServiceConfig}
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  public getInternalBackendWithCredentials<T>(url: string, httpParams?: HttpParams): Observable<T> {
    return this.httpClient.get<T>(this._internalBackendUrl + url, {
      params: httpParams,
      withCredentials: true,
    });
  }

  /** Used for sending a POST request to Matematikfessor BFF gateway
   * @param url the url to append to the {@link _apiBFFGatewayBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  public postBFFGateway<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this._apiBFFGatewayBaseUrl, url, body, wwwForm);
  }

  /** Used for sending a POST request to Lounge
   * @param url the url to append to the {@link _apiLoungeBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  public postLounge<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this._apiLoungeBaseUrl, url, body, wwwForm);
  }

  /** Used for sending a POST request to Next
   * @param url the url to append to the {@link _apiNextBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  public postNext<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this._apiNextBaseUrl, url, body, wwwForm);
  }

  /** Used for sending a POST request to NextSearch
   * @param url the url to append to the {@link _apiNextSearchBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  public postNextSearch<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this._apiNextSearchBaseUrl, url, body, wwwForm);
  }

  /** Used for sending a POST request to Umbraco
   * @param url the url to append to the {@link _apiUmbracoBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  public postUmbraco<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this._apiUmbracoBaseUrl, url, body, wwwForm);
  }

  /** Used for sending a POST request to the InternalBackend
   * @param url the url to append to the {@link _internalBackendUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  public postInternalBackend<T>(url: string, body?: any, wwwForm?: boolean): Observable<T> {
    return this.post<T>(this._internalBackendUrl, url, body, wwwForm);
  }

  /** Used for sending a PUT request to Lounge
   * @param url the url to append to the {@link _apiLoungeBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PUT request in the form of the ContentType
   * @returns The response from the PUT request */
  public putLounge<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this._apiLoungeBaseUrl, url, body);
  }

  /** Used for sending a PUT request to Next
   * @param url the url to append to the {@link _apiNextBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PUT request in the form of the ContentType
   * @returns The response from the PUT request */
  public putNext<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this._apiNextBaseUrl, url, body);
  }

  /** Used for sending a PUT request to NextSearch
   * @param url the url to append to the {@link _apiNextSearchBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PUT request in the form of the ContentType
   * @returns The response from the PUT request */
  public putNextSearch<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this._apiNextSearchBaseUrl, url, body);
  }

  /** Used for sending a PUT request to Umbraco
   * @param url the url to append to the {@link _apiUmbracoBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PUT request in the form of the ContentType
   * @returns The response from the PUT request */
  public putUmbraco<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this._apiUmbracoBaseUrl, url, body);
  }

  /** Used for sending a PUT request to the InternalBackend
   * @param url the url to append to the {@link _internalBackendUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PUT request in the form of the ContentType
   * @returns The response from the PUT request */
  public putInternalBackend<T>(url: string, body?: any): Observable<T> {
    return this.put<T>(this._internalBackendUrl, url, body);
  }

  /** Used for sending a PATCH request to Lounge
   * @param url the url to append to the {@link _apiLoungeBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PATCH request in the form of the ContentType
   * @returns The response from the PATCH request */
  public patchLounge<T>(url: string, body?: any): Observable<T> {
    return this.patch<T>(this._apiLoungeBaseUrl, url, body);
  }

  /** Used for sending a PATCH request to Next
   * @param url the url to append to the {@link _apiNextBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PATCH request in the form of the ContentType
   * @returns The response from the PATCH request */
  public patchNext<T>(url: string, body?: any): Observable<T> {
    return this.patch<T>(this._apiNextBaseUrl, url, body);
  }

  /** Used for sending a PATCH request to NextSearch
   * @param url the url to append to the {@link _apiNextSearchBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PATCH request in the form of the ContentType
   * @returns The response from the PATCH request */
  public patchNextSearch<T>(url: string, body?: any): Observable<T> {
    return this.patch<T>(this._apiNextSearchBaseUrl, url, body);
  }

  /** Used for sending a PATCH request to Umbraco
   * @param url the url to append to the {@link _apiUmbracoBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PATCH request in the form of the ContentType
   * @returns The response from the PATCH request */
  public patchUmbraco<T>(url: string, body?: any): Observable<T> {
    return this.patch<T>(this._apiUmbracoBaseUrl, url, body);
  }

  /** Used for sending a PATCH request to the InternalBackend
   * @param url the url to append to the {@link _internalBackendUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the PATCH request in the form of the ContentType
   * @returns The response from the PATCH request */
  public patchInternalBackend<T>(url: string, body?: any): Observable<T> {
    return this.patch<T>(this._internalBackendUrl, url, body);
  }

  /** Used for sending a DELETE request to Lounge
   * @param url the url to append to the {@link _apiLoungeBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the DELETE request in the form of the ContentType
   * @returns The response from the DELETE request */
  public deleteLounge<T>(url: string, body?: any): Observable<T> {
    return this.delete<T>(this._apiLoungeBaseUrl, url, body);
  }

  /** Used for sending a DELETE request to Next
   * @param url the url to append to the {@link _apiNextBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the DELETE request in the form of the ContentType
   * @returns The response from the DELETE request */
  public deleteNext<T>(url: string, body?: any): Observable<T> {
    return this.delete<T>(this._apiNextBaseUrl, url, body);
  }

  /** Used for sending a DELETE request to NextSearch
   * @param url the url to append to the {@link _apiNextSearchBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the DELETE request in the form of the ContentType
   * @returns The response from the DELETE request */
  public deleteNextSearch<T>(url: string, body?: any): Observable<T> {
    return this.delete<T>(this._apiNextSearchBaseUrl, url, body);
  }

  /** Used for sending a DELETE request to Umbraco
   * @param url the url to append to the {@link _apiUmbracoBaseUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the DELETE request in the form of the ContentType
   * @returns The response from the DELETE request */
  public deleteUmbraco<T>(url: string, body?: any): Observable<T> {
    return this.delete<T>(this._apiUmbracoBaseUrl, url, body);
  }

  /** Used for sending a DELETE request to the InternalBackend
   * @param url the url to append to the {@link _internalBackendUrl} coming from the {@link ApiServiceConfig}
   * @param body the data to attach to the DELETE request in the form of the ContentType
   * @returns The response from the DELETE request */
  public deleteInternalBackend<T>(url: string, body?: any): Observable<T> {
    return this.delete<T>(this._internalBackendUrl, url, body);
  }

  /** Used for sending POST requests
   * @param apiBaseUrl The url for the specific API you want to send the request to
   * @param url The appended url for the specific REST controller you want to send the request to
   * @param body the data to attach to the POST request in the form of the ContentType
   * @param wwwForm Whether to set ContentType to wwwForm or keep it as JSON
   * @returns The response from the POST request */
  private post<T>(apiBaseUrl: string, url: string, body?: any, wwwForm?: boolean): Observable<T> {
    const context = new HttpContext();

    context.set(
      new HttpContextToken(() => 'test'),
      wwwForm ? 'application/x-www-form-urlencoded; charset=utf-8' : 'application/json; charset=utf-8'
    );

    return this.httpClient.post<T>(apiBaseUrl + url, body, {
      context: context,
    });
  }

  /** Used for sending GET requests
   * @param apiBaseUrl The url for the specific API you want to send the request to
   * @param url The appended url for the specific REST controller you want to send the request to
   * @param httpParams can be used for attaching {@link HttpParams} to the request
   * @returns The response from the GET request */
  private get<T>(apiBaseUrl: string, url: string, httpParams?: HttpParams): Observable<T> {
    return this.httpClient.get<T>(apiBaseUrl + url, {
      params: httpParams,
    });
  }

  /** Used for sending PUT requests
   * @param apiBaseUrl The url for the specific API you want to send the request to
   * @param url The appended url for the specific REST controller you want to send the request to
   * @param body the data to attach to the POST request in the form of the ContentType
   * @returns The response from the PUT request */
  private put<T>(apiBaseUrl: string, url: string, body?: any): Observable<T> {
    return this.httpClient.put<T>(apiBaseUrl + url, body);
  }

  /** Used for sending DELETE requests
   * @param apiBaseUrl The url for the specific API you want to send the request to
   * @param url The appended url for the specific REST controller you want to send the request to
   * @param body the data to attach to the DELETE request in the form of the ContentType
   * @returns The response from the DELETE request */
  private delete<T>(apiBaseUrl: string, url: string, body?: any): Observable<T> {
    const httpOptions = {
      body: body,
    };

    return this.httpClient.delete<T>(apiBaseUrl + url, httpOptions);
  }

  /** Used for sending PATCH requests
   * @param apiBaseUrl The url for the specific API you want to send the request to
   * @param url The appended url for the specific REST controller you want to send the request to
   * @param body the data to attach to the PATCH request in the form of the ContentType
   * @returns The response from the PATCH request */
  private patch<T>(apiBaseUrl: string, url: string, body?: any): Observable<T> {
    return this.httpClient.patch<T>(apiBaseUrl + url, body);
  }
}
