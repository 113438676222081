import { createAction, props } from '@ngrx/store';
import { ErrorWithLoadingKey } from '../../../services/loading/store/loading.actions';
import { FullLevelDto } from '../interfaces/endpoint/dtos/dto-level.interface';
import { PermissionDto } from '../interfaces/endpoint/dtos/dto-permission.interface';
import { StudentDto } from '../interfaces/endpoint/dtos/dto-student.interface';
import { TeamWithTeachersDto } from '../interfaces/endpoint/dtos/dto-team-with-teachers.interface';
import { AddTeacher, ILoungePostBodyTeam_CreateTeam } from '../interfaces/endpoint/team.interface';
import { IClassWithUsers, IUserPermissionWithSelected } from './interfaces/team-edit.interface';

const modulePrefix = '[LoungeModule]';

//#region LEVEL ########################################################

export const levelLoadLevels = createAction(`${modulePrefix} levels/load`, props<{ loadingKey: string }>());

export const levelLoadLevelsSuccess = createAction(
  `${modulePrefix} levels/load success`,
  props<{ loadingKey: string; fullLevel: FullLevelDto }>()
);

export const levelLoadLevelsFailure = createAction(`${modulePrefix} levels/load failure`, props<ErrorWithLoadingKey>());

//#endregion LEVEL ########################################################

export const markTeacherForSelection = createAction(
  `${modulePrefix} mark teacher for selection`,
  props<{ teacherId: string; selected: boolean }>()
);

export const markTeacherForSelectionSuccess = createAction(
  `${modulePrefix} mark teacher for selection/success`,
  props<{ teacherId: string; selected: boolean; permission: PermissionDto }>()
);

export const markClassForSelection = createAction(
  `${modulePrefix} mark class for selection`,
  props<{ classId: string; selected: boolean; searchTerm: string }>()
);

export const markClassForSelectionSuccess = createAction(
  `${modulePrefix} mark class for selection/success`,
  props<{ classId: string; selected: boolean; searchTerm: string }>()
);

export const markStudentForSelection = createAction(
  `${modulePrefix} mark student for selection`,
  props<{ classId: string; userId: string; selected: boolean }>()
);

export const markStudentForSelectionSuccess = createAction(
  `${modulePrefix} mark student for selection/success`,
  props<{ classId: string; userId: string; selected: boolean }>()
);

export const removeSelectionsFromStudents = createAction(`${modulePrefix} remove selections for students`);

export const removeSelectionsFromStudentsSuccess = createAction(
  `${modulePrefix} remove selections for students/success`
);

export const removeSelectionsFromTeachers = createAction(`${modulePrefix} remove selections for teachers`);

export const removeSelectionsFromTeachersSuccess = createAction(
  `${modulePrefix} remove selections for teachers/success`
);

export const dlpLoadTeachers = createAction(`${modulePrefix} teachers/load`, props<{ loadingKey: string }>());

export const dlpLoadTeachersSuccess = createAction(
  `${modulePrefix} teachers/load success`,
  props<{ teachers: IUserPermissionWithSelected[] }>()
);

export const dlpLoadTeachersFailure = createAction(
  `${modulePrefix} teachers/load failure`,
  props<ErrorWithLoadingKey>()
);

export const dlpLoadClassesWithStudents = createAction(
  `${modulePrefix} classes with students/load`,
  props<{ loadingKey: string }>()
);

export const dlpLoadClassesWithStudentsSuccess = createAction(
  `${modulePrefix} classes with students/load success`,
  props<{ loadingKey: string; classes: IClassWithUsers[] }>()
);

export const dlpLoadClassesWithStudentsFailure = createAction(
  `${modulePrefix} classes with students/load failure`,
  props<ErrorWithLoadingKey>()
);

export const teamLoadTeams = createAction(`${modulePrefix} teams/load`, props<{ loadingKey: string }>());

export const teamLoadTeamsSuccess = createAction(
  `${modulePrefix} teams/load success`,
  props<{ loadingKey: string; teamList: TeamWithTeachersDto[] }>()
);

export const teamLoadTeamsFailure = createAction(`${modulePrefix} teams/load failure`, props<ErrorWithLoadingKey>());

export const teamCreateTeam = createAction(
  `${modulePrefix} team/create`,
  props<{ loadingKey: string; team: ILoungePostBodyTeam_CreateTeam }>()
);

export const teamCreateTeamSuccess = createAction(
  `${modulePrefix} team/create success`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamCreateTeamFailure = createAction(`${modulePrefix} team/create failure`, props<ErrorWithLoadingKey>());

export const teamEditTeam = createAction(
  `${modulePrefix} team/edit`,
  props<{ loadingKey: string; team: TeamWithTeachersDto }>()
);

export const teamEditTeamCancel = createAction(`${modulePrefix} team/edit cancel`);

export const teamEditAssignTeachers = createAction(
  `${modulePrefix} team/edit assign teachers`,
  props<{ loadingKey: string }>()
);

export const teamEditAssignTeachersSuccess = createAction(
  `${modulePrefix} team/edit assign teachers success`,
  props<{ loadingKey: string; teachers: IUserPermissionWithSelected[] }>()
);

export const teamEditAssignStudents = createAction(`${modulePrefix} team/edit assign students`);

export const teamEditAssignStudentsSuccess = createAction(
  `${modulePrefix} team/edit assign students success`,
  props<{ classWithSelectedUsersList: IClassWithUsers[] }>()
);

export const teamLoadStudents = createAction(
  `${modulePrefix} team/load students`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamLoadStudentsSuccess = createAction(
  `${modulePrefix} team/load students success`,
  props<{ loadingKey: string; studentList: StudentDto[] }>()
);

export const teamLoadStudentsFailure = createAction(
  `${modulePrefix} team/load students failure`,
  props<ErrorWithLoadingKey>()
);

export const teamSaveStudentsToTeam = createAction(
  `${modulePrefix} team/save students`,
  props<{ loadingKey: string; teamId: string; studentIds: string[] }>()
);

export const teamSaveStudentsToTeamSuccess = createAction(
  `${modulePrefix} team/save students success`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamSaveStudentsToTeamFailure = createAction(
  `${modulePrefix} team/save students failure`,
  props<ErrorWithLoadingKey>()
);

export const teamDeleteModal = createAction(`${modulePrefix} team/delete modal`);

export const teamDeleteTeam = createAction(
  `${modulePrefix} team/delete`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamDeleteTeamSuccess = createAction(`${modulePrefix} team/delete success`, props<{ teamId: string }>());

export const teamDeleteTeamFailure = createAction(`${modulePrefix} team/delete failure`, props<ErrorWithLoadingKey>());

export const teamEditDetailsModal = createAction(`${modulePrefix} team/edit details modal`);

export const teamEditDetails = createAction(`${modulePrefix} team/edit details`);

export const teamEditDetailsSuccess = createAction(`${modulePrefix} team/edit details success`);

export const teamEditDetailsFailure = createAction(
  `${modulePrefix} team/edit details failure`,
  props<ErrorWithLoadingKey>()
);

export const teamSaveDetailsToTeam = createAction(
  `${modulePrefix} team/edit details save`,
  props<{ loadingKey: string; teamId: string; title: string; levelIds: string[] }>()
);

export const teamSaveDetailsToTeamSuccess = createAction(
  `${modulePrefix} team/edit details save success`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamSaveDetailsToTeamFailure = createAction(
  `${modulePrefix} team/edit details save failure`,
  props<ErrorWithLoadingKey>()
);

export const teamRemoveTeacherSelfModal = createAction(`${modulePrefix} team/remove teacher self modal`);

export const teamRemoveTeacherSelf = createAction(
  `${modulePrefix} team/remove teacher self`,
  props<{ loadingKey: string; team: TeamWithTeachersDto }>()
);

export const teamRemoveTeacherSelfSuccess = createAction(
  `${modulePrefix} team/remove teacher self success`,
  props<{ team: TeamWithTeachersDto }>()
);

export const teamRemoveTeacherSelfFailure = createAction(
  `${modulePrefix} team/remove teacher self failure`,
  props<ErrorWithLoadingKey>()
);

export const teamAddTeacher = createAction(
  `${modulePrefix} team/add teacher`,
  props<{ loadingKey: string; teamId: string; teacher: AddTeacher }>()
);

export const teamAddTeacherSuccess = createAction(
  `${modulePrefix} team/add teacher success`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamAddTeacherFailure = createAction(
  `${modulePrefix} team/add teacher failure`,
  props<ErrorWithLoadingKey>()
);

export const teamAddTeachers = createAction(
  `${modulePrefix} team/add teachers`,
  props<{ loadingKey: string; teamId: string; teachers: AddTeacher[] }>()
);

export const teamAddTeachersSuccess = createAction(
  `${modulePrefix} team/add teachers success`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamAddTeachersFailure = createAction(
  `${modulePrefix} team/add teachers failure`,
  props<ErrorWithLoadingKey>()
);

export const teamLoadSingleTeamForEdit = createAction(
  `${modulePrefix} team/load for edit`,
  props<{ loadingKey: string; teamId: string }>()
);

export const teamLoadSingleTeamForEditFailure = createAction(
  `${modulePrefix} team/load for edit failure`,
  props<ErrorWithLoadingKey>()
);
