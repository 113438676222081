import { Injectable } from '@angular/core';
import { PageTypeEnum } from '@portal-app/enums/page-type.enum';
import { BehaviorSubject } from 'rxjs';
import { ProjectTypeEnum } from '../../enums/project-type.enum';
import { IHeader } from '../../interfaces/project/header.interface';
import { AppInitService } from './app-init.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private appInitService: AppInitService, private helperService: HelperService) {}

  private behind = new BehaviorSubject<boolean | undefined>(undefined);
  behind$ = this.behind.asObservable();

  /** Set header css colors based on multiple factors */
  setHeader(header: IHeader, projectType?: ProjectTypeEnum) {
    this.behind.subscribe((behind) => {
      document.documentElement.style.setProperty('--header-background-initial', header.InitialBackgroundColor || null);
      document.documentElement.style.setProperty('--header-text-initial', header.InitialTextColor || null);

      if (behind) {
        if (projectType === ProjectTypeEnum.PS) {
          document.documentElement.style.setProperty('--header-background-scrolled', '0,0,0');
          document.documentElement.style.setProperty('--header-text-scrolled', '255,255,255');
        } else {
          document.documentElement.style.setProperty('--header-background-scrolled', '255,255,255');
          document.documentElement.style.setProperty('--header-text-scrolled', '0,0,0');
        }
      } else {
        document.documentElement.style.setProperty(
          '--header-background-scrolled',
          header.ScrolledBackgroundColor || null
        );
        document.documentElement.style.setProperty('--header-text-scrolled', header.ScrolledTextColor || null);
      }
    });
  }

  /** Sets whether or not the header should be on top of content like the hero element or be above it.
   * @example If behind is set to true then the header would be transparant so you can see what it is on top of like the hero
   * */
  setBehind(pageType: PageTypeEnum, hasHero?: boolean) {
    if (
      pageType === PageTypeEnum.BookInfo ||
      pageType === PageTypeEnum.BookCollection ||
      pageType === PageTypeEnum.PsArticleLibrary ||
      pageType === PageTypeEnum.PsAssignmentLibrary ||
      pageType === PageTypeEnum.PsThemeLibrary ||
      pageType === PageTypeEnum.PsArticle ||
      pageType === PageTypeEnum.PsAssignment ||
      (this.appInitService.isPs && hasHero)
    ) {
      this.behind.next(true);
    } else {
      this.behind.next(false);
    }
  }

  resetHeader() {
    const projectType = this.appInitService.siteConfiguration.ProjectType;
    const header: IHeader = {
      InitialBackgroundColor: this.helperService.hexToRgb(
        this.appInitService.siteConfiguration.HeaderColors?.PrimaryBackgroundColor
      ),
      InitialTextColor: this.helperService.hexToRgb(
        this.appInitService.siteConfiguration.HeaderColors?.PrimaryTextColor
      ),
      ScrolledBackgroundColor: this.helperService.hexToRgb(
        this.appInitService.siteConfiguration.HeaderColors?.SecondaryBackgroundColor
      ),
      ScrolledTextColor: this.helperService.hexToRgb(
        this.appInitService.siteConfiguration.HeaderColors?.SecondaryTextColor
      ),
    };

    this.setHeader(header, projectType);
  }
}
