import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedLoadingKeys } from '../../../../../services/loading/shared-loading-keys.enums';
import { LoungeModalPages } from '../../../enums/lounge-modal-pages.enum';
import { LoungeModalService } from '../../../services/lounge-modal.service';
import { LoungeService } from '../../../services/lounge.service';
import { ITeamEdit } from '../../../store/interfaces/team-edit.interface';

/** A component for supplying CRUD for Lounge teams */
@Component({
  selector: 'lru-team-edit-dropdown[team]',
  templateUrl: './team-edit-dropdown.component.html',
  styleUrls: ['./team-edit-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamEditDropdownComponent {
  /** The team to edit */
  @Input() team!: ITeamEdit;

  /** @see {@link LoungeModalPages} */
  pageEnum = LoungeModalPages;

  /** @see {@link SharedLoadingKeys} */
  sharedLoadingKeys = SharedLoadingKeys;

  /** Method linked to event emitter from the modal.
   * @param showModal whether or not to show the team creation modal */
  onModalVisibilityChange(showModal: boolean): void {
    if (!showModal) {
      this.loungeModalService.setPageToNone();
    }
  }

  /** @ignore */
  constructor(public loungeService: LoungeService, public loungeModalService: LoungeModalService) {}
}
