import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, catchError, firstValueFrom, map } from 'rxjs';
import { LocalService } from '../local/local.service';
import { IUmbSiteConfiguration } from '../umbraco/interfaces/configuration/umb-site-configuration.interface';
import { ISiteConfiguration } from '../umbraco/interfaces/site-configuration.interface';
import { UmbracoService } from '../umbraco/umbraco.service';
import { IUser } from '../user/interfaces/user.interface';
import { UserService } from '../user/user.service';
import { ApiService } from './../api/api.service';

/** A service for initializing the web application */
@Injectable({ providedIn: 'root' })
export class InitService {
  /** @ignore */
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private umbracoService: UmbracoService,
    private localService: LocalService
  ) {}

  /** A method that initializes the login by retrieving the user from the internal backend. Furthermore it handles differences between MF and Fagportal response which is not identical.
   *
   * The method also retrieves and stores the configuration from Umbraco. */
  async initializeLogin(): Promise<void> {
    const user = await this.retrieveUser();

    if (user) {
      this.userService.setUser(user);
    }

    const siteConfiguration = await this.retrieveConfig();

    if (siteConfiguration) {
      this.umbracoService.setConfiguration(siteConfiguration);
    }
  }

  /** Retrieves the site configuration from Umbraco and maps it */
  private async retrieveConfig(): Promise<ISiteConfiguration | undefined> {
    return await firstValueFrom(
      this.apiService
        .getUmbraco<IUmbSiteConfiguration>(
          `alineaportal/getportal/${this.localService.hostName}/${this.userService.userType}`
        )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`%cThere was a problem fetching siteconfig.`, 'background: red; color: white', error.message);
            return EMPTY;
          }),
          map((json) => {
            if (!json) {
              return;
            }

            const siteConfig: ISiteConfiguration = {
              productId: json.id.toString(),
            };

            return siteConfig;
          })
        )
    );
  }

  /** Retrieves the user model from the internal backend. Maps it correctly to the IUser interface according to what internal backend is used */
  private async retrieveUser(): Promise<IUser | undefined> {
    return await firstValueFrom(
      this.apiService.getInternalBackendWithCredentials('Home/GetUserModel').pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `%cWhen you run the project on localhost, you need to start the express server. User model data could not be loaded and it's nessesary to perform login.`,
            'background: red; color: white',
            error.message
          );
          return EMPTY;
        }),
        map((json: any) => {
          if (!json) {
            return;
          }

          // MF returns camelCase properties
          if (json?.userId) {
            return json as IUser;
          }

          // Fagportal returns PascalCase properties
          const user: IUser = {
            userId: json.UserId,
            contextIdentifier: json.ContextIdentifier,
            accessToken: json.AccessToken,
            initials: json.Initials,
            hasAccess: json.HasAccess,
            userName: json.UserName,
            isTeacher: json.IsTeacher,
            isAlineaUser: json.IsAlineaUser,
            displayName: json.DisplayName,
            authenticationToken: json.AuthenticationToken,
            buildKey: json.BuildKey,
            institutionName: json.InstitutionName,
            institutionId: json.InstitutionId,
            institutionShortId: json.InstitutionShortId,
            institutionNumber: json.InstitutionNumber,
            expiresAt: json.ExpiresAt,
            idToken: json.IdToken,
            doNotTrack: json.DoNotTrack,
          };

          return user;
        })
      )
    );
  }
}
