import { Injectable, Optional } from '@angular/core';
import { ApiServiceConfig } from '../api/config/api-service-config';

@Injectable({ providedIn: 'root' })
export class LocalService {
  /** The local hostname for contacting Umbraco */
  private _localHostname: string;

  /** @ignore */
  constructor(@Optional() config?: ApiServiceConfig) {
    const defaultConfig = new ApiServiceConfig();

    this._localHostname = config?.localHostname ?? defaultConfig.localHostname;
  }

  /** Returns the hostname. If we're on localhost it returns the hardcoded domain.
   * @example 'superbog.dev.alineadigital.dk' */
  get hostName(): string {
    return this.isLocalhost() ? this._localHostname : window.location.hostname;
  }

  /** Determines if {@link Window.location} is localhost.
   * @returns A boolean value indicating if the current location is localhost */
  isLocalhost(): boolean {
    return window.location.hostname === 'localhost';
  }
}
