import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IScript } from '../../interfaces/project/script.interface';

@Injectable()
export class ScriptService {
  private scripts: string[] = [];
  constructor(@Inject(DOCUMENT) private $document: Document) {}

  load(scripts: string[]) {
    const promises: Promise<IScript>[] = [];

    scripts.forEach((script) => {
      const found = this.scripts.find((item) => item === script);
      if (found) {
        const promise = new Promise<IScript>((resolve, reject) => {
          resolve({ Src: script, Loaded: true });
        });
        promises.push(promise);
      } else {
        this.scripts.push(script);
        const promise = this.loadScript(script);
        promises.push(promise);
      }
    });
    return Promise.all(promises);
  }

  private loadScript(script: string) {
    return new Promise<IScript>((resolve, reject) => {
      const elem = this.$document.createElement('script');
      elem.type = 'text/javascript';
      elem.src = script;
      elem.onload = () => {
        resolve({ Src: script, Loaded: true });
      };
      elem.onerror = () => {
        elem.remove();
        this.scripts = this.scripts.filter((item) => item !== script);
        return reject({ Src: script, Loaded: false });
      };
      this.$document.getElementsByTagName('head')[0].appendChild(elem);
    });
  }
}
