import { Injectable } from '@angular/core';
import { ILevel } from '@portal-app/interfaces/project/level.interface';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  /**
   * used to sort and combine levels to a pretty string
   */
  convertLevelsToString(levels: string[] | undefined): string | undefined {
    // below is for testing

    // levels = ['0 kl.', '1 kl.', '2 kl.', '4 specialklasse', '4 kl.', '5 kl.', 'fritid', '6 kl.', '9 kl.'];
    // levels = ['12 kl.', '1 kl.', '4 kl.', '6 specialklasse', '2 kl.'];
    // levels = ['12 kl.', '1 kl.', '4 kl.', 'specialklasse', '2 kl.'];

    if (!levels || !levels?.length) {
      return;
    }
    const data: ILevel[] = [];
    for (const level of levels) {
      const levelNumber = level.match(/\d/g);
      if (levelNumber && level.indexOf(' kl.') > -1) {
        data.push({ Number: parseInt(levelNumber.join(''), 10) });
      } else {
        data.push({ String: level });
      }
    }

    data.sort((a, b) => {
      const x = a.Number ? a.Number : a.String ? parseInt(a.String, 10) : 0;
      const y = b.Number ? b.Number : b.String ? parseInt(b.String, 10) : 0;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    const complete: string[] = [];
    let current: number[] = [];

    const finishCurrent = () => {
      if (current.length) {
        const part1 = current[0];
        const part2 = current[current.length - 1];
        if (part1 === part2) {
          complete.push(`${part1}. klasse`);
        } else {
          complete.push(`${part1}.-${part2}. klasse`);
        }
        current = [];
      }
    };

    data.forEach((item, index) => {
      if (item.Number !== undefined) {
        if (current.length) {
          const arrayValue = current[current.length - 1];
          if (item.Number - 1 === arrayValue) {
            current.push(item.Number);
          } else {
            finishCurrent();
            current.push(item.Number);
          }
        } else {
          current.push(item.Number);
        }
      } else {
        finishCurrent();
        if (item.String) {
          complete.push(item.String);
        }
      }
    });
    finishCurrent();
    return complete.join(' | ');
  }
}
