import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalService, UserService } from '@lru/felib';
import { WindowScrollService } from '@portal-app/services/shared/window-scroll.service';
import { withLatestFrom } from 'rxjs';
import { HeaderTypeEnum } from '../../../../../enums/header-type.enum';
import { PageTypeEnum } from '../../../../../enums/page-type.enum';
import { AppInitService } from '../../../../../services/shared/app-init.service';
import { HeaderService } from '../../../../../services/shared/header.service';

@Component({
  selector: 'portal-header[headerType][pageType][behind]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() headerType?: HeaderTypeEnum;
  @Input() pageType?: PageTypeEnum;
  @Input() behind?: boolean;

  scrolled?: boolean;
  projectType = this.appInitService.siteConfiguration.ProjectType;
  gradient = this.appInitService.isPs;
  @ViewChild('domHeader') domHeader!: ElementRef<HTMLDivElement>;

  constructor(
    private windowScrollService: WindowScrollService,
    private userService: UserService,
    private appInitService: AppInitService,
    private headerService: HeaderService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.headerService.resetHeader();

    this.windowScrollService
      .getScrollY()
      .pipe(withLatestFrom(this.modalService.displayOverlay$))
      .subscribe(([pos, visible]) => {
        if (!window.scrollLocked && !visible) {
          this.setScrolled(pos);
        }
      });
  }

  ngAfterViewInit() {
    this.appInitService.setHeaderHeight(this.domHeader.nativeElement.clientHeight);
  }

  onLogin() {
    this.userService.logout();
  }

  private setScrolled(verticalOffset: number) {
    const maxScrollVal = 1;

    this.scrolled = verticalOffset >= maxScrollVal;
  }

  get PageTypeEnum() {
    return PageTypeEnum;
  }

  get HeaderTypeEnum() {
    return HeaderTypeEnum;
  }
}
