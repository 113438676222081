import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { ToastrService } from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private translateService: TranslateService) {
    // @Inject(Injector) private readonly injector: Injector
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (this.shouldHandleError(error.url || undefined)) {
        const _errorMessage: IErrorHttpErrorInterceptor = {
          title: `${error.status.toString()}: `,
          text: '',
          status: error.status,
        };
        switch (error.status) {
          case 400:
            _errorMessage.title += this.translateService.instant('GlobalError.400.Title');
            _errorMessage.text = this.translateService.instant('GlobalError.400.Text');
            break;
          case 401:
            _errorMessage.title += this.translateService.instant('GlobalError.401.Title');
            _errorMessage.text = this.translateService.instant('GlobalError.401.Text');
            break;
          case 403:
            _errorMessage.title += this.translateService.instant('GlobalError.403.Title');
            _errorMessage.text = this.translateService.instant('GlobalError.403.Text');
            break;
          case 404:
            _errorMessage.title += this.translateService.instant('GlobalError.404.Title');
            _errorMessage.text = this.translateService.instant('GlobalError.404.Text');
            break;
          case 500:
            _errorMessage.title += this.translateService.instant('GlobalError.500.Title');
            _errorMessage.text = this.translateService.instant('GlobalError.500.Text');
            break;
          default:
            //// WE NEED MORE CASES ABOVE??
            _errorMessage.title += this.translateService.instant('GlobalError.Default.Title');
            _errorMessage.text = this.translateService.instant('GlobalError.Default.Text');
            break;
        }
        this.showToastr(_errorMessage, error.message);
      }
    } else {
      this.showToastr({ text: error.message }, error.message);
    }
  }

  /**
   * Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
   */
  // private get toastr(): ToastrService {
  //  return this.injector.get(ToastrService);
  // }

  // private showToastr(message: string, title?: string, status?: number) {
  private showToastr(args: IErrorHttpErrorInterceptor, error: string) {
    switch (args.status) {
      case 400:
        // this.toastr.error(args.text, args.title);
        console.error(`${args.title}: ${args.text} - ${error}`);
        break;
      case 401:
      case 403:
        // this.toastr.warning(args.text, args.title);
        console.warn(`${args.title}: ${args.text} - ${error}`);
        break;
      case 404:
      case 500:
        // this.toastr.info(args.text, args.title);
        console.log(`${args.title}: ${args.text} - ${error}`);
        break;
      default:
        // this.toastr.show(args.text, args.title);
        console.log(`${args.title}: ${args.text} - ${error}`);
        break;
    }
  }

  private shouldHandleError(url?: string) {
    if (!url) {
      return true;
    }
    const ignore = [
      'https://localhost:44375/Home/GetUserModel',
      'http://localhost:55489/Home/GetUserModel',
      'https://localhost:44375/Home/UpdateSiteManifest',
      'http://localhost:55489/Home/UpdateSiteManifest',
    ];
    // return ignore.indexOf(url) === -1;
    return ignore.indexOf(url) === -1 && url.indexOf('/log/warn/message') === -1;
  }
}

interface IErrorHttpErrorInterceptor {
  text: string;
  title?: string;
  status?: number;
}
