import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@lru/felib';
import { ReplaySubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { INextBook } from '../../interfaces/book/next/next-book';
import { AppInitService } from '../shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class BookFinishedService {
  private data = new ReplaySubject<INextBook[]>(1);
  private loaded = false;

  data$ = this.data.asObservable();

  getBookFinished(id: string) {
    return of(false);
  }

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private appInitService: AppInitService
  ) {}

  initialize() {
    const subject = new ReplaySubject<boolean>(1);
    if (!this.loaded) {
      this.fetchAndUpdate().subscribe(
        (data) => {
          if (data) {
            subject.next(true);
            this.loaded = true;
          } else {
            subject.next(false);
          }
        },
        (error: HttpErrorResponse) => {
          subject.next(false);
        }
      );
    } else {
      subject.next(true);
    }
    return subject;
  }

  fetchAndUpdate() {
    const subject = new ReplaySubject<boolean>(1);
    if (this.userService.isLoggedIn && (this.appInitService.isSuperbog || this.appInitService.isSuperreader)) {
      this.apiService
        .getNext<INextBook[]>(
          `Book/GetFinishedBooks?projectGroupId=${this.appInitService.siteConfiguration.ProjectGroup}`
        )
        .subscribe(
          (data) => {
            this.data.next(data || []);
            subject.next(true);
          },
          (error: HttpErrorResponse) => {
            subject.next(false);
          }
        );
    } else {
      subject.next(true);
    }
    return subject;
  }

  setBookFinished(id: string, state: boolean) {
    return this.apiService
      .postNext<string>(`BookProgress/FinishBook/${id}?finished=${state}`)
      .pipe(tap(() => this.fetchAndUpdate()));
  }
}
