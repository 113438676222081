import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { UmbSettingAlignEnum, UmbSettingWidthEnum } from '@portal-app/enums/umb-ui-settings';
import { IBookLibraryFilters } from '@portal-app/interfaces/book/project/book-library.interface';
import { ICourseLibraryFilters } from '@portal-app/interfaces/course/project/course-library.interface';
import { IQueryString } from '@portal-app/interfaces/project/query-strings.interface';
import { TruncatePipe } from '@portal-app/pipes/truncate.pipe';

/**
 * This service is available as an injectable class and consists of minor helper methods
 * to be shared across the application.
 */
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private translate: TranslateService) {}

  hexToRgb(hex?: string) {
    if (!hex) {
      return;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : undefined;
  }

  lightenDarkenColor(color: string, percent: number) {
    const num = parseInt(color.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);

    /* eslint-disable no-bitwise */
    const r = (num >> 16) + amt;
    const b = ((num >> 8) & 0x00ff) + amt;
    const g = (num & 0x0000ff) + amt;
    /* eslint-enable no-bitwise */

    return (
      '#' +
      (
        0x1000000 +
        (r < 255 ? (r < 1 ? 0 : r) : 255) * 0x10000 +
        (b < 255 ? (b < 1 ? 0 : b) : 255) * 0x100 +
        (g < 255 ? (g < 1 ? 0 : g) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  hexToRGB(hex?: string, alpha?: number) {
    if (!hex) {
      return;
    }

    const r = parseInt(hex.slice(1, 3), 16).toString();
    const g = parseInt(hex.slice(3, 5), 16).toString();
    const b = parseInt(hex.slice(5, 7), 16).toString();

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha.toString() + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  /** @param type Supply name of querystring. Function will return first found querystring in the list */
  getQuerystringFacetList(type: IQueryString) {
    let items = this.getQueryParam(type);

    return items
      ? items.split(',').map((item) => {
          return decodeURIComponent(decodeURIComponent(item));
        })
      : [];
  }

  encodeStringArray(array: string[]) {
    return array.map((item) => encodeURIComponent(item)).join(',');
  }

  getQuerystringFacetListAsBooleans(type: IQueryString) {
    const items = this.getQueryParam(type);
    return items ? items.split(',').map((item) => item.toLowerCase() === 'true') : [];
  }

  getCookie(name: string) {
    const match = new RegExp('(^| )' + name + '=([^;]+)').exec(document.cookie);
    if (match) {
      return match[2];
    } else {
      return undefined;
    }
  }

  getCustomUrl(url?: string) {
    return url ? `/${url.replace('/', '')}` : url;
  }

  getFileIconSvg(extension: string) {
    switch (extension.toLowerCase()) {
      case 'xlsx':
      case 'xlsm':
      case 'xlsb':
      case 'xltx':
        return 'document-excel.svg';
      case 'mp3':
        return 'document-mp3.svg';
      case 'pdf':
        return 'document-pdf.svg';
      case 'doc':
      case 'docx':
        return 'document-word.svg';
      case 'pptx':
      case 'pptm':
      case 'ppt':
        return 'document-powerpoint.svg';
      default:
        return 'download.svg';
    }
  }

  getLocalizedDate(date: Date) {
    return date.toLocaleDateString(
      this.translate.instant('Label.LanguageCode', {
        default: 'da-DK',
      }),
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }
    );
  }

  getKeyName(list: typeof QueryStrings, value: string = '') {
    if (!list) {
      return;
    }

    return Object.entries(list).find(([, val]) => val.da === value || val.en === value)?.[0];
  }

  anyFiltersActive(filters: IBookLibraryFilters | ICourseLibraryFilters) {
    let show = false;
    if (filters.Query) {
      show = true;
      return show;
    }
    if (filters.Facets) {
      for (const facet of filters.Facets) {
        show = facet.Items.filter((item) => item.Selected === true).length ? true : false;
        if (show) {
          break;
        }
      }
    }

    return show;
  }

  getUmbracoAlign(align?: string, fallback?: UmbSettingAlignEnum) {
    const keys = Object.keys(UmbSettingAlignEnum);
    const values = Object.values(UmbSettingAlignEnum);
    const found = keys.findIndex((e) => e === align);
    if (found !== -1) {
      return values[found];
    } else if (fallback) {
      return fallback;
    } else {
      return UmbSettingAlignEnum.Left;
    }
  }

  getUmbracoWidth(width?: string) {
    const keys = Object.keys(UmbSettingWidthEnum);
    const values = Object.values(UmbSettingWidthEnum);
    const found = keys.findIndex((e) => e === width);
    if (found !== -1) {
      return values[found];
    } else {
      return UmbSettingWidthEnum.large;
    }
  }

  getUmbracoPercentageWidth(width?: number): string {
    if (width) {
      return `${width}%`;
    } else {
      return '100%';
    }
  }

  isLocalhost() {
    return window.location.hostname === 'localhost';
  }

  getHttpsUrl(url?: string) {
    if (!url) {
      return;
    }
    if (url.startsWith('www.') || !new RegExp('^(http|https)://', 'i').test(url)) {
      url = 'https://' + url;
    }
    return url;
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase());
  }

  tryParseInt(str: string, defaultValue: number) {
    let retValue = defaultValue;
    if (str !== null) {
      if (str.length > 0) {
        retValue = parseInt(str, 10);
      }
    }
    return retValue;
  }

  truncateString(text: string, length: number) {
    if (!text) {
      return;
    }
    return new TruncatePipe().transform(text, length);
  }

  getLessonOrDurationLabel(duration?: number, isLessons?: boolean) {
    let result: string | undefined;
    if (duration) {
      if (!isLessons) {
        duration = parseFloat(((duration / 45) * 2).toFixed(0)) / 2;
      }

      const durationTransformed = duration.toString().replace('.', ',');

      result =
        duration > 1
          ? this.translate.instant('Label.LessonOrDurationPlural', { duration: durationTransformed })
          : this.translate.instant('Label.LessonOrDuration', { duration: durationTransformed });
    }
    return result;
  }

  /** @param types Supply names of query params. Function will return first found parameter in the list */
  getQueryParam(queryString: IQueryString) {
    const urlParams = new URLSearchParams(window.location.search);
    let result: string | undefined;

    Object.entries(queryString).every(([key, value]) => {
      const paramString = urlParams.get(value);
      if (paramString) {
        result = paramString;
        return false;
      }
      // We return true to keep iterating.
      return true;
    });

    return result;
  }

  getParameterByName(name: string, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private getParameterByNameWithSuffix(facet: string, suffix: string) {
    return this.getParameterByName(`${facet}${suffix}`);
  }

  getParameterByQueryStringAndSuffix(queryString: IQueryString, suffix: IQueryString) {
    return (
      this.getParameterByNameWithSuffix(queryString.en, suffix.en) ??
      this.getParameterByNameWithSuffix(queryString.da, suffix.da)
    );
  }
}
