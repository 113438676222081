import { Injectable, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ZIndexActions from '../store/z-index.actions';
import * as ZIndexSelectors from '../store/z-index.selectors';
import { ZIndexState } from '../store/z-index.state';
import { ZIndexConfig } from '../z-index-config';

/**
 * Service used to give elements a new higher z-index to ensure an element is on top
 */
@Injectable({
  providedIn: 'root',
})
export class ZIndexService {
  /** Get config set for z-index store */
  public config$ = this.store.select(ZIndexSelectors.selectConfig);

  /**
   * Get z-index info for item with specific ID
   * @param id item to get z-index info for
   * @returns z-index item or undefined if not found
   */
  item$ = (id: string): Observable<number | undefined> => this.store.select(ZIndexSelectors.getItemById(id));

  /** the z-index currently being applied */
  public currentZIndex$ = this.store.select(ZIndexSelectors.selectCurrentZIndex);

  /** @ignore */
  constructor(private store: Store<ZIndexState>, @Optional() private config?: ZIndexConfig) {
    this.store.dispatch(
      ZIndexActions.setConfig({
        config: {
          zIndex: this.config?.zIndex,
        },
      })
    );
  }

  /**
   * Set z-index item in store
   * @param id name of item that is getting the new z-index
   */
  setItem(id: string): void {
    this.store.dispatch(ZIndexActions.setItem({ id: id }));
  }
}
