import { Component, OnInit } from '@angular/core';
import { UserService } from '@lru/felib';

@Component({
  selector: 'portal-header-book',
  templateUrl: './header-book.component.html',
  styleUrls: ['./header-book.component.scss'],
})
export class HeaderBookComponent implements OnInit {
  isLoggedIn?: boolean;

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.isLoggedIn = this.userService.isLoggedIn;
  }
}
