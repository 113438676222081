import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LoadingAnimationModule } from '../../components/loading-animation/loading-animation.module';
import { LoadingEffects } from '../loading/store/loading.effects';
import { loadingFeatureKey, loadingReducer } from '../loading/store/loading.reducers';
import { MouseKeyboardService } from '../mouse-keyboard/mouse-keyboard.service';
import { ApiServiceConfig } from './api/config/api-service-config';
import { IServicesModuleConfiguration } from './interfaces/service-module-configuration.interface';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(loadingFeatureKey, loadingReducer),
    EffectsModule.forFeature([LoadingEffects]),
    LoadingAnimationModule,
  ],
})
export class CoreModule {
  /** Ensures CoreModule is only loaded once. Also loads MouseKeyboardService so even if the depending projects aren't DependencyInjecting it, it will still load and add functionality for FELIB. */
  constructor(public mouseKeyboardService: MouseKeyboardService, @Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config?: IServicesModuleConfiguration): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [{ provide: ApiServiceConfig, useValue: config?.apiConfig }],
    };
  }
}
