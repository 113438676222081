import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '@lru/felib';
import { ProjectTypeEnum } from '@portal-app/enums/project-type.enum';
import { IDropdown } from '@portal-app/interfaces/project/dropdown.interface';
import { ISiteLoge } from '@portal-app/interfaces/project/site-configuration.interface';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { WindowScrollService } from '@portal-app/services/shared/window-scroll.service';
import { PageTypeEnum } from '../../../../../enums/page-type.enum';
import { BreakpointsService } from '../../../../../services/shared/breakpoints.service';
import { DropdownService } from '../../../../../services/shared/dropdown.service';
import { NavbarService } from '../../../../../services/shared/navbar.service';

@Component({
  selector: 'portal-header-default[pageType]',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss'],
})
export class HeaderDefaultComponent implements OnInit {
  @Input() pageType!: string;

  scrolled?: boolean;
  isLoggedIn?: boolean;
  dropdownOpen?: IDropdown;
  siteName?: string;
  hasMegaMenu?: boolean;
  isFagportal?: boolean;
  isSuperBogOrReader?: boolean;
  favoriteUrl?: string;
  large?: boolean;
  libraryUrl?: string;
  logo?: ISiteLoge;
  showLogin: boolean = false;

  constructor(
    private windowScrollService: WindowScrollService,
    private appInitService: AppInitService,
    private userService: UserService,
    private navbarService: NavbarService,
    private breakpointsService: BreakpointsService,
    private dropdownService: DropdownService
  ) {}

  ngOnInit() {
    this.logo = this.appInitService.siteConfiguration.logo;
    this.showLogin = this.appInitService.siteConfiguration.ProjectType !== ProjectTypeEnum.Other;

    this.isLoggedIn = this.userService.isLoggedIn;
    this.hasMegaMenu = this.appInitService.siteConfiguration.MegaMenuId ? true : false;
    this.isFagportal = this.appInitService.isFagportal;
    this.isSuperBogOrReader = this.appInitService.isSuperbog || this.appInitService.isSuperreader;

    this.dropdownService.open$.subscribe((value) => {
      this.dropdownOpen = value;
    });

    this.navbarService.favoritesUrl$.subscribe((url) => {
      this.favoriteUrl = url;
    });

    this.breakpointsService.large$.subscribe((data) => {
      this.large = data;
    });

    this.navbarService.libraryUrl$.subscribe((url) => {
      this.libraryUrl = url;
    });

    this.siteName = this.appInitService.siteConfiguration.Title;

    this.windowScrollService.getScrollY().subscribe((pos) => {
      if (!window.scrollLocked) {
        this.setScrolled(pos);
      }
    });
  }

  onLogin() {
    this.userService.login();
  }

  get PageTypeEnum() {
    return PageTypeEnum;
  }

  private setScrolled(verticalOffset: number) {
    const maxScrollVal = 1;

    this.scrolled = verticalOffset >= maxScrollVal;
  }
}
