import { createAction, props } from '@ngrx/store';
import { ToastMessageInstance } from '../interfaces/toast-message-instance.interface';
import { IToastConfig } from '../toast-config.interface';

const modulePrefix = '[ToastModule]';

export const addMessage = createAction(`${modulePrefix} Add message`, props<{ message: ToastMessageInstance }>());

export const updateMessage = createAction(
  `${modulePrefix} Update message`,
  props<{ message: ToastMessageInstance; ignoreTiming?: boolean }>()
);

export const removeMessage = createAction(`${modulePrefix} Remove message`, props<{ id: string }>());

export const pauseMessageExpiration = createAction(`${modulePrefix} Pause message expiration`);

export const continueMessageExpiration = createAction(`${modulePrefix} Continue message expiration`);

export const initializeMessageTimeout = createAction(
  `${modulePrefix} Initialize message timeout`,
  props<{ message: ToastMessageInstance }>()
);

export const clearMessageTimeout = createAction(
  `${modulePrefix} Clear message timeout`,
  props<{ message: ToastMessageInstance }>()
);

export const setConfig = createAction(`${modulePrefix} Set config`, props<{ config: IToastConfig }>());
