export enum SectionTypeEnum {
  CourseContinue = 'course-continue-where-you-left',
  BookContinue = 'book-continue-where-you-left',

  BookMyFavorites = 'book-my-favourites',

  BookInform = 'book-inform',

  CourseList = 'course-list',
  ActivityList = 'activity-list',
  BookList = 'book-list',

  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  RichTextEditor = 'rte',
  PortalContentpagesList = 'portal-contentpages-list',
  Image = 'media-image',
  Quote = 'quote',
  Embed = 'embed',
  CallToAction = 'call-to-action',
  CallToActionFeaturedCourse = 'cta-featured-course',
  CallToActionFeaturedTheme = 'cta-featured-theme', // ps theme
  CallToActionStandard = 'cta-standard',
  DocumentCollection = 'media-document-collection',
  NotImplemented = 'not-implemented',
  DataMissing = "api-data-missing (can't implement)",
  ImageGallery = 'image-gallery',
  CallToActionButtons = 'cta-buttons',

  // fagportaler
  StudentAnswers = 'student-answers',
  StudentAnswersCourseResults = 'student-answers-course-results',

  //// FROM SUPERBOG PROJECT
  ClassReads = 'class-reads',
  MyBookCollections = 'my-reading-boxes',
  FinishedBooks = 'read-books',

  // PS
  PsAssignments = 'ps-assignment-list',
  PsArticles = 'ps-article-list',

  /** Not yet implemented */
  TagCloudList = 'tag-cloud-list',
  ThemeBasic = 'theme-basic',

  /** Deprecated */
  Headline = 'headline',
}
