<label class="checkbox" tabindex="0" [attr.data-variant]="variant" (keydown.enter)="onEnterKeydown()">
  <input type="checkbox" [(ngModel)]="checked" (change)="onChange(checked)" />
  <i *ngIf="!semiChecked"><lru-icon [icon]="{ path: 'felib-check', size: size }"></lru-icon></i>
  <i *ngIf="semiChecked && !checked" class="semi"
    ><lru-icon [icon]="{ path: 'felib-multiple-selection-checkbox-icon', size: size }"></lru-icon
  ></i>
  <span>
    <ng-content></ng-content>
  </span>
</label>
