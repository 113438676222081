import { Component } from '@angular/core';
import { AudioPlayerTypeEnum } from '@portal-app/enums/audio-player-type.enum';
import { LayoutAudioService } from '@portal-app/services/shared/layout-audio.service';

@Component({
  selector: 'portal-layout-audio-recorded',
  templateUrl: './layout-audio-recorded.component.html',
  styleUrls: ['./layout-audio-recorded.component.scss'],
})
export class LayoutAudioRecordedComponent {
  public data$ = this.layoutAudioService.data$;

  constructor(public layoutAudioService: LayoutAudioService) {}

  get AudioPlayerTypeEnum(): typeof AudioPlayerTypeEnum {
    return AudioPlayerTypeEnum;
  }
}
