import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { GoogleTagManagerService, UserService } from '@lru/felib';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { GTMTag } from '@portal-app/interfaces/project/gtm-tag.interface';
import { AppInitService } from '../services/shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class SetGTMForRoute implements CanActivate {
  constructor(
    private userService: UserService,
    private appInitService: AppInitService,
    private googleTagManagerService: GoogleTagManagerService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.appInitService.shouldUseTagManager && this.appInitService.siteConfiguration.GoogleTagManagerId) {
      this.googleTagManagerService.setId(this.appInitService.siteConfiguration.GoogleTagManagerId);
      const gtmTag: GTMTag = {
        event: GTMEvent.Page,
        pageName: decodeURI(state.url),
        visitorType: this.userService.userType,
      };
      this.googleTagManagerService.pushTag(gtmTag);
    }
    return true;
  }
}
