import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs/operators';
import {
  addMessage,
  clearMessageTimeout,
  continueMessageExpiration,
  initializeMessageTimeout,
  pauseMessageExpiration,
  removeMessage,
  updateMessage,
} from './toast.actions';
import { selectAllMessages } from './toast.selectors';
import { ToastState } from './toast.state';

/** Effects that are related to ToastMessages */
@Injectable()
export class ToastEffects {
  /** An effect that maps the message to an InitializeMessageTimeout action */
  addMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addMessage),
      map(({ message }) => initializeMessageTimeout({ message }))
    );
  });

  /** Change a toast message timeout */
  updateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateMessage),
      exhaustMap(({ message, ignoreTiming }) => {
        if (ignoreTiming) {
          /** FNI COMMENT :: Why? */
          return [];
        }

        if (message.expirationTimestamp) {
          message.duration = Date.now() - message.expirationTimestamp;
          delete message.expirationTimestamp;
        }

        return [clearMessageTimeout({ message }), initializeMessageTimeout({ message })];
      })
    );
  });

  /** If message has a duration we start a timeout with the message's duration. After the timeout we dispatch an action to clear the message */
  initializeMessageTimeout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(initializeMessageTimeout),
        map(({ message }) => {
          if (message.duration) {
            const expirationTimestamp = Date.now() + message.duration;

            const timeoutId = window.setTimeout(() => {
              this.toastStore.dispatch(removeMessage({ id: message.id }));
            }, message.duration);

            /** FNI COMMENT :: Why? */
            this.toastStore.dispatch(
              updateMessage({
                message: { ...message, timeoutId, expirationTimestamp },
                ignoreTiming: true,
              })
            );
          }
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Clears timeout for specific toast message.
   * This is needed since timeout can be paused.
   */
  clearMessageTimeout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clearMessageTimeout),
      exhaustMap((action) => {
        window.clearTimeout(action.message.timeoutId);
        const message = { ...action.message };

        delete message.timeoutId;

        return [updateMessage({ message })];
      })
    );
  });

  /** Pause the countdown for toast messages to go away  */
  pauseMessageExpiration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pauseMessageExpiration),
      concatLatestFrom(() => [this.toastStore.select(selectAllMessages)]),
      exhaustMap(([_, messages]) => {
        const now = Date.now();

        return messages
          .filter((message) => message.expirationTimestamp)
          .map((message) => {
            clearTimeout(message.timeoutId);
            const newMessage = {
              ...message,
              // Update the duration to match the remaining time
              duration: message.expirationTimestamp! - now,
            };

            // Remove expiration timestamp since it's now invalid
            delete newMessage.expirationTimestamp;

            return updateMessage({ message: newMessage, ignoreTiming: true });
          });
      })
    );
  });

  /** Continue the countdown for toast messages to go away */
  continueMessageExpiration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(continueMessageExpiration),
      concatLatestFrom(() => [this.toastStore.select(selectAllMessages)]),
      exhaustMap(([_, messages]) => messages.map((message) => initializeMessageTimeout({ message })))
    );
  });

  /** @ignore */
  constructor(private actions$: Actions, private toastStore: Store<ToastState>) {}
}
