<lru-dropdown
  [variant]="'lounge'"
  [sameWidth]="false"
  [spacingFromWindow]="10"
  [spacingFromButton]="10"
  horizontalDirection="left"
  [maxWidth]="300"
  #editDropdown
>
  <ng-container button>
    <lru-button [variant]="'lounge'" [theme]="'white'">
      <span>Rediger</span>
    </lru-button>
  </ng-container>
  <ng-container>
    <div class="team-edit-menu">
      <div class="team-edit-menu__section">
        <label class="team-edit-menu__section__header">ELEVER</label>
        <ul class="team-edit-menu__section__content">
          <li>
            <lru-button
              [variant]="'lounge'"
              [theme]="'white'"
              (click)="loungeService.teamEditAssignStudents(); editDropdown.onCloseClick()"
            >
              <span>Tilføj/fjern elever</span>
              <lru-icon [icon]="{ path: 'felib-add-students', size: 16 }"></lru-icon>
            </lru-button>
          </li>
        </ul>
      </div>
      <div class="team-edit-menu__section">
        <label class="team-edit-menu__section__header">LÆRER</label>
        <ul class="team-edit-menu__section__content">
          <li>
            <lru-button
              [variant]="'lounge'"
              [theme]="'white'"
              (click)="
                loungeService.teamEditAssignTeachers(sharedLoadingKeys.teachersWithoutCurrentUser);
                editDropdown.onCloseClick()
              "
            >
              <span>{{ team.teachers.length === 1 ? 'Del med lærere' : 'Rediger lærerdeling' }}</span>
              <lru-icon [icon]="{ path: 'felib-share-with-teacher', size: 16 }"></lru-icon>
            </lru-button>
          </li>
          <li>
            <lru-button
              [variant]="'lounge'"
              [theme]="'white'"
              (click)="loungeService.teamRemoveTeacherSelfModal(); editDropdown.onCloseClick()"
            >
              <span>Fjern dig som lærer</span>
              <lru-icon [icon]="{ path: 'felib-remove-myself-as-teacher', size: 16 }"></lru-icon>
            </lru-button>
          </li>
        </ul>
      </div>
      <div class="team-edit-menu__section">
        <div class="team-edit-menu__section__header">HOLD</div>
        <ul class="team-edit-menu__section__content">
          <li>
            <lru-button
              [variant]="'lounge'"
              [theme]="'white'"
              (click)="loungeService.teamEditDetailsModal(); editDropdown.onCloseClick()"
            >
              <span>Ret holdnavn og klassetrin</span>
              <lru-icon [icon]="{ path: 'felib-edit', size: 16 }"></lru-icon>
            </lru-button>
          </li>
          <li>
            <lru-button
              [variant]="'lounge'"
              [theme]="'white'"
              (click)="loungeService.teamDeleteModal(); editDropdown.onCloseClick()"
            >
              <span>Slet hold</span>
              <lru-icon [icon]="{ path: 'felib-trash', size: 16 }"></lru-icon>
            </lru-button>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <lru-modal
    [isVisible]="loungeModalService.modalActive || false"
    (isVisibleChange)="onModalVisibilityChange($event)"
    [variant]="'lounge'"
    [height]="(loungeModalService.modalHeight$ | async) ?? undefined"
  >
    <lru-team-edit-assign-students *ngIf="(loungeModalService.page$ | async) === pageEnum.studentPicker">
    </lru-team-edit-assign-students>
    <lru-team-edit-assign-teachers
      *ngIf="(loungeModalService.page$ | async) === pageEnum.shareWithTeachers"
      [teamEdit]="team"
    >
    </lru-team-edit-assign-teachers>
    <lru-team-edit-delete *ngIf="(loungeModalService.page$ | async) === pageEnum.deleteTeam" [teamEdit]="team">
    </lru-team-edit-delete>
    <lru-team-edit-details *ngIf="(loungeModalService.page$ | async) === pageEnum.detailsInput"></lru-team-edit-details>
    <lru-team-edit-remove-teacher-self
      *ngIf="(loungeModalService.page$ | async) === pageEnum.removeTeacherSelf"
      [teamEdit]="team"
    >
    </lru-team-edit-remove-teacher-self>
  </lru-modal>
</lru-dropdown>
