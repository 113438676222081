import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

type theme = 'primary-student' | 'primary-teacher';

/** Lounge box */
@Component({
  selector: 'lru-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
  /** what kind of box should it be? Different colors for teacher/student */
  @Input() theme: theme = 'primary-teacher';

  /** If hollow then the color is applied to a border instead of the background of the box */
  @Input() hollow: boolean = false;
}
