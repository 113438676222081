import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SharedLoadingKeys } from '.././../../../../services/loading/shared-loading-keys.enums';
import { UmbracoService } from '../../../../../services/core/umbraco/umbraco.service';
import { ILoungePostBodyTeam_CreateTeam } from '../../../interfaces/endpoint/team.interface';
import { LoungeService } from '../../../services/lounge.service';

/** Service mainly for creating a team but also handles active and step status of the team creation flow page / modal */
@Injectable({ providedIn: 'root' })
export class TeamCreationService {
  /** @ignore */
  constructor(private loungeService: LoungeService, private umbracoService: UmbracoService) {}

  /** Method for creating a lounge team. Users to add to the team are automatically retrieved from {@link LoungeService}'s selectedUsersList$
   * @param title Title of the team
   * @param levelIds The ids of the levels to add the team to */
  async createTeam(title: string, levelIds?: string[]): Promise<void> {
    const users = await firstValueFrom(this.loungeService.selectedUsersList$.pipe(filter((x) => !!x)));

    const team: ILoungePostBodyTeam_CreateTeam = {
      title,
      description: '',
      isPublic: true,
      studentIds: users.map((x) => x.id),
      productIds: [this.umbracoService.productId],
      levelIds: levelIds,
    };

    this.loungeService.teamCreate(team, SharedLoadingKeys.createTeam);
  }
}
