<portal-dropdown
  [buttonClasses]="'btn square'"
  [contentClasses]="'default-dropdown-theme'"
  [ariaLabel]="'Dropdown.Type.HeaderUser' | translate"
>
  <ng-container button>
    <span>{{ user?.initials }}</span>
  </ng-container>
  <ng-container content>
    <ul>
      <li>
        <button (click)="onLogout()" class="sub-item selectable" tabindex="0">
          <i class="icon icon-16" [inlineSVG]="'logout.svg'"></i>
          <span>{{ 'Actions.LogOut' | translate }}</span>
        </button>
      </li>
    </ul>
  </ng-container>
</portal-dropdown>
