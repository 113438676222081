import { ClassDto } from '@lru/felib/lib/modules/lounge/interfaces/endpoint/dtos/dto-class.interface';
import { Action, createReducer, on } from '@ngrx/store';
import { IBookTile, ICollectionTile } from '@portal-app/interfaces/project/section.interface';
import * as TeacherDashboardStoreActions from './teacher-dashboard.actions';
// import { act } from "@ngrx/effects";

export const teacherDashboardFeatureKey = 'BookTeacherDashboard';

export interface ITeamsAndClassesResponse {
  result: ITeamsAndClasses;
  error?: LoungeError;
}

export interface LoungeError {
  errorCode: string;
  parameters: object;
}

export interface ITeamsAndClasses {
  classes: ClassDto[];
  teamsWithTeachers: ITeacherClassesItem[];
}

export interface ITeacherClassesItem {
  id: string;
  title: string;
  isDlpClass: boolean;
  levelIds: string[];
}

export interface ITeacherClassStudentItem {
  DisplayName: string;
  FirstName: string;
  Initials: string;
  IsActive: boolean;
  LastName: string;
  UserId: string;
}

export interface ISelectedClass {
  classId: string;
  isDlpClass: boolean;
  levelIds: string[];
}

// Overall state - extend as nessary
export interface ITeacherDashboardState {
  selectedClass?: ISelectedClass;
  selectedStudent?: ITeacherClassStudentItem;
  selectedBookTab: string;
  selectedPeriod: string;
  selectedPeriodToDate: string;
  teamsAndClasses?: ITeamsAndClasses;
  students: ITeacherClassStudentItem[];
  bookCollection: ICollectionTile[];
  booksInProgress: IBookTile[];
  booksFinished: IBookTile[];
}

export const initialTeacherDashboardState: ITeacherDashboardState = {
  selectedClass: undefined,
  selectedStudent: undefined,
  selectedBookTab: '',
  selectedPeriod: '',
  selectedPeriodToDate: '',
  teamsAndClasses: undefined,
  students: [],
  bookCollection: [],
  booksInProgress: [],
  booksFinished: [],
};

export const getTeacherClassesSuccessReducer = createReducer(
  initialTeacherDashboardState,
  on(TeacherDashboardStoreActions.ToggleBookCollectionFavouriteAction, (state, action) => {
    return {
      ...state,
      bookCollection: state.bookCollection
        .map((item) => ({ ...item }))
        .map((item) => {
          if (item.Id === action.id) {
            return {
              ...item,
              IsFavorite: action.data,
            };
          } else {
            return item;
          }
        }),
    };
  }),
  on(TeacherDashboardStoreActions.ToggleBookContinueFavouriteAction, (state, action) => {
    return {
      ...state,
      booksInProgress: state.booksInProgress
        .map((item) => ({ ...item }))
        .map((item) => {
          if (item.Id === action.id) {
            return {
              ...item,
              IsFavorite: action.data,
            };
          } else {
            return item;
          }
        }),
    };
  }),
  on(TeacherDashboardStoreActions.ToggleBookFinishedFavouriteAction, (state, action) => {
    return {
      ...state,
      booksFinished: state.booksFinished
        .map((item) => ({ ...item }))
        .map((item) => {
          if (item.Id === action.id) {
            return {
              ...item,
              IsFavorite: action.data,
            };
          } else {
            return item;
          }
        }),
    };
  }),
  on(TeacherDashboardStoreActions.GetBookCollectionSuccessAction, (state, action) => ({
    ...state,
    bookCollection: action.bookCollectionData,
  })),
  on(TeacherDashboardStoreActions.SelectBookPeriod, (state, action) => ({
    ...state,
    selectedPeriod: action.data,
  })),
  on(TeacherDashboardStoreActions.SetBookPeriodTime, (state, action) => ({
    ...state,
    selectedPeriodToDate: action.data,
  })),
  on(TeacherDashboardStoreActions.SetBookPeriod, (state, action) => ({
    ...state,
    selectedPeriod: action.data,
  })),
  on(TeacherDashboardStoreActions.SetOnlyBookPeriodTime, (state, action) => ({
    ...state,
    selectedPeriodToDate: action.data,
  })),
  on(TeacherDashboardStoreActions.GetTeamsAndClassesSuccess, (state, action) => ({
    ...state,
    teamsAndClasses: action.data,
  })),
  on(TeacherDashboardStoreActions.GetStudentsInClassSuccessAction, (state, action) => {
    const newSortedData = [...action.studentsData];
    newSortedData.sort(sortByDisplayName);
    return {
      ...state,
      students: newSortedData,
    };
  }),
  on(TeacherDashboardStoreActions.SelectTeacherClassIdAction, (state, action) => ({
    ...state,
    selectedClass: action.selectedClass,
  })),
  on(TeacherDashboardStoreActions.SelectedBookTapNavigationAction, (state, action) => ({
    ...state,
    selectedBookTab: action.bookTab,
  })),
  on(TeacherDashboardStoreActions.SetSelectedStudent, (state, action) => ({
    ...state,
    selectedStudent: action.student,
  })),
  on(TeacherDashboardStoreActions.GetBooksForUserSuccess, (state, action) => ({
    ...state,
    booksInProgress: action.booksInProgress,
    booksFinished: action.booksFinished,
  }))
);

export function reducer(state: ITeacherDashboardState | undefined, action: Action) {
  return getTeacherClassesSuccessReducer(state, action);
}

function sortByDisplayName(a: ITeacherClassStudentItem, b: ITeacherClassStudentItem): number {
  const compare = a.DisplayName.localeCompare(b.DisplayName, 'da-DK', {
    sensitivity: 'base',
  });
  if (compare > 0) {
    return 1;
  }
  if (compare < 0) {
    return -1;
  }
  return compare;
}
