import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IBookTag } from '@portal-app/interfaces/book/project/book.interface';
import { INextTag, INextTag2 } from '@portal-app/interfaces/next/next-tag.interface';
import { DifficultyTypeEnum } from '../../enums/difficulty-type.enum';
import { AppInitService } from '../../services/shared/app-init.service';

@Injectable({
  providedIn: 'root',
})
export class BookDifficultiesMapper {
  constructor(private appInitService: AppInitService, private translate: TranslateService) {}

  mapBookDifficultyFromApi(difficulties: INextTag[]): IBookTag[] {
    const result: IBookTag[] = [];
    let a: INextTag | undefined;
    if (this.appInitService.isSuperbog) {
      let b: INextTag | undefined;
      if (difficulties.length) {
        a = difficulties.find((item) => item.Type === DifficultyTypeEnum.LIX);
        b = difficulties.find((item) => item.Type === DifficultyTypeEnum.LET);
      }

      if (a) {
        result.push(this.getElement(a, DifficultyTypeEnum.LIX));
      }

      if (b) {
        result.push(this.getElement(b, DifficultyTypeEnum.LET));
      }
    } else if (this.appInitService.isSuperreader) {
      if (difficulties.length) {
        a = difficulties.find((item) => item.Type === DifficultyTypeEnum.LEVEL);
      }
      if (a) {
        result.push(this.getElement(a, DifficultyTypeEnum.LEVEL));
      }
    }
    return result;
  }

  mapBookDifficultyFromApi2(difficulties: INextTag2[]): IBookTag[] {
    if (!difficulties || difficulties.length === 0) {
      return [];
    }

    const result: IBookTag[] = [];
    if (this.appInitService.isSuperbog) {
      const lixTag = difficulties.find((item) => item.type === DifficultyTypeEnum.LIX);
      const letTag = difficulties.find((item) => item.type === DifficultyTypeEnum.LET);

      if (lixTag) {
        result.push(this.getElement2(lixTag, DifficultyTypeEnum.LIX));
      }
      if (letTag) {
        result.push(this.getElement2(letTag, DifficultyTypeEnum.LET));
      }
    } else if (this.appInitService.isSuperreader) {
      const lvlTag = difficulties.find((item) => item.type === DifficultyTypeEnum.LEVEL);

      if (lvlTag) {
        result.push(this.getElement2(lvlTag, DifficultyTypeEnum.LEVEL));
      }
    }
    return result;
  }

  getElement(element: INextTag, type: DifficultyTypeEnum): IBookTag {
    if (element) {
      const splitTitle = element.Title.split(' ');
      if (splitTitle.length) {
        const title = splitTitle[0];
        const value = splitTitle[1] || '-';
        return {
          Title: this.translate.instant(`DifficultyType.${title}`, {
            default: title,
          }),
          Value: value,
          Type: element.Type,
        };
      }
    }
    return {
      Title: type,
      Value: '-',
      Type: type,
    };
  }

  getElement2(element: INextTag2, type: DifficultyTypeEnum): IBookTag {
    if (element) {
      const splitTitle = element.title.split(' ');
      if (splitTitle.length) {
        const title = splitTitle[0];
        const value = splitTitle[1] || '-';
        return {
          Title: this.translate.instant(`DifficultyType.${title}`, {
            default: title,
          }),
          Value: value,
          Type: element.type,
        };
      }
    }
    return {
      Title: type,
      Value: '-',
      Type: type,
    };
  }
}
