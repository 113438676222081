import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@portal-env/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(
    (a) => {
      // App is bootstrapped
    },
    (error) => {
      // There's a bootstrapping error
      console.error('Bootstrapping Error', error);
    }
  );
