import { PeriodSelectorEnum } from '@portal-app/enums/period-selector.enum';

export function periodDateHelper(period: PeriodSelectorEnum): string {
  let newDateSec: number;
  let newDate: Date;
  const today = new Date().getTime();

  switch (period) {
    case PeriodSelectorEnum.now:
      newDateSec = today - 60 * 60 * 1000;
      newDate = new Date(newDateSec);
      break;
    case PeriodSelectorEnum.day:
      newDateSec = today - 60 * 60 * 1000 * 24;
      newDate = new Date(newDateSec);
      break;
    case PeriodSelectorEnum.month:
      newDateSec = today - 60 * 60 * 1000 * 24 * 30;
      newDate = new Date(newDateSec);
      break;
    case PeriodSelectorEnum.week:
      newDateSec = today - 60 * 60 * 1000 * 24 * 7;
      newDate = new Date(newDateSec);
      break;
    case PeriodSelectorEnum.schoolYear:
      newDateSec = today - 60 * 60 * 1000 * 24 * 365;
      newDate = new Date(newDateSec);
      break;
    default:
      // Return month as a default date
      newDateSec = today - 60 * 60 * 1000 * 24 * 30;
      newDate = new Date(newDateSec);
  }

  return `${newDate.getUTCFullYear().toString()}-${newDate.getUTCMonth() + 1}-${newDate.getUTCDate()}`;
}
