import { Component, OnInit } from '@angular/core';
import { IMegaMenu, IMegaMenuTab } from '@portal-app/interfaces/project/mega-menu.interface';
import { MegaMenuMapper } from '@portal-app/mapper/shared/mega-menu.mapper';
import { AppInitService } from '@portal-app/services/shared/app-init.service';
import { MegaMenuService } from '@portal-app/services/shared/mega-menu.service';

@Component({
  selector: 'portal-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent implements OnInit {
  megaMenu?: IMegaMenu;

  constructor(
    private megaMenuService: MegaMenuService,
    private megaMenuMapper: MegaMenuMapper,
    private appInitService: AppInitService
  ) {}

  ngOnInit() {
    const menuId = this.appInitService.siteConfiguration.MegaMenuId;
    if (menuId) {
      this.megaMenuService.getMegaMenu(menuId).subscribe((megaMenu) => {
        this.megaMenu = this.megaMenuMapper.mapMegaMenuFromApi(megaMenu);
      });
    }
  }

  changeTab(tab: IMegaMenuTab) {
    if (this.megaMenu) {
      this.megaMenu.CurrentTab = tab;

      if (this.megaMenu.ActivelyOpenedTab === tab) {
        this.megaMenu.ActivelyOpenedTab = undefined;
      } else {
        this.megaMenu.ActivelyOpenedTab = tab;
      }
    }
  }
}
