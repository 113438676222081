import { Injectable, OnDestroy } from '@angular/core';
import { LoadingService, MouseKeyboardService } from '@lru/felib';
import { LoadingKey } from '@portal-app/enums/loading-keys.enum';
import { IDropdown } from '@portal-app/interfaces/project/dropdown.interface';
import { take } from 'rxjs/operators';
import { DropdownService } from './dropdown.service';
import { ExtendedDialogService } from './extended-dialog.service';
import { LayoutAudioService } from './layout-audio.service';

@Injectable({
  providedIn: 'root',
})
export class MouseAndKeyboardService implements OnDestroy {
  loaded = false;
  private header?: HTMLElement;
  private focus?: HTMLElement;
  private hover?: HTMLElement;
  private isLoading = false;
  private dropdownOpen?: IDropdown;
  private validElements = '[tabindex="0"], a:not([tabindex="-1"]), button:not([tabindex="-1"])';

  private loadingSubscription = this.loadingService
    .loading$(LoadingKey.Page)
    .subscribe((data) => (this.isLoading = data));
  private dropDownSubscription = this.dropdownService.open$.subscribe((data) => {
    this.dropdownOpen = data;
  });

  constructor(
    private dropdownService: DropdownService,
    private extendedDialogService: ExtendedDialogService,
    private layoutAudioService: LayoutAudioService,
    private mouseKeyboardService: MouseKeyboardService,
    private loadingService: LoadingService
  ) {}

  init() {
    this.header = document.getElementById('header') || undefined;
    this.loaded = true;
  }

  handleKeyup(e: KeyboardEvent) {
    let target = e.target as HTMLElement | null;
    if (target && e.key === 'Tab') {
      this.mouseKeyboardService.removeHover();
      if (
        !target.closest('#nav') &&
        (target.closest('.dropdown')?.id !== this.dropdownOpen?.Id || !target.closest('.dropdown'))
      ) {
        this.dropdownService.closeDropdown();
      }
      if (
        (target.closest('.caption .canvas button') && document.body.querySelector('.lightbox')) ||
        target.closest('.lightbox')
      ) {
        target = document.querySelector<HTMLElement>('.lb-closeContainer .lb-close');
      } else {
        this.fixTabOffset(target);
      }
      if (target) {
        this.mouseKeyboardService.addFocus(target);
      }
    }
  }

  handleKeydown(e: KeyboardEvent) {
    const target = e.target as HTMLElement;

    if (this.isLoading) {
      if (e.key === 'Tab' || (e.ctrlKey && e.key === 'a')) {
        e.preventDefault();
      }
      this.mouseKeyboardService.removeHover();
    } else {
      const tabs = (target.closest('.tabs') || this.hover?.closest('.tabs')) as HTMLElement;
      const pagination = (target.closest('.pagination') || this.hover?.closest('.pagination')) as HTMLElement;

      if (e.key === 'Enter') {
        if (this.hover) {
          e.preventDefault();
          this.hover.click();
        } else if (this.focus) {
          if (!target.closest('a') && !target.closest('button')) {
            this.focus.click();
          }
          this.mouseKeyboardService.addFocus(target);
        } else {
          if (target.classList.contains('checkbox') || target.classList.contains('toggle')) {
            target.click();
          }
        }
      } else if (this.dropdownOpen || target.closest('portal-library-search')) {
        this.handleDropdown(e, target);
      } else if (tabs) {
        this.handleArrow(e, tabs);
      } else if (pagination) {
        this.handleArrow(e, pagination);
      } else if (e.key === 'Tab') {
        if (
          (target.closest('.caption .canvas button') && document.body.querySelector('.lightbox')) ||
          target.closest('.lightbox')
        ) {
          e.preventDefault();
        }
      }
    }
  }

  documentClick(target: HTMLElement) {
    this.mouseKeyboardService.removeFocus();
    this.mouseKeyboardService.removeHover();
    if (target.classList.contains('cdk-overlay-container')) {
      this.extendedDialogService.closeAll();
    } else if (this.dropdownOpen) {
      if (
        !target.closest('#nav') &&
        (target.closest('.dropdown')?.id !== this.dropdownOpen?.Id || !target.closest('.dropdown'))
      ) {
        this.dropdownService.closeDropdown();
      }
    }
    this.layoutAudioService.data$.pipe(take(1)).subscribe((data) => {
      if (data.Full?.Enabled && !target.closest('.audio-element.full')) {
        this.layoutAudioService.resetFull();
      }
      if (data.Partial && !target.closest('.audio-element.partial')) {
        this.layoutAudioService.removePartial();
      }
    });
  }

  private handleDropdown(e: KeyboardEvent, target: HTMLElement) {
    if (e.key === 'Escape') {
      e.stopPropagation();
      this.dropdownService.closeDropdown();
    } else if (
      this.isArrowDown(e.key) ||
      this.isArrowLeft(e.key) ||
      this.isArrowRight(e.key) ||
      this.isArrowUp(e.key)
    ) {
      if (target.closest('input[type="text"]') && (this.isArrowLeft(e.key) || this.isArrowRight(e.key))) {
        // allow to go left/right in text input
      } else {
        e.preventDefault();
        if (!(target.nodeName.toLowerCase() === 'input' && target.getAttribute('type') === 'text')) {
          this.mouseKeyboardService.removeFocus();
        }
        const dropdown = this.dropdownOpen?.Id ? document.getElementById(this.dropdownOpen.Id) : undefined;
        const container = dropdown ? dropdown : target.closest('portal-library-search');
        if (container) {
          const items = container.querySelector('.dropdown-content')?.querySelectorAll(this.validElements);

          if (items) {
            if (this.isArrowDown(e.key) || this.isArrowRight(e.key)) {
              if (
                this.hover === undefined ||
                (this.hover && this.hover.closest('.toggle-dropdown')) ||
                (target.closest('.toggle-dropdown') &&
                  (!this.hover || (this.hover && this.hover.closest('.toggle-dropdown'))))
              ) {
                if (items?.length) {
                  const element = items[0] as HTMLElement;
                  this.mouseKeyboardService.addHover(element, true);
                }
              } else {
                const element = this.mouseKeyboardService.selectNext(Array.from(items) as HTMLElement[], true);

                if (element) {
                  this.mouseKeyboardService.addHover(element, true);
                }
              }
            } else if (this.isArrowUp(e.key) || this.isArrowLeft(e.key)) {
              const element = this.mouseKeyboardService.selectPrev(Array.from(items) as HTMLElement[], false);

              if (element) {
                this.mouseKeyboardService.addHover(element, true);
              } else {
                const button = container.querySelector<HTMLElement>('.toggle-dropdown');
                if (button) {
                  this.mouseKeyboardService.addHover(button, true);
                }
              }
            }
          }
        }
      }
    }
  }

  private handleArrow(e: KeyboardEvent, container: HTMLElement) {
    if (this.isArrowDown(e.key) || this.isArrowLeft(e.key) || this.isArrowRight(e.key) || this.isArrowUp(e.key)) {
      e.preventDefault();

      this.mouseKeyboardService.removeFocus();

      let element: HTMLElement | undefined;
      const items = container.querySelectorAll(this.validElements);
      if (items) {
        if (this.isArrowDown(e.key) || this.isArrowRight(e.key)) {
          element = this.mouseKeyboardService.selectNext(Array.from(items) as HTMLElement[], true);
        } else if (this.isArrowUp(e.key) || this.isArrowLeft(e.key)) {
          element = this.mouseKeyboardService.selectPrev(Array.from(items) as HTMLElement[], true);
        }
      }

      if (element) {
        this.mouseKeyboardService.addHover(element);
      }
    }
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
    this.dropDownSubscription?.unsubscribe();
  }

  private fixTabOffset(target: HTMLElement) {
    if (this.header) {
      const correctDistanceFromTop = this.header.clientHeight + 10;
      const top = target.getBoundingClientRect().top - correctDistanceFromTop - 10;
      if (top < 0) {
        window.scrollBy(0, top);
      }
    }
  }

  private isArrowDown(key: string) {
    return key === 'ArrowDown';
  }

  private isArrowUp(key: string) {
    return key === 'ArrowUp';
  }

  private isArrowLeft(key: string) {
    return key === 'ArrowLeft';
  }

  private isArrowRight(key: string) {
    return key === 'ArrowRight';
  }
}
