import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { zIndexFeatureKey } from './z-index.reducers';
import { ZIndexState } from './z-index.state';

export const selectZIndexState = createFeatureSelector<ZIndexState>(zIndexFeatureKey);

export const selectConfig = createSelector(selectZIndexState, (state) => state.config);

export const selectCurrentZIndex = createSelector(selectZIndexState, (state) => state.currentZIndex);

export const getItemById = (
  id: string
): MemoizedSelector<object, number | undefined, DefaultProjectorFn<number | undefined>> =>
  createSelector(selectZIndexState, (state) => {
    return state.items.find((x) => x.id === id)?.zIndex;
  });
