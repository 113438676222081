import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from '@lru/felib';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { IBookSelectedImpression } from '@portal-app/interfaces/book/project/book-impression.interface';
import { INextImpressionTag } from '@portal-app/interfaces/next/next-tag.interface';
import { IBookTile_template } from '@portal-app/interfaces/project/section.interface';
import { BookImpressionMapper } from '@portal-app/mapper/book/book-impression.mapper';
import { BookImpressionService } from '@portal-app/services/book/book-impression.service';

import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './select-impressions-modal.component.html',
  styleUrls: ['./select-impressions-modal.component.scss'],
})
export class SelectImpressionsModalComponent implements OnInit {
  item: IBookTile_template;
  impressions?: IBookSelectedImpression[];
  total = 3;
  remaining?: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBookTile_template,
    private dialogRef: MatDialogRef<SelectImpressionsModalComponent>,
    private router: Router,
    private bookImpressionService: BookImpressionService,
    private bookImpressionMapper: BookImpressionMapper,
    private gtmService: GoogleTagManagerService
  ) {
    this.item = this.data;
  }

  async ngOnInit() {
    this.router.events.pipe(take(1)).subscribe(() => {
      this.dialogRef.close();
    });

    const impressions = await firstValueFrom(this.bookImpressionService.getImpressions());

    if (this.item.Id) {
      const observable = this.bookImpressionService.fetchAndUpdate(this.item.Id);
      let selected: INextImpressionTag[] | undefined;

      if (observable) {
        selected = await firstValueFrom(observable);
        this.impressions = this.bookImpressionMapper.mapSelectImpressionsFromApi(impressions, selected);
      }
    }

    this.setRemaining();
  }

  setRemaining() {
    if (this.impressions?.length) {
      this.remaining = this.total - this.impressions.filter((element) => element.Selected).length;
    }
  }

  async checkImpression(event: Event, impression: IBookSelectedImpression) {
    const target = event.target as HTMLInputElement;

    if (target.checked === true) {
      if (this.remaining && this.item.Id) {
        impression.Selected = true;
        await firstValueFrom(this.bookImpressionService.addSelectedImpressions(this.item.Id, impression.Slug || ''));

        this.gtmService.pushTagWithUserType(GTMEvent.Overview_readbook_recommendation_tag, {
          book: this.item.Title,
          tag: impression.Title,
        });
      } else {
        target.checked = false;
        impression.Selected = false;
      }
    } else if (this.item.Id) {
      impression.Selected = false;
      await firstValueFrom(this.bookImpressionService.removeSelectedImpressions(this.item.Id, impression.Slug || ''));
      this.gtmService.pushTagWithUserType(GTMEvent.Overview_readbook_recommendation_tag_remove, {
        book: this.item.Title,
        tag: impression.Title,
      });
    }
    this.setRemaining();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
