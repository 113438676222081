<div *ngIf="isTeacher">
  <button
    [matMenuTriggerFor]="booktile"
    class="btn square action-button"
    (menuOpened)="menuOpened(); initializeBookCollections()"
    (menuClosed)="menuClosed()"
  >
    <i class="icon icon-20" [inlineSVG]="'plus-circle.svg'"></i>
  </button>
  <mat-menu #booktile="matMenu" xPosition="before" backdropClass="light">
    <ul>
      <li *ngIf="headerText" class="sub-item">
        <h4 class="header">{{ headerText }}</h4>
      </li>
      <li
        (click)="onClickCreateCollection()"
        class="sub-item selectable create-collection"
        [routerLink]="'/' + PageTypeEnum.BookCollection + '/add/' + id"
      >
        <i class="icon icon-20" [inlineSVG]="'plus-circle.svg'"></i>
        <span>{{ 'ManageBookCollection.CreateNewBookbox' | translate }}</span>
      </li>
      <li
        *ngFor="let collection of collections"
        (click)="collection.Selected ? onRemoveFromCollectionClick(collection) : onAddToCollectionClick(collection)"
      >
        <portal-checkbox class="sub-item selectable" [checked]="collection.Selected" style="pointer-events: none">
          <span>{{ collection.Title }}</span>
        </portal-checkbox>
      </li>
    </ul>
  </mat-menu>
</div>
