import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 25, ellipsis = '...') {
    const cleanText = this.strip(value);
    // split on characters
    return cleanText.length > limit ? cleanText.substring(0, limit) + ellipsis : cleanText;
    // split on words
    // return cleanText.split(' ').length  > limit ? cleanText.split(' ').splice(0, limit).join(' ') + ellipsis : cleanText;
  }

  strip(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
}
