import { Injectable } from '@angular/core';
import { IBookSelectedImpression } from '@portal-app/interfaces/book/project/book-impression.interface';
import { INextImpressionTag, INextImpressionTag2 } from '@portal-app/interfaces/next/next-tag.interface';

@Injectable({
  providedIn: 'root',
})
export class BookImpressionMapper {
  mapSelectImpressionsFromApi(all: INextImpressionTag[], selected: INextImpressionTag[]): IBookSelectedImpression[] {
    const result: IBookSelectedImpression[] = [];
    for (const item of all) {
      result.push({
        Slug: item.Slug,
        Title: item.Title,
        Icon: item.Icon,
        Selected: selected?.some((element) => element.Slug === item.Slug) || false,
      });
    }

    return result;
  }

  mapList(impressions2?: INextImpressionTag2[]) {
    return impressions2?.map((x) => this.map(x));
  }

  map(impression2: INextImpressionTag2): INextImpressionTag {
    return {
      Id: impression2.id,
      Title: impression2.title,
      Slug: impression2.slug,
      Icon: impression2.icon,
    };
  }
}
