<ng-container *ngIf="megaMenu">
  <div class="flex column space-between left">
    <ul class="menu">
      <li *ngFor="let tab of megaMenu.Tabs">
        <button
          class="menu-item"
          [class.selected]="tab === megaMenu.CurrentTab"
          (click)="changeTab(tab)"
          [class.open]="tab === megaMenu.ActivelyOpenedTab"
        >
          <ng-container
            [ngTemplateOutlet]="title"
            [ngTemplateOutletContext]="{
              title: tab.Title,
              label: tab.Label
            }"
          ></ng-container>
          <i class="icon icon-24" [inlineSVG]="'mega-menu-arrow.svg'"></i>
        </button>
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ items: tab.Items }"></ng-container>
      </li>
    </ul>
    <a class="menu-item" *ngIf="megaMenu.Link" href="{{ megaMenu.Link.Url }}" target="_blank" rel="noopener">
      <span>{{ megaMenu.Link.Title }}</span>
      <i class="icon icon-24" [inlineSVG]="'outside-link.svg'"></i>
    </a>
  </div>
  <div class="right">
    <ng-container *ngFor="let tab of megaMenu.Tabs">
      <ng-container *ngIf="tab === megaMenu.CurrentTab">
        <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ items: tab.Items }"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #content let-items="items">
  <div class="content flex wrap">
    <ng-container *ngFor="let item of items">
      <a
        *ngIf="!item.Icon"
        data-theme="text"
        href="{{ item.Url }}"
        target="{{ item.Target }}"
        rel="noopener"
        [class]="item.Hover ? item.Hover.ClassName : ''"
      >
        <ng-container
          [ngTemplateOutlet]="title"
          [ngTemplateOutletContext]="{
            title: item.Title,
            label: item.Label
          }"
        ></ng-container>
      </a>
      <div *ngIf="item.Icon" data-theme="icon">
        <i class="icon" [style.background-image]="item.Icon | umbracoImage : 32 : 32 : 'crop' | backgroundImage"></i>
        <a href="{{ item.Url }}" target="_blank" rel="noopener">
          <ng-container
            [ngTemplateOutlet]="title"
            [ngTemplateOutletContext]="{
              title: item.Title,
              label: item.Label,
              class: 'headline-5'
            }"
          ></ng-container>
          <div class="rte font-size-16" *ngIf="item.Description" [innerHtml]="item.Description"></div>
        </a>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #title let-title="title" let-label="label" let-class="class">
  <span class="flex space-2 wrap vertical-center">
    <label class="{{ class }}">{{ title }}</label>
    <span>
      <span class="flex space-1 wrap" *ngIf="label">
        <label class="badge">{{ label }}</label>
      </span>
    </span>
  </span>
</ng-template>
