import { Injectable } from '@angular/core';
import { UserService } from '@lru/felib';
import { IItemBase } from '@portal-app/interfaces/course/project/course-tabs.interface';
import { CourseFavoriteApiService } from '@portal-app/services/course/course-favorite-api.service';
import { EMPTY, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CourseFavoriteMapper {
  constructor(private courseFavoriteApiService: CourseFavoriteApiService, private userService: UserService) {}

  setTextsFavorite(items: IItemBase[]) {
    if (this.userService.isLoggedIn && items.length) {
      const ids = items.map((item) => item.Id);
      this.courseFavoriteApiService
        .getFavoriteTextsByIds(ids)
        .pipe(
          catchError(() => {
            items.map((item) => (item.FavoriteLoaded = true));
            return EMPTY;
          })
        )
        .subscribe((data) => {
          for (const item of items) {
            item.IsFavorite = data.find((elem) => elem.Id === item.Id)?.IsFavorite;
            item.FavoriteLoaded = true;
          }
        });
    }
  }

  setTasksFavorite(items: IItemBase[]) {
    if (this.userService.isLoggedIn && items.length) {
      const ids = items.map((item) => item.Id);
      this.courseFavoriteApiService
        .getFavoriteTasksByIds(ids)
        .pipe(
          catchError(() => {
            items.map((item) => (item.FavoriteLoaded = true));
            return EMPTY;
          })
        )
        .subscribe((data) => {
          for (const item of items) {
            item.IsFavorite = data.find((elem) => elem.Id === item.Id)?.IsFavorite;
            item.FavoriteLoaded = true;
          }
        });
    }
  }

  setCoursesFavorite(items: IItemBase[]) {
    return this.setFavorite(items, 'course');
  }

  setActivitiesFavorite(items: IItemBase[]) {
    return this.setFavorite(items, 'activity');
  }

  private setFavorite(items: IItemBase[], type: 'activity' | 'course') {
    if (this.userService.isLoggedIn && items.length) {
      const ids = items.map((item) => item.Id);

      const request =
        type === 'activity'
          ? this.courseFavoriteApiService.getFavoriteActivitiesByIds(ids)
          : this.courseFavoriteApiService.getFavoriteCourseByIds(ids);

      return request.pipe(
        tap((favorites) => {
          for (const item of items) {
            item.IsFavorite = favorites.find((elem) => elem.Id === item.Id)?.IsFavorite;
            item.FavoriteLoaded = true;
          }
        }),
        catchError(() => {
          items.forEach((item) => (item.FavoriteLoaded = true));
          return of(false);
        }),
        map(() => true)
      );
    }

    return of(false);
  }
}
