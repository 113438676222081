import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ToastMessageInstance } from '../../interfaces/toast-message-instance.interface';
import { ToastService } from '../../services/toast.service';

/** Individual toast message */
@Component({
  selector: 'lru-toast-message[message]',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastMessageComponent {
  /** Toast message instance */
  @Input() message!: ToastMessageInstance;

  /** Makes it possible to add styling for different states like for example "success" | "info" */
  @HostBinding('attr.data-type')
  public get toastType(): string {
    return this.message.type;
  }

  /** @ignore */
  constructor(private toastService: ToastService) {}

  /** Close toast popup */
  public onDismissClick(): void {
    this.toastService.removeMessage(this.message.id);
  }
}
