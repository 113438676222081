<button
  *ngIf="userService.isLoggedIn"
  class="btn square action-button"
  [class.favorited]="isFavorite"
  [class.filled]="isFavorite"
  [class]="className"
  (click)="onFavoriteClick()"
>
  <span>
    <i class="icon icon-18" [inlineSVG]="'heart-combined.svg'" [class.favorite]="isFavorite"></i>
  </span>
</button>
